import * as React from "react";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";

export default function Account({ signingOut, authToken }) {
  const navigate = useNavigate();
  const handleClick = (event) => {
    let path = `/myaccount/profile/`;
    navigate(path);
  };

  return (
    <>
      <div>
        <div
          className="cursor-pointer mr-2 lg:mr-6"
          id="basic-button"
          onClick={handleClick}
        >
          <span className="hidden md:block"><PersonIcon fontSize="large" /></span>
          <span className="md:hidden"><PersonIcon fontSize="medium" /></span>
        </div>
      </div>
    </>
  );
}
