import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ProductProvider from "./contexts/ProductContext";
import SidebarProvider from "./contexts/SidebarContext";
import CartProvider from "./contexts/CartContext";
import AuthProvider from "./contexts/AuthContext";
import OrderProvider from "./contexts/OrderContext";
import RecoveryProvider from "./contexts/RecoveryContext";
import CategoryProvider from "./contexts/CategoryContext";
import WishListProvider from "./contexts/WishlistContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthProvider>
    <SidebarProvider>
      <ProductProvider>
        <OrderProvider>
          <RecoveryProvider>
            <CategoryProvider>
              <CartProvider>
                <WishListProvider>
                  <React.StrictMode>
                    <App />
                  </React.StrictMode>
                </WishListProvider>
              </CartProvider>
            </CategoryProvider>
          </RecoveryProvider>
        </OrderProvider>
      </ProductProvider>
    </SidebarProvider>
  </AuthProvider>
);
