import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { AuthContext } from "../contexts/AuthContext";
import FavoriteIcon from '@mui/icons-material/Favorite';
import SearchIcon from '@mui/icons-material/Search';
import { WishlistContext } from "../contexts/WishlistContext";

const Product = ({ product }) => {
  const { id, img, category, title, price, qty } = product;
  const { addToCart } = useContext(CartContext);
  const { addToWishList } = useContext(WishlistContext);
  const navigate = useNavigate();
  const { token, getAuthToken } = useContext(AuthContext);

  const imgBaseUrl = 'https://firebasestorage.googleapis.com/v0/b/ostore-86bf8.appspot.com/o/';

  useEffect(() => {
    getAuthToken();
  }, [token]);

  const navigateToProductDetails = () => {
    let path = `/product/${id}`;
    navigate(path);
  }

  function TextAbstract(text, length) {
    if (text == null) {
      return "";
    }
    if (text.length <= length) {
      return text;
    }
    text = text.substring(0, length);
    var last = text.lastIndexOf(" ");
    text = text.substring(0, last);
    return text + "...";
  }

  return (
    <div >
      <div class=" flex flex-col justify-between bg-white shadow rounded overflow-hidden group h-[340px] md:h-[380px]">
        <div className="">
          <div className="relative mx-auto">
            <img
              className={`mx-auto h-[200px]  ${qty <= 0 && "opacity-60"}`}
              src={`${imgBaseUrl + img[0].replace(/\//g, '%2F') + '?alt=media'}`}
              alt=""
            ></img>
            <div class="absolute inset-0 bg-black bg-opacity-20 flex items-center 
                    justify-center gap-2 opacity-0 group-hover:opacity-100 transition">
              <Link to={`/product/${id}`}>
                <p
                  class="text-white text-lg w-9 h-8 rounded-full bg-blue-600 flex items-center justify-center hover:bg-gray-400 transition"
                  title="view product">
                  <SearchIcon />
                </p>
              </Link>
              <p onClick={() => {
                if (token) {
                  addToWishList(product, product.id);
                } else {
                  navigate("/myaccount");
                }
              }}
                class="text-white text-lg w-9 h-8 rounded-full bg-blue-600 flex items-center justify-center hover:bg-gray-400 transition cursor-pointer"
                title="add to wishlist">
                <FavoriteIcon />
              </p>
            </div>
          </div>
          <div>
            <div class="pt-2 md:pt-4 md:pb-3 px-4">
              <div className={"text-xs md:text-sm capitalize text-gray-500 mb-1"}>{category}</div>
              <a href="#">
                <Link to={`/product/${id}`}>
                  <h2 class="uppercase text-xs md:text-sm  font-medium  mb-2 text-blue-900 hover:text-primary transition">{TextAbstract(title, 30)}</h2>
                </Link>

              </a>
              <div class="flex items-baseline mb-1 space-x-2">
                <p class="text-sm md:text-xl text-primary font-semibold">LKR {price.toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>
        {qty > 0 ? <button
          onClick={() => {
            if (token) {
              addToCart(product, product.id, 1);
            } else {
              navigate("/myaccount");
            }
          }}
          className="block w-full text-sm py-1 text-center text-white bg-blue-600 border uppercase border-blue-600 rounded-b hover:bg-transparent hover:text-blue-600 transition"
        >
          Add to cart
        </button> : <div
          className="bg-red-500 text-sm cursor-default py-1 text-center text-white w-full opacity-60 uppercase transitio-all duration-300"
        >
          Out of Stock
        </div>}
      </div>
    </div>
  );
};

export default Product;
