import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import { CategoryContext } from "../../contexts/CategoryContext";
import { Link, useNavigate } from "react-router-dom";

export default function MenuDrawer() {
  const anchor = "left";
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [categoryList, setCategoryList] = useState([]);
  const { categories, loading } = useContext(CategoryContext);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    setCategoryList(categories);
  }, [loading]);

  const navigate = useNavigate();

  const navigateToCategory = (category) => {
    let path = `/category/${category.replace(/ /g, "-")}`;
    navigate(path);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {categoryList.map((element, index) => {
            if(element.head){

            }else{
              return(
                <ListItem key={element.name} disablePadding>
                  <ListItemButton onClick={()=>{navigateToCategory(element.name)}}>
                    <ListItemText primary={element.name} />
                  </ListItemButton>
                </ListItem>
              )
            }
        })}
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      <React.Fragment key={anchor}>
        <div onClick={toggleDrawer(anchor, true)} className="lg:hidden mr-2">
          <MenuIcon fontSize="medium" />
        </div>
        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
