
export default function PrivacyPolicy() {

    return <div className="lg:mt-28 mt-16">
        <section className="py-10 container mx-auto md:px-12">
            <div class="border rounded-lg ">
                <div class="items-center gap-8">
                    {/* <div class="bg-cover bg-no-repeat bg-center border rounded-lg py-36" style={{ backgroundImage: `url(${logo1})` }}>
                    <div class="container mx-auto md:px-12 text-center">
                        <h1 class="text-6xl text-gray-800 font-medium mb-4 capitalize">
                            Contact Us
                        </h1>
                    </div>
                </div>
                <div className="w-full flex justify-center py-8">
                    <div className="w-1/2 text-center">
                        <p class="mt-4 text-gray-600 text-lg">Onet Market envisions becoming a leading direct selling company that offers premium lifestyle products with a strong commitment to environmental sustainability. Our mission is to provide eco-friendly solutions to our customers while maintaining a high standard of quality, at the most affordable prices!</p>
                    </div>
                </div> */}
                    <div class="w-full">
                        <section class="bg-white">
                            <div class="py-8 lg:py-16 px-4 mx-auto">
                                <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900">Privacy policy</h2>
                                <div className="flex justify-center w-full mt-8">
                                    <p class="font-light text-center md:w-1/2 text-gray-500 sm:text-lg">1. We collect personal information such as name, email address, shipping address, and payment details during the checkout process.</p>
                                </div>
                                <div className="flex justify-center w-full">
                                    <p class="mt-8 font-light text-center md:w-1/2 text-gray-500 sm:text-lg">2. Personal information is used to process orders, provide customer support, and enhance your shopping experience.</p>
                                </div>
                                <div className="flex justify-center w-full">
                                    <p class="mt-8 font-light text-center md:w-1/2 text-gray-500 sm:text-lg">3. We may use third-party services, such as payment processors and shipping partners, to fulfill orders. These entities have their own privacy policies, and we encourage you to review them.</p>
                                </div>
                                <div className="flex justify-center w-full">
                                    <p class="mt-8 font-light text-center md:w-1/2 text-gray-500 sm:text-lg">4. Personal information may be shared with trusted partners and service providers for order fulfillment and customer support purposes.</p>
                                </div>
                                <div className="flex justify-center w-full">
                                    <p class="mt-8 font-light text-center md:w-1/2 text-gray-500 sm:text-lg">4. We prioritize the security of your payment information and employ industry-standard encryption technologies to safeguard transactions.</p>
                                </div>
                                <div className="flex justify-center w-full">
                                    <p class="mt-8 font-light text-center md:w-1/2 text-gray-500 sm:text-lg">5. This privacy policy is effective as of 01/01/2023 and applies to all information collected from you on or after this date.</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    </div>
}