import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import API from "../components/api";
import { useForm } from "react-hook-form";
import { AuthContext } from "../contexts/AuthContext";
import { RecoveryContext } from "../contexts/RecoveryContext";
import Select from "react-select";
import * as Bank from "../components/constants";

function CusRegister() {
  const hCategory = "MY ACCOUNT";
  const { register, handleSubmit } = useForm();
  const { register: login, handleSubmit: handleLogin } = useForm();
  const { setToken } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const { setPage } = useContext(RecoveryContext);
  const Swal = require("sweetalert2");
  const [wait, setWait] = useState(false);
  const navigate = useNavigate();

  function validatePassword(p) {
    if (p.length < 8) {
      return "Your password must be at least 8 characters.";
    }
    if (p.search(/[a-z]/i) < 0) {
      return "Your password must contain at least one letter.";
    }
    if (p.search(/[0-9]/) < 0) {
      return "Your password must contain at least one digit.";
    }
    return "";
  }

  const onUserRegister = (data) => {
    setWait(true);
    if (data.password === data.repassword) {
      if (!validatePassword(data.password)) {
        const newData = {
          ...data,
          email: data.email.toLowerCase(),
        };
        API.post("/auth/register", newData)
          .then(function (res) {
            if (res.data.data) {
              localStorage.setItem("user_token", JSON.stringify(res.data.data));
              setToken(res.data.data);
              let path = `/`;
              navigate(path);
              // Swal.fire({
              //   icon: "success",
              //   title: "REGISTRATION",
              //   text: "Registered succesfully.",
              //   confirmButtonColor: "#2563eb",
              // });
            } else {
              setWait(false);
              Swal.fire({
                icon: "error",
                title: "REGISTRATION",
                text: "Oops.. something went wrong.",
                confirmButtonColor: "#2563eb",
              });
            }
          })
          .catch(function (error) {
            if (error.response.data.message === "Email is already exist") {
              setWait(false);
              Swal.fire({
                icon: "error",
                title: "REGISTRATION",
                text: "This email is already in use.",
                confirmButtonColor: "#2563eb",
              });
            } else {
              setWait(false);
              Swal.fire({
                icon: "error",
                title: "REGISTRATION",
                text: "Oops.. something went wrong.",
                confirmButtonColor: "#2563eb",
              });
            }
          });
      } else {
        setWait(false);
        Swal.fire({
          icon: "error",
          title: "REGISTRATION",
          text: validatePassword(data.password),
          confirmButtonColor: "#2563eb",
        });
      }
    } else {
      setWait(false);
      Swal.fire({
        icon: "error",
        title: "REGISTRATION",
        text: "Passwords do not match",
        confirmButtonColor: "#2563eb",
      });
    }
  };

  return (
    <div>
      <section class="lg:mt-28 mt-16">
        <div className="py-3 bg-gray-400 md:text-lg text-sm text-white">
          <div className="container items-center mx-auto md:px-12 uppercase text-sm md:text-base font-medium">
            <Link to={"/"}>
              <span className="mr-1 hover:underline cursor-pointer">HOME</span>
            </Link>{" "}
            {hCategory && " > "} <span className="mr-1 ml-1">REGISTRATION</span>
          </div>
        </div>
        <hr />
        <div className="md:py-6 mt-4 container mx-auto md:px-12">
          <div class="w-full">
            <div class="space-y-4 md:space-y-6 lg:pr-28 mt-12 md:mt-0">
              <h1 class="text-3xl font-bold leading-tight tracking-tight">
                REGISTRATION DETAILS
              </h1>
              <hr />
              <form
                class="space-y-4 md:space-y-6 md:w-8/12 w-full"
                onSubmit={handleSubmit(onUserRegister)}
              >
                <div>
                  <label for="firstName" class="block mb-2 text-sm font-medium">
                    First Name
                  </label>
                  <input
                    {...register("firstName")}
                    type="firstName"
                    name="firstName"
                    id="firstName"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div>
                  <label for="lastName" class="block mb-2 text-sm font-medium">
                    Last Name
                  </label>
                  <input
                    {...register("lastName")}
                    type="lastName"
                    name="lastName"
                    id="lastName"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div>
                  <label for="email" class="block mb-2 text-sm font-medium">
                    Email
                  </label>
                  <input
                    {...register("email")}
                    type="email"
                    name="email"
                    id="email"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>

                <div>
                  <label for="password" class="block mb-2 text-sm font-medium">
                    Password
                  </label>
                  <input
                    {...register("password")}
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div className="text-sm text-gray-400">
                  * Your password must be at least 8 characters.
                  <br />* Your password must contain at least one letter.
                  <br />* Your password must contain at least one digit.
                </div>
                <div>
                  <label
                    for="repassword"
                    class="block mb-2 text-sm font-medium"
                  >
                    Confirm Password
                  </label>
                  <input
                    {...register("repassword")}
                    type="password"
                    name="repassword"
                    id="repassword"
                    placeholder="••••••••"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div class="flex items-start">
                  <div class="flex items-center h-5">
                    <input
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "You need to agree to the terms and conditions before proceeding."
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      id="terms"
                      aria-describedby="terms"
                      type="checkbox"
                      class="w-4 h-4 borderfocus:ring-3 focus:ring-blue-600"
                      required
                    />
                  </div>
                  <div>
                    {errorMessage && (
                      <p className="error -mt-4 text-red-400">
                        {" "}
                        {errorMessage}{" "}
                      </p>
                    )}
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="terms" class="font-light">
                      I accept the terms and practices outlined in the{" "}
                      <Link
                        class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                        to={"/privacy-policy"}
                      >
                        privacy policy.
                      </Link>
                    </label>
                  </div>
                </div>
                {wait ? (
                  <button
                    type="submit"
                    disabled={true}
                    class="w-1/2 bg-blue-400 hover:bg-blue-400 cursor-not-allowed text-white hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Register
                  </button>
                ) : (
                  <button
                    type="submit"
                    class="w-1/2 bg-blue-600 hover:bg-blue-600 text-white hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Register
                  </button>
                )}
                <div className="h-5"></div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CusRegister;
