
export default function ReturnPolicy() {

    return <div className="lg:mt-28 mt-16">
        <section className="py-10 container mx-auto md:px-12">
            <div class="border rounded-lg ">
                <div class="items-center gap-8">
                    {/* <div class="bg-cover bg-no-repeat bg-center border rounded-lg py-36" style={{ backgroundImage: `url(${logo1})` }}>
                    <div class="container mx-auto md:px-12 text-center">
                        <h1 class="text-6xl text-gray-800 font-medium mb-4 capitalize">
                            Contact Us
                        </h1>
                    </div>
                </div>
                <div className="w-full flex justify-center py-8">
                    <div className="w-1/2 text-center">
                        <p class="mt-4 text-gray-600 text-lg">Onet Market envisions becoming a leading direct selling company that offers premium lifestyle products with a strong commitment to environmental sustainability. Our mission is to provide eco-friendly solutions to our customers while maintaining a high standard of quality, at the most affordable prices!</p>
                    </div>
                </div> */}
                    <div class="w-full">
                        <section class="bg-white">
                            <div class="py-8 lg:py-16 px-4 mx-auto">
                                <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900">Return and exchange policy</h2>
                                <div className="flex justify-center w-full mt-8">
                                    <p class="font-light text-center md:w-1/2 text-gray-500 sm:text-lg">1. Once The Product Has Been Purchased, And Collected By, Or Delivered To the Consumer, Returns Will Not Be Accepted By Onet Market. As A Strict Policy, Product Return Or Exchange Requests Will Not Be Accommodated For A Consumer’s Unmatched Expectations, Or Change Of Mind, Unless There Is A Valid Defect With The Device, For Which Warranty Can Be Claimed For.</p>
                                </div>
                                <div className="flex justify-center w-full">
                                    <p class="mt-8 font-light text-center md:w-1/2 text-gray-500 sm:text-lg">2. Refunds Are Not Applicable.</p>
                                </div>
                                <div className="flex justify-center w-full">
                                    <p class="mt-8 font-light text-center md:w-1/2 text-gray-500 sm:text-lg">3. Should You Choose To Purchase Any Product Via The Onet Market
                                        Website Or Store, You Agree To Accept The Above Terms And Conditions Of This Limited Warranty.</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    </div>
}