import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import API from "../components/api";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LogoutIcon from '@mui/icons-material/Logout';
import ProfileInfo from "../components/Account/ProfileInfo";
import Address from "../components/Account/Address";
import ResetPassword from "../components/Account/ResetPassword";
import MyOrders from "../components/Account/MyOrders";
import MyWishlist from "../components/Account/MyWishlist";
import PaidIcon from '@mui/icons-material/Paid';
import MyEarnings from "../components/Account/MyEarnings";
import OrderDetails from "../components/Account/OrderDetails";

function Profile() {
  const { token, loading } = useContext(AuthContext);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [page, setPage] = useState('profile');
  const { pathname } = useLocation();

  useEffect(() => {
    if (!loading) {
      if (!token) {
        navigate("/");
      } else {
        setUser(token);
      }
    }
  }, [token, loading]);

  useEffect(() => {
    let arr = pathname.split('/');
    if(arr[3]){
      if (arr[3] === "profile") {
        setPage('profile');
      } else if (arr[3] === "info") {
        setPage('profile');
      } else if (arr[3] === "address") {
        setPage('address');
      } else if (arr[3] === "password") {
        setPage('password');
      } else if (arr[3] === "wishlist") {
        setPage('wishlist');
      } else if (arr[3] === "orders") {
          if(arr[5]){
            setPage('order');
          }else{
            setPage('orders');
          }
      } else if (arr[3] === "earnings") {
        if (user != null && user.role === "user") {
          setPage('earnings');
        }
      }
    }
  }, [pathname]);


  const renderPage = (param, order) => {
    switch (param) {
      case 'profile':
        return <ProfileInfo />
      case 'address':
        return <Address />
      case 'password':
        return <ResetPassword />
      case 'orders':
        return <MyOrders viewOrder={viewOrder} />
      case 'wishlist':
        return <MyWishlist />
      case 'earnings':
        return <MyEarnings />
      case 'order':
        return <OrderDetails />
      default:
        return 'foo';
    }
  }

  const viewOrder = (order) => {
    navigate(`/myaccount/profile/orders/order/${order}`);
    setPage('order');
  };

  return (
    <div className="lg:mt-28 mt-16">
      <div className="py-3 bg-gray-400 md:text-lg text-sm text-white">
        <div className="container mx-auto md:px-12 uppercase text-sm md:text-base font-medium">
          <Link to={"/"}>
            <span className="mr-1 hover:underline cursor-pointer">HOME</span>
          </Link>{" "}
          {" > "} <span className="mr-1 ml-1">My Account</span>
          {" > "} <span className="mr-1 ml-1">My Profile</span>
        </div>
      </div>
      <div class="container mx-auto md:px-12 md:grid md:grid-cols-12 items-start gap-6 pt-4 pb-16">


        <div class="col-span-3">
          <div class="px-4 py-3 shadow flex items-center gap-4">
            <div class="flex-shrink-0">
              <AccountCircleIcon fontSize="large" color="primary" />
            </div>
            <div class="flex-grow">
              <p class="text-gray-600">Hello,</p>
              <h4 class="text-gray-800 font-medium">{user != null ? user.firstName + ' ' + user.lastName : "User"}</h4>
            </div>
          </div>

          <div class="mt-6 bg-white shadow rounded p-4 divide-y divide-gray-200 space-y-4 text-gray-600">
            <div class="space-y-1 pl-8">
              <p class="relative hover:text-primary block capitalize transition cursor-pointer">
                <span class="absolute -left-8 top-0 text-base">
                  <FolderSharedIcon color="primary" fontSize="small" />
                </span>
                Manage account
              </p>
              <p onClick={() => {
                navigate(`/myaccount/profile/info`);
                setPage('profile')
              }} class="relative hover:text-primary block capitalize transition cursor-pointer">
                Profile information
              </p>
              <p onClick={() => {
                navigate(`/myaccount/profile/address`);
                setPage('address')
              }} class="relative hover:text-primary block capitalize transition cursor-pointer">

                Manage addresses
              </p>
              <p onClick={() => {
                navigate(`/myaccount/profile/password`);
                setPage('password')
              }} class="relative hover:text-primary block capitalize transition cursor-pointer">
                Change password
              </p>
            </div>
            <div class="space-y-1 pl-8 pt-4">
              <p onClick={() => {
                navigate(`/myaccount/profile/orders`);
                setPage("orders")
              }} class="relative hover:text-primary block font-medium capitalize transition cursor-pointer">
                <span class="absolute -left-8 top-0 text-base">
                  <ShoppingCartIcon color="primary" fontSize="small" />
                </span>
                My order history
              </p>
              <p onClick={() => {
                navigate(`/myaccount/profile/orders/unpaid`);
                setPage("orders")
              }} class="relative hover:text-primary block capitalize transition cursor-pointer">
                Awaiting payments
              </p>
              <p onClick={() => {
                navigate(`/myaccount/profile/orders/processing`);
                setPage("orders")
              }} class="relative hover:text-primary block capitalize transition cursor-pointer">
                Processing
              </p>
              <p onClick={() => {
                navigate(`/myaccount/profile/orders/shipped`);
                setPage("orders")
              }} class="relative hover:text-primary block capitalize transition cursor-pointer">
                Shipped
              </p>
              <p onClick={() => {
                navigate(`/myaccount/profile/orders/completed`);
                setPage("orders")
              }} class="relative hover:text-primary block capitalize transition cursor-pointer">
                Completed
              </p>
              <p onClick={() => {
                navigate(`/myaccount/profile/orders/cancel`);
                setPage("orders")
              }} class="relative hover:text-primary block capitalize transition cursor-pointer">
                Cancelled
              </p>
            </div>
            {
              user != null && user.role === "user" ? <div class="space-y-1 pl-8 pt-4">
                <p onClick={() => {
                  navigate(`/myaccount/profile/earnings`);
                  setPage("earnings")
                }} class="relative hover:text-primary block font-medium capitalize transition cursor-pointer">
                  <span class="absolute -left-8 top-0 text-base">
                    <PaidIcon color="primary" fontSize="small" />
                  </span>
                  My Earnings
                </p>
              </div> : <></>
            }
            <div class="space-y-1 pl-8 pt-4">
              <p onClick={() => {
                navigate(`/myaccount/profile/wishlist`);
                setPage("wishlist")
              }} class="relative hover:text-primary block font-medium capitalize transition cursor-pointer">
                <span class="absolute -left-8 top-0 text-base">
                  <FavoriteIcon color="primary" fontSize="small" />
                </span>
                My wishlist
              </p>
            </div>

            <div class="space-y-1 pl-8 pt-4">
              <p onClick={() => { navigate("/myaccount/logout") }} class="relative hover:text-primary block font-medium capitalize transition cursor-pointer">
                <span class="absolute -left-8 top-0 text-base">
                  <LogoutIcon color="primary" fontSize="small" />
                </span>
                Logout
              </p>
            </div>

          </div>
        </div>
        <div className="col-span-9">
          {
            renderPage(page)
          }
        </div>

      </div>
    </div>
  );
}

export default Profile;
