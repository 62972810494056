import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {IoMdAdd, IoMdClose, IoMdRemove} from 'react-icons/io'
import { CartContext } from '../contexts/CartContext';

const CartItem = ({ item }) => {
  const {removeFromCart, increaseAmount, decreaseAmount} = useContext(CartContext);
  const { id, title, img, price, qty } = item;
  const imgBaseUrl = 'https://firebasestorage.googleapis.com/v0/b/ostore-86bf8.appspot.com/o/';


  return <div className='flex gap-x-4 py-2 lg:px-6 border-b border-gray-200 w-full text-gray-500'>
    <div className='w-full min-h-[150px] flex items-center gap-x-4'>
      <Link to={`/product/${id}`}>
        <img src={`${imgBaseUrl + img[0].replace(/\//g, '%2F') + '?alt=media'}`}  className='max-w-[80px]' alt="image" />
      </Link>
      <div className='flex w-full flex-col'>
        <div className='flex justify-between mb-2'>
          <Link to={`/product/${id}`} className="text-sm uppercase font-medium max-w-[240px] text-primary hover:underline">
            {title}
          </Link>
          <div onClick={()=>{removeFromCart(id)}} className='text-xl cursor-pointer'>
            <IoMdClose className='text-gray-500 hover:text-red-500 transition'/>
          </div>
        </div>
        <div className='flex gap-x-2 h-[36px] text-sm'>
          <div className='flex flex-1 max-w-[100px] h-full border text-primary font-medium items-center'>
            <div onClick={()=>{decreaseAmount(id)}} className='flex-1 flex justify-center items-center cursor-pointer'>
              <IoMdRemove />
            </div>
            <div className='h-full flex justify-center items-center px-2'>{qty}</div>
            <div onClick={()=>{increaseAmount(id)}} className='flex-1 flex justify-center items-center cursor-pointer'>
              <IoMdAdd />
            </div>
          </div>
          <div className='flex-1 flex items-center justify-around'>LKR {price}</div>
          <div className='flex-1 flex justify-end items-center text-primary font-medium' >{`LKR ${parseFloat(price * qty).toFixed(2)}`}</div>
        </div>
      </div>
    </div>
  </div>;
};

export default CartItem;
