import React, { useContext, useEffect } from "react";
import Product from "../components/Product";
import Select from "react-select";
import { ProductContext } from "../contexts/ProductContext";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const CategoryDetails = () => {
  const [category, setCategory] = useState("");
  const [sCategory, setSCategory] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [page, setPage] = React.useState(1);
  const [noOfPages, setNoOfPages] = useState(2);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(12);

  const { products, loading } = useContext(ProductContext);
  const options = [
    { value: "default", label: "Sort by default" },
    { value: "latest", label: "Sort by latest" },
    { value: "lowtohigh", label: "Sort by price: low to high" },
    { value: "hightolow", label: "Sort by price: high to low" },
  ];
  let location = useLocation();

  const initialize = () => {
    if (!loading) {
      const pathname = window.location.pathname;
      var patharr = pathname.split("/");

      const fproducts = products.filter((item) => {
        if (patharr.length === 3) {
          setCategory(patharr[2].replace(/-/g, " "));
          setSCategory("");
          return item.category === patharr[2].replace(/-/g, " ");
        } else if (patharr.length === 4) {
          setCategory(patharr[2].replace(/-/g, " "));
          setSCategory(patharr[3].replace(/-/g, " "));
          return (
            item.category === patharr[2].replace(/-/g, " ") &&
            item.sCategory === patharr[3].replace(/-/g, " ")
          );
        }
      });
      setFilteredProducts(fproducts);
      var noOfPages = Math.ceil(fproducts.length/12);
      setNoOfPages(noOfPages);
      
    }
  };
  
  useEffect(() => {
    initialize();
  }, [loading, products, location]);

  if (loading) {
    return (
      <section className="h-screen flex justify-center items-center">
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </section>
    );
  }

  if (!products) {
    return (
      <section className="h-screen flex justify-center items-center">
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </section>
    );
  }

  const handleChange = (event, value) => {
    setStart(12 * (value - 1));
    setEnd(value * 12);
    setPage(value);
  };

  const handleSelect = (e) =>{
    if(e.value === "default"){
      initialize();
    }else if(e.value === "latest"){
      initialize();
      const temp = reverseArray(filteredProducts);
      setFilteredProducts(temp);
    }else if(e.value === "lowtohigh"){
      const temp = priceLowToHigh(filteredProducts);
      setFilteredProducts(temp);
    }else if(e.value === "hightolow"){
      const temp = priceHighToLow(filteredProducts);
      setFilteredProducts(temp);
    }
    setPage(1);
    setStart(0);
    setEnd(12);
  }

  const reverseArray = (array) => {
    const revArray = array.reverse();
    return [...revArray] 
  }

  const priceHighToLow = (array) => {
    const revArray = array.sort((a, b) => b.price - a.price);
    return [...revArray] 
  }
  const priceLowToHigh = (array) => {
    const revArray = array.sort((a, b) => a.price - b.price);
    return [...revArray] 
  }

  return (
    <div className="lg:mt-28 mt-16 mb-10">
      {/* <div className="bg-green-100 w-full bg-no-repeat h-auto bg-hero">
        <img className="w-full" src="https://www.glifestyle.net/-/media/sites/shared/products/uae-category-banners/homelivingbanner.ashx?h=400&w=1600&la=en&hash=6FCB3EBBCB8FFF39D4DA4AA6007211085D1B106A" />
      </div> */}
      <div className="py-3 bg-gray-400 text-sm md:text-lg text-white">
        <div className="container mx-auto md:px-12 uppercase text-sm md:text-base font-medium">
          <Link to={"/"}>
            <span className="mr-1 hover:underline cursor-pointer">HOME</span>
          </Link>{" "}
          {" > "}{" "}
          <Link to={`/category/${category.replace(/ /g, "-")}`}>
            <span className="mr-1 ml-1 hover:underline cursor-pointer">
              {category}
            </span>
          </Link>{" "}
          {sCategory && " > "}{" "}
          <Link
            to={`/category/${category.replace(/ /g, "-")}/${sCategory.replace(
              / /g,
              "-"
            )}`}
          >
            <span className="mr-1 ml-1 hover:underline cursor-pointer">
              {sCategory}
            </span>
          </Link>
        </div>
      </div>
      <div className="container mx-auto md:px-12">
        <div className="py-5 flex justify-between">
          <div className="hidden w-full md:flex text-2xl">{sCategory ? sCategory: category}</div>
          <div className="w-full md:w-96 flex items-center">
              <FilterAltIcon/>{" "} <Select className="w-full ml-2 text-sm" options={options} onChange={handleSelect} defaultValue={  { value: "default", label: "Sort by default" }}/>
            </div>
        </div>
        <hr />
      </div>
      <section className="py-8">
        <div className="container mx-auto md:px-12">
          <div
            className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-[30px]
         mx-auto"
          >
            {filteredProducts.slice(start, end).map((product) => {
              return <Product product={product} key={product.id} />;
            })}
          </div>
          <hr className="mt-12" />
          <Pagination
            className="mt-8"
            count={noOfPages}
            page={page}
            onChange={handleChange}
            color="primary"
            variant="outlined"
            shape="rounded"
          />
        </div>
      </section>
    </div>
  );
};

export default CategoryDetails;
