import React, { useContext, useEffect, useState } from "react";
import { SidebarContext } from "../../contexts/SidebarContext";
import { BsBag, BsSearch, BsPersonCircle } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { CartContext } from "../../contexts/CartContext";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Account from "./Account";
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import MenuDrawer from "./Drawer";
import Categories from "./Categories";
import PersonIcon from '@mui/icons-material/Person';
import logo from '../../img/logo.svg';
import { useLocation } from 'react-router-dom';

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const { isOpen, setIsOpen } = useContext(SidebarContext);
  const { itemAmount } = useContext(CartContext);
  const { token, getAuthToken, signOut } = useContext(AuthContext);
  const [authToken, setAuthToken] = useState(null);
  const { pathname } = useLocation();
  // const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.scrollY > 20 ? setIsActive(true) : setIsActive(false);
    });
  });

  useEffect(() => {
    if (pathname === "/myaccount/logout") {
      signingOut();
    }
  }, [pathname]);

  useEffect(() => {
    getAuthToken();

    setAuthToken(token);
  }, [token]);

  const signingOut = () => {
    signOut();
    let path = `/myaccount`;
    navigate(path);
  };

  const onUserSearch = (data) =>{
    let path = `/products/${data.search}`;
    navigate(path);
  }

  return (
    <div
      className={`${isActive ? "shadow-md bg-white" : "bg-white "
        } fixed w-full z-10 transition-all items-center`}
    >
      <nav className="hidden lg:block">
        <div className="container mx-auto px-12 bg-white py-1.5 text-sm">
          <div className="flex justify-between text-gray-600">
            <div className="flex">
              <div className="">
                support@onetmarket.com
              </div>
              <div className="text-gray-300 px-2 mt-">
                |
              </div>
              <div className="pl-2">
                +94 76 043 6884
              </div>
            </div>
            <div className="flex">
              <Link to={'/'}>
                HOME
              </Link>
              <div className="text-gray-300 px-2">
                |
              </div>
              <Link to={'/about'}>
                ABOUT US
              </Link>
              <div className="text-gray-300 px-2">
                |
              </div>
              <Link to={'/contact'}>
                CONTACT US
              </Link>
            </div>
          </div>
        </div>
      </nav>
      <hr />
      <nav class="bg-white border-gray-200 py-2 container mx-auto md:px-12">
        <div class="flex justify-between items-center mx-auto max-w-screen-xl py-2.5 w-full">
          <div class="flex items-center w-full justify-between">
            <div className="flex items-center">
              <MenuDrawer />
              <Link to={`/`}>
                <div class="flex items-center">
                  <img
                    class="mr-3 md:h-9 h-5 "
                    alt="Flowbite Logo"
                    src={logo}
                  />
                  {/* <span class="self-center text-xl font-semibold whitespace-nowrap md:block hidden">
                Onet Market
              </span> */}
                </div>
              </Link>
            </div>
            <div className="w-3/4 text-center px-12 hidden lg:block">
              <form onSubmit={handleSubmit(onUserSearch)}>
                <div class="flex">
                  <Categories />
                  <div class="relative w-full">
                    <input
                      type="text"
                      {...register("search")}
                      id="search"
                      class="block p-2.5 w-full text-sm focus:ring-transparent focus:outline-none focus:ring-0 bg-gray-50 rounded-r-lg border-l-gray-50 border border-gray-300"
                      placeholder="Search Your Products..."
                      required
                    />
                    <button
                      type="submit"
                      class="absolute top-0 right-0 p-2.5 text-sm font-medium text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800"
                    >
                      <svg
                        aria-hidden="true"
                        class="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                      <span class="sr-only">Search</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="flex items-center ">
              <div className="mt-1 cursor-pointer hidden md:flex mr-2 lg:mr-6 relative lg:hidden">
                <SearchIcon fontSize="large" />
              </div>
              <div className="mt-1 cursor-pointer md:hidden mr-2 lg:mr-6 flex relative lg:hidden">
                <SearchIcon fontSize="medium" />
              </div>
              {/* <a
              href="tel:5541251234"
              class="mr-6 text-sm font-medium text-gray-500 hover:underline"
            >
              (555) 412-1234
            </a> */}
              {authToken ? (
                <Account signingOut={signingOut} authToken={authToken} />
              ) : (
                <Link to={"/myaccount"}>
                  <p
                    href="#"
                    class="mr-2 md:mr-6 hidden md:block text-sm font-medium text-blue-600 w-28"
                  >
                    Login | Register
                  </p>
                  <p
                    href="#"
                    class="mr-2 md:mr-6 md:hidden"
                  >
                    <PersonIcon fontSize="medium" />
                  </p>
                </Link>
              )}
              <div onClick={() => {
                if (token) {
                  setIsOpen(!isOpen)
                } else {
                  navigate("/myaccount");
                }
              }}>
                <div className="cursor-pointer flex relative items-center">
                  <BsBag className="md:text-2xl text-lg" />
                  <div className="bg-red-500 absolute -right-2 -bottom-2 text-[12px] w-[18px] h-[18px] text-white rounded-full flex justify-center items-center">
                    {itemAmount}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <hr />
    </div>
  );
};

export default Navbar;

