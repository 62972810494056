import React, { useContext } from "react";
import { Link } from "react-router-dom";
import OTPInput from "../components/ForgotPassword/OTPInput";
import FPassword from "../components/ForgotPassword/FPassword";
import Reset from "../components/ForgotPassword/Reset";
import { RecoveryContext } from "../contexts/RecoveryContext";
import Login from "./Login";
import Recovered from "../components/ForgotPassword/Recovered";

const hCategory = "FORGOT PASSWORD";

function ForgotPassword() {
  const { setEmail, setPage, page, email, setOTP } = useContext(RecoveryContext);

  function NavigateComponents() {
    if (page === "login") return <FPassword />;
    if (page === "otp") return <OTPInput />;
    if (page === "reset") return <Reset />;
    if (page === "recovered") return <Recovered />;
    //] return <Recovered />;
  }

  return (
    <div>
      <section class="lg:mt-28 mt-16">
        <div className="py-3 bg-gray-400 text-sm md:text-lg text-white">
          <div className="container mx-auto md:px-12 uppercase text-sm md:text-base font-medium">
            <Link to={"/"}>
              <span className="mr-1 hover:underline cursor-pointer">HOME</span>
            </Link>
            {" > "}{" "}
            <Link to={"/myaccount"}>
              <span className="mr-1 hover:underline cursor-pointer">
                MY ACCOUNT
              </span>
            </Link>{" "}
            {hCategory && " > "} <span className="mr-1 ml-1">{hCategory}</span>
          </div>
        </div>
        <hr />
        <NavigateComponents />
      </section>
    </div>
  );
}

export default ForgotPassword;
