import React, { createContext, useEffect, useState, useContext } from 'react';
import API from "../components/api";
import { AuthContext } from './AuthContext';
export const CartContext = createContext();


const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [itemAmount, setItemAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [shippingFee, setShippingFee] = useState(0);
  const [totalDirectCommision, setTotalDirectCommision] = useState(0);
  const [totalIndirectCommision, setTotalIndirectCommision] = useState(0);
  const { token, getAuthToken } = useContext(AuthContext);

  useEffect(() => {
    if (cart) {
      const amount = cart.reduce((accu, currentItem) => {
        return accu + currentItem.qty;
      }, 0);
      const total = cart.reduce((accu, currentItem) => {
        return accu + currentItem.price * currentItem.qty;
      }, 0);
      const shipping = cart.reduce((accu, currentItem) => {
        return accu + currentItem.shippingFee * currentItem.qty;
      }, 0);
      const totDCommision = cart.reduce((accu, currentItem) => {
        return accu + currentItem.directCommision * currentItem.qty;
      }, 0);
      const totICommision = cart.reduce((accu, currentItem) => {
        return accu + currentItem.indirectCommision * currentItem.qty;
      }, 0);

      setItemAmount(amount);
      setTotal(total);
      setShippingFee(shipping);
      setTotalDirectCommision(totDCommision);
      setTotalIndirectCommision(totICommision);
    }
  }, [cart]);

  useEffect(() => {
    getAuthToken();
    if (!token) {
    }
    getCartItems();
  }, [token]);

  const addToCart = (product, id, count) => {
    const newItem = { ...product, qty: count };
    const cartItem = cart.find((item) => {
      return item.id === id;
    })
    if (cartItem) {
      const newCart = [...cart].map((item) => {
        if (item.id === id) {
          return { ...item, qty: cartItem.qty + count }
        } else {
          return item;
        }
      });
      setCart(newCart);
      if (token) {
        API.post("/api/cart/", { userId: token.userId, cart: newCart })
          .then(function (response) {

          })
          .catch(function (error) {
            console.log(error)
          });
      }
    } else {
      setCart([...cart, newItem]);
      if (token) {
        API.post("/api/cart/", { userId: token.userId, cart: [...cart, newItem] })
          .then(function (response) {

          })
          .catch(function (error) {
            console.log(error)
          });
      }
    }
  }

  const getCartItems = async () => {
    if (token) {
      API.get(`/api/cart/${token.userId}`)
        .then((res) => {
          if (res.data) {
            const newCart = res.data.data[0].cart.filter(item => {
              return item.qty !== 0;
            });
            setCart(newCart);
          }
        })
        .catch((err) => { });
    }
  }

  const removeFromCart = (id) => {
    const newCart = cart.filter(item => {
      return item.id !== id;
    });
    setCart(newCart);
    if (token) {
      API.post("/api/cart/", { userId: token.userId, cart: newCart })
        .then(function (response) {

        })
        .catch(function (error) {
          console.log(error)
        });
    }
  }

  const clearCart = () => {
    setCart([]);
    if (token) {
      API.post("/api/cart/", { userId: token.userId, cart: [] })
        .then(function (response) {

        })
        .catch(function (error) {
          console.log(error)
        });
    }
  }

  const increaseAmount = (id) => {
    const item = cart.find(item => {
      return item.id === id
    });
    addToCart(item, id, 1);
  }

  const decreaseAmount = (id) => {
    const cartItem = cart.find(item => {
      return item.id === id;
    });

    if (cartItem) {
      const newCart = cart.map(item => {
        if (item.id === id) {
          return { ...item, qty: cartItem.qty - 1 }
        } else {
          return item;
        }
      });
      setCart(newCart);
      if (token) {
        API.post("/api/cart/", { userId: token.userId, cart: newCart })
          .then(function (response) {

          })
          .catch(function (error) {
            console.log(error)
          });
      }
    }
    if (cartItem.qty < 2) {
      removeFromCart(id);
    }
  }

  return <CartContext.Provider value={{ cart, addToCart, getCartItems, removeFromCart, clearCart, increaseAmount, decreaseAmount, itemAmount, total, shippingFee, totalDirectCommision, totalIndirectCommision }}>{children}</CartContext.Provider>;
};

export default CartProvider;
