import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { OrderContext } from "../contexts/OrderContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { DataGrid } from "@mui/x-data-grid";
import API from '../components/api';
import moment from 'moment';

const columns = [
  { field: "date", headerName: "Date", width: 120, },
  { field: "id", headerName: "Transaction ID", width: 200 },
  { field: "description", headerName: "Description", flex: 1,  },
  { field: "amount", headerName: "Amount", width: 130 },
];

function Payments() {
  const [lpayout, setLPayout] = useState(0);
  const [totFunds, setTotFunds] = useState(0);
  const { token, getAuthToken, loading } = useContext(AuthContext);

  const [rows, setRows] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getAuthToken();
    if(!loading){
      if (!token) {
        navigate("/");
      }else{
        GetAllTransactions();
      }
    }
  }, [token, loading]);

  const GetAllTransactions= async()=>{
    let tot = 0;
    let count = 0;
    if(token.status == "active"){
      await API.get(`/api/transaction/getByUserId/${token.userId}`)
      .then((res) => {
        if (res.data.data) {
          let rows = [];
          res.data.data.forEach((item)=>{
            if(item.type === "income"){
              let obj={
                date: moment(item.createdAt).format('DD MMM, YYYY'),
                id: item.id,
                description: "Order Id : " + item.orderId.id,
                amount: item.amount
              }
              tot = tot + item.amount;
              rows.push(obj);
            }else{
              let obj={
                date: moment(item.createdAt).format('DD MMM, YYYY'),
                id: item.id,
                description: "Payout",
                amount: item.amount
              }
              count++;
              if(count == 1){
                setLPayout(item.amount);
              }
              tot = tot - item.amount;
              rows.push(obj);
            }
          });
          setRows(rows);
          setTotFunds(tot);
        }
      })
      .catch((err) => {
        console.log(err)
      });
    } 
  }

  return (
    <div className="lg:mt-28 mt-16">
      <div className="py-3 bg-gray-400 md:text-lg text-sm text-white">
        <div className="container mx-auto md:px-12 uppercase">
          <Link to={"/"}>
            <span className="mr-1 hover:underline cursor-pointer">HOME</span>
          </Link>{" "}
          {" > "} <span className="mr-1 ml-1">My Account</span>
          {" > "} <span className="mr-1 ml-1">My Payments</span>
        </div>
      </div>
      <div className="container my-3 mx-auto md:px-12 py-4 md:py-6">
        <div className="items-center w-full bg-white">
          <div className="w-full md:flex py-4 text-2xl">
            Your financial summary
          </div>
          <hr/>
          <div className="items-center">
            <div className="py-5 md:flex justify-between">
              <div className="w-full bg-white md:mr-2 md:mb-0 mb-4 border p-4 border-gray-200 rounded-lg">
                <div className="text-lg">Your total funds</div>
                <div className="text-2xl font-bold">LKR {totFunds}</div>
              </div>
              <div className="w-full md:ml-2 bg-white border p-4 border-gray-200 rounded-lg">
                <div className="text-lg">Last payout</div>
                <div className="text-2xl">LKR {lpayout}</div>
              </div>
            </div>
            <div className="w-full text-xl py-4">Recent activity</div>
            <hr/>
            <div className="mb-8 mt-6" style={{ height: 600, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payments;
