import React, { useContext, useEffect } from "react";
import { ProductContext } from "../contexts/ProductContext";
import Product from "../components/Product";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import logo1 from "../img/banner-bg.jpg";
import imgDelivery from "../img/delivery-van.svg";
import imgTrust from "../img/money-back.svg";
import imgSupport from "../img/service-hours.svg";
import imgCat1 from "../img/cat1.jpg";
import imgCat2 from "../img/cat2.jpg";
import imgCat3 from "../img/cat3.jpg";
import imgBanner from "../img/banner.png";
import { Link } from "react-router-dom";

const Home = () => {
  const { products, loading } = useContext(ProductContext);

  if (loading) {
    return (
      <section className="h-screen flex justify-center items-center">
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </section>
    );
  }

  return (
    <div className="lg:mt-28 mt-16">
      {/* <Hero /> */}
      {/* <div className="font-semibold flex items-center uppercase w-full justify-center pt-8 ">
        <div className="md:w-[200px] w-20 h-[2px] bg-blue-600 mr-3"></div>
        <p className="md:text-2xl text-blue-600 text-lg">BEST SELLING</p>
        <div className="md:w-[200px] w-20 h-[2px] bg-blue-600 ml-3"></div>
      </div> */}
      {/* <img src={logo1} /> */}
      <div class="bg-cover bg-no-repeat bg-center py-16 md:py-36" style={{ backgroundImage: `url(${logo1})` }}>
        <div class="container mx-auto md:px-12">
          <h1 class="text-4xl md:text-6xl text-gray-800 font-medium mb-4 capitalize">
            best collection for <br /> home & Living
          </h1>
          <p>Onet Market envisions becoming a leading direct selling company that offers premium lifestyle products <br />
            with a strong commitment to environmental sustainability. </p>
          <div class="mt-12">
            <a href="#" class="bg-primary border border-primary text-white px-8 py-3 font-medium 
                    rounded-md hover:bg-transparent hover:text-primary">Shop Now</a>
          </div>
        </div>
      </div>

      <div class="container py-8 md:py-16 mx-auto">
        <div class="md:px-12 grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6 mx-auto justify-between">
          <div class="border border-blue-600 rounded-sm px-3 py-4 md:py-6 flex justify-center items-center gap-5">
            <img src={imgDelivery} alt="Delivery" class="w-6 h-6 md:w-12 md:h-12 object-contain" />
            <div>
              <h4 class="font-medium capitalize text-lg text-blue-600">Islandwide Delivery</h4>
            </div>
          </div>
          <div class="border border-blue-600 rounded-sm px-3 py-4 md:py-6 flex justify-center items-center gap-5">
            <img src={imgTrust} alt="Delivery" class="w-6 h-6 md:w-12 md:h-12 object-contain" />
            <div>
              <h4 class="font-medium capitalize text-lg text-blue-600">Trusted Warranty</h4>
            </div>
          </div>
          <div class="border border-blue-600 rounded-sm px-3 py-4 md:py-6 flex justify-center items-center gap-5">
            <img src={imgSupport} alt="Delivery" class="w-6 h-6 md:w-12 md:h-12 object-contain" />
            <div>
              <h4 class="font-medium capitalize text-lg text-blue-600">24/7 Support</h4>
            </div>
          </div>
        </div>
      </div>

      <div class="container  mx-auto md:px-12">
        <h2 class="text-xl md:text-2xl font-medium text-gray-800 uppercase mb-3 md:mb-6">shop by category</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-3">
          <Link to={"/category/Home-&-Living"}>
            <div class="relative rounded-sm overflow-hidden group">
              <img src={imgCat1} alt="category 1" class="h-28 md:h-full" />
              <p href="#"
                class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-sm md:text-xl text-white font-roboto font-medium group-hover:bg-opacity-60 transition">HOME & LIVING</p>
            </div>
          </Link>
          <Link to={"/category/Home-&-Living/Appliances"}>
          <div class="relative rounded-sm overflow-hidden group">
            <img src={imgCat2} alt="category 1" class="h-28 md:h-full" />
            <a href="#"
              class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-sm md:text-xl text-white font-roboto font-medium group-hover:bg-opacity-60 transition">APPLIANCES</a>
          </div>
          </Link>
          <Link to={"/category/Holiday"}>
          <div class="relative rounded-sm overflow-hidden group">
            <img src={imgCat3} alt="category 1" class="h-28 md:h-full" />
            <a href="#"
              class="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center text-sm md:text-xl text-white font-roboto font-medium group-hover:bg-opacity-60 transition">HOLIDAY
            </a>
          </div>
          </Link>
        </div>
      </div>

      <div class="container mx-auto md:px-12 mt-8 md:mt-16">
        <h2 class="text-xl md:text-2xl font-medium text-gray-800 uppercase mb-3 md:mb-6">top new arrival</h2>
        <section >
          <div className="">
            <div
              className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-[30px]
         mx-auto"
            >
              {products.slice(products.length - 12, products.length).map((product) => {
                return <Product product={product} key={product.id} />;
              })}
            </div>
          </div>
        </section>
      </div>

      <div class="container mx-auto md:px-12 mt-8 md:mt-16">
        <Link to={'/'}>
          <img src={imgBanner} alt="ads" class="w-full border rounded-lg" />
        </Link>
      </div>

      <div class="container mx-auto md:px-12 mt-8 mb-16">
        <h2 class="text-xl md:text-2xl font-medium text-gray-800 uppercase mb-6">recomended for you</h2>
        <section >
          <div className="">
            <div
              className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-[30px]
         mx-auto"
            >
              {products.slice(0, 12).map((product) => {
                return <Product product={product} key={product.id} />;
              })}
            </div>
          </div>
        </section>
      </div>

      {/* <div className="w-full py-12 bg-gray-100 flex justify-center">
        <div className="flex flex-col lg:flex-row items-center container md:px-12">
          <div className="flex flex-1 justify-center items-center mb-8 lg:mb-0">
            <img
              className="max-w-[200px] lg:max-w-sm h-96"
              src={
                "https://starfish-app-vil5p.ondigitalocean.app//uploads/" + products[0].img[0]
              }
              alt=""
            />
          </div>
          <div className="flex-1 text-center lg:text-left">
            <h1 className="md:text-[26px] text-xl font-medium mb-2 max-w-[450px] mx-auto lg:mx-0">
              {products[0].title}
            </h1>
            <p className="mb-8">{products[0].description.split("\n")[0]}</p>
          </div>
        </div>
      </div>
      <div className="w-full py-12 bg-gray-400 text-white flex justify-center">
        <div className="flex flex-col lg:flex-row items-center container px-12">
          <div className="flex-1 text-center lg:text-left">
            <h1 className="text-[26px] font-medium mb-2 max-w-[450px] mx-auto lg:mx-0">
              {products[1].title}
            </h1>
            <p className="mb-8">{products[1].description.split("\n")[0]}</p>
          </div>
          <div className="flex flex-1 justify-center items-center mb-8 lg:mb-0">
            <img
              className="max-w-[200px] lg:max-w-sm h-96"
              src={"https://starfish-app-vil5p.ondigitalocean.app//uploads/" + products[1].img[1]}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="w-full py-12 flex justify-center">
        <div className="flex flex-col lg:flex-row items-center container md:px-12">
          <div className="flex flex-1 justify-center items-center mb-8 lg:mb-0">
            <img
              className="max-w-[200px] lg:max-w-sm"
              src={
                "https://starfish-app-vil5p.ondigitalocean.app//uploads/" + products[3].img[0]
              }
              alt=""
            />
          </div>
          <div className="flex-1 text-center lg:text-left">
            <h1 className="md:text-[26px] text-xl font-medium mb-2 max-w-[450px] mx-auto lg:mx-0">
              {products[3].title}
            </h1>
            <p className="mb-8">{products[3].description.split("\n")[0]}</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
