import React from "react";

function OrderItem({ item }) {
  const { id, title, image, price, qty } = item;
  return (
    <div className="flex justify-between">
      <div className="text-sm w-52">{title} x <span className="font-semibold">{qty}</span></div>
      <div className="text-sm">{`LKR ${parseFloat(price * qty).toFixed(2)}`}</div>
    </div>
  );
}

export default OrderItem;
