import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import API from "../components/api";
import { useForm } from "react-hook-form";
import { AuthContext } from "../contexts/AuthContext";
import { RecoveryContext } from "../contexts/RecoveryContext";
import { CartContext } from "../contexts/CartContext";

function Login() {
  const hCategory = "MY ACCOUNT";
  const { register, handleSubmit } = useForm();
  const { register: login, handleSubmit: handleLogin } = useForm();
  const { setToken } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const { setPage } = useContext(RecoveryContext);
  const { cart } = useContext(CartContext);
  const [wait, setWait] = useState(false);
  const navigate = useNavigate();

  const onUserLogin = (data) => {
    setWait(true);
    setErrorMessage("");
    const newData = {
      ...data,
      email: data.email.toLowerCase(),
    };
    API.post("/auth/login", newData)
      .then(function (res) {
        if (res.data.email) {
          if (res.data.status != "suspend") {
            window.sessionStorage.setItem(
              "user_token",
              JSON.stringify(res.data)
            );
            setToken(res.data);
          }
          let path = `/`;
          navigate(path);
        }
      })
      .catch(function (error) {
        setWait(false);
        setErrorMessage("Invalid Username or Password");
      });
  };

  const goToForgotPassword = () => {
    setPage("login");
    let path = `/myaccount/forgot-password`;
    navigate(path);
  };

  return (
    <div>
      <section class="lg:mt-28 mt-16">
        <div className="py-3 bg-gray-400 md:text-lg text-sm text-white">
          <div className="container items-center mx-auto md:px-12 uppercase text-sm md:text-base font-medium">
            <Link to={"/"}>
              <span className="mr-1 hover:underline cursor-pointer">HOME</span>
            </Link>{" "}
            {hCategory && " > "} <span className="mr-1 ml-1">{hCategory}</span>
          </div>
        </div>
        <hr />
        <div className="py-10 container mx-auto md:px-12">
          <div class="md:grid md:grid-cols-2 items-start md:divide-x justify-center py-2 lg:py-0 mb-20">
            <div class="w-full">
              <div class="space-y-4 md:space-y-6 lg:pr-40">
                <h1 class="text-3xl font-bold leading-tight tracking-tight">
                  Have an account ?
                </h1>
                <p>Sign in using your email address.</p>
                <form
                  class="space-y-4 md:space-y-6"
                  onSubmit={handleLogin(onUserLogin)}
                >
                  <div>
                    <label for="email" class="block mb-2 text-sm font-medium">
                      Your email
                    </label>
                    <input
                      {...login("email")}
                      type="text"
                      name="email"
                      id="email"
                      class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                      required
                    />
                  </div>
                  <div>
                    <label
                      for="password"
                      class="block mb-2 text-sm font-medium"
                    >
                      Password
                    </label>
                    <input
                      {...login("password")}
                      type="password"
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                      required
                    />
                  </div>
                  <div>
                    {errorMessage && (
                      <p className="error -mt-4 text-red-400">
                        {" "}
                        {errorMessage}{" "}
                      </p>
                    )}
                  </div>
                  <div class="">
                    <div class="text-sm">
                      <label for="terms" class="font-light">
                        <div
                          onClick={() => {
                            goToForgotPassword();
                          }}
                        >
                          <p className="cursor-pointer font-bold text-md hover:underline">
                            Forgot your password?
                          </p>
                        </div>
                      </label>
                    </div>
                  </div>
                  {wait ? (
                    <button
                      type="submit"
                      disabled={true}
                      class="w-1/2 bg-blue-400 hover:bg-blue-400 cursor-not-allowed text-white hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      LOGIN
                    </button>
                  ) : (
                    <button
                      type="submit"
                      class="w-1/2 bg-blue-600 hover:bg-blue-600 text-white hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      LOGIN
                    </button>
                  )}
                </form>
              </div>
            </div>
            <div class="w-full h-full ">
              <div class="space-y-4 md:space-y-6 lg:pr-28 md:pl-12 mt-12 md:mt-0">
                <h1 class="text-3xl font-bold leading-tight tracking-tight">
                  New to Onet Market?
                </h1>
                <p>
                  Explore and discover our life-changing products as a Retail
                  Customer.
                </p>
                <Link to={"/customer/register"}>
                  <div class="md:w-1/2 w-full  mt-10  hover:border-blue-900 hover:text-blue-900 border-solid border-2 border-blue-600  text-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    START SHOPPING WITH YOUR EMAIL ADDRESS
                  </div>
                </Link>
              </div>
              <div class="space-y-4 md:space-y-6 lg:pr-28 md:pl-12 pt-12 md:mt-0">
                <h1 class="text-3xl font-bold leading-tight tracking-tight">
                  Interested in the opportunity?
                </h1>
                <p>
                  Become a member and start earning by participating in our
                  competitive compensation plan.
                </p>
                <Link to={"/register"}>
                  <div class="md:w-1/2 w-full mt-10  hover:border-blue-900 hover:text-blue-900 border-solid border-2 border-blue-600  text-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    BECOME A MARKETING ASSOCIATE
                  </div>
                </Link>
              </div>
              {/* <div class="md:pr-0 pr-8 space-y-4 md:space-y-6 lg:pr-28 md:pl-12 mt-12 md:mt-0">
                <h1 class="text-3xl font-bold leading-tight tracking-tight">
                  New to Ostore?
                </h1>
                <p>Create an account </p>
                <form
                  class="space-y-4 md:space-y-6"
                  onSubmit={handleSubmit(onUserRegister)}
                >
                  <div>
                    <label
                      for="firstName"
                      class="block mb-2 text-sm font-medium"
                    >
                      First Name
                    </label>
                    <input
                      {...register("firstName")}
                      type="firstName"
                      name="firstName"
                      id="firstName"
                      class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                      placeholder="First Name"
                      required
                    />
                  </div>
                  <div>
                    <label
                      for="lastName"
                      class="block mb-2 text-sm font-medium"
                    >
                      Last Name
                    </label>
                    <input
                      {...register("lastName")}
                      type="lastName"
                      name="lastName"
                      id="lastName"
                      class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                  <div>
                    <label for="email" class="block mb-2 text-sm font-medium">
                      Email
                    </label>
                    <input
                      {...register("email")}
                      type="email"
                      name="email"
                      id="email"
                      class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                      placeholder="name@company.com"
                      required
                    />
                  </div>
                  <div>
                    <label for="email" class="block mb-2 text-sm font-medium">
                      Phone
                    </label>
                    <input
                      {...register("phone")}
                      type="phone"
                      name="phone"
                      id="phone"
                      class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                      placeholder=""
                      required
                    />
                  </div>
                  <div>
                    <label
                      for="password"
                      class="block mb-2 text-sm font-medium"
                    >
                      Password
                    </label>
                    <input
                      {...register("password")}
                      type="password"
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                      required
                    />
                  </div>
                  <div>
                    <label
                      for="repassword"
                      class="block mb-2 text-sm font-medium"
                    >
                      Confirm Password
                    </label>
                    <input
                      {...register("repassword")}
                      type="password"
                      name="repassword"
                      id="repassword"
                      placeholder="••••••••"
                      class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                      required
                    />
                  </div>
                  <div class="flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="terms"
                        aria-describedby="terms"
                        type="checkbox"
                        class="w-4 h-4 borderfocus:ring-3 focus:ring-blue-600"
                        required
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="terms" class="font-light">
                        I accept the{" "}
                        <a
                          class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                          href="#"
                        >
                          Terms and Conditions
                        </a>
                      </label>
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="w-1/2 bg-blue-600 hover:bg-blue-600 text-white hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Register
                  </button>
                </form>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
