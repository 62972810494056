import contact from "../img/contact-us.jpg";

export default function ContactUs() {

    return <div className="lg:mt-28 mt-16">
        <section className="py-10 container mx-auto md:px-12">
            <div class="border rounded-lg ">
                <div class="items-center gap-8">
                    {/* <div class="bg-cover bg-no-repeat bg-center border rounded-lg py-36" style={{ backgroundImage: `url(${logo1})` }}>
                        <div class="container mx-auto md:px-12 text-center">
                            <h1 class="text-6xl text-gray-800 font-medium mb-4 capitalize">
                                Contact Us
                            </h1>
                        </div>
                    </div>
                    <div className="w-full flex justify-center py-8">
                        <div className="w-1/2 text-center">
                            <p class="mt-4 text-gray-600 text-lg">Onet Market envisions becoming a leading direct selling company that offers premium lifestyle products with a strong commitment to environmental sustainability. Our mission is to provide eco-friendly solutions to our customers while maintaining a high standard of quality, at the most affordable prices!</p>
                        </div>
                    </div> */}
                    <div class="w-full">
                        <section class="bg-white">
                            <div class="py-8 lg:py-16 px-4 mx-auto">
                                <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900">Contact Us</h2>
                                <div className="flex justify-center w-full">
                                    <p class="mb-8 lg:mb-16 font-light text-center md:w-1/2 text-gray-500 sm:text-lg">You can contact our Customer Service for all questions and queries on any of the following channels.</p>
                                </div>
                                <div className="md:flex w-full md:px-12">
                                    <form action="#" class="space-y-8 md:w-3/4">
                                        <div>
                                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900">Your email</label>
                                            <input type="email" id="email" class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" placeholder="name@email.com" required />
                                        </div>
                                        <div>
                                            <label for="subject" class="block mb-2 text-sm font-medium text-gray-900 ">Subject</label>
                                            <input type="text" id="subject" class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" placeholder="Let us know how we can help you" required />
                                        </div>
                                        <div class="sm:col-span-2">
                                            <label for="message" class="block mb-2 text-sm font-medium text-gray-900 ">Your message</label>
                                            <textarea id="message" rows="6" class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" placeholder="Leave a comment..."></textarea>
                                        </div>
                                        <button type="submit" class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-blue-600 sm:w-fit focus:ring-4 focus:outline-none focus:ring-blue-500">Send message</button>
                                    </form>
                                    <div className="md:w-1/2 pt-2 md:pl-4 xl:pl-0 md:flex md:justify-end">
                                        <div>
                                        <img src={contact} alt="About Us Image" class="object-cover w-80" />
                                        <div class="mt-4 space-y-2">
                                            <p class="text-sm text-gray-600 hover:text-white block">📍 No. 77/35, Isuru Place, Mampitiya, Galle</p>
                                            <p class="text-sm text-gray-600 hover:text-white block">📍 No. 77/35, Isuru Place, Mampitiya, Galle</p>
                                            <p class="text-sm text-gray-600 hover:text-white block">📞 +94 76 043 6884</p>
                                            <p class="text-sm text-gray-600 hover:text-white block">📧 support@onetmarket.com</p>

                                            <div className="pt-4">
                                                <button class="bg-transparent font-semibold text-blue-600 inline-flex items-center space-x-2 rounded mr-2">
                                                    <svg
                                                        class="w-8 h-8  fill-current"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                                                    </svg>
                                                </button>

                                                <button class="bg-transparent font-semibold  inline-flex items-center space-x-2 rounded mr-2">
                                                    <svg
                                                        class="w-8 h-8 text-green-400 fill-current"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 448 512"
                                                    >
                                                        <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
                                                    </svg>
                                                </button>

                                                <button class="bg-transparent font-semibold  inline-flex items-center space-x-2 rounded mr-2">
                                                    <svg
                                                        class="w-8 h-8 text-blue-400 fill-current"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 496 512"
                                                    >
                                                        <path d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    </div>
}