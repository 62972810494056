import React, {useState, createContext} from 'react';
export const AuthContext = createContext();

const AuthProvider = ({children}) => {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  const getAuthToken = async() =>{
    if(!token || token === 'undefined'){
      const user_token = JSON.parse(window.sessionStorage.getItem('user_token'));
      setLoading(false);
      setToken(user_token);
      return token;
    }
    return token;
  }

  const signOut = () =>{
    window.sessionStorage.removeItem('user_token')
    setToken(null);
  }

  return <AuthContext.Provider value={{token, getAuthToken, signOut, setToken, loading}}>
    {children}
  </AuthContext.Provider>;
};

export default AuthProvider;
