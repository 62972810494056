import React from "react";
import { Link } from "react-router-dom";

export default function Recovered() {
  return (
    <div className="py-10 md:py-20 container mx-auto md:px-12 mb-40">
    <div class="md:grid md:grid-cols-2 md:divide-x items-center justify-center py-2 lg:py-0">
      <div class="w-full">
        <div class="pr-8 space-y-6 md:space-y-6 lg:pr-40">
          <h1 class="text-3xl font-bold leading-tight tracking-tight">
            Password Changed!
          </h1>
          <p>Your password has beeen changed succesfully.</p>
          <p>Please login with the new password.</p>
          <h1></h1>
          <Link to={'/myaccount'}
              type="button"
              class="w-1/2 bg-blue-600 hover:bg-blue-600 text-white hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              LOGIN
            </Link>
        </div>
      </div>
      <div class="w-1/2 h-full"></div>
    </div>
  </div>
  );
}