import React, { createContext, useState, useEffect } from "react";
import API from "../components/api";
export const ProductContext = createContext();

const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      // const response = await fetch('https://fakestoreapi.com/products');
      // const data = response;
      API.get("api/product/")
        .then((res) => {
          if (res.data) {
            setProducts(res.data.data);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err)
        });
    };
    fetchProducts();
  }, []);

  return (
    <ProductContext.Provider value={{ products, loading }}>
      {children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
