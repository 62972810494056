import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { DataGrid } from "@mui/x-data-grid";
import API from '../api';
import moment from 'moment';

const columns = [
    { field: "date", headerName: "Date", width: 120, },
    { field: "id", headerName: "Transaction ID", width: 200 },
    { field: "description", headerName: "Description", flex: 1, },
    { field: "amount", headerName: "Amount", width: 130 },
];

function MyEarnings() {
    const [lpayout, setLPayout] = useState(0);
    const [totFunds, setTotFunds] = useState(0);
    const { token, getAuthToken, loading } = useContext(AuthContext);

    const [rows, setRows] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        getAuthToken();
        if (!loading) {
            if (!token) {
                navigate("/");
            } else {
                GetAllTransactions();
            }
        }
    }, [token, loading]);

    const GetAllTransactions = async () => {
        let tot = 0;
        let count = 0;
        if (token.status == "active") {
            await API.get(`/api/transaction/getByUserId/${token.userId}`)
                .then((res) => {
                    if (res.data.data) {
                        let rows = [];
                        res.data.data.forEach((item) => {
                            if (item.type === "income") {
                                let obj = {
                                    date: moment(item.createdAt).format('DD MMM, YYYY'),
                                    id: item.id,
                                    description: "Order Id : " + item.orderId.id,
                                    amount: item.amount
                                }
                                tot = tot + item.amount;
                                rows.push(obj);
                            } else {
                                let obj = {
                                    date: moment(item.createdAt).format('DD MMM, YYYY'),
                                    id: item.id,
                                    description: "Payout",
                                    amount: item.amount
                                }
                                count++;
                                if (count == 1) {
                                    setLPayout(item.amount);
                                }
                                tot = tot - item.amount;
                                rows.push(obj);
                            }
                        });
                        setRows(rows);
                        setTotFunds(tot);
                    }
                })
                .catch((err) => {
                    console.log(err)
                });
        }
    }

    return (
        <div className="col-span-9 shadow rounded md:px-6 pt-5 pb-7">
            <div className="container mx-auto ">
                <div className="items-center w-full bg-white">
                    <h4 class="text-lg font-medium capitalize mb-4">
                        Your financial summary
                    </h4>
                    <div className="items-center">
                        <div className="py-1 md:flex justify-between">
                            <div className="w-full bg-white md:mr-2 md:mb-0 mb-4 border p-4 border-gray-200 rounded-lg">
                                <div className="text-lg">Your total funds</div>
                                <div className="text-2xl font-bold">LKR {totFunds}</div>
                            </div>
                            <div className="w-full md:ml-2 bg-white border p-4 border-gray-200 rounded-lg">
                                <div className="text-lg">Last payout</div>
                                <div className="text-2xl">LKR {lpayout}</div>
                            </div>
                        </div>
                        <h4 class="text-base font-medium capitalize mt-6">
                            Recent activity
                        </h4>

                        <div className="mb-8 mt-4" style={{ height: 600, width: "100%" }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyEarnings;
