import { Link } from "react-router-dom";
import { useContext } from "react";
import { WishlistContext } from "../contexts/WishlistContext";
import DeleteIcon from '@mui/icons-material/Delete';

const WishlistItem = ({ wishistItem }) => {
    const { removeFromWishList } = useContext(WishlistContext);
    const imgBaseUrl = 'https://firebasestorage.googleapis.com/v0/b/ostore-86bf8.appspot.com/o/';

    function TextAbstract(text, length) {
        if (text == null) {
            return "";
        }
        if (text.length <= length) {
            return text;
        }
        text = text.substring(0, length);
        var last = text.lastIndexOf(" ");
        text = text.substring(0, last);
        return text + "...";
    }

    return (
        <div>
            <div class="md:flex items-center justify-between border gap-6 p-4 border-gray-200 rounded mb-2">
                <div class="w-28">
                    <img src={`${imgBaseUrl + wishistItem.img[0].replace(/\//g, '%2F') + '?alt=media'}`} alt="image" class="mx-auto h-[100px]" />
                </div>
                <div class="md:w-1/3">
                    <h2 class="text-gray-800 text-lg font-medium uppercase">{TextAbstract(wishistItem.title, 35)}</h2>
                    <p class="text-gray-500 text-sm">Availability: <span class="text-green-600">In Stock</span></p>
                </div>
                <div class="text-primary text-lg font-semibold">LKR {wishistItem.price.toFixed(2)}</div>
                <Link to={`/product/${wishistItem.id}`}>
                    <p
                        class="px-6 py-2 text-center text-sm text-white bg-blue-600 border border-blue-600 rounded hover:bg-transparent hover:text-blue-600 transition uppercase font-roboto font-medium">View</p>
                </Link>
                <div onClick={()=>{removeFromWishList(wishistItem.id)}} class="text-red-400 hidden md:block cursor-pointer hover:text-red-600">
                    <DeleteIcon />
                </div>
                <p onClick={()=>{removeFromWishList(wishistItem.id)}} class="cursor-pointer md:hidden mt-2 px-6 py-1 text-center text-sm text-white bg-red-600 border border-red-600 rounded hover:bg-transparent hover:text-red-600 transition uppercase font-roboto font-medium">Remove</p>
            </div>
        </div>
    );
}

export default WishlistItem;