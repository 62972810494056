import React, { createContext, useEffect, useState } from "react";
import API from "../components/api";
export const CategoryContext = createContext();

const CategoryProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    API.get(`/api/category`)
      .then((res) => {
        if (res.data) {
          setCategories(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  return (
    <CategoryContext.Provider value={{ categories, loading }}>
      {children}
    </CategoryContext.Provider>
  );
};

export default CategoryProvider;
