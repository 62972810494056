import React from "react";
import { useContext, useState } from "react";
import { RecoveryContext } from "../../contexts/RecoveryContext";
import API from "../api";

export default function Reset() {
  const { setPage, email, otp, role } = useContext(RecoveryContext);
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function changePassword(e) {
    e.preventDefault();
    setErrorMessage("");
    if (password === cpassword) {

      API.post("/auth/update_password", {
        email: email,
        otp: otp,
        role: role,
        password: password,
      })
        .then((res) => {
          if(res.data){
            setPage("recovered")
          }else{
            setErrorMessage("Error, unable to update the passwrod.");
          }
        })
        .catch(console.log);
    }else{
      setErrorMessage("The password confirmation does not match.");
    }
  }

  return (
    <div className="py-10 md:py-20 mb-20 container mx-auto md:px-12">
      <div class="md:grid md:grid-cols-2 md:divide-x items-center justify-center py-2 lg:py-0">
        <div class="w-full">
          <div class="space-y-6 md:space-y-8 lg:pr-40">
            <h1 class="text-3xl font-bold leading-tight tracking-tight">
              New password
            </h1>
            <form
              class="space-y-4 md:space-y-6 pb-40"
              onSubmit={changePassword}
            >
              <p>Enter your new password</p>
              <div>
                <input
                  // {...login("password")}
                  onChange={(evt) => {
                    setPassword(evt.target.value);
                  }}
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                  required
                />
              </div>
              <p>Confirm your new password</p>
              <div>
                <input
                  onChange={(evt) => {
                    setCPassword(evt.target.value);
                  }}
                  // {...login("password")}
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                  required
                />
              </div>
              <div>
                    {errorMessage && <p className="error -mt-4 text-red-400"> {errorMessage} </p>}
                  </div>
              <button
                type="submit"
                class="w-1/2 bg-blue-600 hover:bg-blue-600 text-white hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                SUBMIT
              </button>
            </form>
          </div>
        </div>
        <div class="w-1/2 h-full"></div>
      </div>
    </div>
  );
}
