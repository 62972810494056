import React, { useState, createContext } from "react";

export const RecoveryContext = createContext();

const RecoveryProvider = ({ children }) => {
  const [page, setPage] = useState("login");
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState();
  const [role, setRole] = useState("");

  return (
    <RecoveryContext.Provider value={{  page, setPage, otp, setOTP, setEmail, email, role, setRole }}>
      {children}
    </RecoveryContext.Provider>
  );
};

export default RecoveryProvider;
