import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import API from "../api";

export default function ProfileInfo() {
    const { token, loading } = useContext(AuthContext);
    const { register, reset, handleSubmit } = useForm();
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading) {
            if (!token) {
                navigate("/");
            } else {
                setUserDetails(token.userId);
            }
        }
    }, [token, loading]);

    const setUserDetails = async (id) => {
        if (token.role == "user") {
            await API.get(`/api/user/${id}`)
                .then((res) => {
                    if (res.data.data) {
                        const user = res.data.data[0];
                        reset({
                            userId: user.userId,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            phone: user.phone,
                            address: user.address,
                            city: user.city,
                            dob: user.dob,
                            province: user.province,
                            landmark: user.landmark,
                            postcode: user.postcode
                        });
                    }
                })
                .catch((err) => {
                });
        } else {
            await API.get(`/api/customer/${id}`)
                .then((res) => {
                    if (res.data.data) {
                        const user = res.data.data[0];
                        reset({
                            userId: user.userId,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            phone: user.phone,
                            address: user.address,
                            city: user.city,
                            dob: user.dob,
                            province: user.province,
                            landmark: user.landmark,
                            postcode: user.postcode
                        });
                    }
                })
                .catch((err) => {
                });
        }
    }

    const onUserUpdate = (data) => {
        if (token.role == "user") {
            const newData = {
              ...data,
              userId: token.userId,
            }
            API.put("/api/user", newData)
              .then(function (response) {
                if (response.data.data) {
                  Swal.fire({
                    icon: 'success',
                    title: 'MY DETAILS',
                    text: 'Profiel details updated succesfully.',
                    confirmButtonColor: "#2563eb"
                  })
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'MY DETAILS',
                    text: 'Oops.. something went wrong.',
                    confirmButtonColor: "#2563eb"
                  })
                }
              })
              .catch(function (error) {
                Swal.fire({
                  icon: 'error',
                  title: 'MY DETAILS',
                  text: 'Oops.. something went wrong.',
                  confirmButtonColor: "#2563eb"
                })
              });
          } else {
            const newData = {
              ...data,
              userId: token.userId,
            }
            API.put("/api/customer", newData)
              .then(function (response) {
                if (response.data.data) {
                  Swal.fire({
                    icon: 'success',
                    title: 'MY DETAILS',
                    text: 'Profiel details updated succesfully.',
                    confirmButtonColor: "#2563eb"
                  })
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'MY DETAILS',
                    text: 'Oops.. something went wrong.',
                    confirmButtonColor: "#2563eb"
                  })
                }
              })
              .catch(function (error) {
                Swal.fire({
                  icon: 'error',
                  title: 'MY DETAILS',
                  text: 'Oops.. something went wrong.',
                  confirmButtonColor: "#2563eb"
                })
              });
          }
      
    }

    return (
        <section>
            <div class="col-span-9 shadow rounded px-6 pt-5 pb-7">
                <h4 class="text-lg font-medium capitalize mb-4">
                    Profile information
                </h4>
                <form onSubmit={handleSubmit(onUserUpdate)} class="space-y-4 text-sm">
                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <label for="userId">User ID</label>
                            <input {...register("userId")} disabled className="w-full px-4 py-3  text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" type="text" name="userId" id="userId" />
                        </div>
                        <div>
                            <label for="email">Email Address</label>
                            <input disabled {...register("email")} className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" type="email" name="email" id="email" />
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <label for="firstName">First Name</label>
                            <input {...register("firstName")} className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" type="text" name="firstName" id="firstName" />
                        </div>
                        <div>
                            <label for="lastName">Last Name</label>
                            <input {...register("lastName")} className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" type="text" name="lastName" id="lastName" />
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <label for="dob">Birthday</label>
                            <input {...register("dob")} className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" type="date" name="dob" id="dob" />
                        </div>
                        <div>
                            <label for="phone">Phone Number</label>
                            <input {...register("phone")} className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" type="text" name="phone" id="phone" />
                        </div>
                    </div>
                    <div class="mt-4">
                        <button type="submit"
                            class="py-3 px-4 text-center text-white bg-blue-600 rounded-md  font-medium">save
                            changes</button>
                    </div>
                </form>
            </div>
        </section>
    );
}