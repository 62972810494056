export const bankList = [
    { value: 7010, name: "Bank of Ceylon", label: "Bank of Ceylon" },
    {
      value: 7038,
      name: "Standard Chartered Bank",
      label: "Standard Chartered Bank",
    },
    { value: 7047, name: "Citi Bank", label: "Citi Bank" },
    { value: 7056, name: "Commercial Bank PLC", label: "Commercial Bank PLC" },
    { value: 7074, name: "Habib Bank Ltd", label: "Habib Bank Ltd" },
    {
      value: 7083,
      name: "Hatton National Bank PLC",
      label: "Hatton National Bank PLC",
    },
    {
      value: 7092,
      name: "Hongkong   Shanghai Bank",
      label: "Hongkong   Shanghai Bank",
    },
    { value: 7108, name: "Indian Bank", label: "Indian Bank" },
    { value: 7117, name: "Indian Overseas Bank", label: "Indian Overseas Bank" },
    { value: 7135, name: "Peoples Bank", label: "Peoples Bank" },
    { value: 7144, name: "State Bank of India", label: "State Bank of India" },
    {
      value: 7162,
      name: "Nations Trust Bank PLC",
      label: "Nations Trust Bank PLC",
    },
    { value: 7205, name: "Deutsche Bank", label: "Deutsche Bank" },
    {
      value: 7214,
      name: "National Development Bank PLC",
      label: "National Development Bank PLC",
    },
    { value: 7269, name: "MCB Bank Ltd", label: "MCB Bank Ltd" },
    { value: 7278, name: "Sampath Bank PLC", label: "Sampath Bank PLC" },
    { value: 7287, name: "Seylan Bank PLC", label: "Seylan Bank PLC" },
    { value: 7296, name: "Public Bank", label: "Public Bank" },
    {
      value: 7302,
      name: "Union Bank of Colombo PLC",
      label: "Union Bank of Colombo PLC",
    },
    {
      value: 7311,
      name: "Pan Asia Banking Corporation PLC",
      label: "Pan Asia Banking Corporation PLC",
    },
    { value: 7384, name: "ICICI Bank Ltd", label: "ICICI Bank Ltd" },
    { value: 7454, name: "DFCC Bank PLC", label: "DFCC Bank PLC" },
    { value: 7463, name: "Amana Bank PLC", label: "Amana Bank PLC" },
    { value: 7472, name: "Axis Bank", label: "Axis Bank" },
    { value: 7481, name: "Cargills Bank Limited", label: "Cargills Bank Limited" },
    { value: 7719, name: "National Savings Bank", label: "National Savings Bank" },
    {
      value: 7728,
      name: "Sanasa Development Bank",
      label: "Sanasa Development Bank",
    },
    { value: 7737, name: "HDFC Bank", label: "HDFC Bank" },
    {
      value: 7746,
      name: "Citizen Development Business Finance PLC",
      label: "Citizen Development Business Finance",
    },
    {
      value: 7755,
      name: "Regional Development Bank",
      label: "Regional Development Bank",
    },
    {
      value: 7764,
      name: "State Mortgage & Investment Bank",
      label: "State Mortgage & Investment Bank",
    },
    { value: 7773, name: "LB Finance PLC", label: "LB Finance" },
    { value: 7782, name: "Senkadagala Finance PLC", label: "Senkadagala Finance" },
    {
      value: 7807,
      name: "Commercial Leasing and Finance",
      label: "Commercial Leasing and Finance",
    },
    { value: 7816, name: "Vallibel Finance PLC", label: "Vallibel Finance" },
    { value: 7825, name: "Central Finance PLC", label: "Central Finance" },
    { value: 7834, name: "Kanrich Finance Limited", label: "Kanrich Finance" },
    {
      value: 7852,
      name: "Alliance Finance Company PLC",
      label: "Alliance Finance Company",
    },
    { value: 7861, name: "LOLC Finance PLC", label: "LOLC Finance" },
    {
      value: 7870,
      name: "Commercial Credit & Finance PLC",
      label: "Commercial Credit & Finance",
    },
    {
      value: 7898,
      name: "Merchant Bank of Sri Lanka & Finance PLC",
      label: "Merchant Bank of Sri Lanka & Finance",
    },
    {
      value: 7904,
      name: "HNB Grameen Finance Limited",
      label: "HNB Grameen Finance",
    },
    {
      value: 7913,
      name: "Mercantile Investment and Finance PLC",
      label: "Mercantile Investment and Finance",
    },
    {
      value: 7922,
      name: "People's Leasing & Finance PLC",
      label: "People's Leasing & Finance",
    },
    {
      value: 8004,
      name: "Central Bank of Sri Lanka",
      label: "Central Bank of Sri Lanka",
    },
  ];
  
export const branchList = {
  7010: [
    { bankvalue: 7010, value: 1, label: "City Office" },
    { bankvalue: 7010, value: 2, label: "Kandy" },
    { bankvalue: 7010, value: 3, label: "Galle Fort" },
    { bankvalue: 7010, value: 4, label: "Pettah" },
    { bankvalue: 7010, value: 5, label: "Jaffna" },
    { bankvalue: 7010, value: 6, label: "Trincomalee" },
    { bankvalue: 7010, value: 7, label: "Panadura" },
    { bankvalue: 7010, value: 9, label: "Kurunegala" },
    { bankvalue: 7010, value: 11, label: "Badulla" },
    { bankvalue: 7010, value: 12, label: "Batticaloa" },
    { bankvalue: 7010, value: 15, label: "Central Office" },
    { bankvalue: 7010, value: 16, label: "Kalutara S/G" },
    { bankvalue: 7010, value: 18, label: "Negombo" },
    { bankvalue: 7010, value: 20, label: "Chilaw" },
    { bankvalue: 7010, value: 21, label: "Ampara" },
    { bankvalue: 7010, value: 22, label: "Anuradhapura" },
    { bankvalue: 7010, value: 23, label: "Wellawatte" },
    { bankvalue: 7010, value: 24, label: "Matara" },
    { bankvalue: 7010, value: 26, label: "Main Street" },
    { bankvalue: 7010, value: 27, label: "Kegalle" },
    { bankvalue: 7010, value: 28, label: "Point Pedro" },
    { bankvalue: 7010, value: 29, label: "Nuwara Eliya" },
    { bankvalue: 7010, value: 30, label: "Katubedda" },
    { bankvalue: 7010, value: 31, label: "Ratnapura" },
    { bankvalue: 7010, value: 32, label: "Aluthkade" },
    { bankvalue: 7010, value: 34, label: "Kollupitiya" },
    { bankvalue: 7010, value: 35, label: "Haputale" },
    { bankvalue: 7010, value: 37, label: "Bambalapitiya" },
    { bankvalue: 7010, value: 38, label: "Borella S/G" },
    { bankvalue: 7010, value: 39, label: "Ja Ela" },
    { bankvalue: 7010, value: 40, label: "Hatton" },
    { bankvalue: 7010, value: 41, label: "Maradana" },
    { bankvalue: 7010, value: 42, label: "Peliyagoda" },
    { bankvalue: 7010, value: 43, label: "Union Place" },
    { bankvalue: 7010, value: 44, label: "Vavuniya" },
    { bankvalue: 7010, value: 45, label: "Gampaha S/G" },
    { bankvalue: 7010, value: 46, label: "Mannar" },
    { bankvalue: 7010, value: 47, label: "Ambalangoda" },
    { bankvalue: 7010, value: 48, label: "Puttalam" },
    { bankvalue: 7010, value: 49, label: "Nugegoda Supergrade" },
    { bankvalue: 7010, value: 50, label: "Nattandiya" },
    { bankvalue: 7010, value: 51, label: "Dehiwala" },
    { bankvalue: 7010, value: 52, label: "Kuliyapitiya" },
    { bankvalue: 7010, value: 53, label: "Chunnakam" },
    { bankvalue: 7010, value: 54, label: "Horana" },
    { bankvalue: 7010, value: 55, label: "Maharagama" },
    { bankvalue: 7010, value: 56, label: "Tangalle" },
    { bankvalue: 7010, value: 57, label: "Eheliyagoda" },
    { bankvalue: 7010, value: 58, label: "Beruwala" },
    { bankvalue: 7010, value: 59, label: "Kadawatha" },
    { bankvalue: 7010, value: 60, label: "Fifth City" },
    { bankvalue: 7010, value: 61, label: "Moratuwa" },
    { bankvalue: 7010, value: 63, label: "Velanai" },
    { bankvalue: 7010, value: 68, label: "Matale" },
    { bankvalue: 7010, value: 82, label: "Monaragala" },
    { bankvalue: 7010, value: 83, label: "Polonnaruwa New Town" },
    { bankvalue: 7010, value: 85, label: "Hambantota" },
    { bankvalue: 7010, value: 87, label: "International Division" },
    { bankvalue: 7010, value: 88, label: "Mirigama" },
    { bankvalue: 7010, value: 89, label: "Galle Bazaar" },
    { bankvalue: 7010, value: 92, label: "Naula" },
    { bankvalue: 7010, value: 93, label: "Kilinochchi" },
    { bankvalue: 7010, value: 98, label: "Anuradhapura New Town" },
    { bankvalue: 7010, value: 99, label: "Primary Dealer Unit" },
    { bankvalue: 7010, value: 101, label: "Galaha" },
    { bankvalue: 7010, value: 102, label: "Bentota" },
    { bankvalue: 7010, value: 104, label: "Welpalla" },
    { bankvalue: 7010, value: 118, label: "Muttur" },
    { bankvalue: 7010, value: 122, label: "Galenbindunuwewa" },
    { bankvalue: 7010, value: 127, label: "Padavi Parakramapura" },
    { bankvalue: 7010, value: 135, label: "Imaduwa" },
    { bankvalue: 7010, value: 139, label: "Weeraketiya" },
    { bankvalue: 7010, value: 144, label: "Yatawatte" },
    { bankvalue: 7010, value: 152, label: "Pemaduwa" },
    { bankvalue: 7010, value: 157, label: "Tirappane" },
    { bankvalue: 7010, value: 162, label: "Medawachchiya" },
    { bankvalue: 7010, value: 167, label: "Rikillagaskada" },
    { bankvalue: 7010, value: 172, label: "Kobeigane" },
    { bankvalue: 7010, value: 183, label: "Sewagama" },
    { bankvalue: 7010, value: 217, label: "Horowpathana" },
    { bankvalue: 7010, value: 236, label: "Ipalogama" },
    { bankvalue: 7010, value: 238, label: "Medagama" },
    { bankvalue: 7010, value: 250, label: "Tawalama" },
    { bankvalue: 7010, value: 255, label: "Malkaduwawa" },
    { bankvalue: 7010, value: 256, label: "Thanthirimale" },
    { bankvalue: 7010, value: 257, label: "Mawathagama" },
    { bankvalue: 7010, value: 258, label: "Elakanda" },
    { bankvalue: 7010, value: 259, label: "Rathgama" },
    { bankvalue: 7010, value: 260, label: "Diyatalawa" },
    { bankvalue: 7010, value: 261, label: "Katuwana" },
    { bankvalue: 7010, value: 262, label: "Kekanadura" },
    { bankvalue: 7010, value: 263, label: "Kosmodera" },
    { bankvalue: 7010, value: 264, label: "Kudawella" },
    { bankvalue: 7010, value: 265, label: "Lunugamvehera" },
    { bankvalue: 7010, value: 266, label: "Maha-Edanda" },
    { bankvalue: 7010, value: 267, label: "Makandura - Matara" },
    { bankvalue: 7010, value: 268, label: "Malimbada" },
    { bankvalue: 7010, value: 270, label: "Morawaka" },
    { bankvalue: 7010, value: 271, label: "Pasgoda" },
    { bankvalue: 7010, value: 272, label: "Pitabeddara" },
    { bankvalue: 7010, value: 273, label: "Digana" },
    { bankvalue: 7010, value: 274, label: "Weli-Oya" },
    { bankvalue: 7010, value: 276, label: "Ahangama" },
    { bankvalue: 7010, value: 277, label: "Aluthwala" },
    { bankvalue: 7010, value: 278, label: "Barawakumbura" },
    { bankvalue: 7010, value: 280, label: "Karapitiya" },
    { bankvalue: 7010, value: 281, label: "Manipay" },
    { bankvalue: 7010, value: 282, label: "Kitulgala" },
    { bankvalue: 7010, value: 283, label: "Kolonna" },
    { bankvalue: 7010, value: 284, label: "Kotiyakumbura" },
    { bankvalue: 7010, value: 285, label: "Morontota" },
    { bankvalue: 7010, value: 286, label: "Sabaragamuwa University" },
    { bankvalue: 7010, value: 287, label: "Pinnawala" },
    { bankvalue: 7010, value: 288, label: "Sabaragamuwa Provincial Council" },
    { bankvalue: 7010, value: 290, label: "Seethawakapura" },
    { bankvalue: 7010, value: 291, label: "Udawalawe" },
    { bankvalue: 7010, value: 292, label: "Weligepola" },
    { bankvalue: 7010, value: 293, label: "Dodangoda" },
    { bankvalue: 7010, value: 294, label: "Karawanella" },
    { bankvalue: 7010, value: 295, label: "Karawita" },
    { bankvalue: 7010, value: 297, label: "Kegalle Hospital" },
    { bankvalue: 7010, value: 298, label: "Urubokka" },
    { bankvalue: 7010, value: 299, label: "Makandura" },
    { bankvalue: 7010, value: 300, label: "Marawila" },
    { bankvalue: 7010, value: 301, label: "Palaviya" },
    { bankvalue: 7010, value: 302, label: "Pallama" },
    { bankvalue: 7010, value: 303, label: "Paragahadeniya" },
    { bankvalue: 7010, value: 305, label: "Thoduwawa" },
    { bankvalue: 7010, value: 306, label: "Udappuwa" },
    { bankvalue: 7010, value: 307, label: "Wayamba University" },
    { bankvalue: 7010, value: 308, label: "Weerapokuna" },
    { bankvalue: 7010, value: 309, label: "Wellawa" },
    { bankvalue: 7010, value: 311, label: "Bulathkohupitiya" },
    { bankvalue: 7010, value: 312, label: "Embilipitiya City" },
    { bankvalue: 7010, value: 313, label: "Endana" },
    { bankvalue: 7010, value: 314, label: "Galigamuwa" },
    { bankvalue: 7010, value: 315, label: "Ratnapura Hospital" },
    { bankvalue: 7010, value: 316, label: "Gonagaldeniya" },
    { bankvalue: 7010, value: 317, label: "Kiriella" },
    { bankvalue: 7010, value: 318, label: "Potuvil" },
    { bankvalue: 7010, value: 319, label: "Mahawewa" },
    { bankvalue: 7010, value: 320, label: "Ballaketuwa" },
    { bankvalue: 7010, value: 322, label: "Thanamalwila" },
    { bankvalue: 7010, value: 323, label: "Kochchikade" },
    { bankvalue: 7010, value: 324, label: "Kumbukgete" },
    { bankvalue: 7010, value: 325, label: "Kuruwita" },
    { bankvalue: 7010, value: 326, label: "Thirumurukandi" },
    { bankvalue: 7010, value: 328, label: "Visuvamadu" },
    { bankvalue: 7010, value: 329, label: "Ambanpola" },
    { bankvalue: 7010, value: 330, label: "Anawilundawa" },
    { bankvalue: 7010, value: 331, label: "Dambadeniya" },
    { bankvalue: 7010, value: 332, label: "Katuneriya" },
    { bankvalue: 7010, value: 333, label: "Katupotha" },
    { bankvalue: 7010, value: 334, label: "Kirimetiyana" },
    { bankvalue: 7010, value: 335, label: "Mihintale" },
    { bankvalue: 7010, value: 336, label: "Thalaimannar Pier" },
    { bankvalue: 7010, value: 337, label: "Pussellawa" },
    { bankvalue: 7010, value: 338, label: "Savalkaddu" },
    { bankvalue: 7010, value: 339, label: "Sirupvaluedy" },
    { bankvalue: 7010, value: 340, label: "Wattegama" },
    { bankvalue: 7010, value: 341, label: "Puthukudieruppu" },
    { bankvalue: 7010, value: 342, label: "Puthukulam" },
    { bankvalue: 7010, value: 343, label: "Uva Paranagama" },
    { bankvalue: 7010, value: 344, label: "Pesalai" },
    { bankvalue: 7010, value: 345, label: "Poonagary" },
    { bankvalue: 7010, value: 346, label: "Poovarasankulam" },
    { bankvalue: 7010, value: 347, label: "Punnalaikadduvan" },
    { bankvalue: 7010, value: 348, label: "Padiyatalawa" },
    { bankvalue: 7010, value: 349, label: "Mallavi" },
    { bankvalue: 7010, value: 351, label: "Manthikai" },
    { bankvalue: 7010, value: 352, label: "Maruthankerny" },
    { bankvalue: 7010, value: 353, label: "Mulankavil" },
    { bankvalue: 7010, value: 355, label: "Mullativu" },
    { bankvalue: 7010, value: 356, label: "Murungan" },
    { bankvalue: 7010, value: 357, label: "Nainativu" },
    { bankvalue: 7010, value: 358, label: "Nallur" },
    { bankvalue: 7010, value: 359, label: "Nanatan" },
    { bankvalue: 7010, value: 360, label: "Nedunkerny" },
    { bankvalue: 7010, value: 361, label: "Oddusudan" },
    { bankvalue: 7010, value: 362, label: "Omanthai" },
    { bankvalue: 7010, value: 363, label: "Pallai" },
    { bankvalue: 7010, value: 364, label: "Paranthan" },
    { bankvalue: 7010, value: 365, label: "Hasalaka" },
    { bankvalue: 7010, value: 366, label: "Jaffna Bus Stand" },
    { bankvalue: 7010, value: 368, label: "Jaffna Main Street" },
    { bankvalue: 7010, value: 369, label: "Jaffna University" },
    { bankvalue: 7010, value: 370, label: "Kaithady" },
    { bankvalue: 7010, value: 371, label: "Kalviyankadu" },
    { bankvalue: 7010, value: 372, label: "Karanavai" },
    { bankvalue: 7010, value: 373, label: "Kayts" },
    { bankvalue: 7010, value: 375, label: "Kodikamam" },
    { bankvalue: 7010, value: 376, label: "Kokuvil" },
    { bankvalue: 7010, value: 377, label: "Illavalai" },
    { bankvalue: 7010, value: 378, label: "Madhu" },
    { bankvalue: 7010, value: 379, label: "Wariyapola" },
    { bankvalue: 7010, value: 380, label: "Alaveddy" },
    { bankvalue: 7010, value: 381, label: "Andankulam" },
    { bankvalue: 7010, value: 382, label: "Cheddikulam" },
    { bankvalue: 7010, value: 383, label: "Delft" },
    { bankvalue: 7010, value: 384, label: "Meegahakiwula" },
    { bankvalue: 7010, value: 385, label: "Vavunathivu" },
    { bankvalue: 7010, value: 386, label: "Vellaveli" },
    { bankvalue: 7010, value: 388, label: "Diyabeduma" },
    { bankvalue: 7010, value: 389, label: "Diyasenpura" },
    { bankvalue: 7010, value: 390, label: "Doramadalawa" },
    { bankvalue: 7010, value: 391, label: "Galamuna" },
    { bankvalue: 7010, value: 392, label: "General Hospital, A' pura" },
    { bankvalue: 7010, value: 393, label: "Habarana" },
    { bankvalue: 7010, value: 394, label: "Minneriya" },
    { bankvalue: 7010, value: 395, label: "Padaviya" },
    { bankvalue: 7010, value: 396, label: "Rajanganaya" },
    { bankvalue: 7010, value: 397, label: "Rajina Junction" },
    { bankvalue: 7010, value: 398, label: "Ranajayapura" },
    { bankvalue: 7010, value: 399, label: "Sevanapitiya" },
    { bankvalue: 7010, value: 400, label: "Thalawa" },
    { bankvalue: 7010, value: 401, label: "Ayagama" },
    { bankvalue: 7010, value: 402, label: "Oddamavady" },
    { bankvalue: 7010, value: 403, label: "Oluwil" },
    { bankvalue: 7010, value: 404, label: "Palugamam" },
    { bankvalue: 7010, value: 405, label: "Polwatte" },
    { bankvalue: 7010, value: 406, label: "Palmuddai" },
    { bankvalue: 7010, value: 407, label: "Sainthamarathu" },
    { bankvalue: 7010, value: 408, label: "Serunuwara" },
    { bankvalue: 7010, value: 409, label: "Thambiluvil" },
    { bankvalue: 7010, value: 410, label: "Thampalakamam" },
    { bankvalue: 7010, value: 411, label: "Thoppur" },
    { bankvalue: 7010, value: 413, label: "Uhana" },
    { bankvalue: 7010, value: 414, label: "Uppuvely" },
    { bankvalue: 7010, value: 415, label: "Vakarai" },
    { bankvalue: 7010, value: 416, label: "Siyambalanduwa" },
    { bankvalue: 7010, value: 417, label: "Mollipothana" },
    { bankvalue: 7010, value: 418, label: "Morawewa" },
    { bankvalue: 7010, value: 419, label: "Navithanvely" },
    { bankvalue: 7010, value: 420, label: "Nilavely" },
    { bankvalue: 7010, value: 421, label: "Seeduwa" },
    { bankvalue: 7010, value: 422, label: "Malwatte" },
    { bankvalue: 7010, value: 423, label: "Mamangama" },
    { bankvalue: 7010, value: 424, label: "Maruthamunai" },
    { bankvalue: 7010, value: 425, label: "Pundaluoya" },
    { bankvalue: 7010, value: 426, label: "Kallady" },
    { bankvalue: 7010, value: 427, label: "Kallar" },
    { bankvalue: 7010, value: 428, label: "Karadiyanaru" },
    { bankvalue: 7010, value: 429, label: "Karaitivu" },
    { bankvalue: 7010, value: 430, label: "Kiran" },
    { bankvalue: 7010, value: 431, label: "Kokkadicholai" },
    { bankvalue: 7010, value: 432, label: "Galewela" },
    { bankvalue: 7010, value: 433, label: "Divulapitiya" },
    { bankvalue: 7010, value: 434, label: "Wellawaya" },
    { bankvalue: 7010, value: 436, label: "China Bay" },
    { bankvalue: 7010, value: 437, label: "Eastern University" },
    { bankvalue: 7010, value: 438, label: "Gonagolla" },
    { bankvalue: 7010, value: 439, label: "Irakkamam" },
    { bankvalue: 7010, value: 440, label: "Samanthurai" },
    { bankvalue: 7010, value: 441, label: "Pujapitiya" },
    { bankvalue: 7010, value: 442, label: "Ragala" },
    { bankvalue: 7010, value: 443, label: "Sigiriya" },
    { bankvalue: 7010, value: 444, label: "Ukuwela" },
    { bankvalue: 7010, value: 445, label: "University Of Peradeniya" },
    { bankvalue: 7010, value: 446, label: "Upcott" },
    { bankvalue: 7010, value: 447, label: "Wilgamuwa" },
    { bankvalue: 7010, value: 448, label: "Addalachchenai" },
    { bankvalue: 7010, value: 449, label: "Alankerny" },
    { bankvalue: 7010, value: 451, label: "Araiyampathy" },
    { bankvalue: 7010, value: 452, label: "Batticaloa Town" },
    { bankvalue: 7010, value: 453, label: "Independent  Square" },
    { bankvalue: 7010, value: 454, label: "Kandy Hospital" },
    { bankvalue: 7010, value: 455, label: "Kotagala" },
    { bankvalue: 7010, value: 456, label: "Marassana" },
    { bankvalue: 7010, value: 458, label: "Meepilimana" },
    { bankvalue: 7010, value: 459, label: "Menikhinna" },
    { bankvalue: 7010, value: 461, label: "Palapathwela" },
    { bankvalue: 7010, value: 462, label: "Botanical Gardens Peradeniya" },
    { bankvalue: 7010, value: 463, label: "Haldummulla" },
    { bankvalue: 7010, value: 464, label: "Pallekelle" },
    { bankvalue: 7010, value: 465, label: "Bokkawala" },
    { bankvalue: 7010, value: 466, label: "Danture" },
    { bankvalue: 7010, value: 467, label: "Daulagala" },
    { bankvalue: 7010, value: 469, label: "Digana Village" },
    { bankvalue: 7010, value: 470, label: "Gampola City" },
    { bankvalue: 7010, value: 472, label: "Hatharaliyadda" },
    { bankvalue: 7010, value: 471, label: "Ginigathhena" },
    { bankvalue: 7010, value: 473, label: "Kandy City Centre" },
    { bankvalue: 7010, value: 474, label: "Court Complex Kandy" },
    { bankvalue: 7010, value: 476, label: "Ettampitiya" },
    { bankvalue: 7010, value: 477, label: "Yatiyantota" },
    { bankvalue: 7010, value: 487, label: "Adikarigama" },
    { bankvalue: 7010, value: 488, label: "Agarapathana" },
    { bankvalue: 7010, value: 489, label: "Akurana" },
    { bankvalue: 7010, value: 490, label: "Ankumbura" },
    { bankvalue: 7010, value: 491, label: "Bogawantalawa" },
    { bankvalue: 7010, value: 492, label: "Padiyapelella" },
    { bankvalue: 7010, value: 494, label: "Andiambalama" },
    { bankvalue: 7010, value: 497, label: "Dankotuwa" },
    { bankvalue: 7010, value: 498, label: "Alawwa" },
    { bankvalue: 7010, value: 499, label: "Wijerama Junction" },
    { bankvalue: 7010, value: 500, label: "Jaffna 2nd Branch" },
    { bankvalue: 7010, value: 501, label: "Chavakachcheri" },
    { bankvalue: 7010, value: 502, label: "Kaduruwela" },
    { bankvalue: 7010, value: 503, label: "Passara" },
    { bankvalue: 7010, value: 504, label: "Devinuwara" },
    { bankvalue: 7010, value: 505, label: "Wattala" },
    { bankvalue: 7010, value: 506, label: "Maskeliya" },
    { bankvalue: 7010, value: 507, label: "Kahawatte" },
    { bankvalue: 7010, value: 508, label: "Wennappuwa" },
    { bankvalue: 7010, value: 509, label: "Hingurana" },
    { bankvalue: 7010, value: 510, label: "Kalmunai" },
    { bankvalue: 7010, value: 511, label: "Mulliyawalai" },
    { bankvalue: 7010, value: 512, label: "Thimbirigasyaya" },
    { bankvalue: 7010, value: 513, label: "Kurunegala Bazaar" },
    { bankvalue: 7010, value: 514, label: "Galnewa" },
    { bankvalue: 7010, value: 515, label: "Bandarawela" },
    { bankvalue: 7010, value: 516, label: "Thalawathugoda" },
    { bankvalue: 7010, value: 517, label: "Walasmulla" },
    { bankvalue: 7010, value: 518, label: "Mvaluedeniya" },
    { bankvalue: 7010, value: 520, label: "Sri Jayawardenapura Hospital" },
    { bankvalue: 7010, value: 521, label: "Hyde Park" },
    { bankvalue: 7010, value: 522, label: "Batapola" },
    { bankvalue: 7010, value: 524, label: "Geli Oya" },
    { bankvalue: 7010, value: 525, label: "Baddegama" },
    { bankvalue: 7010, value: 526, label: "Polgahawela" },
    { bankvalue: 7010, value: 527, label: "Welisara" },
    { bankvalue: 7010, value: 528, label: "Deniyaya" },
    { bankvalue: 7010, value: 529, label: "Kamburupitiya" },
    { bankvalue: 7010, value: 530, label: "Avissawella" },
    { bankvalue: 7010, value: 531, label: "Talawakelle" },
    { bankvalue: 7010, value: 532, label: "Rvalueigama" },
    { bankvalue: 7010, value: 533, label: "Pitakotte" },
    { bankvalue: 7010, value: 534, label: "Narammala" },
    { bankvalue: 7010, value: 535, label: "Embilipitiya" },
    { bankvalue: 7010, value: 536, label: "Kegalle Bazaar" },
    { bankvalue: 7010, value: 537, label: "Ambalantota" },
    { bankvalue: 7010, value: 538, label: "Tissamaharama" },
    { bankvalue: 7010, value: 539, label: "Beliatta" },
    { bankvalue: 7010, value: 540, label: "Badalkumbura" },
    { bankvalue: 7010, value: 541, label: "Pelawatte City Kalutara" },
    { bankvalue: 7010, value: 542, label: "Mahiyangana" },
    { bankvalue: 7010, value: 543, label: "Kiribathgoda" },
    { bankvalue: 7010, value: 544, label: "Madampe" },
    { bankvalue: 7010, value: 545, label: "Minuwangoda" },
    { bankvalue: 7010, value: 546, label: "Pannala" },
    { bankvalue: 7010, value: 547, label: "Nikaweratiya" },
    { bankvalue: 7010, value: 548, label: "Anamaduwa" },
    { bankvalue: 7010, value: 549, label: "Galgamuwa" },
    { bankvalue: 7010, value: 550, label: "Weligama" },
    { bankvalue: 7010, value: 551, label: "Anuradhapura Bazzar" },
    { bankvalue: 7010, value: 552, label: "National Institute Of Education" },
    { bankvalue: 7010, value: 553, label: "Giriulla" },
    { bankvalue: 7010, value: 554, label: "Bingiriya" },
    { bankvalue: 7010, value: 555, label: "Melsiripura" },
    { bankvalue: 7010, value: 556, label: "Matugama" },
    { bankvalue: 7010, value: 557, label: "Moratumulla" },
    { bankvalue: 7010, value: 558, label: "Waikkal" },
    { bankvalue: 7010, value: 559, label: "Mawanella" },
    { bankvalue: 7010, value: 560, label: "Buttala" },
    { bankvalue: 7010, value: 561, label: "Dematagoda" },
    { bankvalue: 7010, value: 562, label: "Warakapola" },
    { bankvalue: 7010, value: 563, label: "Dharga Town" },
    { bankvalue: 7010, value: 564, label: "Maho" },
    { bankvalue: 7010, value: 565, label: "Madurankuliya" },
    { bankvalue: 7010, value: 566, label: "Aranayake" },
    { bankvalue: 7010, value: 567, label: "Dedicated Economic Centre - Meegoda" },
    { bankvalue: 7010, value: 568, label: "Homagama" },
    { bankvalue: 7010, value: 569, label: "Hiripitiya" },
    { bankvalue: 7010, value: 570, label: "Hettipola" },
    { bankvalue: 7010, value: 571, label: "Kirindiwela" },
    { bankvalue: 7010, value: 572, label: "Negombo Bazzar" },
    { bankvalue: 7010, value: 573, label: "Central Bus Stand" },
    { bankvalue: 7010, value: 574, label: "Mankulam" },
    { bankvalue: 7010, value: 575, label: "Gampola" },
    { bankvalue: 7010, value: 576, label: "Dambulla" },
    { bankvalue: 7010, value: 577, label: "Lunugala" },
    { bankvalue: 7010, value: 578, label: "Yakkalamulla" },
    { bankvalue: 7010, value: 579, label: "Bibile" },
    { bankvalue: 7010, value: 580, label: "Dummalasuriya" },
    { bankvalue: 7010, value: 581, label: "Madawala" },
    { bankvalue: 7010, value: 582, label: "Rambukkana" },
    { bankvalue: 7010, value: 583, label: "Mattegoda" },
    { bankvalue: 7010, value: 584, label: "Wadduwa" },
    { bankvalue: 7010, value: 585, label: "Ruwanwella" },
    { bankvalue: 7010, value: 587, label: "Pilimatalawa" },
    { bankvalue: 7010, value: 588, label: "Peradeniya" },
    { bankvalue: 7010, value: 589, label: "Kalpitiya" },
    { bankvalue: 7010, value: 590, label: "Akkaraipattu" },
    { bankvalue: 7010, value: 591, label: "Nintavur" },
    { bankvalue: 7010, value: 592, label: "Dikwella" },
    { bankvalue: 7010, value: 593, label: "Milagiriya" },
    { bankvalue: 7010, value: 594, label: "Rakwana" },
    { bankvalue: 7010, value: 595, label: "Kolonnawa" },
    { bankvalue: 7010, value: 596, label: "Talgaswela" },
    { bankvalue: 7010, value: 597, label: "Nivitigala" },
    { bankvalue: 7010, value: 598, label: "Nawalapitiya" },
    { bankvalue: 7010, value: 599, label: "Aralaganwila" },
    { bankvalue: 7010, value: 600, label: "Jayanthipura" },
    { bankvalue: 7010, value: 601, label: "Hingurakgoda" },
    { bankvalue: 7010, value: 602, label: "Kirulapana" },
    { bankvalue: 7010, value: 603, label: "Lanka Hospital" },
    { bankvalue: 7010, value: 604, label: "Ingiriya" },
    { bankvalue: 7010, value: 605, label: "Kankesanthurai" },
    { bankvalue: 7010, value: 606, label: "Uda Dumbara" },
    { bankvalue: 7010, value: 607, label: "Panadura Bazaar" },
    { bankvalue: 7010, value: 608, label: "Kaduwela" },
    { bankvalue: 7010, value: 609, label: "Hikkaduwa" },
    { bankvalue: 7010, value: 610, label: "Pitigala" },
    { bankvalue: 7010, value: 611, label: "Kaluwanchikudy" },
    { bankvalue: 7010, value: 612, label: "Lake View" },
    { bankvalue: 7010, value: 613, label: "Akuressa" },
    { bankvalue: 7010, value: 614, label: "Matara City" },
    { bankvalue: 7010, value: 615, label: "Galagedera" },
    { bankvalue: 7010, value: 616, label: "Kataragama" },
    { bankvalue: 7010, value: 617, label: "Keselwatte" },
    { bankvalue: 7010, value: 618, label: "Metropolitan" },
    { bankvalue: 7010, value: 619, label: "Elpitiya" },
    { bankvalue: 7010, value: 620, label: "Kesbewa" },
    { bankvalue: 7010, value: 621, label: "Kebithigollawa" },
    { bankvalue: 7010, value: 622, label: "Kahatagasdigiliya" },
    { bankvalue: 7010, value: 623, label: "Kantale Bazaar" },
    { bankvalue: 7010, value: 624, label: "Trincomalee Bazaar" },
    { bankvalue: 7010, value: 625, label: "Katukurunda" },
    { bankvalue: 7010, value: 626, label: "Valachchenai" },
    { bankvalue: 7010, value: 627, label: "Regent Street" },
    { bankvalue: 7010, value: 628, label: "Grandpass" },
    { bankvalue: 7010, value: 629, label: "Koslanda" },
    { bankvalue: 7010, value: 630, label: "Chenkalady" },
    { bankvalue: 7010, value: 631, label: "Katubedda Campus" },
    { bankvalue: 7010, value: 633, label: "Kandapola" },
    { bankvalue: 7010, value: 634, label: "Dehiowita" },
    { bankvalue: 7010, value: 636, label: "Lake House" },
    { bankvalue: 7010, value: 638, label: "Nelliady" },
    { bankvalue: 7010, value: 639, label: "Rattota" },
    { bankvalue: 7010, value: 640, label: "Pallepola" },
    { bankvalue: 7010, value: 641, label: "Medirigiriya" },
    { bankvalue: 7010, value: 642, label: "Deraniyagala" },
    { bankvalue: 7010, value: 643, label: "Gonapola" },
    { bankvalue: 7010, value: 644, label: "Parliamentary Complex" },
    { bankvalue: 7010, value: 645, label: "Kalawana" },
    { bankvalue: 7010, value: 646, label: "Boralesgamuwa" },
    { bankvalue: 7010, value: 647, label: "Lunuwatte" },
    { bankvalue: 7010, value: 648, label: "Kattankudy" },
    { bankvalue: 7010, value: 649, label: "Kandy 2nd" },
    { bankvalue: 7010, value: 650, label: "Talatuoya" },
    { bankvalue: 7010, value: 651, label: "Bombuwela" },
    { bankvalue: 7010, value: 652, label: "Bakamuna" },
    { bankvalue: 7010, value: 653, label: "Galkiriyagama" },
    { bankvalue: 7010, value: 654, label: "Madatugama" },
    { bankvalue: 7010, value: 655, label: "Tambuttegama" },
    { bankvalue: 7010, value: 656, label: "Nochchiyagama" },
    { bankvalue: 7010, value: 657, label: "Agalawatta" },
    { bankvalue: 7010, value: 658, label: "Katunayake I.P.Z." },
    { bankvalue: 7010, value: 659, label: "Gurugoda" },
    { bankvalue: 7010, value: 660, label: "Corporate" },
    { bankvalue: 7010, value: 662, label: "Baduraliya" },
    { bankvalue: 7010, value: 663, label: "Kotahena" },
    { bankvalue: 7010, value: 664, label: "Pothuhera" },
    { bankvalue: 7010, value: 665, label: "Bandaragama" },
    { bankvalue: 7010, value: 666, label: "Katugastota" },
    { bankvalue: 7010, value: 667, label: "Neluwa" },
    { bankvalue: 7010, value: 668, label: "Borella  2nd" },
    { bankvalue: 7010, value: 669, label: "Girandurukotte" },
    { bankvalue: 7010, value: 670, label: "Kollupitiya 2nd" },
    { bankvalue: 7010, value: 672, label: "Central Super Market" },
    { bankvalue: 7010, value: 673, label: "Bulathsinhala" },
    { bankvalue: 7010, value: 674, label: "Enderamulla" },
    { bankvalue: 7010, value: 675, label: "Nittambuwa" },
    { bankvalue: 7010, value: 676, label: "Kekirawa" },
    { bankvalue: 7010, value: 677, label: "Weliweriya" },
    { bankvalue: 7010, value: 678, label: "Padukka" },
    { bankvalue: 7010, value: 679, label: "Battaramulla" },
    { bankvalue: 7010, value: 680, label: "Aluthgama" },
    { bankvalue: 7010, value: 681, label: "Personal" },
    { bankvalue: 7010, value: 682, label: "Veyangoda" },
    { bankvalue: 7010, value: 683, label: "Pelmadulla" },
    { bankvalue: 7010, value: 684, label: "Ratnapura Bazaar" },
    { bankvalue: 7010, value: 685, label: "Ward Place" },
    { bankvalue: 7010, value: 686, label: "Dehiattakandiya" },
    { bankvalue: 7010, value: 687, label: "Raddolugama" },
    { bankvalue: 7010, value: 688, label: "Balangoda" },
    { bankvalue: 7010, value: 689, label: "Ratmalana" },
    { bankvalue: 7010, value: 690, label: "Pelawatta" },
    { bankvalue: 7010, value: 691, label: "Hakmana" },
    { bankvalue: 7010, value: 692, label: "Eppawala" },
    { bankvalue: 7010, value: 693, label: "Ruhunu Campus" },
    { bankvalue: 7010, value: 699, label: "Bogahakumbura" },
    { bankvalue: 7010, value: 700, label: "Dambagalla" },
    { bankvalue: 7010, value: 701, label: "Ella" },
    { bankvalue: 7010, value: 702, label: "Ethiliwewa" },
    { bankvalue: 7010, value: 703, label: "Keppetipola" },
    { bankvalue: 7010, value: 705, label: "Moneragala Town" },
    { bankvalue: 7010, value: 706, label: "Okkampitiya" },
    { bankvalue: 7010, value: 707, label: "Pelawatta" },
    { bankvalue: 7010, value: 708, label: "Batuwatte" },
    { bankvalue: 7010, value: 711, label: "Bopitiya" },
    { bankvalue: 7010, value: 713, label: "Asiri Central" },
    {
      bankvalue: 7010,
      value: 714,
      label: "Katuwellegama Courtaulds Clothing Lanka (Pvt) Ltd",
    },
    { bankvalue: 7010, value: 715, label: "Dalugama" },
    { bankvalue: 7010, value: 716, label: "Delgoda" },
    { bankvalue: 7010, value: 718, label: "Fish Market Peliyagoda" },
    { bankvalue: 7010, value: 717, label: "Demanhandiya" },
    { bankvalue: 7010, value: 720, label: "Ganemulla" },
    { bankvalue: 7010, value: 721, label: "Gothatuwa" },
    { bankvalue: 7010, value: 723, label: "Mulleriyawa New Town" },
    { bankvalue: 7010, value: 722, label: "Katana" },
    { bankvalue: 7010, value: 724, label: "Naiwala" },
    { bankvalue: 7010, value: 728, label: "Meegalewa" },
    { bankvalue: 7010, value: 729, label: "Badulla City" },
    { bankvalue: 7010, value: 730, label: "Welimada" },
    { bankvalue: 7010, value: 731, label: "CEYBANK Credit card centre" },
    { bankvalue: 7010, value: 732, label: "Biyagama" },
    { bankvalue: 7010, value: 733, label: "Warapitiya" },
    { bankvalue: 7010, value: 735, label: "Kinniya" },
    { bankvalue: 7010, value: 736, label: "Piliyandala" },
    { bankvalue: 7010, value: 741, label: "Hanwella" },
    { bankvalue: 7010, value: 743, label: "Walapane" },
    { bankvalue: 7010, value: 744, label: "Walgama" },
    { bankvalue: 7010, value: 746, label: "Rajagiriya" },
    { bankvalue: 7010, value: 747, label: "Taprobane" },
    { bankvalue: 7010, value: 748, label: "Uragasmanhandiya" },
    { bankvalue: 7010, value: 749, label: "Karainagar" },
    { bankvalue: 7010, value: 750, label: "Koggala E.P.Z" },
    { bankvalue: 7010, value: 751, label: "Suriyawewa" },
    { bankvalue: 7010, value: 752, label: "Thihagoda" },
    { bankvalue: 7010, value: 753, label: "Udugama" },
    { bankvalue: 7010, value: 754, label: "Ahungalla" },
    { bankvalue: 7010, value: 757, label: "Athurugiriya" },
    { bankvalue: 7010, value: 760, label: "Treasury Division" },
    { bankvalue: 7010, value: 761, label: "Thirunelvely" },
    { bankvalue: 7010, value: 762, label: "Narahenpita" },
    { bankvalue: 7010, value: 763, label: "Malabe" },
    { bankvalue: 7010, value: 764, label: "Ragama" },
    { bankvalue: 7010, value: 765, label: "Pugoda" },
    { bankvalue: 7010, value: 766, label: "Mount Lavinia" },
    { bankvalue: 7010, value: 767, label: "Ranna" },
    { bankvalue: 7010, value: 768, label: "Alawathugoda" },
    { bankvalue: 7010, value: 769, label: "Yakkala" },
    { bankvalue: 7010, value: 770, label: "Ibbagamuwa" },
    { bankvalue: 7010, value: 771, label: "Kandana" },
    { bankvalue: 7010, value: 772, label: "Hemmathagama" },
    { bankvalue: 7010, value: 773, label: "Kottawa" },
    { bankvalue: 7010, value: 774, label: "Angunakolapelessa" },
    { bankvalue: 7010, value: 775, label: "Visakha" },
    { bankvalue: 7010, value: 776, label: "Islamic Banking Unit" },
    { bankvalue: 7010, value: 777, label: "Electronic Banking Unit" },
    { bankvalue: 7010, value: 778, label: "Atchuvely" },
    { bankvalue: 7010, value: 779, label: "Norchcholei" },
    { bankvalue: 7010, value: 780, label: "Kadawatha 2nd City" },
    { bankvalue: 7010, value: 781, label: "Teldeniya" },
    { bankvalue: 7010, value: 782, label: "Rambewa" },
    { bankvalue: 7010, value: 783, label: "Polpithigama" },
    { bankvalue: 7010, value: 784, label: "Deiyandara" },
    { bankvalue: 7010, value: 785, label: "Hali ela" },
    { bankvalue: 7010, value: 786, label: "Godakawela" },
    { bankvalue: 7010, value: 787, label: "Kopay" },
    { bankvalue: 7010, value: 788, label: "BOC premier" },
    { bankvalue: 7010, value: 789, label: "Makola" },
    { bankvalue: 7010, value: 790, label: "Eravur" },
    { bankvalue: 7010, value: 791, label: "Valvettithurai" },
    { bankvalue: 7010, value: 792, label: "Chankanai" },
    { bankvalue: 7010, value: 793, label: "Vavuniya City" },
    { bankvalue: 7010, value: 794, label: "Urumpirai" },
    { bankvalue: 7010, value: 795, label: "Boragas" },
    { bankvalue: 7010, value: 796, label: "Mattala Airport" },
    { bankvalue: 7010, value: 797, label: "Medawala" },
    { bankvalue: 7010, value: 798, label: "Thalduwa" },
    { bankvalue: 7010, value: 799, label: "Nelundeniya" },
    { bankvalue: 7010, value: 800, label: "Wanduramba" },
    { bankvalue: 7010, value: 802, label: "Provincial Council Complex, Pallakelle" },
    { bankvalue: 7010, value: 803, label: "Welioya-Sampath Nuwara" },
    { bankvalue: 7010, value: 804, label: "Vaddukoddai" },
    { bankvalue: 7010, value: 805, label: "Madawakkulama" },
    { bankvalue: 7010, value: 806, label: "Mahaoya" },
    { bankvalue: 7010, value: 808, label: "Bogaswewa" },
    { bankvalue: 7010, value: 809, label: "Kurunduwatte" },
    { bankvalue: 7010, value: 810, label: "Ethimale" },
    { bankvalue: 7010, value: 811, label: "Central Camp" },
    { bankvalue: 7010, value: 812, label: "Aladeniya" },
    { bankvalue: 7010, value: 813, label: "Kothalawala Defence University" },
    { bankvalue: 7010, value: 814, label: "Saliyawewa" },
    { bankvalue: 7010, value: 815, label: "Wahalkada" },
    { bankvalue: 7010, value: 816, label: "Pallebedda" },
    { bankvalue: 7010, value: 817, label: "Welikanda" },
    { bankvalue: 7010, value: 818, label: "Nagoda" },
    { bankvalue: 7010, value: 822, label: "Corporate 2nd" },
    { bankvalue: 7010, value: 999, label: "Head Office" },
  ],
  7038: [
    { bankvalue: 7038, value: 1, label: "Head Office" },
    { bankvalue: 7038, value: 2, label: "Colpetty" },
    { bankvalue: 7038, value: 3, label: "Wellawatte" },
    { bankvalue: 7038, value: 4, label: "Kiribathgoda" },
    { bankvalue: 7038, value: 5, label: "Kirullapone" },
    { bankvalue: 7038, value: 6, label: "Moratuwa" },
    { bankvalue: 7038, value: 7, label: "Rajagiriya" },
    { bankvalue: 7038, value: 10, label: "Pettah" },
    { bankvalue: 7038, value: 11, label: "Priority Banking Centre" },
    { bankvalue: 7038, value: 999, label: "Head Office" },
  ],
  7047: [
    { bankvalue: 7047, value: 0, label: "Head Office" },
    { bankvalue: 7047, value: 1, label: "Head Office" },
    { bankvalue: 7047, value: 100, label: "Cash Mgmt" },
    { bankvalue: 7047, value: 999, label: "Head Office" },
  ],
  7056: [
    { bankvalue: 7056, value: 0, label: "Head Office" },
    { bankvalue: 7056, value: 1, label: "Head Office" },
    { bankvalue: 7056, value: 2, label: "City Office" },
    { bankvalue: 7056, value: 3, label: "Foreign" },
    { bankvalue: 7056, value: 4, label: "Kandy" },
    { bankvalue: 7056, value: 5, label: "Galle Fort" },
    { bankvalue: 7056, value: 6, label: "Jaffna" },
    { bankvalue: 7056, value: 7, label: "Matara" },
    { bankvalue: 7056, value: 8, label: "Matale" },
    { bankvalue: 7056, value: 9, label: "Galewela" },
    { bankvalue: 7056, value: 10, label: "Wellawatte" },
    { bankvalue: 7056, value: 11, label: "Kollupitiya" },
    { bankvalue: 7056, value: 12, label: "Kotahena" },
    { bankvalue: 7056, value: 13, label: "Negombo" },
    { bankvalue: 7056, value: 14, label: "Hikkaduwa" },
    { bankvalue: 7056, value: 15, label: "Hingurakgoda" },
    { bankvalue: 7056, value: 16, label: "Kurunegala" },
    { bankvalue: 7056, value: 17, label: "Old Moor Street" },
    { bankvalue: 7056, value: 18, label: "Maharagama" },
    { bankvalue: 7056, value: 19, label: "Borella" },
    { bankvalue: 7056, value: 20, label: "Nugegoda" },
    { bankvalue: 7056, value: 21, label: "Kegalle" },
    { bankvalue: 7056, value: 22, label: "Narahenpita" },
    { bankvalue: 7056, value: 23, label: "Mutuwal" },
    { bankvalue: 7056, value: 24, label: "Pettah" },
    { bankvalue: 7056, value: 25, label: "Katunayake FTZ" },
    { bankvalue: 7056, value: 26, label: "Wennappuwa" },
    { bankvalue: 7056, value: 27, label: "Galle City" },
    { bankvalue: 7056, value: 28, label: "Koggala" },
    { bankvalue: 7056, value: 29, label: "Battaramulla" },
    { bankvalue: 7056, value: 30, label: "Embilipitiya" },
    { bankvalue: 7056, value: 31, label: "Kandana" },
    { bankvalue: 7056, value: 32, label: "Maradana" },
    { bankvalue: 7056, value: 33, label: "Minuwangoda" },
    { bankvalue: 7056, value: 34, label: "Nuwara Eliya" },
    { bankvalue: 7056, value: 35, label: "Akuressa" },
    { bankvalue: 7056, value: 36, label: "Kalutara" },
    { bankvalue: 7056, value: 37, label: "Trincomalee" },
    { bankvalue: 7056, value: 38, label: "Panchikawatte" },
    { bankvalue: 7056, value: 39, label: "Keyzer Street" },
    { bankvalue: 7056, value: 40, label: "Aluthgama" },
    { bankvalue: 7056, value: 41, label: "Panadura" },
    { bankvalue: 7056, value: 42, label: "Kaduwela" },
    { bankvalue: 7056, value: 43, label: "Chilaw" },
    { bankvalue: 7056, value: 44, label: "Gampaha" },
    { bankvalue: 7056, value: 45, label: "Katugastota" },
    { bankvalue: 7056, value: 46, label: "Ratmalana" },
    { bankvalue: 7056, value: 47, label: "Kirulapona" },
    { bankvalue: 7056, value: 48, label: "Union Place" },
    { bankvalue: 7056, value: 49, label: "Ratnapura" },
    { bankvalue: 7056, value: 50, label: "Colombo 07" },
    { bankvalue: 7056, value: 51, label: "Kuliyapitiya" },
    { bankvalue: 7056, value: 52, label: "Badulla" },
    { bankvalue: 7056, value: 53, label: "Anuradhapura" },
    { bankvalue: 7056, value: 54, label: "Dambulla" },
    { bankvalue: 7056, value: 55, label: "Nattandiya" },
    { bankvalue: 7056, value: 56, label: "Wattala" },
    { bankvalue: 7056, value: 57, label: "Grandpass" },
    { bankvalue: 7056, value: 58, label: "Dehiwala" },
    { bankvalue: 7056, value: 59, label: "Moratuwa" },
    { bankvalue: 7056, value: 60, label: "Narammala" },
    { bankvalue: 7056, value: 61, label: "Vavuniya" },
    { bankvalue: 7056, value: 62, label: "Rajagiriya" },
    { bankvalue: 7056, value: 63, label: "Ambalantota" },
    { bankvalue: 7056, value: 64, label: "Seeduwa" },
    { bankvalue: 7056, value: 65, label: "Nittambuwa" },
    { bankvalue: 7056, value: 66, label: "Mirigama" },
    { bankvalue: 7056, value: 67, label: "Kadawatha" },
    { bankvalue: 7056, value: 68, label: "Duplication Road" },
    { bankvalue: 7056, value: 69, label: "Kiribathgoda" },
    { bankvalue: 7056, value: 70, label: "Avissawella" },
    { bankvalue: 7056, value: 71, label: "Ekala  (CSP)" },
    { bankvalue: 7056, value: 72, label: "Pettah Main Street" },
    { bankvalue: 7056, value: 73, label: "Peradeniya  (CSP)" },
    { bankvalue: 7056, value: 74, label: "Kochchikade" },
    { bankvalue: 7056, value: 75, label: "Homagama" },
    { bankvalue: 7056, value: 76, label: "Horana" },
    { bankvalue: 7056, value: 77, label: "Piliyandala" },
    { bankvalue: 7056, value: 78, label: "Thalawathugoda (C.S.P.)" },
    { bankvalue: 7056, value: 79, label: "Mawanella" },
    { bankvalue: 7056, value: 80, label: "Bandarawela" },
    { bankvalue: 7056, value: 81, label: "Ja-Ela" },
    { bankvalue: 7056, value: 82, label: "Balangoda" },
    { bankvalue: 7056, value: 83, label: "Nikaweratiya" },
    { bankvalue: 7056, value: 84, label: "Bandaragama (C.S.P.)" },
    { bankvalue: 7056, value: 85, label: "Yakkala" },
    { bankvalue: 7056, value: 86, label: "Malabe (C.S.P.)" },
    { bankvalue: 7056, value: 87, label: "Kohuwala" },
    { bankvalue: 7056, value: 88, label: "Kaduruwela" },
    { bankvalue: 7056, value: 89, label: "Nawalapitiya" },
    { bankvalue: 7056, value: 93, label: "Mount Lavinia(C.S.P.)" },
    { bankvalue: 7056, value: 95, label: "Card Centre" },
    { bankvalue: 7056, value: 96, label: "Mathugama" },
    { bankvalue: 7056, value: 97, label: "Ambalangoda" },
    { bankvalue: 7056, value: 98, label: "Baddegama" },
    { bankvalue: 7056, value: 100, label: "Ampara" },
    { bankvalue: 7056, value: 101, label: "Nawala  (C.S.P.)" },
    { bankvalue: 7056, value: 102, label: "Gampola" },
    { bankvalue: 7056, value: 103, label: "Elpitiya" },
    { bankvalue: 7056, value: 104, label: "Kamburupitiya" },
    { bankvalue: 7056, value: 105, label: "Batticaloa" },
    { bankvalue: 7056, value: 106, label: "Bambalapitiya" },
    { bankvalue: 7056, value: 107, label: "Chunakkam" },
    { bankvalue: 7056, value: 108, label: "Nelliady" },
    { bankvalue: 7056, value: 109, label: "Pilimathalawa" },
    { bankvalue: 7056, value: 110, label: "Kekirawa" },
    { bankvalue: 7056, value: 111, label: "Deniyaya" },
    { bankvalue: 7056, value: 112, label: "Weligama" },
    { bankvalue: 7056, value: 113, label: "Baseline" },
    { bankvalue: 7056, value: 114, label: "Katubedda" },
    { bankvalue: 7056, value: 115, label: "Hatton" },
    { bankvalue: 7056, value: 116, label: "Revalue Avenue" },
    { bankvalue: 7056, value: 117, label: "Pitakotte CSP" },
    { bankvalue: 7056, value: 118, label: "Negombo Extension Office" },
    { bankvalue: 7056, value: 119, label: "Kotikawatta" },
    { bankvalue: 7056, value: 120, label: "Monaragala" },
    { bankvalue: 7056, value: 121, label: "Kottawa" },
    { bankvalue: 7056, value: 122, label: "Kurunegala City Office" },
    { bankvalue: 7056, value: 123, label: "Tangalle" },
    { bankvalue: 7056, value: 124, label: "Tissamaharama" },
    { bankvalue: 7056, value: 125, label: "Neluwa" },
    { bankvalue: 7056, value: 126, label: "Chavakachcheri" },
    { bankvalue: 7056, value: 127, label: "Stanley Road, Jaffna" },
    { bankvalue: 7056, value: 128, label: "Warakapola" },
    { bankvalue: 7056, value: 129, label: "Udugama" },
    { bankvalue: 7056, value: 130, label: "Athurugiriya" },
    { bankvalue: 7056, value: 131, label: "Raddolugama" },
    { bankvalue: 7056, value: 132, label: "Boralesgamuwa CSP" },
    { bankvalue: 7056, value: 133, label: "Kahawatta" },
    { bankvalue: 7056, value: 134, label: "Delkanda" },
    { bankvalue: 7056, value: 135, label: "karapitiya" },
    { bankvalue: 7056, value: 136, label: "Welimada" },
    { bankvalue: 7056, value: 137, label: "Mahiyanganaya" },
    { bankvalue: 7056, value: 138, label: "Kalawana" },
    { bankvalue: 7056, value: 139, label: "Kirindiwela" },
    { bankvalue: 7056, value: 140, label: "Digana" },
    { bankvalue: 7056, value: 141, label: "Polgahawela" },
    { bankvalue: 7056, value: 142, label: "Boralesgamuwa" },
    { bankvalue: 7056, value: 143, label: "Hanwella" },
    { bankvalue: 7056, value: 144, label: "Pannala" },
    { bankvalue: 7056, value: 145, label: "Ward Place" },
    { bankvalue: 7056, value: 146, label: "Wadduwa" },
    { bankvalue: 7056, value: 147, label: "Biyagama" },
    { bankvalue: 7056, value: 148, label: "Puttlam" },
    { bankvalue: 7056, value: 149, label: "Pelmadulla" },
    { bankvalue: 7056, value: 150, label: "Kandy City office" },
    { bankvalue: 7056, value: 151, label: "Matara City office" },
    { bankvalue: 7056, value: 152, label: "Kalmunai" },
    { bankvalue: 7056, value: 153, label: "Manipay" },
    { bankvalue: 7056, value: 154, label: "Mannar" },
    { bankvalue: 7056, value: 155, label: "Kilinochchi" },
    { bankvalue: 7056, value: 156, label: "Atchchuvely" },
    { bankvalue: 7056, value: 157, label: "Thirunelvely" },
    { bankvalue: 7056, value: 158, label: "Eheliyagoda" },
    { bankvalue: 7056, value: 159, label: "Valachchenai" },
    { bankvalue: 7056, value: 160, label: "Wellawaya" },
    { bankvalue: 7056, value: 161, label: "Mawathagama" },
    { bankvalue: 7056, value: 162, label: "Thambuttegama" },
    { bankvalue: 7056, value: 163, label: "Ruwanwella" },
    { bankvalue: 7056, value: 164, label: "Dankotuwa" },
    { bankvalue: 7056, value: 165, label: "Peliyagoda CSP" },
    { bankvalue: 7056, value: 166, label: "Hambantota" },
    { bankvalue: 7056, value: 167, label: "Katubedda CSP" },
    { bankvalue: 7056, value: 168, label: "Chenkalady" },
    { bankvalue: 7056, value: 169, label: "Priority Banking Centre" },
    { bankvalue: 7056, value: 170, label: "Velanai" },
    { bankvalue: 7056, value: 171, label: "Vavuniya Extension Office" },
    { bankvalue: 7056, value: 172, label: "Akkaraipattu" },
    { bankvalue: 7056, value: 173, label: "Kataragama" },
    { bankvalue: 7056, value: 174, label: "Wariyapola" },
    { bankvalue: 7056, value: 175, label: "Kuruwita" },
    { bankvalue: 7056, value: 176, label: "Mvaluedeniya" },
    { bankvalue: 7056, value: 177, label: "Ganemulla" },
    { bankvalue: 7056, value: 178, label: "World Trade Centre" },
    { bankvalue: 7056, value: 179, label: "Wellawatte 2nd" },
    { bankvalue: 7056, value: 180, label: "Divulapitiya" },
    { bankvalue: 7056, value: 181, label: "Beliatta" },
    { bankvalue: 7056, value: 182, label: "Giriulla" },
    { bankvalue: 7056, value: 183, label: "Marawila" },
    { bankvalue: 7056, value: 184, label: "Thalawakelle" },
    { bankvalue: 7056, value: 185, label: "Anuradhapura New Town" },
    { bankvalue: 7056, value: 186, label: "Passara" },
    { bankvalue: 7056, value: 187, label: "Padukka" },
    { bankvalue: 7056, value: 188, label: "Alawwa" },
    { bankvalue: 7056, value: 189, label: "Panadura Second" },
    { bankvalue: 7056, value: 190, label: "Katunayake 24/7 Centre" },
    { bankvalue: 7056, value: 193, label: "Kattankudy" },
    { bankvalue: 7056, value: 194, label: "Pottuvil" },
    { bankvalue: 7056, value: 195, label: "Maskeliya" },
    { bankvalue: 7056, value: 196, label: "Liberty Plaza CSP" },
    { bankvalue: 7056, value: 197, label: "Godakawela CSP" },
    { bankvalue: 7056, value: 198, label: "Kodikamam" },
    { bankvalue: 7056, value: 199, label: "Makola" },
    { bankvalue: 7056, value: 200, label: "Medawachchiya" },
    { bankvalue: 7056, value: 204, label: "SLIC- CSP" },
    { bankvalue: 7056, value: 206, label: "Palavi" },
    { bankvalue: 7056, value: 208, label: "Nawala (Minicom)" },
    { bankvalue: 7056, value: 209, label: "Maharagama Minicom" },
    { bankvalue: 7056, value: 210, label: "Moratuwa Minicom" },
    { bankvalue: 7056, value: 211, label: "Union Place (Keells Super) Branch" },
    { bankvalue: 7056, value: 212, label: "Kurunegala Service Point" },
    { bankvalue: 7056, value: 213, label: "Ratnapura (Laugfs Super)" },
    { bankvalue: 7056, value: 216, label: "Ramanayake Mawatha" },
    { bankvalue: 7056, value: 217, label: "Kirulapone Minicom" },
    { bankvalue: 7056, value: 218, label: "Gampaha (Minicom)" },
    { bankvalue: 7056, value: 219, label: "Akurana Service Point" },
    { bankvalue: 7056, value: 220, label: "Beruwala CSP" },
    { bankvalue: 7056, value: 221, label: "Ragama" },
    { bankvalue: 7056, value: 222, label: "Matara Minicom Centre" },
    { bankvalue: 7056, value: 223, label: "Panadura CSP" },
    { bankvalue: 7056, value: 224, label: "Horana Service Point" },
    { bankvalue: 7056, value: 225, label: "Wattala Minicom" },
    { bankvalue: 7056, value: 226, label: "Bokundara CSP" },
    { bankvalue: 7056, value: 227, label: "Katukurunda CSP" },
    { bankvalue: 7056, value: 228, label: "Weliweria" },
    { bankvalue: 7056, value: 229, label: "Mulliyawalai" },
    { bankvalue: 7056, value: 230, label: "Ja - Ela (Minicom)" },
    { bankvalue: 7056, value: 231, label: "Attvalueiya" },
    { bankvalue: 7056, value: 232, label: "Hulftsdorp" },
    { bankvalue: 7056, value: 233, label: "Rajagiriya (Keells Super)" },
    { bankvalue: 7056, value: 234, label: "Kadawatha (Arpico Super)" },
    { bankvalue: 7056, value: 236, label: "Urubokka" },
    { bankvalue: 7056, value: 237, label: "Colombo Gold Centre" },
    { bankvalue: 7056, value: 238, label: "Nawinna (Arpico Super)" },
    { bankvalue: 7056, value: 239, label: "Chankanai" },
    { bankvalue: 7056, value: 240, label: "Batapola" },
    { bankvalue: 7056, value: 241, label: "Nochchiyagama" },
    { bankvalue: 7056, value: 242, label: "Batticaloa Second" },
    { bankvalue: 7056, value: 244, label: "Badulla Second" },
    { bankvalue: 7056, value: 245, label: "Katugastota (Minicom)" },
    { bankvalue: 7056, value: 246, label: "Wattgama" },
    { bankvalue: 7056, value: 247, label: "Bandarawatta (Laugfs Super)" },
    { bankvalue: 7056, value: 248, label: "Wennapuwa (Arpico) Super" },
    { bankvalue: 7056, value: 249, label: "BIA Counter" },
    { bankvalue: 7056, value: 250, label: "Veyangoda" },
    { bankvalue: 7056, value: 251, label: "Dehiwala Arpico Super Centre" },
    { bankvalue: 7056, value: 252, label: "Batharamulla Arpico Super Centre" },
    { bankvalue: 7056, value: 253, label: "Hydepark Arpico Service Center" },
    { bankvalue: 7056, value: 254, label: "Anniwatte CSP" },
    { bankvalue: 7056, value: 255, label: "Kundasale" },
    { bankvalue: 7056, value: 256, label: "Negambo Arpico Super Centre" },
    { bankvalue: 7056, value: 257, label: "Kiribathgoda Service Point" },
    { bankvalue: 7056, value: 258, label: "Holvalueay Banking Centre - Majestic City" },
    { bankvalue: 7056, value: 259, label: "Nawam Mawatha" },
    { bankvalue: 7056, value: 260, label: "Wattala Arpico Super Center" },
    { bankvalue: 7056, value: 261, label: "Nittambuwa CSP" },
    { bankvalue: 7056, value: 262, label: "Pelawatte CSP" },
    { bankvalue: 7056, value: 264, label: "Handala (Minicom)" },
    { bankvalue: 7056, value: 265, label: "Gelioya Service Point" },
    { bankvalue: 7056, value: 266, label: "Kohuwala Service Point" },
    { bankvalue: 7056, value: 267, label: "Kalutara (Arpico Super)" },
    { bankvalue: 7056, value: 268, label: "Mattegoda (Laugfs Super)" },
    { bankvalue: 7056, value: 269, label: "Delgoda Laugfs Super" },
    { bankvalue: 7056, value: 270, label: "Orion City ABC" },
    { bankvalue: 7056, value: 271, label: "Galle Main Street" },
    { bankvalue: 7056, value: 272, label: "Kilinochchi Minicom" },
    { bankvalue: 7056, value: 274, label: "Point Pedro" },
    { bankvalue: 7056, value: 275, label: "Kelaniya" },
    { bankvalue: 7056, value: 276, label: "Deiyandara" },
    { bankvalue: 7056, value: 277, label: "Morawaka" },
    { bankvalue: 7056, value: 278, label: "Rambukkana" },
    { bankvalue: 7056, value: 279, label: "Katana" },
    { bankvalue: 7056, value: 280, label: "Bopitiya" },
    { bankvalue: 7056, value: 281, label: "Yatiyantota" },
    { bankvalue: 7056, value: 282, label: "Kolonnawa" },
    { bankvalue: 7056, value: 901, label: "Islamic Banking Unit" },
    { bankvalue: 7056, value: 999, label: "Head Office" },
  ],
  7074: [
    { bankvalue: 7074, value: 0, label: "Habib Bank Ltd" },
    { bankvalue: 7074, value: 1, label: "Pettah" },
    { bankvalue: 7074, value: 2, label: "Dharmapala Mawatha" },
    { bankvalue: 7074, value: 3, label: "Kalmunai" },
    { bankvalue: 7074, value: 5, label: "Kandy" },
    { bankvalue: 7074, value: 6, label: "Galle" },
    { bankvalue: 7074, value: 7, label: "Wellawatte" },
    { bankvalue: 7074, value: 21, label: "Islamic Banking" },
    { bankvalue: 7074, value: 999, label: "Head Office" },
  ],
  7083: [
    { bankvalue: 7083, value: 1, label: "Aluthkade" },
    { bankvalue: 7083, value: 2, label: "City Office" },
    { bankvalue: 7083, value: 3, label: "Head Office" },
    { bankvalue: 7083, value: 4, label: "Head Office" },
    { bankvalue: 7083, value: 5, label: "Green Path" },
    { bankvalue: 7083, value: 6, label: "Maligawatta" },
    { bankvalue: 7083, value: 7, label: "Pettah" },
    { bankvalue: 7083, value: 9, label: "Wellawatta" },
    { bankvalue: 7083, value: 10, label: "Anuradhapura" },
    { bankvalue: 7083, value: 11, label: "Badulla" },
    { bankvalue: 7083, value: 12, label: "Bandarawela" },
    { bankvalue: 7083, value: 13, label: "Galle" },
    { bankvalue: 7083, value: 14, label: "Gampola" },
    { bankvalue: 7083, value: 15, label: "Hatton" },
    { bankvalue: 7083, value: 16, label: "Jaffna Metro" },
    { bankvalue: 7083, value: 17, label: "Kahawatte" },
    { bankvalue: 7083, value: 18, label: "Kandy" },
    { bankvalue: 7083, value: 19, label: "Kurunegala" },
    { bankvalue: 7083, value: 20, label: "Mannar" },
    { bankvalue: 7083, value: 21, label: "Maskeliya" },
    { bankvalue: 7083, value: 22, label: "Moratuwa" },
    { bankvalue: 7083, value: 23, label: "Nawalapitiya" },
    { bankvalue: 7083, value: 24, label: "Negombo" },
    { bankvalue: 7083, value: 25, label: "Nittambuwa" },
    { bankvalue: 7083, value: 26, label: "Nochchiyagama" },
    { bankvalue: 7083, value: 27, label: "Nugegoda" },
    { bankvalue: 7083, value: 28, label: "Nuwara Eliya" },
    { bankvalue: 7083, value: 29, label: "Pussellawa" },
    { bankvalue: 7083, value: 30, label: "Ratnapura" },
    { bankvalue: 7083, value: 31, label: "Trincomalee" },
    { bankvalue: 7083, value: 32, label: "Vavuniya" },
    { bankvalue: 7083, value: 33, label: "Welimada" },
    { bankvalue: 7083, value: 34, label: "Kalutara" },
    { bankvalue: 7083, value: 35, label: "Wattala" },
    { bankvalue: 7083, value: 36, label: "Rajagiriya" },
    { bankvalue: 7083, value: 38, label: "Piliyandala" },
    { bankvalue: 7083, value: 39, label: "Bambalapitiya" },
    { bankvalue: 7083, value: 40, label: "Chilaw" },
    { bankvalue: 7083, value: 41, label: "Kegalle" },
    { bankvalue: 7083, value: 42, label: "Matara" },
    { bankvalue: 7083, value: 43, label: "Kirulapone" },
    { bankvalue: 7083, value: 44, label: "Polonnaruwa" },
    { bankvalue: 7083, value: 45, label: "Ambalantota" },
    { bankvalue: 7083, value: 46, label: "Grandpass" },
    { bankvalue: 7083, value: 47, label: "Biyagama" },
    { bankvalue: 7083, value: 48, label: "Dambulla" },
    { bankvalue: 7083, value: 49, label: "Katunayake" },
    { bankvalue: 7083, value: 50, label: "Embilipitiya" },
    { bankvalue: 7083, value: 51, label: "Gampaha" },
    { bankvalue: 7083, value: 52, label: "Horana" },
    { bankvalue: 7083, value: 53, label: "Monaragala" },
    { bankvalue: 7083, value: 54, label: "International Division" },
    { bankvalue: 7083, value: 55, label: "Borella" },
    { bankvalue: 7083, value: 56, label: "Kiribathgoda" },
    { bankvalue: 7083, value: 57, label: "Batticaloa" },
    { bankvalue: 7083, value: 58, label: "Ampara" },
    { bankvalue: 7083, value: 59, label: "Panchikawatta" },
    { bankvalue: 7083, value: 60, label: "Bogawanthalawa" },
    { bankvalue: 7083, value: 61, label: "Mount Lavinia" },
    { bankvalue: 7083, value: 63, label: "Hulftsdorp" },
    { bankvalue: 7083, value: 64, label: "Maharagama" },
    { bankvalue: 7083, value: 65, label: "Matale" },
    { bankvalue: 7083, value: 66, label: "Pinnawala" },
    { bankvalue: 7083, value: 67, label: "Suriyawewa" },
    { bankvalue: 7083, value: 68, label: "Hambantota" },
    { bankvalue: 7083, value: 69, label: "Panadura" },
    { bankvalue: 7083, value: 70, label: "Dankotuwa" },
    { bankvalue: 7083, value: 71, label: "Balangoda" },
    { bankvalue: 7083, value: 72, label: "Sea Street" },
    { bankvalue: 7083, value: 73, label: "Moratumulla" },
    { bankvalue: 7083, value: 74, label: "Kuliyapitiya" },
    { bankvalue: 7083, value: 75, label: "Buttala" },
    { bankvalue: 7083, value: 76, label: "Cinnamon Gardens" },
    { bankvalue: 7083, value: 77, label: "Homagama" },
    { bankvalue: 7083, value: 78, label: "Akkaraipattu" },
    { bankvalue: 7083, value: 79, label: "Marandagahamula" },
    { bankvalue: 7083, value: 80, label: "Marawila" },
    { bankvalue: 7083, value: 81, label: "Ambalangoda" },
    { bankvalue: 7083, value: 82, label: "Kaduwela" },
    { bankvalue: 7083, value: 83, label: "Puttalam" },
    { bankvalue: 7083, value: 84, label: "Kadawatha" },
    { bankvalue: 7083, value: 85, label: "Talangama" },
    { bankvalue: 7083, value: 86, label: "Tangalle" },
    { bankvalue: 7083, value: 87, label: "Ja-Ela" },
    { bankvalue: 7083, value: 88, label: "Thambuttegama" },
    { bankvalue: 7083, value: 89, label: "Mawanella" },
    { bankvalue: 7083, value: 90, label: "Tissamaharama" },
    { bankvalue: 7083, value: 91, label: "Kalmunai" },
    { bankvalue: 7083, value: 92, label: "Thimbirigasyaya" },
    { bankvalue: 7083, value: 93, label: "Dehiwala" },
    { bankvalue: 7083, value: 94, label: "Minuwangoda" },
    { bankvalue: 7083, value: 95, label: "Kantale" },
    { bankvalue: 7083, value: 96, label: "Kotahena" },
    { bankvalue: 7083, value: 97, label: "Mutwal" },
    { bankvalue: 7083, value: 98, label: "Kottawa" },
    { bankvalue: 7083, value: 99, label: "Kirindiwela" },
    { bankvalue: 7083, value: 100, label: "Katugastota" },
    { bankvalue: 7083, value: 101, label: "Pelmadulla" },
    { bankvalue: 7083, value: 102, label: "Ragama" },
    { bankvalue: 7083, value: 103, label: "Dematagoda" },
    { bankvalue: 7083, value: 104, label: "Narahenpita" },
    { bankvalue: 7083, value: 105, label: "Treasury Division" },
    { bankvalue: 7083, value: 106, label: "Wellawaya" },
    { bankvalue: 7083, value: 107, label: "Elpitiya" },
    { bankvalue: 7083, value: 108, label: "Maradana" },
    { bankvalue: 7083, value: 109, label: "Aluthgama" },
    { bankvalue: 7083, value: 110, label: "Wennappuwa" },
    { bankvalue: 7083, value: 111, label: "Avissawella" },
    { bankvalue: 7083, value: 112, label: "Boralesgamuwa" },
    { bankvalue: 7083, value: 113, label: "Card Centre" },
    { bankvalue: 7083, value: 114, label: "Central Colombo" },
    { bankvalue: 7083, value: 115, label: "Kollupitiya" },
    { bankvalue: 7083, value: 117, label: "Chunnakam" },
    { bankvalue: 7083, value: 118, label: "Nelliady" },
    { bankvalue: 7083, value: 119, label: "Kandana" },
    { bankvalue: 7083, value: 120, label: "Deniyaya Customer Centre" },
    { bankvalue: 7083, value: 121, label: "Nikaweratiya" },
    { bankvalue: 7083, value: 122, label: "Delgoda" },
    { bankvalue: 7083, value: 123, label: "Alawwa" },
    { bankvalue: 7083, value: 124, label: "Mahiyanganaya" },
    { bankvalue: 7083, value: 125, label: "Mathugama" },
    { bankvalue: 7083, value: 126, label: "Warakapola" },
    { bankvalue: 7083, value: 127, label: "Mvaluedeniya" },
    { bankvalue: 7083, value: 128, label: "Galgamuwa" },
    { bankvalue: 7083, value: 129, label: "Kohuwela" },
    { bankvalue: 7083, value: 130, label: "Weliweriya" },
    { bankvalue: 7083, value: 131, label: "Hendala" },
    { bankvalue: 7083, value: 132, label: "Point Pedro" },
    { bankvalue: 7083, value: 133, label: "Norochchole" },
    { bankvalue: 7083, value: 134, label: "Thirukovil" },
    { bankvalue: 7083, value: 135, label: "Eravur" },
    { bankvalue: 7083, value: 136, label: "Ganemulla" },
    { bankvalue: 7083, value: 137, label: "Chavakachcheri" },
    { bankvalue: 7083, value: 138, label: "Kelaniya" },
    { bankvalue: 7083, value: 139, label: "Hanwella" },
    { bankvalue: 7083, value: 140, label: "Padukka" },
    { bankvalue: 7083, value: 141, label: "Pilimatalawa" },
    { bankvalue: 7083, value: 142, label: "Thalawathugoda" },
    { bankvalue: 7083, value: 143, label: "Medawachchiya" },
    { bankvalue: 7083, value: 144, label: "Thirunelvely" },
    { bankvalue: 7083, value: 145, label: "Negombo Metro" },
    { bankvalue: 7083, value: 146, label: "Kilinochchi South" },
    { bankvalue: 7083, value: 147, label: "Nawala" },
    { bankvalue: 7083, value: 148, label: "Giriulla" },
    { bankvalue: 7083, value: 149, label: "Galewela" },
    { bankvalue: 7083, value: 150, label: "Manipay" },
    { bankvalue: 7083, value: 151, label: "Akuressa" },
    { bankvalue: 7083, value: 152, label: "Hettipola" },
    { bankvalue: 7083, value: 153, label: "Wariyapola" },
    { bankvalue: 7083, value: 154, label: "Athurugiriya" },
    { bankvalue: 7083, value: 155, label: "Kochchikade" },
    { bankvalue: 7083, value: 156, label: "Malabe" },
    { bankvalue: 7083, value: 157, label: "Chankanai" },
    { bankvalue: 7083, value: 158, label: "Pottuvil" },
    { bankvalue: 7083, value: 159, label: "Ninthavur" },
    { bankvalue: 7083, value: 160, label: "Beruwela" },
    { bankvalue: 7083, value: 161, label: "Velanai" },
    { bankvalue: 7083, value: 162, label: "Rikillagaskada" },
    { bankvalue: 7083, value: 163, label: "Yakkala" },
    { bankvalue: 7083, value: 164, label: "Thandenweli" },
    { bankvalue: 7083, value: 165, label: "Kaluwanchikudy" },
    { bankvalue: 7083, value: 166, label: "Pugoda" },
    { bankvalue: 7083, value: 167, label: "Valachchenai" },
    { bankvalue: 7083, value: 168, label: "Madampe" },
    { bankvalue: 7083, value: 169, label: "Kinniya" },
    { bankvalue: 7083, value: 170, label: "Siyabalanduwa" },
    { bankvalue: 7083, value: 171, label: "Udappuwa" },
    { bankvalue: 7083, value: 172, label: "Nanattan" },
    { bankvalue: 7083, value: 173, label: "Ginigathhena" },
    { bankvalue: 7083, value: 174, label: "Mullipothanai" },
    { bankvalue: 7083, value: 175, label: "Uppuvelli" },
    { bankvalue: 7083, value: 176, label: "Digana" },
    { bankvalue: 7083, value: 177, label: "Anamaduwa" },
    { bankvalue: 7083, value: 178, label: "Dikwella" },
    { bankvalue: 7083, value: 179, label: "Medirigiriya" },
    { bankvalue: 7083, value: 180, label: "Mirigama" },
    { bankvalue: 7083, value: 181, label: "Padavi Parakramapura" },
    { bankvalue: 7083, value: 182, label: "Uhana" },
    { bankvalue: 7083, value: 183, label: "Mullativu" },
    { bankvalue: 7083, value: 184, label: "Karaitive" },
    { bankvalue: 7083, value: 185, label: "Maruthamunai" },
    { bankvalue: 7083, value: 186, label: "Serunuwara" },
    { bankvalue: 7083, value: 187, label: "Pitigala" },
    { bankvalue: 7083, value: 188, label: "Kundasale" },
    { bankvalue: 7083, value: 189, label: "Atchchuvely" },
    { bankvalue: 7083, value: 190, label: "Kodikamam" },
    { bankvalue: 7083, value: 191, label: "Muthur" },
    { bankvalue: 7083, value: 192, label: "Kallady" },
    { bankvalue: 7083, value: 193, label: "Aralaganwila" },
    { bankvalue: 7083, value: 194, label: "Kolonnawa" },
    { bankvalue: 7083, value: 195, label: "Killinochchi North" },
    { bankvalue: 7083, value: 196, label: "Dehiattakandiya" },
    { bankvalue: 7083, value: 197, label: "Kalawana" },
    { bankvalue: 7083, value: 198, label: "Galaha" },
    { bankvalue: 7083, value: 199, label: "Urubokka" },
    { bankvalue: 7083, value: 200, label: "Hakmana" },
    { bankvalue: 7083, value: 201, label: "Bandaragama" },
    { bankvalue: 7083, value: 202, label: "Hikkaduwa" },
    { bankvalue: 7083, value: 203, label: "Wadduwa" },
    { bankvalue: 7083, value: 204, label: "Mirihana" },
    { bankvalue: 7083, value: 205, label: "Mulliyawalai" },
    { bankvalue: 7083, value: 206, label: "Kurumankadu" },
    { bankvalue: 7083, value: 207, label: "Jampettah Street" },
    { bankvalue: 7083, value: 208, label: "Ratmalana" },
    { bankvalue: 7083, value: 209, label: "Seeduwa" },
    { bankvalue: 7083, value: 210, label: "Pamunugama" },
    { bankvalue: 7083, value: 211, label: "Kattankudy" },
    { bankvalue: 7083, value: 212, label: "Mallavi" },
    { bankvalue: 7083, value: 213, label: "Weligama" },
    { bankvalue: 7083, value: 214, label: "Veyangoda" },
    { bankvalue: 7083, value: 215, label: "Batapola" },
    { bankvalue: 7083, value: 216, label: "Yakkalamulla" },
    { bankvalue: 7083, value: 217, label: "Walasmulla" },
    { bankvalue: 7083, value: 218, label: "Gelioya" },
    { bankvalue: 7083, value: 219, label: "Jaffna" },
    { bankvalue: 7083, value: 220, label: "Passara" },
    { bankvalue: 7083, value: 221, label: "Pamankada" },
    { bankvalue: 7083, value: 222, label: "Sammanthurai" },
    { bankvalue: 7083, value: 223, label: "Peradeniya" },
    { bankvalue: 7083, value: 224, label: "Kurunegala Metro" },
    { bankvalue: 7083, value: 225, label: "Trincomalee Metro" },
    { bankvalue: 7083, value: 226, label: "Wijerama" },
    { bankvalue: 7083, value: 227, label: "Karapitiya" },
    { bankvalue: 7083, value: 228, label: "Peliyagoda" },
    { bankvalue: 7083, value: 229, label: "Divulapitiya" },
    { bankvalue: 7083, value: 230, label: "Ekala" },
    { bankvalue: 7083, value: 231, label: "Kekirawa" },
    { bankvalue: 7083, value: 232, label: "Anuradhapura Metro" },
    { bankvalue: 7083, value: 233, label: "Bibile" },
    { bankvalue: 7083, value: 234, label: "Haputale" },
    { bankvalue: 7083, value: 235, label: "Godakawela" },
    { bankvalue: 7083, value: 236, label: "Akurana" },
    { bankvalue: 7083, value: 237, label: "Koggala" },
    { bankvalue: 7083, value: 238, label: "Devinuwara" },
    { bankvalue: 7083, value: 239, label: "Angunakolapelassa" },
    { bankvalue: 7083, value: 240, label: "Kataragama" },
    { bankvalue: 7083, value: 241, label: "Kaithady" },
    { bankvalue: 7083, value: 243, label: "Kurunduwatte" },
    { bankvalue: 7083, value: 244, label: "Ragala" },
    { bankvalue: 7083, value: 245, label: "Ingiriya" },
    { bankvalue: 7083, value: 246, label: "Thalawakele" },
    { bankvalue: 7083, value: 247, label: "Pettah Metro" },
    { bankvalue: 7083, value: 248, label: "Lotus Road" },
    { bankvalue: 7083, value: 249, label: "Asiri Surgical Hospital" },
    { bankvalue: 7083, value: 250, label: "Islamic Banking Unit" },
    { bankvalue: 7083, value: 251, label: "Lanka Hospital" },
    { bankvalue: 7083, value: 500, label: "Digital Branch" },
    { bankvalue: 7083, value: 701, label: "Nawam Mawatha" },
    { bankvalue: 7083, value: 703, label: "World Trade Centre" },
  ],
  7092: [
    { bankvalue: 7092, value: 0, label: "Hongkong & Shanghai Bank" },
    { bankvalue: 7092, value: 1, label: "Head Office" },
    { bankvalue: 7092, value: 2, label: "Kandy" },
    { bankvalue: 7092, value: 3, label: "Colpetty" },
    { bankvalue: 7092, value: 4, label: "Wellawatte" },
    { bankvalue: 7092, value: 5, label: "Nugegoda" },
    { bankvalue: 7092, value: 6, label: "World Trade Center" },
    { bankvalue: 7092, value: 7, label: "Bambalapitiya" },
    { bankvalue: 7092, value: 8, label: "Pelawatte" },
    { bankvalue: 7092, value: 12, label: "Union Place" },
    { bankvalue: 7092, value: 14, label: "Wattala" },
    { bankvalue: 7092, value: 15, label: "Premier Centre" },
    { bankvalue: 7092, value: 17, label: "Negombo" },
    { bankvalue: 7092, value: 18, label: "Moratuwa" },
    { bankvalue: 7092, value: 20, label: "kohuwela" },
    { bankvalue: 7092, value: 21, label: "Jaffna" },
    { bankvalue: 7092, value: 22, label: "Galle" },
    { bankvalue: 7092, value: 999, label: "Head Office" },
  ],
  7108: [
    { bankvalue: 7108, value: 0, label: "Head Office" },
    { bankvalue: 7108, value: 1, label: "Head Office" },
    { bankvalue: 7108, value: 2, label: "Jaffna" },
    { bankvalue: 7108, value: 999, label: "Head Office" },
  ],
  7117: [
    { bankvalue: 7117, value: 0, label: "Head Office" },
    { bankvalue: 7117, value: 1, label: "Matara" },
    { bankvalue: 7117, value: 999, label: "Head Office" },
  ],
  7135: [
    { bankvalue: 7135, value: 1, label: "Duke Street" },
    { bankvalue: 7135, value: 2, label: "Matale" },
    { bankvalue: 7135, value: 3, label: "Kandy" },
    { bankvalue: 7135, value: 4, label: "International Division" },
    { bankvalue: 7135, value: 5, label: "Polonnaruwa" },
    { bankvalue: 7135, value: 6, label: "Hingurakgoda" },
    { bankvalue: 7135, value: 7, label: "Hambantota" },
    { bankvalue: 7135, value: 8, label: "Anuradhapura" },
    { bankvalue: 7135, value: 9, label: "Puttalam" },
    { bankvalue: 7135, value: 10, label: "Badulla" },
    { bankvalue: 7135, value: 11, label: "Bibile" },
    { bankvalue: 7135, value: 12, label: "Kurunegala" },
    { bankvalue: 7135, value: 13, label: "Galle Fort" },
    { bankvalue: 7135, value: 14, label: "Union Place" },
    { bankvalue: 7135, value: 15, label: "Ampara" },
    { bankvalue: 7135, value: 16, label: "Welimada" },
    { bankvalue: 7135, value: 17, label: "Balangoda" },
    { bankvalue: 7135, value: 18, label: "Gampola" },
    { bankvalue: 7135, value: 19, label: "Dehiwala" },
    { bankvalue: 7135, value: 20, label: "Mulativu" },
    { bankvalue: 7135, value: 21, label: "Minuwangoda" },
    { bankvalue: 7135, value: 22, label: "Hanguranketha" },
    { bankvalue: 7135, value: 23, label: "Kalmunai" },
    { bankvalue: 7135, value: 24, label: "Chilaw" },
    { bankvalue: 7135, value: 25, label: "Hyde park Corner" },
    { bankvalue: 7135, value: 26, label: "Gampaha" },
    { bankvalue: 7135, value: 27, label: "Kegalle" },
    { bankvalue: 7135, value: 28, label: "Kuliyapitiya" },
    { bankvalue: 7135, value: 29, label: "Avissawella" },
    { bankvalue: 7135, value: 30, label: "Jaffna Stanley Road" },
    { bankvalue: 7135, value: 31, label: "Kankasanthurai" },
    { bankvalue: 7135, value: 32, label: "Matara Uyanwatta" },
    { bankvalue: 7135, value: 33, label: "Queens" },
    { bankvalue: 7135, value: 34, label: "Negombo" },
    { bankvalue: 7135, value: 35, label: "Ambalangoda" },
    { bankvalue: 7135, value: 36, label: "Ragala" },
    { bankvalue: 7135, value: 37, label: "Bandarawela" },
    { bankvalue: 7135, value: 38, label: "Talawakele" },
    { bankvalue: 7135, value: 39, label: "Kalutara" },
    { bankvalue: 7135, value: 40, label: "Vavuniya" },
    { bankvalue: 7135, value: 41, label: "Horana" },
    { bankvalue: 7135, value: 42, label: "Kekirawa" },
    { bankvalue: 7135, value: 43, label: "Padaviya" },
    { bankvalue: 7135, value: 44, label: "Mannar" },
    { bankvalue: 7135, value: 45, label: "Embilipitiya" },
    { bankvalue: 7135, value: 46, label: "Mudalige Mawatha" },
    { bankvalue: 7135, value: 47, label: "Yatiyantota" },
    { bankvalue: 7135, value: 48, label: "Kilinochchi" },
    { bankvalue: 7135, value: 49, label: "Homagama" },
    { bankvalue: 7135, value: 51, label: "Kahatagasdigiliya" },
    { bankvalue: 7135, value: 52, label: "Maho" },
    { bankvalue: 7135, value: 53, label: "Nawalapitiya" },
    { bankvalue: 7135, value: 54, label: "Warakapola" },
    { bankvalue: 7135, value: 55, label: "Kelaniya" },
    { bankvalue: 7135, value: 56, label: "Sri Sangaraja Mawatha" },
    { bankvalue: 7135, value: 57, label: "Peradeniya" },
    { bankvalue: 7135, value: 58, label: "Mahiyangana" },
    { bankvalue: 7135, value: 59, label: "Polgahawela" },
    { bankvalue: 7135, value: 60, label: "Morawaka" },
    { bankvalue: 7135, value: 61, label: "Tissamaharama" },
    { bankvalue: 7135, value: 62, label: "Wellawaya" },
    { bankvalue: 7135, value: 63, label: "Akkaraipattu" },
    { bankvalue: 7135, value: 64, label: "Samanthurai" },
    { bankvalue: 7135, value: 65, label: "Kattankudy" },
    { bankvalue: 7135, value: 66, label: "Trincomalee" },
    { bankvalue: 7135, value: 67, label: "Tangalle" },
    { bankvalue: 7135, value: 68, label: "Monaragala" },
    { bankvalue: 7135, value: 69, label: "Mawanella" },
    { bankvalue: 7135, value: 70, label: "Mathugama" },
    { bankvalue: 7135, value: 71, label: "Dematagoda" },
    { bankvalue: 7135, value: 72, label: "Ambalantota" },
    { bankvalue: 7135, value: 73, label: "Elpitiya" },
    { bankvalue: 7135, value: 74, label: "Wattegama" },
    { bankvalue: 7135, value: 75, label: "Batticaloa" },
    { bankvalue: 7135, value: 76, label: "Wennappuwa" },
    { bankvalue: 7135, value: 77, label: "Weligama" },
    { bankvalue: 7135, value: 78, label: "Borella" },
    { bankvalue: 7135, value: 79, label: "Veyangoda" },
    { bankvalue: 7135, value: 80, label: "Ratmalana" },
    { bankvalue: 7135, value: 81, label: "Ruwanwella" },
    { bankvalue: 7135, value: 82, label: "Narammala" },
    { bankvalue: 7135, value: 83, label: "Nattandiya" },
    { bankvalue: 7135, value: 84, label: "Aluthgama" },
    { bankvalue: 7135, value: 85, label: "Eheliyagoda" },
    { bankvalue: 7135, value: 86, label: "Thimbirigasyaya" },
    { bankvalue: 7135, value: 87, label: "Baddegama" },
    { bankvalue: 7135, value: 88, label: "Ratnapura" },
    { bankvalue: 7135, value: 89, label: "Katugastota" },
    { bankvalue: 7135, value: 90, label: "Kantale" },
    { bankvalue: 7135, value: 91, label: "Moratuwa" },
    { bankvalue: 7135, value: 92, label: "Giriulla" },
    { bankvalue: 7135, value: 93, label: "Pugoda" },
    { bankvalue: 7135, value: 94, label: "Kinniya" },
    { bankvalue: 7135, value: 95, label: "Muttur" },
    { bankvalue: 7135, value: 96, label: "Medawachchiya" },
    { bankvalue: 7135, value: 97, label: "Gangodawila" },
    { bankvalue: 7135, value: 98, label: "Kotikawatte" },
    { bankvalue: 7135, value: 100, label: "Marandagahamula" },
    { bankvalue: 7135, value: 101, label: "Rambukkana" },
    { bankvalue: 7135, value: 102, label: "Valaichechenai" },
    { bankvalue: 7135, value: 103, label: "Piliyandala" },
    { bankvalue: 7135, value: 104, label: "Jaffna Main Street" },
    { bankvalue: 7135, value: 105, label: "Kayts" },
    { bankvalue: 7135, value: 106, label: "Nelliady" },
    { bankvalue: 7135, value: 107, label: "Atchchuvely" },
    { bankvalue: 7135, value: 108, label: "Chankanai" },
    { bankvalue: 7135, value: 109, label: "Chunnakam" },
    { bankvalue: 7135, value: 110, label: "Chavakachcheri" },
    { bankvalue: 7135, value: 111, label: "Paranthan" },
    { bankvalue: 7135, value: 112, label: "Teldeniya" },
    { bankvalue: 7135, value: 113, label: "Batticaloa Town" },
    { bankvalue: 7135, value: 114, label: "Galagedera" },
    { bankvalue: 7135, value: 115, label: "Galewela" },
    { bankvalue: 7135, value: 116, label: "Passara" },
    { bankvalue: 7135, value: 117, label: "Akuressa" },
    { bankvalue: 7135, value: 118, label: "Delgoda" },
    { bankvalue: 7135, value: 119, label: "Narahenpita" },
    { bankvalue: 7135, value: 120, label: "Walasmulla" },
    { bankvalue: 7135, value: 121, label: "Bandaragama" },
    { bankvalue: 7135, value: 122, label: "Wilgamuwa" },
    { bankvalue: 7135, value: 123, label: "Eravur" },
    { bankvalue: 7135, value: 124, label: "Nikeweratiya" },
    { bankvalue: 7135, value: 125, label: "Kalpitiya" },
    { bankvalue: 7135, value: 126, label: "Grandpass" },
    { bankvalue: 7135, value: 127, label: "Nildandahinna." },
    { bankvalue: 7135, value: 128, label: "Ratthota" },
    { bankvalue: 7135, value: 129, label: "Rakwana" },
    { bankvalue: 7135, value: 130, label: "Hakmana" },
    { bankvalue: 7135, value: 131, label: "Udugama" },
    { bankvalue: 7135, value: 132, label: "Deniyaya" },
    { bankvalue: 7135, value: 133, label: "Kamburupitiya" },
    { bankvalue: 7135, value: 134, label: "Nuwara Eliya" },
    { bankvalue: 7135, value: 135, label: "Dickwella" },
    { bankvalue: 7135, value: 136, label: "Hikkaduwa" },
    { bankvalue: 7135, value: 137, label: "Makandura" },
    { bankvalue: 7135, value: 138, label: "Dambulla" },
    { bankvalue: 7135, value: 139, label: "Pettah" },
    { bankvalue: 7135, value: 140, label: "Hasalaka" },
    { bankvalue: 7135, value: 141, label: "Velvetiturai" },
    { bankvalue: 7135, value: 142, label: "Kochchikade" },
    { bankvalue: 7135, value: 143, label: "Suduwella" },
    { bankvalue: 7135, value: 144, label: "Hettipola" },
    { bankvalue: 7135, value: 145, label: "Wellawatte" },
    { bankvalue: 7135, value: 146, label: "Naula" },
    { bankvalue: 7135, value: 147, label: "Buttala" },
    { bankvalue: 7135, value: 148, label: "Panadura" },
    { bankvalue: 7135, value: 149, label: "Alawwa" },
    { bankvalue: 7135, value: 150, label: "Kebithigollawa" },
    { bankvalue: 7135, value: 151, label: "Diyatalawa" },
    { bankvalue: 7135, value: 152, label: "Matara Dharmapala Mawatha" },
    { bankvalue: 7135, value: 153, label: "Akurana" },
    { bankvalue: 7135, value: 154, label: "Balapitiya" },
    { bankvalue: 7135, value: 155, label: "Kahawatte" },
    { bankvalue: 7135, value: 156, label: "Uva-Paranagama" },
    { bankvalue: 7135, value: 157, label: "Menikhinna" },
    { bankvalue: 7135, value: 158, label: "Senkadagala" },
    { bankvalue: 7135, value: 159, label: "Kadugannawa" },
    { bankvalue: 7135, value: 160, label: "Pelmadulla" },
    { bankvalue: 7135, value: 161, label: "Bulathsinhala" },
    { bankvalue: 7135, value: 162, label: "Jaffna University" },
    { bankvalue: 7135, value: 163, label: "Wariyapola" },
    { bankvalue: 7135, value: 164, label: "Potuvil" },
    { bankvalue: 7135, value: 165, label: "Mankulam" },
    { bankvalue: 7135, value: 166, label: "Murunkan" },
    { bankvalue: 7135, value: 167, label: "Town Hall, Colombo" },
    { bankvalue: 7135, value: 168, label: "Kataragama" },
    { bankvalue: 7135, value: 169, label: "Galle Main Street" },
    { bankvalue: 7135, value: 170, label: "Eppawela" },
    { bankvalue: 7135, value: 171, label: "Nochchiyagama" },
    { bankvalue: 7135, value: 172, label: "Bingiriya" },
    { bankvalue: 7135, value: 173, label: "Pundaluoya" },
    { bankvalue: 7135, value: 174, label: "Nugegoda" },
    { bankvalue: 7135, value: 175, label: "Kandana" },
    { bankvalue: 7135, value: 176, label: "Mvalue City" },
    { bankvalue: 7135, value: 177, label: "Galenbindunuwewa" },
    { bankvalue: 7135, value: 178, label: "Maskeliya" },
    { bankvalue: 7135, value: 179, label: "Galnewa" },
    { bankvalue: 7135, value: 180, label: "Deraniyagala" },
    { bankvalue: 7135, value: 181, label: "Maha - Oya" },
    { bankvalue: 7135, value: 183, label: "Ankumbura" },
    { bankvalue: 7135, value: 184, label: "Galgamuwa" },
    { bankvalue: 7135, value: 185, label: "Galigamuwa" },
    { bankvalue: 7135, value: 186, label: "Hatton" },
    { bankvalue: 7135, value: 188, label: "Ahangama" },
    { bankvalue: 7135, value: 189, label: "Uhana" },
    { bankvalue: 7135, value: 190, label: "Kaluwanchikudy" },
    { bankvalue: 7135, value: 191, label: "Malwana" },
    { bankvalue: 7135, value: 192, label: "Nivitigala" },
    { bankvalue: 7135, value: 193, label: "Rvalueigama" },
    { bankvalue: 7135, value: 194, label: "Kolonnawa" },
    { bankvalue: 7135, value: 195, label: "Haldummulla" },
    { bankvalue: 7135, value: 196, label: "Kaduwela" },
    { bankvalue: 7135, value: 197, label: "Uragasmanhandiya" },
    { bankvalue: 7135, value: 198, label: "Mirigama" },
    { bankvalue: 7135, value: 199, label: "Mawathagama" },
    { bankvalue: 7135, value: 200, label: "Majestic City" },
    { bankvalue: 7135, value: 201, label: "Ukuwela" },
    { bankvalue: 7135, value: 202, label: "Kirindiwela" },
    { bankvalue: 7135, value: 203, label: "Habarana" },
    { bankvalue: 7135, value: 204, label: "Head Quarters" },
    { bankvalue: 7135, value: 205, label: "Angunakolapalessa" },
    { bankvalue: 7135, value: 206, label: "Davulagala" },
    { bankvalue: 7135, value: 207, label: "Ibbagamuwa" },
    { bankvalue: 7135, value: 208, label: "Battaramulla" },
    { bankvalue: 7135, value: 209, label: "Boralanda" },
    { bankvalue: 7135, value: 210, label: "Kollupitiya Co-op House" },
    { bankvalue: 7135, value: 211, label: "Panwila" },
    { bankvalue: 7135, value: 214, label: "Mutuwal" },
    { bankvalue: 7135, value: 215, label: "Madampe" },
    { bankvalue: 7135, value: 216, label: "Haputale" },
    { bankvalue: 7135, value: 217, label: "Mahara" },
    { bankvalue: 7135, value: 218, label: "Horowpathana" },
    { bankvalue: 7135, value: 219, label: "Tambuttegama" },
    { bankvalue: 7135, value: 220, label: "Anuradhapura-Nuwarawewa" },
    { bankvalue: 7135, value: 221, label: "Hemmathagama" },
    { bankvalue: 7135, value: 222, label: "Wattala" },
    { bankvalue: 7135, value: 223, label: "Karaitivu" },
    { bankvalue: 7135, value: 224, label: "Thirukkovil" },
    { bankvalue: 7135, value: 225, label: "Haliela" },
    { bankvalue: 7135, value: 226, label: "Kurunegala Maliyadeva" },
    { bankvalue: 7135, value: 227, label: "Chenkalady" },
    { bankvalue: 7135, value: 228, label: "Addalachchene" },
    { bankvalue: 7135, value: 229, label: "Hanwella" },
    { bankvalue: 7135, value: 230, label: "Tanamalwila" },
    { bankvalue: 7135, value: 231, label: "Medirigiriya" },
    { bankvalue: 7135, value: 232, label: "Polonnaruwa Town" },
    { bankvalue: 7135, value: 233, label: "Serunuwara" },
    { bankvalue: 7135, value: 234, label: "Batapola" },
    { bankvalue: 7135, value: 235, label: "Kalawana" },
    { bankvalue: 7135, value: 236, label: "Maradana" },
    { bankvalue: 7135, value: 237, label: "Kiribathgoda" },
    { bankvalue: 7135, value: 238, label: "Gonagaldeniya" },
    { bankvalue: 7135, value: 239, label: "Ja Ela" },
    { bankvalue: 7135, value: 240, label: "Keppetipola" },
    { bankvalue: 7135, value: 241, label: "Pallepola" },
    { bankvalue: 7135, value: 242, label: "Bakamuna" },
    { bankvalue: 7135, value: 243, label: "Devinuwara" },
    { bankvalue: 7135, value: 244, label: "Beliatta" },
    { bankvalue: 7135, value: 245, label: "Godakawela" },
    { bankvalue: 7135, value: 246, label: "Meegalewa" },
    { bankvalue: 7135, value: 247, label: "Imaduwa" },
    { bankvalue: 7135, value: 248, label: "Aranayake" },
    { bankvalue: 7135, value: 249, label: "Neboda" },
    { bankvalue: 7135, value: 250, label: "Kandeketiya" },
    { bankvalue: 7135, value: 251, label: "Lunugala" },
    { bankvalue: 7135, value: 252, label: "Bulathkohupitiya" },
    { bankvalue: 7135, value: 253, label: "Aralaganwila" },
    { bankvalue: 7135, value: 254, label: "Welikanda" },
    { bankvalue: 7135, value: 255, label: "Trincomalee Town" },
    { bankvalue: 7135, value: 256, label: "Pilimatalawa" },
    { bankvalue: 7135, value: 257, label: "Deltota" },
    { bankvalue: 7135, value: 258, label: "Medagama" },
    { bankvalue: 7135, value: 259, label: "Kehelwatte" },
    { bankvalue: 7135, value: 260, label: "Koslanda" },
    { bankvalue: 7135, value: 261, label: "Pelawatte" },
    { bankvalue: 7135, value: 262, label: "Wadduwa" },
    { bankvalue: 7135, value: 263, label: "Kuruwita" },
    { bankvalue: 7135, value: 264, label: "Suriyawewa" },
    { bankvalue: 7135, value: 265, label: "Mvaluedeniya" },
    { bankvalue: 7135, value: 266, label: "Kiriella" },
    { bankvalue: 7135, value: 267, label: "Anamaduwa" },
    { bankvalue: 7135, value: 268, label: "Girandurukotte" },
    { bankvalue: 7135, value: 269, label: "Badulla-Muthiyangana" },
    { bankvalue: 7135, value: 270, label: "Thulhiriya" },
    { bankvalue: 7135, value: 271, label: "Urubokka" },
    { bankvalue: 7135, value: 272, label: "Talgaswela" },
    { bankvalue: 7135, value: 273, label: "Kadawatha" },
    { bankvalue: 7135, value: 274, label: "Pussellawa" },
    { bankvalue: 7135, value: 275, label: "Olcott Mawatha" },
    { bankvalue: 7135, value: 276, label: "Katunayake" },
    { bankvalue: 7135, value: 277, label: "Sea Street" },
    { bankvalue: 7135, value: 278, label: "Nittambuwa" },
    { bankvalue: 7135, value: 279, label: "Pitakotte" },
    { bankvalue: 7135, value: 280, label: "Pothuhera" },
    { bankvalue: 7135, value: 281, label: "Kobeigane" },
    { bankvalue: 7135, value: 282, label: "Maggona" },
    { bankvalue: 7135, value: 283, label: "Baduraliya" },
    { bankvalue: 7135, value: 284, label: "Jaffna Kannathvaluedy" },
    { bankvalue: 7135, value: 285, label: "Point Pedro" },
    { bankvalue: 7135, value: 288, label: "Kudawella" },
    { bankvalue: 7135, value: 289, label: "Kaltota" },
    { bankvalue: 7135, value: 290, label: "Moratumulla" },
    { bankvalue: 7135, value: 291, label: "Dankotuwa" },
    { bankvalue: 7135, value: 292, label: "Udapussellawa" },
    { bankvalue: 7135, value: 293, label: "Dehiowita" },
    { bankvalue: 7135, value: 294, label: "Alawathugoda" },
    { bankvalue: 7135, value: 295, label: "Udawalawe" },
    { bankvalue: 7135, value: 296, label: "Nintavur" },
    { bankvalue: 7135, value: 297, label: "Dam Street" },
    { bankvalue: 7135, value: 298, label: "Ginthupitiya" },
    { bankvalue: 7135, value: 299, label: "Kegalle Bazaar" },
    { bankvalue: 7135, value: 300, label: "Ingiriya" },
    { bankvalue: 7135, value: 301, label: "Galkiriyagama" },
    { bankvalue: 7135, value: 302, label: "Ginigathhena" },
    { bankvalue: 7135, value: 303, label: "Mahawewa" },
    { bankvalue: 7135, value: 304, label: "Walasgala" },
    { bankvalue: 7135, value: 306, label: "Maharagama" },
    { bankvalue: 7135, value: 307, label: "Gandara" },
    { bankvalue: 7135, value: 308, label: "Kotahena" },
    { bankvalue: 7135, value: 309, label: "Liberty Plaza" },
    { bankvalue: 7135, value: 310, label: "Bambalapitiya" },
    { bankvalue: 7135, value: 311, label: "Beruwala" },
    { bankvalue: 7135, value: 312, label: "Malwatta Road" },
    { bankvalue: 7135, value: 313, label: "Katubedda" },
    { bankvalue: 7135, value: 315, label: "Talawa" },
    { bankvalue: 7135, value: 316, label: "Ragama" },
    { bankvalue: 7135, value: 317, label: "Ratnapura Town" },
    { bankvalue: 7135, value: 318, label: "Pamunugama" },
    { bankvalue: 7135, value: 319, label: "Kirulapana" },
    { bankvalue: 7135, value: 320, label: "Borella Town" },
    { bankvalue: 7135, value: 321, label: "Panadura Town" },
    { bankvalue: 7135, value: 322, label: "Marawila" },
    { bankvalue: 7135, value: 324, label: "Seeduwa" },
    { bankvalue: 7135, value: 325, label: "Wanduramba" },
    { bankvalue: 7135, value: 326, label: "Capricon" },
    { bankvalue: 7135, value: 327, label: "Kesbewa" },
    { bankvalue: 7135, value: 328, label: "Kottawa" },
    { bankvalue: 7135, value: 329, label: "Koggala" },
    { bankvalue: 7135, value: 330, label: "Dehiattakandiya" },
    { bankvalue: 7135, value: 331, label: "Lucky Plaza" },
    { bankvalue: 7135, value: 332, label: "Ganemulla" },
    { bankvalue: 7135, value: 333, label: "Yakkala" },
    { bankvalue: 7135, value: 334, label: "Kurunegala-Ethugalpura" },
    { bankvalue: 7135, value: 335, label: "Nugegoda City" },
    { bankvalue: 7135, value: 336, label: "Mount Lavinia" },
    { bankvalue: 7135, value: 337, label: "Dehiwela (Galle Rd.)" },
    { bankvalue: 7135, value: 338, label: "Sainthamaruthu" },
    { bankvalue: 7135, value: 339, label: "Kallar" },
    { bankvalue: 7135, value: 340, label: "Oddamavady" },
    { bankvalue: 7135, value: 341, label: "Hataraliyadda" },
    { bankvalue: 7135, value: 342, label: "Kokkaddicholai" },
    { bankvalue: 7135, value: 343, label: "Karapitiya" },
    { bankvalue: 7135, value: 344, label: "Melsiripura" },
    { bankvalue: 7135, value: 345, label: "Ranna" },
    { bankvalue: 7135, value: 346, label: "Maruthamunai" },
    { bankvalue: 7135, value: 347, label: "Badalkubura" },
    { bankvalue: 7135, value: 348, label: "Boralesgamuwa" },
    { bankvalue: 7135, value: 349, label: "Pallebedda" },
    { bankvalue: 7135, value: 350, label: "Weeraketiya" },
    { bankvalue: 7135, value: 351, label: "Thambala" },
    { bankvalue: 7135, value: 352, label: "Pulmudai" },
    { bankvalue: 7135, value: 353, label: "Rikillagaskada" },
    { bankvalue: 7135, value: 354, label: "Bogawanthalawa" },
    { bankvalue: 7135, value: 355, label: "Kotiyakumbura" },
    { bankvalue: 7135, value: 356, label: "Cheddikulam" },
    { bankvalue: 7135, value: 357, label: "Kandy City Centre" },
    { bankvalue: 7135, value: 358, label: "Poojapitiya" },
    { bankvalue: 7135, value: 359, label: "Piliyandala City" },
    { bankvalue: 7135, value: 360, label: "Polpithigama" },
    { bankvalue: 7135, value: 361, label: "Kodikamam" },
    { bankvalue: 7135, value: 600, label: "Card Center" },
    { bankvalue: 7135, value: 796, label: "Overseas Customers Unit" },
    { bankvalue: 7135, value: 999, label: "CCD" },
  ],
  7144: [
    { bankvalue: 7144, value: 0, label: "Head Office" },
    { bankvalue: 7144, value: 1, label: "Head Office" },
    { bankvalue: 7144, value: 2, label: "Kandy" },
    { bankvalue: 7144, value: 3, label: "Jaffna" },
    { bankvalue: 7144, value: 32, label: "Kohuwala" },
    { bankvalue: 7144, value: 999, label: "Head Office" },
  ],
  7162: [
    { bankvalue: 7162, value: 0, label: "Central Clearing" },
    { bankvalue: 7162, value: 1, label: "NTB.Head Office," },
    { bankvalue: 7162, value: 2, label: "Colpetty" },
    { bankvalue: 7162, value: 3, label: "Sri Sangharaja Mw." },
    { bankvalue: 7162, value: 4, label: "Kandy" },
    { bankvalue: 7162, value: 5, label: "Wellawatte" },
    { bankvalue: 7162, value: 6, label: "Corporate" },
    { bankvalue: 7162, value: 7, label: "Negombo(C.S.P.)" },
    { bankvalue: 7162, value: 8, label: "Pettah-Main Street" },
    { bankvalue: 7162, value: 9, label: "Mahabage" },
    { bankvalue: 7162, value: 10, label: "Battaramulla" },
    { bankvalue: 7162, value: 11, label: "Dharmapala Mawatha" },
    { bankvalue: 7162, value: 12, label: "Kurunegala" },
    { bankvalue: 7162, value: 13, label: "Maharagama" },
    { bankvalue: 7162, value: 14, label: "Moratuwa" },
    { bankvalue: 7162, value: 15, label: "Borella" },
    { bankvalue: 7162, value: 16, label: "Kiribathgoda" },
    { bankvalue: 7162, value: 17, label: "Panadura" },
    { bankvalue: 7162, value: 18, label: "Gampaha" },
    { bankvalue: 7162, value: 19, label: "Kotahena" },
    { bankvalue: 7162, value: 20, label: "Ward Place" },
    { bankvalue: 7162, value: 21, label: "Kadawatha" },
    { bankvalue: 7162, value: 22, label: "Crescat" },
    { bankvalue: 7162, value: 23, label: "Dehiwala" },
    { bankvalue: 7162, value: 24, label: "Nawam Mawatha" },
    { bankvalue: 7162, value: 25, label: "Havelock Town" },
    { bankvalue: 7162, value: 26, label: "Peradeniya" },
    { bankvalue: 7162, value: 27, label: "Nawala" },
    { bankvalue: 7162, value: 28, label: "Matara" },
    { bankvalue: 7162, value: 29, label: "Galle" },
    { bankvalue: 7162, value: 30, label: "Thalawathugoda" },
    { bankvalue: 7162, value: 31, label: "Homagama" },
    { bankvalue: 7162, value: 32, label: "Bandarawela" },
    { bankvalue: 7162, value: 33, label: "Vavuniya" },
    { bankvalue: 7162, value: 34, label: "Batticaloa" },
    { bankvalue: 7162, value: 35, label: "Jaffna" },
    { bankvalue: 7162, value: 36, label: "Horana" },
    { bankvalue: 7162, value: 37, label: "Kalmunai" },
    { bankvalue: 7162, value: 38, label: "Malabe" },
    { bankvalue: 7162, value: 39, label: "Anuradhapura" },
    { bankvalue: 7162, value: 40, label: "Piliyandala" },
    { bankvalue: 7162, value: 41, label: "Ratnapura" },
    { bankvalue: 7162, value: 42, label: "Nuwara Eliya" },
    { bankvalue: 7162, value: 43, label: "Chilaw" },
    { bankvalue: 7162, value: 44, label: "Nelliady" },
    { bankvalue: 7162, value: 45, label: "Kaduruwela" },
    { bankvalue: 7162, value: 46, label: "Pettah-Bankshall Street" },
    { bankvalue: 7162, value: 47, label: "Aluthgama" },
    { bankvalue: 7162, value: 48, label: "Wennappuwa" },
    { bankvalue: 7162, value: 49, label: "Trincomalee" },
    { bankvalue: 7162, value: 50, label: "World Trade Centre" },
    { bankvalue: 7162, value: 51, label: "Wattala" },
    { bankvalue: 7162, value: 52, label: "Mount Lavinia" },
    { bankvalue: 7162, value: 53, label: "Nugegoda" },
    { bankvalue: 7162, value: 54, label: "Kohuwala" },
    { bankvalue: 7162, value: 55, label: "Kuliyapitiya" },
    { bankvalue: 7162, value: 56, label: "Ambalangoda" },
    { bankvalue: 7162, value: 57, label: "Akkaraipattu" },
    { bankvalue: 7162, value: 58, label: "Hambantota" },
    { bankvalue: 7162, value: 59, label: "Badulla" },
    { bankvalue: 7162, value: 60, label: "Ja-Ela" },
    { bankvalue: 7162, value: 61, label: "Embilipitiya" },
    { bankvalue: 7162, value: 62, label: "Ambalantota" },
    { bankvalue: 7162, value: 63, label: "Akuressa" },
    { bankvalue: 7162, value: 64, label: "Balangoda" },
    { bankvalue: 7162, value: 65, label: "Deniyaya" },
    { bankvalue: 7162, value: 66, label: "Matugama" },
    { bankvalue: 7162, value: 67, label: "Karapitiya" },
    { bankvalue: 7162, value: 68, label: "Rajagiriya" },
    { bankvalue: 7162, value: 69, label: "Katugastota" },
    { bankvalue: 7162, value: 70, label: "Kaduwela" },
    { bankvalue: 7162, value: 71, label: "Old Moor Street" },
    { bankvalue: 7162, value: 72, label: "Bandaragama" },
    { bankvalue: 7162, value: 73, label: "Digana" },
    { bankvalue: 7162, value: 74, label: "Monaragala" },
    { bankvalue: 7162, value: 75, label: "Boralasgamuwa" },
    { bankvalue: 7162, value: 76, label: "Kottawa" },
    { bankvalue: 7162, value: 77, label: "Gothatuwa" },
    { bankvalue: 7162, value: 78, label: "Wariyapola" },
    { bankvalue: 7162, value: 79, label: "Kegalle" },
    { bankvalue: 7162, value: 80, label: "Tissamaharama" },
    { bankvalue: 7162, value: 81, label: "Narahenpita" },
    { bankvalue: 7162, value: 82, label: "Elpitiya" },
    { bankvalue: 7162, value: 83, label: "Giriulla" },
    { bankvalue: 7162, value: 84, label: "Weligama" },
    { bankvalue: 7162, value: 85, label: "Nittambuwa" },
    { bankvalue: 7162, value: 86, label: "Minuwangoda" },
    { bankvalue: 7162, value: 87, label: "Hikkaduwa" },
    { bankvalue: 7162, value: 88, label: "Gampola" },
    { bankvalue: 7162, value: 89, label: "Tangalle" },
    { bankvalue: 7162, value: 90, label: "Mawathagama" },
    { bankvalue: 7162, value: 91, label: "Avissawella" },
    { bankvalue: 7162, value: 92, label: "Matale" },
    { bankvalue: 7162, value: 93, label: "Kandy City Center" },
    { bankvalue: 7162, value: 400, label: "Card Center" },
    { bankvalue: 7162, value: 500, label: "Liberty Plaza" },
    { bankvalue: 7162, value: 501, label: "Wattala" },
    { bankvalue: 7162, value: 502, label: "Mount Lavinia" },
    { bankvalue: 7162, value: 503, label: "Nugegoda" },
    { bankvalue: 7162, value: 504, label: "Kohuwala" },
    { bankvalue: 7162, value: 999, label: "Head Office" },
  ],
  7205: [
    { bankvalue: 7205, value: 0, label: "Head Office" },
    { bankvalue: 7205, value: 1, label: "Main Branch" },
    { bankvalue: 7205, value: 999, label: "Head Office" },
  ],
  7214: [
    { bankvalue: 7214, value: 0, label: "Head Office" },
    { bankvalue: 7214, value: 1, label: "Navam Mawatha" },
    { bankvalue: 7214, value: 2, label: "Kandy" },
    { bankvalue: 7214, value: 3, label: "Jawatta" },
    { bankvalue: 7214, value: 4, label: "Nugegoda" },
    { bankvalue: 7214, value: 5, label: "Rajagiriya" },
    { bankvalue: 7214, value: 6, label: "Matara" },
    { bankvalue: 7214, value: 7, label: "Kurunegala" },
    { bankvalue: 7214, value: 8, label: "Wellawatte" },
    { bankvalue: 7214, value: 9, label: "Negombo" },
    { bankvalue: 7214, value: 10, label: "Chilaw" },
    { bankvalue: 7214, value: 11, label: "Wattala" },
    { bankvalue: 7214, value: 12, label: "Maharagama" },
    { bankvalue: 7214, value: 13, label: "Ratnapura" },
    { bankvalue: 7214, value: 14, label: "Head Office" },
    { bankvalue: 7214, value: 15, label: "Moratuwa" },
    { bankvalue: 7214, value: 16, label: "Kalutara" },
    { bankvalue: 7214, value: 17, label: "Kegalle" },
    { bankvalue: 7214, value: 18, label: "Badulla" },
    { bankvalue: 7214, value: 19, label: "Anuradhapura" },
    { bankvalue: 7214, value: 20, label: "Mount Lavinia" },
    { bankvalue: 7214, value: 21, label: "Galle" },
    { bankvalue: 7214, value: 22, label: "Pelawatte" },
    { bankvalue: 7214, value: 23, label: "Piliyandala" },
    { bankvalue: 7214, value: 24, label: "Kotahena" },
    { bankvalue: 7214, value: 25, label: "Kiribathgoda" },
    { bankvalue: 7214, value: 26, label: "Kadawatha" },
    { bankvalue: 7214, value: 27, label: "Horana" },
    { bankvalue: 7214, value: 28, label: "Kandana" },
    { bankvalue: 7214, value: 29, label: "Gampaha" },
    { bankvalue: 7214, value: 30, label: "Homagama" },
    { bankvalue: 7214, value: 31, label: "Malabe" },
    { bankvalue: 7214, value: 32, label: "Kohuwala" },
    { bankvalue: 7214, value: 33, label: "Puttalam" },
    { bankvalue: 7214, value: 34, label: "Awissawella" },
    { bankvalue: 7214, value: 35, label: "Panadura" },
    { bankvalue: 7214, value: 36, label: "Wennappuwa" },
    { bankvalue: 7214, value: 37, label: "Jaffna" },
    { bankvalue: 7214, value: 38, label: "Trincomalee" },
    { bankvalue: 7214, value: 39, label: "Batticaloa" },
    { bankvalue: 7214, value: 40, label: "Ampara" },
    { bankvalue: 7214, value: 41, label: "Vavuniya" },
    { bankvalue: 7214, value: 42, label: "Kuliyapitiya" },
    { bankvalue: 7214, value: 43, label: "Pettah" },
    { bankvalue: 7214, value: 44, label: "Ja Ela" },
    { bankvalue: 7214, value: 45, label: "Matugama" },
    { bankvalue: 7214, value: 46, label: "Matale" },
    { bankvalue: 7214, value: 47, label: "Ambalangoda" },
    { bankvalue: 7214, value: 48, label: "Ambalantota" },
    { bankvalue: 7214, value: 49, label: "Pilimatalawa" },
    { bankvalue: 7214, value: 50, label: "Gampola" },
    { bankvalue: 7214, value: 51, label: "Bandarawela" },
    { bankvalue: 7214, value: 52, label: "Borella" },
    { bankvalue: 7214, value: 53, label: "kalmunai" },
    { bankvalue: 7214, value: 54, label: "Nittambuwa" },
    { bankvalue: 7214, value: 55, label: "Kaduwela" },
    { bankvalue: 7214, value: 56, label: "Wariyapola" },
    { bankvalue: 7214, value: 57, label: "Hambantota" },
    { bankvalue: 7214, value: 58, label: "Embilipitiya" },
    { bankvalue: 7214, value: 59, label: "Aluthgama" },
    { bankvalue: 7214, value: 60, label: "Kaduruwela" },
    { bankvalue: 7214, value: 61, label: "Nawalapitiya" },
    { bankvalue: 7214, value: 62, label: "Chunnakam" },
    { bankvalue: 7214, value: 63, label: "Minuwangoda" },
    { bankvalue: 7214, value: 64, label: "Dambulla" },
    { bankvalue: 7214, value: 65, label: "Akuressa" },
    { bankvalue: 7214, value: 66, label: "Nelliady" },
    { bankvalue: 7214, value: 67, label: "Elpitiya" },
    { bankvalue: 7214, value: 68, label: "Yakkala" },
    { bankvalue: 7214, value: 69, label: "Nikaweratiya" },
    { bankvalue: 7214, value: 70, label: "Monaragala" },
    { bankvalue: 7214, value: 71, label: "Athurugiriya" },
    { bankvalue: 7214, value: 72, label: "Boralesgamuwa" },
    { bankvalue: 7214, value: 73, label: "Ratmalana" },
    { bankvalue: 7214, value: 74, label: "Narahenpita" },
    { bankvalue: 7214, value: 75, label: "Mahiyangana" },
    { bankvalue: 7214, value: 76, label: "Nuwara Eliya" },
    { bankvalue: 7214, value: 77, label: "Balangoda" },
    { bankvalue: 7214, value: 78, label: "Eheliyagoda" },
    { bankvalue: 7214, value: 79, label: "Old Moor Street" },
    { bankvalue: 7214, value: 80, label: "Kandy City Centre" },
    { bankvalue: 7214, value: 81, label: "Uragasmanhandiya" },
    { bankvalue: 7214, value: 82, label: "Katunayake" },
    { bankvalue: 7214, value: 83, label: "Kakirawa" },
    { bankvalue: 7214, value: 84, label: "Kahawatta" },
    { bankvalue: 7214, value: 86, label: "Giriulla" },
    { bankvalue: 7214, value: 87, label: "Pitakotte" },
    { bankvalue: 7214, value: 88, label: "Handala" },
    { bankvalue: 7214, value: 89, label: "Kochchikade" },
    { bankvalue: 7214, value: 90, label: "Battaramulla" },
    { bankvalue: 7214, value: 91, label: "Narammala" },
    { bankvalue: 7214, value: 92, label: "Tissamaharama" },
    { bankvalue: 7214, value: 93, label: "Chenkalady" },
    { bankvalue: 7214, value: 94, label: "Akkaraipattu" },
    { bankvalue: 7214, value: 96, label: "Hanwella" },
    { bankvalue: 7214, value: 97, label: "Manipai" },
    { bankvalue: 7214, value: 98, label: "Thirunelvely" },
    { bankvalue: 7214, value: 99, label: "Chavakachcheri" },
    { bankvalue: 7214, value: 95, label: "Natthandiya" },
    { bankvalue: 7214, value: 100, label: "Head Office(Retail)" },
    { bankvalue: 7214, value: 101, label: "Warakapola" },
    { bankvalue: 7214, value: 102, label: "Hingurakgoda" },
    { bankvalue: 7214, value: 103, label: "Marine Drive" },
    { bankvalue: 7214, value: 104, label: "Thambuttegama" },
    { bankvalue: 7214, value: 105, label: "Mahawewa" },
    { bankvalue: 7214, value: 109, label: "Katana" },
    { bankvalue: 7214, value: 111, label: "Kottawa" },
    { bankvalue: 7214, value: 601, label: "Islamic Banking Unit" },
    { bankvalue: 7214, value: 900, label: "Head Office(Corporare)" },
    { bankvalue: 7214, value: 999, label: "Head Office" },
  ],
  7269: [
    { bankvalue: 7269, value: 0, label: "Mvaluedle East Bank" },
    { bankvalue: 7269, value: 1, label: "Head Office" },
    { bankvalue: 7269, value: 2, label: "Pettah" },
    { bankvalue: 7269, value: 3, label: "Maradana" },
    { bankvalue: 7269, value: 4, label: "Islamic Banking Unit" },
    { bankvalue: 7269, value: 5, label: "Wellawatte" },
    { bankvalue: 7269, value: 6, label: "Kandy" },
    { bankvalue: 7269, value: 7, label: "Batticaloa" },
    { bankvalue: 7269, value: 8, label: "Galle" },
    { bankvalue: 7269, value: 9, label: "Kollupitiya" },
    { bankvalue: 7269, value: 999, label: "Head Office" },
  ],
  7278: [
    { bankvalue: 7278, value: 1, label: "City Office" },
    { bankvalue: 7278, value: 2, label: "Pettah" },
    { bankvalue: 7278, value: 3, label: "Nugegoda" },
    { bankvalue: 7278, value: 4, label: "Borella" },
    { bankvalue: 7278, value: 5, label: "Kiribathgoda" },
    { bankvalue: 7278, value: 6, label: "Kurunegala" },
    { bankvalue: 7278, value: 7, label: "Kandy Super" },
    { bankvalue: 7278, value: 8, label: "Wattala" },
    { bankvalue: 7278, value: 9, label: "Nawam Mawatha" },
    { bankvalue: 7278, value: 10, label: "Matara" },
    { bankvalue: 7278, value: 11, label: "Bambalapitiya" },
    { bankvalue: 7278, value: 12, label: "Fort" },
    { bankvalue: 7278, value: 13, label: "Maharagama" },
    { bankvalue: 7278, value: 14, label: "Deniyaya" },
    { bankvalue: 7278, value: 15, label: "Morawaka" },
    { bankvalue: 7278, value: 16, label: "Gampaha" },
    { bankvalue: 7278, value: 17, label: "Dehiwala" },
    { bankvalue: 7278, value: 18, label: "Ratmalana" },
    { bankvalue: 7278, value: 19, label: "Piliyandala" },
    { bankvalue: 7278, value: 20, label: "Eheliyagoda" },
    { bankvalue: 7278, value: 21, label: "Anuradhapura Super Branch" },
    { bankvalue: 7278, value: 22, label: "Avissawella" },
    { bankvalue: 7278, value: 23, label: "Kuliyapitiya" },
    { bankvalue: 7278, value: 24, label: "Negombo" },
    { bankvalue: 7278, value: 25, label: "Matale" },
    { bankvalue: 7278, value: 26, label: "Panadura" },
    { bankvalue: 7278, value: 27, label: "Old Moor Street" },
    { bankvalue: 7278, value: 28, label: "Tissamaharama" },
    { bankvalue: 7278, value: 29, label: "Headquarters" },
    { bankvalue: 7278, value: 30, label: "Wennappuwa" },
    { bankvalue: 7278, value: 31, label: "Moratuwa" },
    { bankvalue: 7278, value: 32, label: "Katugastota" },
    { bankvalue: 7278, value: 33, label: "Rathnapura" },
    { bankvalue: 7278, value: 34, label: "Thimbirigasyaya" },
    { bankvalue: 7278, value: 35, label: "Galle" },
    { bankvalue: 7278, value: 36, label: "Wellawatte Super" },
    { bankvalue: 7278, value: 37, label: "Kotahena" },
    { bankvalue: 7278, value: 38, label: "Kaduruwela" },
    { bankvalue: 7278, value: 39, label: "Malabe" },
    { bankvalue: 7278, value: 40, label: "Narahenpita" },
    { bankvalue: 7278, value: 41, label: "Kalawana" },
    { bankvalue: 7278, value: 42, label: "Main Street" },
    { bankvalue: 7278, value: 43, label: "Embilipitiya" },
    { bankvalue: 7278, value: 44, label: "Wariyapola (PBC)" },
    { bankvalue: 7278, value: 45, label: "Wellampitiya (PBC)" },
    { bankvalue: 7278, value: 46, label: "Bandarawela" },
    { bankvalue: 7278, value: 47, label: "Panadura Wekada" },
    { bankvalue: 7278, value: 48, label: "Thambuttegama (PBC)" },
    { bankvalue: 7278, value: 49, label: "Deraniyagala PBC" },
    { bankvalue: 7278, value: 50, label: "Kalutara" },
    { bankvalue: 7278, value: 51, label: "Peradeniya PBC" },
    { bankvalue: 7278, value: 52, label: "Kottawa" },
    { bankvalue: 7278, value: 53, label: "Alawwa PBC" },
    { bankvalue: 7278, value: 54, label: "Neluwa PBC" },
    { bankvalue: 7278, value: 55, label: "Vavunia" },
    { bankvalue: 7278, value: 56, label: "Mahiyanganaya" },
    { bankvalue: 7278, value: 57, label: "Horana" },
    { bankvalue: 7278, value: 58, label: "Harbour-View PBC" },
    { bankvalue: 7278, value: 59, label: "Bandaragama" },
    { bankvalue: 7278, value: 60, label: "Kadawatha" },
    { bankvalue: 7278, value: 61, label: "Battaramulla" },
    { bankvalue: 7278, value: 62, label: "Ampara" },
    { bankvalue: 7278, value: 63, label: "Pelawatte PBC" },
    { bankvalue: 7278, value: 64, label: "Kegall" },
    { bankvalue: 7278, value: 65, label: "Minuwangoda" },
    { bankvalue: 7278, value: 66, label: "Trincomalee" },
    { bankvalue: 7278, value: 67, label: "Athurugiriya PBC" },
    { bankvalue: 7278, value: 68, label: "Yakkala PBC" },
    { bankvalue: 7278, value: 69, label: "Homagama" },
    { bankvalue: 7278, value: 70, label: "Gregorys Road PBC" },
    { bankvalue: 7278, value: 71, label: "Nittambuwa" },
    { bankvalue: 7278, value: 72, label: "Ambalongoda" },
    { bankvalue: 7278, value: 73, label: "Ragama PBC" },
    { bankvalue: 7278, value: 74, label: "Monaragala" },
    { bankvalue: 7278, value: 75, label: "Wadduwa PBC" },
    { bankvalue: 7278, value: 76, label: "Kandana" },
    { bankvalue: 7278, value: 77, label: "veyangoda PBC" },
    { bankvalue: 7278, value: 78, label: "Ganemulla PBC" },
    { bankvalue: 7278, value: 79, label: "Aluthgama" },
    { bankvalue: 7278, value: 80, label: "Hatton" },
    { bankvalue: 7278, value: 81, label: "Welimada" },
    { bankvalue: 7278, value: 82, label: "Nawala" },
    { bankvalue: 7278, value: 83, label: "Kirindiwela PBC" },
    { bankvalue: 7278, value: 84, label: "Nuwara Eliya" },
    { bankvalue: 7278, value: 85, label: "Digana PBC" },
    { bankvalue: 7278, value: 86, label: "Mirigama" },
    { bankvalue: 7278, value: 87, label: "Pannipitiya" },
    { bankvalue: 7278, value: 88, label: "Negombo 2nd" },
    { bankvalue: 7278, value: 89, label: "Attvalueiya" },
    { bankvalue: 7278, value: 90, label: "Dambulla" },
    { bankvalue: 7278, value: 91, label: "Pitakotte" },
    { bankvalue: 7278, value: 92, label: "Maharagama Super" },
    { bankvalue: 7278, value: 93, label: "Badulla" },
    { bankvalue: 7278, value: 94, label: "Kohuwela" },
    { bankvalue: 7278, value: 95, label: "Giriulla" },
    { bankvalue: 7278, value: 96, label: "Hendala" },
    { bankvalue: 7278, value: 97, label: "Balangoda" },
    { bankvalue: 7278, value: 98, label: "Ja-Ela" },
    { bankvalue: 7278, value: 99, label: "Narammala" },
    { bankvalue: 7278, value: 100, label: "Kandy Metro" },
    { bankvalue: 7278, value: 101, label: "Gampola" },
    { bankvalue: 7278, value: 102, label: "Nikaweratiya" },
    { bankvalue: 7278, value: 103, label: "Pelmadulla" },
    { bankvalue: 7278, value: 104, label: "Ambalantota" },
    { bankvalue: 7278, value: 105, label: "Wattegama" },
    { bankvalue: 7278, value: 106, label: "Matugama" },
    { bankvalue: 7278, value: 107, label: "Batticaloa" },
    { bankvalue: 7278, value: 108, label: "Chilaw" },
    { bankvalue: 7278, value: 109, label: "Mawathagama" },
    { bankvalue: 7278, value: 110, label: "Hingurakgoda" },
    { bankvalue: 7278, value: 111, label: "Akkaraipattu" },
    { bankvalue: 7278, value: 112, label: "Kalmunai" },
    { bankvalue: 7278, value: 113, label: "Wellawaya" },
    { bankvalue: 7278, value: 114, label: "Embuldeniya" },
    { bankvalue: 7278, value: 115, label: "Kattankudy" },
    { bankvalue: 7278, value: 116, label: "Tangalle" },
    { bankvalue: 7278, value: 117, label: "Kirulapone" },
    { bankvalue: 7278, value: 118, label: "Baddegama" },
    { bankvalue: 7278, value: 119, label: "Mannar" },
    { bankvalue: 7278, value: 120, label: "Jaffna" },
    { bankvalue: 7278, value: 121, label: "Chenkalady" },
    { bankvalue: 7278, value: 122, label: "Rajagiriya" },
    { bankvalue: 7278, value: 123, label: "Kandy City Centre" },
    { bankvalue: 7278, value: 124, label: "Oddamavady" },
    { bankvalue: 7278, value: 125, label: "Kaluwanchikudy" },
    { bankvalue: 7278, value: 126, label: "Sainthamaruthu" },
    { bankvalue: 7278, value: 127, label: "Grandpass" },
    { bankvalue: 7278, value: 128, label: "Chunnakam" },
    { bankvalue: 7278, value: 129, label: "Nelliady" },
    { bankvalue: 7278, value: 130, label: "Pottuvil" },
    { bankvalue: 7278, value: 131, label: "Platinum Plus" },
    { bankvalue: 7278, value: 132, label: "Nattandiya" },
    { bankvalue: 7278, value: 133, label: "Kundasale" },
    { bankvalue: 7278, value: 134, label: "Kollupitiya" },
    { bankvalue: 7278, value: 135, label: "Gangodawila" },
    { bankvalue: 7278, value: 136, label: "Peliyagoda" },
    { bankvalue: 7278, value: 137, label: "Hanwella" },
    { bankvalue: 7278, value: 138, label: "Nochchiyagama" },
    { bankvalue: 7278, value: 139, label: "2nd Branch Batticaloa" },
    { bankvalue: 7278, value: 140, label: "Ingiriya" },
    { bankvalue: 7278, value: 141, label: "Karapitiya" },
    { bankvalue: 7278, value: 142, label: "Boralesgamuwa" },
    { bankvalue: 7278, value: 143, label: "Anamabuwa" },
    { bankvalue: 7278, value: 144, label: "Maradana" },
    { bankvalue: 7278, value: 145, label: "Buttala" },
    { bankvalue: 7278, value: 146, label: "Passara" },
    { bankvalue: 7278, value: 147, label: "Manipay" },
    { bankvalue: 7278, value: 148, label: "Kilinochchi" },
    { bankvalue: 7278, value: 149, label: "Kekirawa" },
    { bankvalue: 7278, value: 150, label: "Pilimatalawa" },
    { bankvalue: 7278, value: 151, label: "Keselwatta" },
    { bankvalue: 7278, value: 152, label: "Pussellawa" },
    { bankvalue: 7278, value: 153, label: "Matara Bazaar" },
    { bankvalue: 7278, value: 154, label: "Aralangawila" },
    { bankvalue: 7278, value: 155, label: "Moratumulla" },
    { bankvalue: 7278, value: 156, label: "Puttalam" },
    { bankvalue: 7278, value: 157, label: "Sooriyawewa" },
    { bankvalue: 7278, value: 158, label: "Mvaluedeniya" },
    { bankvalue: 7278, value: 159, label: "Galle Bazaar" },
    { bankvalue: 7278, value: 160, label: "Mawanella" },
    { bankvalue: 7278, value: 161, label: "Bibile" },
    { bankvalue: 7278, value: 162, label: "Kaduwela" },
    { bankvalue: 7278, value: 163, label: "Rikillagaskada" },
    { bankvalue: 7278, value: 164, label: "Chankanai" },
    { bankvalue: 7278, value: 165, label: "Kochchikade" },
    { bankvalue: 7278, value: 166, label: "Pannala" },
    { bankvalue: 7278, value: 167, label: "Dehiattakandiya" },
    { bankvalue: 7278, value: 168, label: "Anuradhapura New Town" },
    { bankvalue: 7278, value: 169, label: "Chavakachcheri" },
    { bankvalue: 7278, value: 170, label: "Vavuniya Metro" },
    { bankvalue: 7278, value: 171, label: "Kayts" },
    { bankvalue: 7278, value: 172, label: "Kantale" },
    { bankvalue: 7278, value: 173, label: "Gothatuwa New Town" },
    { bankvalue: 7278, value: 174, label: "Mallavi" },
    { bankvalue: 7278, value: 175, label: "Colombo Super" },
    { bankvalue: 7278, value: 176, label: "Mattegoda" },
    { bankvalue: 7278, value: 177, label: "Kinniya" },
    { bankvalue: 7278, value: 178, label: "Thalawathugoda" },
    { bankvalue: 7278, value: 179, label: "Akuressa" },
    { bankvalue: 7278, value: 180, label: "Beliatta" },
    { bankvalue: 7278, value: 181, label: "Habaraduwa" },
    { bankvalue: 7278, value: 182, label: "Ahangama" },
    { bankvalue: 7278, value: 183, label: "Marandagahamula" },
    { bankvalue: 7278, value: 184, label: "Menikhinna" },
    { bankvalue: 7278, value: 185, label: "Ninthavur" },
    { bankvalue: 7278, value: 186, label: "Thirunelvely" },
    { bankvalue: 7278, value: 187, label: "Hettipola" },
    { bankvalue: 7278, value: 188, label: "Rambukkana" },
    { bankvalue: 7278, value: 189, label: "Madampe" },
    { bankvalue: 7278, value: 190, label: "Galewela" },
    { bankvalue: 7278, value: 191, label: "Panchikawatte" },
    { bankvalue: 7278, value: 192, label: "Padukka" },
    { bankvalue: 7278, value: 193, label: "Mutwal" },
    { bankvalue: 7278, value: 194, label: "Marawila" },
    { bankvalue: 7278, value: 195, label: "Nawalapitiya" },
    { bankvalue: 7278, value: 196, label: "Dankotuwa" },
    { bankvalue: 7278, value: 197, label: "Maho" },
    { bankvalue: 7278, value: 198, label: "Divulapitiya" },
    { bankvalue: 7278, value: 199, label: "Mount Lavinia" },
    { bankvalue: 7278, value: 200, label: "Kiribathgoda" },
    { bankvalue: 7278, value: 201, label: "Ruwanwella" },
    { bankvalue: 7278, value: 202, label: "Delgoda" },
    { bankvalue: 7278, value: 203, label: "Kahatagasdigiliya" },
    { bankvalue: 7278, value: 204, label: "Elpitiya" },
    { bankvalue: 7278, value: 205, label: "Warakapola" },
    { bankvalue: 7278, value: 206, label: "Kaburupitiya" },
    { bankvalue: 7278, value: 207, label: "Makola" },
    { bankvalue: 7278, value: 208, label: "Muttur" },
    { bankvalue: 7278, value: 209, label: "Weligama" },
    { bankvalue: 7278, value: 210, label: "Karagampitiya" },
    { bankvalue: 7278, value: 211, label: "Talahena" },
    { bankvalue: 7278, value: 212, label: "Gampaha Super" },
    { bankvalue: 7278, value: 213, label: "Seeduwa" },
    { bankvalue: 7278, value: 214, label: "Orugodawatte" },
    { bankvalue: 7278, value: 215, label: "Kurunegala Metro" },
    { bankvalue: 7278, value: 216, label: "Dickwella" },
    { bankvalue: 7278, value: 217, label: "Prince Street" },
    { bankvalue: 7278, value: 218, label: "Borella Super" },
    { bankvalue: 7278, value: 219, label: "World Trade Center" },
    { bankvalue: 7278, value: 220, label: "Heerassagala" },
    { bankvalue: 7278, value: 221, label: "Poojapitiya" },
    { bankvalue: 7278, value: 222, label: "Thalawakele" },
    { bankvalue: 7278, value: 223, label: "Nawala Koswatta" },
    { bankvalue: 7278, value: 224, label: "Kesbewa" },
    { bankvalue: 7278, value: 225, label: "Godakawela" },
    { bankvalue: 7278, value: 226, label: "Ratnapura" },
    { bankvalue: 7278, value: 227, label: "Kelaniya" },
    { bankvalue: 7278, value: 228, label: "Polgahawela" },
    { bankvalue: 7278, value: 229, label: "Hikkaduwa" },
    { bankvalue: 7278, value: 920, label: "Imports" },
    { bankvalue: 7278, value: 929, label: "Sampath Viswa" },
    { bankvalue: 7278, value: 993, label: "Central Clearing Department" },
    { bankvalue: 7278, value: 996, label: "Card Centre" },
    { bankvalue: 7278, value: 999, label: "Head Office" },
  ],
  7287: [
    { bankvalue: 7287, value: 0, label: "Seylan Bank Head Office" },
    { bankvalue: 7287, value: 1, label: "City Office" },
    { bankvalue: 7287, value: 2, label: "Matara" },
    { bankvalue: 7287, value: 3, label: "Mount Lavinia" },
    { bankvalue: 7287, value: 4, label: "Maharagama" },
    { bankvalue: 7287, value: 5, label: "Panadura" },
    { bankvalue: 7287, value: 6, label: "Kiribathgoda" },
    { bankvalue: 7287, value: 7, label: "Ratnapura" },
    { bankvalue: 7287, value: 8, label: "Kollupitiya" },
    { bankvalue: 7287, value: 9, label: "Moratuwa" },
    { bankvalue: 7287, value: 10, label: "Kegalle" },
    { bankvalue: 7287, value: 11, label: "Gampaha" },
    { bankvalue: 7287, value: 12, label: "Nugegoda" },
    { bankvalue: 7287, value: 13, label: "Negombo" },
    { bankvalue: 7287, value: 14, label: "Dehiwala" },
    { bankvalue: 7287, value: 15, label: "Chilaw" },
    { bankvalue: 7287, value: 16, label: "Galle" },
    { bankvalue: 7287, value: 17, label: "Kandy" },
    { bankvalue: 7287, value: 18, label: "Kurunegala" },
    { bankvalue: 7287, value: 19, label: "Nuwara Eliya" },
    { bankvalue: 7287, value: 20, label: "Balangoda" },
    { bankvalue: 7287, value: 21, label: "Anuradhapura" },
    { bankvalue: 7287, value: 22, label: "Grandpass" },
    { bankvalue: 7287, value: 23, label: "Horana" },
    { bankvalue: 7287, value: 24, label: "Ambalangoda" },
    { bankvalue: 7287, value: 25, label: "Gampola" },
    { bankvalue: 7287, value: 26, label: "Badulla" },
    { bankvalue: 7287, value: 27, label: "Ja-Ela" },
    { bankvalue: 7287, value: 28, label: "Kadawatha" },
    { bankvalue: 7287, value: 29, label: "Dehiattakandiya" },
    { bankvalue: 7287, value: 30, label: "Colombo Fort" },
    { bankvalue: 7287, value: 31, label: "Katunayaka" },
    { bankvalue: 7287, value: 32, label: "Cinnamon Gardens" },
    { bankvalue: 7287, value: 33, label: "Kottawa" },
    { bankvalue: 7287, value: 34, label: "Boralesgamuwa" },
    { bankvalue: 7287, value: 35, label: "Yakkala" },
    { bankvalue: 7287, value: 36, label: "Kalutara" },
    { bankvalue: 7287, value: 37, label: "Tissamaharama" },
    { bankvalue: 7287, value: 38, label: "Matale" },
    { bankvalue: 7287, value: 39, label: "Hatton" },
    { bankvalue: 7287, value: 40, label: "Sarikkamulla" },
    { bankvalue: 7287, value: 41, label: "Attvalueiya" },
    { bankvalue: 7287, value: 42, label: "Kalubowila" },
    { bankvalue: 7287, value: 43, label: "Homagama" },
    { bankvalue: 7287, value: 44, label: "Kuliyapitiya" },
    { bankvalue: 7287, value: 45, label: "Embilipitiya" },
    { bankvalue: 7287, value: 46, label: "Bandarawela" },
    { bankvalue: 7287, value: 47, label: "Maradana" },
    { bankvalue: 7287, value: 48, label: "Mawanella" },
    { bankvalue: 7287, value: 49, label: "Puttalam" },
    { bankvalue: 7287, value: 50, label: "Old Moor Street" },
    { bankvalue: 7287, value: 51, label: "Hingurakgoda" },
    { bankvalue: 7287, value: 52, label: "Nawala" },
    { bankvalue: 7287, value: 53, label: "Manampitiya" },
    { bankvalue: 7287, value: 54, label: "Bandaragama" },
    { bankvalue: 7287, value: 55, label: "Katuneriya" },
    { bankvalue: 7287, value: 56, label: "Koggala" },
    { bankvalue: 7287, value: 57, label: "Welimada" },
    { bankvalue: 7287, value: 58, label: "Kochchikade" },
    { bankvalue: 7287, value: 59, label: "Bogawanthalawa" },
    { bankvalue: 7287, value: 60, label: "Ganemulla" },
    { bankvalue: 7287, value: 61, label: "Kotagala Talawakale" },
    { bankvalue: 7287, value: 62, label: "Raddolugama" },
    { bankvalue: 7287, value: 63, label: "Weliveriya" },
    { bankvalue: 7287, value: 64, label: "Pettah" },
    { bankvalue: 7287, value: 65, label: "Beliatta" },
    { bankvalue: 7287, value: 66, label: "Mathugama" },
    { bankvalue: 7287, value: 67, label: "Malabe" },
    { bankvalue: 7287, value: 68, label: "Wellawatte" },
    { bankvalue: 7287, value: 69, label: "Dam Street" },
    { bankvalue: 7287, value: 70, label: "Warakapola" },
    { bankvalue: 7287, value: 71, label: "Wattala" },
    { bankvalue: 7287, value: 72, label: "Vavuniya" },
    { bankvalue: 7287, value: 73, label: "Batticaloa" },
    { bankvalue: 7287, value: 74, label: "Kaththankudy" },
    { bankvalue: 7287, value: 75, label: "Awissawella" },
    { bankvalue: 7287, value: 76, label: "Nawalapitiya" },
    { bankvalue: 7287, value: 77, label: "Kekirawa" },
    { bankvalue: 7287, value: 78, label: "Mirigama" },
    { bankvalue: 7287, value: 79, label: "Soysapura" },
    { bankvalue: 7287, value: 80, label: "Ruwanwella" },
    { bankvalue: 7287, value: 81, label: "Hambantota" },
    { bankvalue: 7287, value: 82, label: "Borella" },
    { bankvalue: 7287, value: 83, label: "Havelock Town" },
    { bankvalue: 7287, value: 84, label: "Marandagahamula" },
    { bankvalue: 7287, value: 85, label: "Jaffna" },
    { bankvalue: 7287, value: 86, label: "Millenium" },
    { bankvalue: 7287, value: 87, label: "Nittambuwa" },
    { bankvalue: 7287, value: 88, label: "Trincomalee" },
    { bankvalue: 7287, value: 89, label: "Meegoda" },
    { bankvalue: 7287, value: 90, label: "Pelmadulla." },
    { bankvalue: 7287, value: 91, label: "Ampara" },
    { bankvalue: 7287, value: 92, label: "Nelliady" },
    { bankvalue: 7287, value: 93, label: "Kilinochchi" },
    { bankvalue: 7287, value: 94, label: "Mannar" },
    { bankvalue: 7287, value: 95, label: "Chavakachcheri" },
    { bankvalue: 7287, value: 96, label: "Mullativu" },
    { bankvalue: 7287, value: 97, label: "Kalmunai" },
    { bankvalue: 7287, value: 98, label: "Chenkalady" },
    { bankvalue: 7287, value: 99, label: "Piliyandala" },
    { bankvalue: 7287, value: 100, label: "Akuressa" },
    { bankvalue: 7287, value: 101, label: "Battaramulla" },
    { bankvalue: 7287, value: 102, label: "Kaduruwela" },
    { bankvalue: 7287, value: 103, label: "Dambulla" },
    { bankvalue: 7287, value: 104, label: "Monaragala" },
    { bankvalue: 7287, value: 105, label: "Ambalantota" },
    { bankvalue: 7287, value: 106, label: "Narammala" },
    { bankvalue: 7287, value: 107, label: "Mahiyanganaya" },
    { bankvalue: 7287, value: 108, label: "Veyangoda" },
    { bankvalue: 7287, value: 109, label: "Mawathagama" },
    { bankvalue: 7287, value: 110, label: "Pussellawa" },
    { bankvalue: 7287, value: 111, label: "Dummalasooriya" },
    { bankvalue: 7287, value: 112, label: "Godagama" },
    { bankvalue: 7287, value: 113, label: "Galenbvalueunuwewa" },
    { bankvalue: 7287, value: 114, label: "Pitakotte" },
    { bankvalue: 7287, value: 115, label: "Kanthale" },
    { bankvalue: 7287, value: 116, label: "Akkaraipatthu" },
    { bankvalue: 7287, value: 117, label: "Chankanai" },
    { bankvalue: 7287, value: 118, label: "Kotahena" },
    { bankvalue: 7287, value: 119, label: "Udappu" },
    { bankvalue: 7287, value: 120, label: "Ingiriya" },
    { bankvalue: 7287, value: 121, label: "Beruwala" },
    { bankvalue: 7287, value: 122, label: "Kirulapone" },
    { bankvalue: 7287, value: 123, label: "Gothatuwa" },
    { bankvalue: 7287, value: 124, label: "Minuwangoda" },
    { bankvalue: 7287, value: 125, label: "Moratumulla" },
    { bankvalue: 7287, value: 126, label: "Kirindiwela" },
    { bankvalue: 7287, value: 127, label: "Yatiyantota" },
    { bankvalue: 7287, value: 128, label: "Ranpokunugama" },
    { bankvalue: 7287, value: 129, label: "Aralaganwila" },
    { bankvalue: 7287, value: 130, label: "Kalawanchikudy" },
    { bankvalue: 7287, value: 131, label: "Manipay" },
    { bankvalue: 7287, value: 132, label: "Medawachchiya" },
    { bankvalue: 7287, value: 133, label: "Nochchiyagama" },
    { bankvalue: 7287, value: 134, label: "Pallekelle" },
    { bankvalue: 7287, value: 135, label: "Wijerama" },
    { bankvalue: 7287, value: 136, label: "Kotiyakumbura" },
    { bankvalue: 7287, value: 137, label: "Bowatta" },
    { bankvalue: 7287, value: 138, label: "Eppawala" },
    { bankvalue: 7287, value: 139, label: "Bambalapitiya" },
    { bankvalue: 7287, value: 140, label: "Hikkaduwa" },
    { bankvalue: 7287, value: 141, label: "Rvalueeegama" },
    { bankvalue: 7287, value: 142, label: "Wadduwa" },
    { bankvalue: 7287, value: 143, label: "Kelaniya" },
    { bankvalue: 7287, value: 144, label: "Colombo Gold Centre" },
    { bankvalue: 7287, value: 145, label: "Baduraliya" },
    { bankvalue: 7287, value: 146, label: "Kamburupitiya" },
    { bankvalue: 7287, value: 147, label: "Kalpitiya" },
    { bankvalue: 7287, value: 148, label: "Kataragama" },
    { bankvalue: 7287, value: 149, label: "Katugastota" },
    { bankvalue: 7287, value: 151, label: "Pothuvil" },
    { bankvalue: 7287, value: 152, label: "Samanthurai" },
    { bankvalue: 7287, value: 153, label: "Siyambalanduwa" },
    { bankvalue: 7287, value: 154, label: "Padaviya" },
    { bankvalue: 7287, value: 155, label: "Hasalaka" },
    { bankvalue: 7287, value: 157, label: "Peradeniya" },
    { bankvalue: 7287, value: 158, label: "Pilimathalawa" },
    { bankvalue: 7287, value: 159, label: "Polonnaruwa" },
    { bankvalue: 7287, value: 160, label: "Wennappuwa" },
    { bankvalue: 7287, value: 161, label: "Dankotuwa" },
    { bankvalue: 7287, value: 162, label: "Pannala" },
    { bankvalue: 7287, value: 163, label: "Athurugiriya" },
    { bankvalue: 7287, value: 164, label: "Karapitiya" },
    { bankvalue: 7287, value: 165, label: "Matara Bazzar" },
    { bankvalue: 7287, value: 166, label: "Chunnakam" },
    { bankvalue: 7287, value: 167, label: "Mutwal" },
    { bankvalue: 7287, value: 168, label: "Kandana" },
    { bankvalue: 7287, value: 169, label: "Wariyapola" },
    { bankvalue: 7287, value: 170, label: "Aluthgama" },
    { bankvalue: 7287, value: 996, label: "Central Processing Unit" },
    { bankvalue: 7287, value: 997, label: "Seylan Card Centre (SCC)" },
    { bankvalue: 7287, value: 998, label: "Retail Remittance Center" },
    { bankvalue: 7287, value: 999, label: "Head Office" },
  ],
  7296: [{ bankvalue: 7296, value: 1, label: "All Branches" }],
  7302: [
    { bankvalue: 7302, value: 1, label: "UB Main Office" },
    { bankvalue: 7302, value: 2, label: "Colpetty" },
    { bankvalue: 7302, value: 3, label: "Kandy" },
    { bankvalue: 7302, value: 4, label: "Nawala" },
    { bankvalue: 7302, value: 5, label: "Wellawatte" },
    { bankvalue: 7302, value: 6, label: "Pettah" },
    { bankvalue: 7302, value: 7, label: "Old Moor Street" },
    { bankvalue: 7302, value: 8, label: "Head Office Branch" },
    { bankvalue: 7302, value: 9, label: "Kurunegala" },
    { bankvalue: 7302, value: 10, label: "Negambo" },
    { bankvalue: 7302, value: 11, label: "Matara" },
    { bankvalue: 7302, value: 12, label: "Kotahena" },
    { bankvalue: 7302, value: 14, label: "Ja ela" },
    { bankvalue: 7302, value: 15, label: "Neugegoda" },
    { bankvalue: 7302, value: 16, label: "Moratuwa" },
    { bankvalue: 7302, value: 17, label: "Wennappuwa" },
    { bankvalue: 7302, value: 18, label: "Jaffna" },
    { bankvalue: 7302, value: 19, label: "Mannar" },
    { bankvalue: 7302, value: 20, label: "Vavuniya" },
    { bankvalue: 7302, value: 21, label: "Chunnakkam" },
    { bankvalue: 7302, value: 22, label: "Atchuvely" },
    { bankvalue: 7302, value: 23, label: "Wattala" },
    { bankvalue: 7302, value: 24, label: "Gampaha" },
    { bankvalue: 7302, value: 25, label: "Batticaloa" },
    { bankvalue: 7302, value: 26, label: "Trincomalee" },
    { bankvalue: 7302, value: 27, label: "Galle" },
    { bankvalue: 7302, value: 28, label: "Piliyandala" },
    { bankvalue: 7302, value: 29, label: "Kadawatha" },
    { bankvalue: 7302, value: 30, label: "Ratnapura" },
    { bankvalue: 7302, value: 31, label: "Anuradhapura" },
    { bankvalue: 7302, value: 32, label: "Dhambulla" },
    { bankvalue: 7302, value: 33, label: "Badulla" },
    { bankvalue: 7302, value: 34, label: "Horana" },
    { bankvalue: 7302, value: 35, label: "Ambalangoda" },
    { bankvalue: 7302, value: 36, label: "Chilaw" },
    { bankvalue: 7302, value: 37, label: "Marawila" },
    { bankvalue: 7302, value: 38, label: "Panadura" },
    { bankvalue: 7302, value: 39, label: "Narammala" },
    { bankvalue: 7302, value: 40, label: "Pelawatte" },
    { bankvalue: 7302, value: 41, label: "Angunakolapelessa" },
    { bankvalue: 7302, value: 42, label: "Kebithigollewa" },
    { bankvalue: 7302, value: 43, label: "Gampola" },
    { bankvalue: 7302, value: 44, label: "Embilipitiya" },
    { bankvalue: 7302, value: 45, label: "Kegalle" },
    { bankvalue: 7302, value: 46, label: "Warakapola" },
    { bankvalue: 7302, value: 47, label: "Kekirawa" },
    { bankvalue: 7302, value: 48, label: "Horowpathana" },
    { bankvalue: 7302, value: 49, label: "Ambalanthota" },
    { bankvalue: 7302, value: 50, label: "Minuwangoda" },
    { bankvalue: 7302, value: 51, label: "Balangoda" },
    { bankvalue: 7302, value: 52, label: "Maharagama" },
    { bankvalue: 7302, value: 53, label: "Mathugama" },
    { bankvalue: 7302, value: 54, label: "Nawalapitiya" },
    { bankvalue: 7302, value: 55, label: "Ibbagamuwa" },
    { bankvalue: 7302, value: 56, label: "Kuliyapitiya" },
    { bankvalue: 7302, value: 57, label: "Pilimathalawa" },
    { bankvalue: 7302, value: 58, label: "Moneragala" },
    { bankvalue: 7302, value: 59, label: "Ganemulla" },
    { bankvalue: 7302, value: 60, label: "Elpitiya" },
    { bankvalue: 7302, value: 61, label: "Medawachchiya" },
    { bankvalue: 7302, value: 62, label: "Ratmalana" },
    { bankvalue: 7302, value: 63, label: "Akuressa" },
    { bankvalue: 7302, value: 64, label: "Rajagiriaya" },
    { bankvalue: 7302, value: 65, label: "Attvalueiya" },
    { bankvalue: 7302, value: 66, label: "Kohuwala" },
    { bankvalue: 7302, value: 67, label: "New Borella" },
    { bankvalue: 7302, value: 68, label: "Peradeniya" },
    { bankvalue: 7302, value: 69, label: "Bandarawela" },
    { bankvalue: 7302, value: 997, label: "UB Corporate" },
  ],
  7311: [
    { bankvalue: 7311, value: 1, label: "Metro" },
    { bankvalue: 7311, value: 2, label: "Panchikawatte" },
    { bankvalue: 7311, value: 3, label: "Kollupitiya" },
    { bankvalue: 7311, value: 4, label: "Pettah" },
    { bankvalue: 7311, value: 5, label: "Kandy" },
    { bankvalue: 7311, value: 6, label: "Rajagiriya" },
    { bankvalue: 7311, value: 7, label: "Ratnapura" },
    { bankvalue: 7311, value: 8, label: "Nugegoda" },
    { bankvalue: 7311, value: 9, label: "Bambalapitiya" },
    { bankvalue: 7311, value: 10, label: "Negombo" },
    { bankvalue: 7311, value: 11, label: "Gampaha" },
    { bankvalue: 7311, value: 12, label: "Kurunegala" },
    { bankvalue: 7311, value: 13, label: "Matara" },
    { bankvalue: 7311, value: 14, label: "Kotahena" },
    { bankvalue: 7311, value: 15, label: "Dehiwela" },
    { bankvalue: 7311, value: 16, label: "Wattala" },
    { bankvalue: 7311, value: 17, label: "Panadura" },
    { bankvalue: 7311, value: 18, label: "Old Moor Street" },
    { bankvalue: 7311, value: 19, label: "Dam Street" },
    { bankvalue: 7311, value: 20, label: "Katugastota" },
    { bankvalue: 7311, value: 21, label: "Narahenpita" },
    { bankvalue: 7311, value: 22, label: "Kirulapana" },
    { bankvalue: 7311, value: 23, label: "Maharagama" },
    { bankvalue: 7311, value: 24, label: "Moratuwa" },
    { bankvalue: 7311, value: 25, label: "Galle" },
    { bankvalue: 7311, value: 26, label: "Kadawatha" },
    { bankvalue: 7311, value: 27, label: "Kegalle" },
    { bankvalue: 7311, value: 28, label: "Wennappuwa" },
    { bankvalue: 7311, value: 29, label: "Wellawatta" },
    { bankvalue: 7311, value: 30, label: "Gampola" },
    { bankvalue: 7311, value: 31, label: "Borella" },
    { bankvalue: 7311, value: 32, label: "Anuradhapura" },
    { bankvalue: 7311, value: 33, label: "Kalutara" },
    { bankvalue: 7311, value: 34, label: "Vavuniya" },
    { bankvalue: 7311, value: 35, label: "Malabe" },
    { bankvalue: 7311, value: 36, label: "Chilaw" },
    { bankvalue: 7311, value: 37, label: "Jaffna" },
    { bankvalue: 7311, value: 38, label: "Embilipitiya" },
    { bankvalue: 7311, value: 39, label: "Matale" },
    { bankvalue: 7311, value: 40, label: "Batticaloa" },
    { bankvalue: 7311, value: 41, label: "Ambalangoda" },
    { bankvalue: 7311, value: 42, label: "Kalmunai" },
    { bankvalue: 7311, value: 43, label: "Kilinochchi" },
    { bankvalue: 7311, value: 44, label: "Kandy City Centre" },
    { bankvalue: 7311, value: 45, label: "Badulla" },
    { bankvalue: 7311, value: 46, label: "Kuliyapitiya" },
    { bankvalue: 7311, value: 47, label: "Kalubowila" },
    { bankvalue: 7311, value: 48, label: "Bandarawela" },
    { bankvalue: 7311, value: 49, label: "Dambulla" },
    { bankvalue: 7311, value: 50, label: "Ratmalana" },
    { bankvalue: 7311, value: 51, label: "Peradeniya" },
    { bankvalue: 7311, value: 52, label: "Kaduruwela" },
    { bankvalue: 7311, value: 53, label: "Ambalanthota" },
    { bankvalue: 7311, value: 54, label: "Kiribathgoda" },
    { bankvalue: 7311, value: 55, label: "Piliyandala" },
    { bankvalue: 7311, value: 56, label: "Nelliady" },
    { bankvalue: 7311, value: 57, label: "Kanthankudy" },
    { bankvalue: 7311, value: 58, label: "Kundasale" },
    { bankvalue: 7311, value: 59, label: "Monaragala" },
    { bankvalue: 7311, value: 60, label: "Akkaraipaththu" },
    { bankvalue: 7311, value: 61, label: "Chunnakam" },
    { bankvalue: 7311, value: 62, label: "Balangoda" },
    { bankvalue: 7311, value: 63, label: "Battaramulla" },
    { bankvalue: 7311, value: 64, label: "Puttalama" },
    { bankvalue: 7311, value: 65, label: "Pilimathalawa" },
    { bankvalue: 7311, value: 66, label: "Ja-Ela" },
    { bankvalue: 7311, value: 67, label: "Kekirawa" },
    { bankvalue: 7311, value: 68, label: "Thalawathugoda" },
    { bankvalue: 7311, value: 69, label: "Minuwangoda" },
    { bankvalue: 7311, value: 70, label: "Warakapola" },
    { bankvalue: 7311, value: 71, label: "Galewela" },
    { bankvalue: 7311, value: 72, label: "Akuressa" },
    { bankvalue: 7311, value: 73, label: "Trincomalee" },
    { bankvalue: 7311, value: 74, label: "Tangalle" },
    { bankvalue: 7311, value: 75, label: "Hatton" },
    { bankvalue: 7311, value: 76, label: "Homagama" },
    { bankvalue: 7311, value: 77, label: "Horana" },
    { bankvalue: 7311, value: 78, label: "Colombo Gold Centre" },
    { bankvalue: 7311, value: 79, label: "Nawala" },
    { bankvalue: 7311, value: 80, label: "Kottawa" },
    { bankvalue: 7311, value: 81, label: "Kaduwela" },
    { bankvalue: 7311, value: 82, label: "Mawanella" },
    { bankvalue: 7311, value: 83, label: "Dankotuwa" },
    { bankvalue: 7311, value: 84, label: "Hikkaduwa" },
    { bankvalue: 7311, value: 85, label: "Weligama" },
    { bankvalue: 7311, value: 814, label: "Internationl Remittance" },
    { bankvalue: 7311, value: 999, label: "Head Office" },
  ],
  7384: [{ bankvalue: 7384, value: 1, label: "Sri Lanka Branch" }],
  7454: [
    { bankvalue: 7454, value: 1, label: "Head Office" },
    { bankvalue: 7454, value: 2, label: "Nugegoda" },
    { bankvalue: 7454, value: 3, label: "Malabe" },
    { bankvalue: 7454, value: 4, label: "Matara" },
    { bankvalue: 7454, value: 5, label: "Kurunegala" },
    { bankvalue: 7454, value: 6, label: "Katugastota" },
    { bankvalue: 7454, value: 7, label: "City Office" },
    { bankvalue: 7454, value: 8, label: "Rathnapura" },
    { bankvalue: 7454, value: 9, label: "Anuradhapura" },
    { bankvalue: 7454, value: 10, label: "Gampaha" },
    { bankvalue: 7454, value: 11, label: "Badulla" },
    { bankvalue: 7454, value: 12, label: "Borella" },
    { bankvalue: 7454, value: 14, label: "Kaduruwela" },
    { bankvalue: 7454, value: 15, label: "Bandaranayake Mawatha" },
    { bankvalue: 7454, value: 16, label: "Maharagama" },
    { bankvalue: 7454, value: 17, label: "Bandarawela" },
    { bankvalue: 7454, value: 18, label: "Negombo" },
    { bankvalue: 7454, value: 19, label: "Kottawa" },
    { bankvalue: 7454, value: 20, label: "Dambulla" },
    { bankvalue: 7454, value: 21, label: "Wattala" },
    { bankvalue: 7454, value: 22, label: "Kuliyapitiya" },
    { bankvalue: 7454, value: 23, label: "Panadura" },
    { bankvalue: 7454, value: 24, label: "Piliyandala" },
    { bankvalue: 7454, value: 25, label: "Deniyaya" },
    { bankvalue: 7454, value: 26, label: "Kaluthara" },
    { bankvalue: 7454, value: 27, label: "Kiribathgoda" },
    { bankvalue: 7454, value: 28, label: "Nawala" },
    { bankvalue: 7454, value: 29, label: "Kadawatha" },
    { bankvalue: 7454, value: 30, label: "Gampola" },
    { bankvalue: 7454, value: 31, label: "Matale" },
    { bankvalue: 7454, value: 32, label: "Chilaw" },
    { bankvalue: 7454, value: 33, label: "Wellawatte" },
    { bankvalue: 7454, value: 34, label: "Horana" },
    { bankvalue: 7454, value: 35, label: "Galle" },
    { bankvalue: 7454, value: 36, label: "Nuwara Eliya" },
    { bankvalue: 7454, value: 37, label: "Kalawana" },
    { bankvalue: 7454, value: 38, label: "Ambalangoda" },
    { bankvalue: 7454, value: 39, label: "Avissawella" },
    { bankvalue: 7454, value: 40, label: "Batticaloa" },
    { bankvalue: 7454, value: 41, label: "Ampara" },
    { bankvalue: 7454, value: 42, label: "Jaffna" },
    { bankvalue: 7454, value: 43, label: "Moratuwa" },
    { bankvalue: 7454, value: 44, label: "Trincomalee" },
    { bankvalue: 7454, value: 45, label: "Embilipitiya" },
    { bankvalue: 7454, value: 46, label: "Pettah Peoples Park" },
    { bankvalue: 7454, value: 47, label: "Vavuniya" },
    { bankvalue: 7454, value: 48, label: "Katugastota" },
    { bankvalue: 7454, value: 49, label: "Kegalle" },
    { bankvalue: 7454, value: 50, label: "Monaragala" },
    { bankvalue: 7454, value: 51, label: "Sainthamaruthu" },
    { bankvalue: 7454, value: 52, label: "Kilinochchi" },
    { bankvalue: 7454, value: 53, label: "Elpitiya" },
    { bankvalue: 7454, value: 54, label: "Akuressa" },
    { bankvalue: 7454, value: 55, label: "Kattankudy" },
    { bankvalue: 7454, value: 56, label: "Tangalle" },
    { bankvalue: 7454, value: 57, label: "Oddamavadi" },
    { bankvalue: 7454, value: 58, label: "Akkaraipattu" },
    { bankvalue: 7454, value: 59, label: "Chunnakam" },
    { bankvalue: 7454, value: 60, label: "Manipai" },
    { bankvalue: 7454, value: 61, label: "Nelliady" },
    { bankvalue: 7454, value: 62, label: "Hambantota" },
    { bankvalue: 7454, value: 63, label: "Ja Ela" },
    { bankvalue: 7454, value: 64, label: "Kotahena" },
    { bankvalue: 7454, value: 65, label: "Digana" },
    { bankvalue: 7454, value: 66, label: "Thambuttegama" },
    { bankvalue: 7454, value: 67, label: "Galewela" },
    { bankvalue: 7454, value: 68, label: "Eheliyagoda" },
    { bankvalue: 7454, value: 69, label: "Mathugama" },
    { bankvalue: 7454, value: 70, label: "Narammala" },
    { bankvalue: 7454, value: 71, label: "Nikaweratiya" },
    { bankvalue: 7454, value: 72, label: "Mount Lavinia" },
    { bankvalue: 7454, value: 73, label: "Hatton" },
    { bankvalue: 7454, value: 74, label: "Welimada" },
    { bankvalue: 7454, value: 75, label: "Ibbagamuwa" },
    { bankvalue: 7454, value: 76, label: "Gangodawila" },
    { bankvalue: 7454, value: 77, label: "Weligama" },
    { bankvalue: 7454, value: 78, label: "Kaduwela" },
    { bankvalue: 7454, value: 79, label: "Hingurakgoda" },
    { bankvalue: 7454, value: 80, label: "Balangoda" },
    { bankvalue: 7454, value: 81, label: "Bandaragama" },
    { bankvalue: 7454, value: 82, label: "Peradeniya" },
    { bankvalue: 7454, value: 83, label: "Kandy City Centre" },
    { bankvalue: 7454, value: 84, label: "Dehiattakandiya" },
    { bankvalue: 7454, value: 85, label: "Aralaganwila" },
    { bankvalue: 7454, value: 86, label: "Medirigiriya" },
    { bankvalue: 7454, value: 87, label: "Pettah-Main Street" },
    { bankvalue: 7454, value: 88, label: "Kahawatta" },
    { bankvalue: 7454, value: 89, label: "Kochchikade" },
    { bankvalue: 7454, value: 90, label: "Giriulla" },
    { bankvalue: 7454, value: 91, label: "Wennappuwa" },
    { bankvalue: 7454, value: 92, label: "Hikkaduwa" },
    { bankvalue: 7454, value: 93, label: "Nawalapitiya" },
    { bankvalue: 7454, value: 94, label: "Dankotuwa" },
    { bankvalue: 7454, value: 95, label: "Ambalantota" },
    { bankvalue: 7454, value: 96, label: "Wattegama" },
    { bankvalue: 7454, value: 97, label: "Tissamaharama" },
    { bankvalue: 7454, value: 98, label: "Morawaka" },
    { bankvalue: 7454, value: 99, label: "Baddegama" },
    { bankvalue: 7454, value: 100, label: "Matara City" },
    { bankvalue: 7454, value: 101, label: "Nivithigala" },
    { bankvalue: 7454, value: 102, label: "Urubokka" },
    { bankvalue: 7454, value: 103, label: "Buttala" },
    { bankvalue: 7454, value: 104, label: "Wellawaya" },
    { bankvalue: 7454, value: 501, label: "Southern Province SLP Units" },
    { bankvalue: 7454, value: 511, label: "Western  Province SLP Units" },
    { bankvalue: 7454, value: 521, label: "North Western Province SLP Units" },
    { bankvalue: 7454, value: 531, label: "Central Province SLP Units" },
    { bankvalue: 7454, value: 541, label: "Sabaragamuwa Province SLP Units" },
    { bankvalue: 7454, value: 551, label: "North Central  Province SLP Units" },
    { bankvalue: 7454, value: 561, label: "Eastern  Province SLP Units" },
    { bankvalue: 7454, value: 571, label: "Uva Province SLP Units" },
    { bankvalue: 7454, value: 700, label: "Premier Banking Centre" },
    { bankvalue: 7454, value: 800, label: "Central Operations" },
    { bankvalue: 7454, value: 999, label: "Head Office" },
  ],
  7463: [
    { bankvalue: 7463, value: 1, label: "Head Office" },
    { bankvalue: 7463, value: 2, label: "Pettah" },
    { bankvalue: 7463, value: 3, label: "Kandy" },
    { bankvalue: 7463, value: 4, label: "Kattankudy" },
    { bankvalue: 7463, value: 5, label: "Ladies" },
    { bankvalue: 7463, value: 6, label: "Kalmunai" },
    { bankvalue: 7463, value: 8, label: "Galle" },
    { bankvalue: 7463, value: 9, label: "Oddamavadi" },
    { bankvalue: 7463, value: 10, label: "Akurana" },
    { bankvalue: 7463, value: 11, label: "Gampola" },
    { bankvalue: 7463, value: 12, label: "Sammanthurai" },
    { bankvalue: 7463, value: 13, label: "Mawanella" },
    { bankvalue: 7463, value: 14, label: "Kurunegala" },
    { bankvalue: 7463, value: 15, label: "Akkaraipattu" },
    { bankvalue: 7463, value: 16, label: "Dehiwela" },
    { bankvalue: 7463, value: 17, label: "Nintavur" },
    { bankvalue: 7463, value: 18, label: "Kuliyapitiya" },
    { bankvalue: 7463, value: 19, label: "Eravur" },
    { bankvalue: 7463, value: 20, label: "Negombo" },
    { bankvalue: 7463, value: 21, label: "Badulla" },
    { bankvalue: 7463, value: 22, label: "Kaduruwela" },
    { bankvalue: 7463, value: 23, label: "Puttalam" },
    { bankvalue: 7463, value: 24, label: "Kinniya" },
    { bankvalue: 7463, value: 25, label: "Ratnapura" },
    { bankvalue: 7463, value: 26, label: "Kalmunai Unity Square" },
    { bankvalue: 7463, value: 27, label: "Old Moor Street" },
    { bankvalue: 7463, value: 28, label: "Dematagoda" },
    { bankvalue: 7463, value: 29, label: "Kirulapana" },
  ],
  7472: [{ bankvalue: 7472, value: 2, label: "Colombo" }],
  7481: [
    { bankvalue: 7481, value: 1, label: "Corporate" },
    { bankvalue: 7481, value: 2, label: "Maitland Crescent" },
    { bankvalue: 7481, value: 3, label: "Old Moor Street" },
    { bankvalue: 7481, value: 4, label: "Matara" },
    { bankvalue: 7481, value: 5, label: "Maharagama" },
    { bankvalue: 7481, value: 6, label: "Vavuniya" },
    { bankvalue: 7481, value: 7, label: "Hawaeliya" },
    { bankvalue: 7481, value: 8, label: "Thanamalwila" },
    { bankvalue: 7481, value: 9, label: "Kurunegala" },
    { bankvalue: 7481, value: 10, label: "Jaffna City Center" },
    { bankvalue: 7481, value: 11, label: "Chunnakkam" },
    { bankvalue: 7481, value: 12, label: "Galle" },
    { bankvalue: 7481, value: 13, label: "Nuwara Eliya" },
    { bankvalue: 7481, value: 14, label: "Kandy" },
    { bankvalue: 7481, value: 15, label: "Peradeniya" },
    { bankvalue: 7481, value: 16, label: "Ratnapura" },
    { bankvalue: 7481, value: 18, label: "Wattala" },
    { bankvalue: 7481, value: 19, label: "Kaduruwela" },
    { bankvalue: 7481, value: 925, label: "Central Processing Office" },
  ],
  7719: [
    { bankvalue: 7719, value: 1, label: "Head Office" },
    { bankvalue: 7719, value: 2, label: "City" },
    { bankvalue: 7719, value: 3, label: "Galle" },
    { bankvalue: 7719, value: 4, label: "Matara" },
    { bankvalue: 7719, value: 5, label: "Anuradhapura" },
    { bankvalue: 7719, value: 6, label: "Jaffna" },
    { bankvalue: 7719, value: 7, label: "Chilaw" },
    { bankvalue: 7719, value: 8, label: "Kuliyapitiya" },
    { bankvalue: 7719, value: 9, label: "Negombo" },
    { bankvalue: 7719, value: 10, label: "Ratnapura" },
    { bankvalue: 7719, value: 11, label: "Ambalantota" },
    { bankvalue: 7719, value: 12, label: "Kalutara" },
    { bankvalue: 7719, value: 13, label: "Embilipitiya" },
    { bankvalue: 7719, value: 14, label: "Kekirawa" },
    { bankvalue: 7719, value: 15, label: "Kandy" },
    { bankvalue: 7719, value: 16, label: "Matale" },
    { bankvalue: 7719, value: 17, label: "kurunegala" },
    { bankvalue: 7719, value: 18, label: "Kegalle" },
    { bankvalue: 7719, value: 19, label: "Kilinochchi" },
    { bankvalue: 7719, value: 20, label: "Moratuwa" },
    { bankvalue: 7719, value: 21, label: "Batticaloa" },
    { bankvalue: 7719, value: 22, label: "Badulla" },
    { bankvalue: 7719, value: 23, label: "Bambalapitiya" },
    { bankvalue: 7719, value: 24, label: "Dehiwala" },
    { bankvalue: 7719, value: 25, label: "Peliyagoda" },
    { bankvalue: 7719, value: 26, label: "Nugegoda" },
    { bankvalue: 7719, value: 27, label: "Homagama" },
    { bankvalue: 7719, value: 28, label: "Beruwala" },
    { bankvalue: 7719, value: 29, label: "Wennappuwa" },
    { bankvalue: 7719, value: 30, label: "Ampara" },
    { bankvalue: 7719, value: 31, label: "Kochchikade" },
    { bankvalue: 7719, value: 32, label: "Point Pedro" },
    { bankvalue: 7719, value: 33, label: "Ambalangoda" },
    { bankvalue: 7719, value: 34, label: "Naththandiya" },
    { bankvalue: 7719, value: 35, label: "Ruwanwella" },
    { bankvalue: 7719, value: 36, label: "Ja-Ela" },
    { bankvalue: 7719, value: 37, label: "Gampaha" },
    { bankvalue: 7719, value: 39, label: "Devinuwara" },
    { bankvalue: 7719, value: 40, label: "Nikaweratiya" },
    { bankvalue: 7719, value: 41, label: "Mahiyanganaya" },
    { bankvalue: 7719, value: 42, label: "Warakapola" },
    { bankvalue: 7719, value: 43, label: "Panadura" },
    { bankvalue: 7719, value: 44, label: "Puttalama" },
    { bankvalue: 7719, value: 45, label: "Matugama" },
    { bankvalue: 7719, value: 46, label: "Monaragala" },
    { bankvalue: 7719, value: 47, label: "Kalmunai" },
    { bankvalue: 7719, value: 48, label: "Beliatta" },
    { bankvalue: 7719, value: 49, label: "Mannar" },
    { bankvalue: 7719, value: 50, label: "Nawalapitiya" },
    { bankvalue: 7719, value: 51, label: "Pettah" },
    { bankvalue: 7719, value: 52, label: "Katunayake" },
    { bankvalue: 7719, value: 53, label: "Maharagama" },
    { bankvalue: 7719, value: 54, label: "Deniyaya" },
    { bankvalue: 7719, value: 55, label: "Akuressa" },
    { bankvalue: 7719, value: 56, label: "Nuwara Eliya" },
    { bankvalue: 7719, value: 57, label: "Avissawella" },
    { bankvalue: 7719, value: 58, label: "Galnewa" },
    { bankvalue: 7719, value: 59, label: "Mawanella" },
    { bankvalue: 7719, value: 60, label: "Bandarawela" },
    { bankvalue: 7719, value: 61, label: "Borella" },
    { bankvalue: 7719, value: 62, label: "Hakmana" },
    { bankvalue: 7719, value: 63, label: "Horana" },
    { bankvalue: 7719, value: 64, label: "Narahenpita" },
    { bankvalue: 7719, value: 65, label: "Kollupitiya 2nd" },
    { bankvalue: 7719, value: 66, label: "Weligama" },
    { bankvalue: 7719, value: 67, label: "Kiribathgoda" },
    { bankvalue: 7719, value: 68, label: "Mount Lavinia" },
    { bankvalue: 7719, value: 69, label: "Marawila" },
    { bankvalue: 7719, value: 70, label: "Wellawatta" },
    { bankvalue: 7719, value: 71, label: "Piliyandala" },
    { bankvalue: 7719, value: 72, label: "Chunnakam" },
    { bankvalue: 7719, value: 73, label: "Chavakachcheri" },
    { bankvalue: 7719, value: 74, label: "Gampola" },
    { bankvalue: 7719, value: 75, label: "Kadawatha" },
    { bankvalue: 7719, value: 76, label: "Hingurakgoda" },
    { bankvalue: 7719, value: 77, label: "Maligawatta" },
    { bankvalue: 7719, value: 78, label: "Thalawakele" },
    { bankvalue: 7719, value: 79, label: "Mirigama" },
    { bankvalue: 7719, value: 80, label: "Battaramulla" },
    { bankvalue: 7719, value: 81, label: "Kandy 2nd" },
    { bankvalue: 7719, value: 82, label: "Dickwella" },
    { bankvalue: 7719, value: 83, label: "Mahabage" },
    { bankvalue: 7719, value: 84, label: "Pilimathalawa" },
    { bankvalue: 7719, value: 85, label: "Wattala" },
    { bankvalue: 7719, value: 86, label: "Kamburupitiya" },
    { bankvalue: 7719, value: 87, label: "Kotahena" },
    { bankvalue: 7719, value: 88, label: "Vavuniya" },
    { bankvalue: 7719, value: 89, label: "Trincomalee" },
    { bankvalue: 7719, value: 90, label: "Morawaka" },
    { bankvalue: 7719, value: 91, label: "Balangoda" },
    { bankvalue: 7719, value: 92, label: "Veyangoda" },
    { bankvalue: 7719, value: 93, label: "Katubedda" },
    { bankvalue: 7719, value: 94, label: "Elpitiya" },
    { bankvalue: 7719, value: 95, label: "Kaduwela" },
    { bankvalue: 7719, value: 96, label: "Divulapitiya" },
    { bankvalue: 7719, value: 97, label: "Tissamaharama" },
    { bankvalue: 7719, value: 98, label: "Minuwangoda" },
    { bankvalue: 7719, value: 99, label: "Kirindiwela" },
    { bankvalue: 7719, value: 100, label: "Nittambuwa" },
    { bankvalue: 7719, value: 101, label: "Welimada" },
    { bankvalue: 7719, value: 102, label: "Kottawa" },
    { bankvalue: 7719, value: 103, label: "Dambulla" },
    { bankvalue: 7719, value: 104, label: "Kahathuduwa" },
    { bankvalue: 7719, value: 105, label: "Aluthgama" },
    { bankvalue: 7719, value: 106, label: "Meegoda" },
    { bankvalue: 7719, value: 107, label: "Manipay" },
    { bankvalue: 7719, value: 108, label: "Thirunelvely" },
    { bankvalue: 7719, value: 109, label: "Chenkaladi" },
    { bankvalue: 7719, value: 110, label: "Uragasmanhandiya" },
    { bankvalue: 7719, value: 111, label: "Nawala" },
    { bankvalue: 7719, value: 112, label: "Deraniyagala" },
    { bankvalue: 7719, value: 113, label: "Hikkaduwa" },
    { bankvalue: 7719, value: 114, label: "Kalawanchikudy" },
    { bankvalue: 7719, value: 115, label: "Kalubowila" },
    { bankvalue: 7719, value: 116, label: "Hatton" },
    { bankvalue: 7719, value: 117, label: "Welikada" },
    { bankvalue: 7719, value: 118, label: "Samanthurai" },
    { bankvalue: 7719, value: 119, label: "Delkanda" },
    { bankvalue: 7719, value: 120, label: "Yakkala" },
    { bankvalue: 7719, value: 121, label: "Karapitiya" },
    { bankvalue: 7719, value: 122, label: "Kaduruwela" },
    { bankvalue: 7719, value: 123, label: "Malabe" },
    { bankvalue: 7719, value: 124, label: "Boralesgamuwa" },
    { bankvalue: 7719, value: 125, label: "Moratumulla" },
    { bankvalue: 7719, value: 126, label: "Bandaragama" },
    { bankvalue: 7719, value: 127, label: "Mulgampola" },
    { bankvalue: 7719, value: 128, label: "Badalkumbura" },
    { bankvalue: 7719, value: 129, label: "Dankotuwa" },
    { bankvalue: 7719, value: 130, label: "Mullative" },
    { bankvalue: 7719, value: 131, label: "Kodikamam" },
    { bankvalue: 7719, value: 132, label: "Alawathugoda" },
    { bankvalue: 7719, value: 133, label: "Godakawela" },
    { bankvalue: 7719, value: 134, label: "Periyakallar" },
    { bankvalue: 7719, value: 135, label: "Thamabiluvil" },
    { bankvalue: 7719, value: 136, label: "Horawpathana" },
    { bankvalue: 7719, value: 137, label: "Galenbindunuwewa" },
    { bankvalue: 7719, value: 138, label: "Pitakatte" },
    { bankvalue: 7719, value: 139, label: "Pitigala" },
    { bankvalue: 7719, value: 140, label: "Urubokka" },
    { bankvalue: 7719, value: 141, label: "Kandana" },
    { bankvalue: 7719, value: 142, label: "Maho" },
    { bankvalue: 7719, value: 143, label: "Udugama" },
    { bankvalue: 7719, value: 144, label: "Uhana" },
    { bankvalue: 7719, value: 145, label: "Galagedara" },
    { bankvalue: 7719, value: 146, label: "Siyambalanduwa" },
    { bankvalue: 7719, value: 147, label: "Kopay" },
    { bankvalue: 7719, value: 148, label: "Peradeniya" },
    { bankvalue: 7719, value: 149, label: "City Plus" },
    { bankvalue: 7719, value: 150, label: "Piliyandala 2nd" },
    { bankvalue: 7719, value: 701, label: "Ragama Piyasa" },
    { bankvalue: 7719, value: 702, label: "WTC Piyasa" },
    { bankvalue: 7719, value: 703, label: "Athurugiriya Piyasa" },
    { bankvalue: 7719, value: 704, label: "Neluwa Piyasa" },
    { bankvalue: 7719, value: 705, label: "Ganemulla Piyasa" },
    { bankvalue: 7719, value: 706, label: "Wellampitiya Piyasa" },
    { bankvalue: 7719, value: 707, label: "Narammala Piyasa" },
    { bankvalue: 7719, value: 708, label: "Bibile 'piyasa'" },
    { bankvalue: 7719, value: 709, label: "Hettipola" },
    { bankvalue: 7719, value: 710, label: "Bulathkohupitiya" },
    { bankvalue: 7719, value: 711, label: "Kandy City Center" },
    { bankvalue: 7719, value: 712, label: "Weeraketiya NSB Piyasa" },
    { bankvalue: 7719, value: 713, label: "Tangalle NSB Piyasa" },
    { bankvalue: 7719, value: 714, label: "Walasmulla NSB Piyasa" },
    { bankvalue: 7719, value: 715, label: "Wadduwa NSB Piyasa" },
    { bankvalue: 7719, value: 716, label: "Kelaniya NSB Piyasa" },
    { bankvalue: 7719, value: 717, label: "Nelliady NSB Piyasa" },
    { bankvalue: 7719, value: 718, label: "Atchuvely NSB Piyasa" },
    { bankvalue: 7719, value: 719, label: "Puwakaramba Piyasa" },
    { bankvalue: 7719, value: 720, label: "Valaichenai Piyasa" },
    { bankvalue: 7719, value: 721, label: "Aranayake Piyasa" },
    { bankvalue: 7719, value: 722, label: "Rikillagaskada Piyasa" },
    { bankvalue: 7719, value: 723, label: "Katugastota Piyasa" },
    { bankvalue: 7719, value: 724, label: "Mvaluedeniya Piyasa" },
    { bankvalue: 7719, value: 725, label: "Chankanai Piyasa" },
    { bankvalue: 7719, value: 726, label: "Polgahawela" },
    { bankvalue: 7719, value: 727, label: "Arayampathy" },
    { bankvalue: 7719, value: 728, label: "Raddolugama" },
    { bankvalue: 7719, value: 729, label: "Galgamuwa" },
    { bankvalue: 7719, value: 730, label: "Kayts" },
    { bankvalue: 7719, value: 731, label: "Karainagar" },
    { bankvalue: 7719, value: 732, label: "Mutwal" },
    { bankvalue: 7719, value: 733, label: "Mulleriyawa New Town" },
    { bankvalue: 7719, value: 734, label: "Baddegama" },
    { bankvalue: 7719, value: 735, label: "Habaraduwa" },
    { bankvalue: 7719, value: 736, label: "Pelmadulla" },
    { bankvalue: 7719, value: 737, label: "Kahawatte" },
    { bankvalue: 7719, value: 738, label: "Bulathsinhala" },
    { bankvalue: 7719, value: 739, label: "Kalawana" },
    { bankvalue: 7719, value: 740, label: "Wellawaya" },
    { bankvalue: 7719, value: 741, label: "Buttala" },
    { bankvalue: 7719, value: 742, label: "Alawwa" },
    { bankvalue: 7719, value: 743, label: "Yatiyantota" },
    { bankvalue: 7719, value: 744, label: "Pundaluoya" },
    { bankvalue: 7719, value: 745, label: "Nochchiyagama" },
    { bankvalue: 7719, value: 746, label: "Anamaduwa" },
    { bankvalue: 7719, value: 747, label: "Delgoda" },
    { bankvalue: 7719, value: 748, label: "Digana" },
    { bankvalue: 7719, value: 749, label: "Mawathagama" },
    { bankvalue: 7719, value: 750, label: "Hanwella" },
    { bankvalue: 7719, value: 751, label: "Hali Ela" },
    { bankvalue: 7719, value: 752, label: "Akkaraipattu" },
    { bankvalue: 7719, value: 753, label: "Palugamam" },
    { bankvalue: 7719, value: 754, label: "Sooriyawewa" },
    { bankvalue: 7719, value: 755, label: "Galewela" },
    { bankvalue: 7719, value: 756, label: "Tambuttegama" },
    { bankvalue: 7719, value: 757, label: "Imaduwa" },
    { bankvalue: 7719, value: 758, label: "Passara" },
    { bankvalue: 7719, value: 759, label: "Kahatagasdigiliya" },
    { bankvalue: 7719, value: 760, label: "Angunakolapelessa" },
    { bankvalue: 7719, value: 761, label: "Madampe" },
    { bankvalue: 7719, value: 762, label: "Kantale" },
    { bankvalue: 7719, value: 763, label: "Medawachchiya" },
    { bankvalue: 7719, value: 764, label: "Ingiriya" },
    { bankvalue: 7719, value: 765, label: "Rambukkana" },
    { bankvalue: 7719, value: 766, label: "Wariyapola" },
    { bankvalue: 7719, value: 767, label: "Kebithigollawa" },
    { bankvalue: 7719, value: 768, label: "Wattegama" },
    { bankvalue: 7719, value: 769, label: "Pussellawa" },
    { bankvalue: 7719, value: 770, label: "Pothuvil" },
    { bankvalue: 7719, value: 771, label: "Ibbagamuwa" },
    { bankvalue: 7719, value: 772, label: "Kuruwita" },
    { bankvalue: 7719, value: 773, label: "Vankalai" },
    { bankvalue: 7719, value: 774, label: "Mallavi" },
    { bankvalue: 7719, value: 775, label: "Kekanadura" },
    { bankvalue: 7719, value: 776, label: "Medirigiriya" },
    { bankvalue: 7719, value: 777, label: "Nivithigala" },
    { bankvalue: 7719, value: 778, label: "Kiriella" },
    { bankvalue: 7719, value: 779, label: "Naula" },
    { bankvalue: 7719, value: 780, label: "Melsiripura" },
    { bankvalue: 7719, value: 781, label: "Hambantota" },
    { bankvalue: 7719, value: 782, label: "Rvalueeegama" },
    { bankvalue: 7719, value: 783, label: "Deltota" },
    { bankvalue: 7719, value: 784, label: "Ginigathhena" },
    { bankvalue: 7719, value: 785, label: "Bingiriya" },
    { bankvalue: 7719, value: 786, label: "Baduraliya" },
    { bankvalue: 7719, value: 787, label: "Giriulla" },
    { bankvalue: 7719, value: 788, label: "Eppawala" },
    { bankvalue: 7719, value: 789, label: "Thalawathugoda" },
    { bankvalue: 7719, value: 790, label: "Pugoda" },
    { bankvalue: 7719, value: 791, label: "Gelioya" },
    { bankvalue: 7719, value: 792, label: "Ragala" },
    { bankvalue: 7719, value: 901, label: "PBU - Head Office" },
    { bankvalue: 7719, value: 902, label: "PBU - Kandy" },
    { bankvalue: 7719, value: 903, label: "PBU - Galle" },
    { bankvalue: 7719, value: 904, label: "PBU - Matara" },
    { bankvalue: 7719, value: 905, label: "PBU - Kegalle" },
    { bankvalue: 7719, value: 906, label: "PBU - Anuradhapura" },
    { bankvalue: 7719, value: 907, label: "PBU - Kalutara" },
    { bankvalue: 7719, value: 908, label: "PBU - Gampaha" },
    { bankvalue: 7719, value: 909, label: "PBU - Ampara" },
    { bankvalue: 7719, value: 910, label: "Credit Division, (H.L Division)" },
    { bankvalue: 7719, value: 911, label: "IBU (NRFC)" },
    { bankvalue: 7719, value: 912, label: "Br. Management Division" },
    { bankvalue: 7719, value: 915, label: "PBU - Kurunegala" },
    { bankvalue: 7719, value: 916, label: "PBU - Jaffna" },
    { bankvalue: 7719, value: 917, label: "PBU - Eheliyagoda" },
  ],
  7728: [
    { bankvalue: 7728, value: 1, label: "1st Colombo City" },
    { bankvalue: 7728, value: 2, label: "Kegalle" },
    { bankvalue: 7728, value: 3, label: "Battaramulla" },
    { bankvalue: 7728, value: 4, label: "Embilipitiya" },
    { bankvalue: 7728, value: 5, label: "Horana" },
    { bankvalue: 7728, value: 6, label: "Kiribathgoda" },
    { bankvalue: 7728, value: 7, label: "Karapitiya" },
    { bankvalue: 7728, value: 8, label: "Akurassa" },
    { bankvalue: 7728, value: 9, label: "Matale" },
    { bankvalue: 7728, value: 10, label: "Kandy" },
    { bankvalue: 7728, value: 11, label: "Chilaw" },
    { bankvalue: 7728, value: 12, label: "Vavuniya" },
    { bankvalue: 7728, value: 13, label: "Manaragala" },
    { bankvalue: 7728, value: 14, label: "Ruwanwella" },
    { bankvalue: 7728, value: 15, label: "Rathnapura" },
    { bankvalue: 7728, value: 16, label: "Warakapola" },
    { bankvalue: 7728, value: 17, label: "Anuradhapura" },
    { bankvalue: 7728, value: 18, label: "Sahasapura" },
    { bankvalue: 7728, value: 19, label: "Rikillagaskada" },
    { bankvalue: 7728, value: 20, label: "Kurunegala" },
    { bankvalue: 7728, value: 21, label: "Trincomalle" },
    { bankvalue: 7728, value: 22, label: "Kalmuani" },
    { bankvalue: 7728, value: 23, label: "Ambalanthota" },
    { bankvalue: 7728, value: 25, label: "Kalutara" },
    { bankvalue: 7728, value: 26, label: "Kuliyapitiya" },
    { bankvalue: 7728, value: 27, label: "Negombo" },
    { bankvalue: 7728, value: 28, label: "Polonnaruwa" },
    { bankvalue: 7728, value: 29, label: "Batticaloa" },
    { bankvalue: 7728, value: 30, label: "Ambalangoda" },
    { bankvalue: 7728, value: 31, label: "Matara" },
    { bankvalue: 7728, value: 32, label: "Galle" },
    { bankvalue: 7728, value: 33, label: "Giriulla" },
    { bankvalue: 7728, value: 34, label: "Rambukkana" },
    { bankvalue: 7728, value: 35, label: "Dambulla" },
    { bankvalue: 7728, value: 36, label: "Thambuththegama" },
    { bankvalue: 7728, value: 37, label: "Maho" },
    { bankvalue: 7728, value: 38, label: "Wennappuwa" },
    { bankvalue: 7728, value: 39, label: "Ampara" },
    { bankvalue: 7728, value: 40, label: "Medawachchiya" },
    { bankvalue: 7728, value: 41, label: "Muthur" },
    { bankvalue: 7728, value: 42, label: "Jaffna" },
    { bankvalue: 7728, value: 43, label: "Nanatan" },
    { bankvalue: 7728, value: 44, label: "Badulla" },
    { bankvalue: 7728, value: 45, label: "Pottuvil" },
    { bankvalue: 7728, value: 46, label: "Mapalagama" },
    { bankvalue: 7728, value: 47, label: "Mathugama" },
    { bankvalue: 7728, value: 48, label: "Gampola" },
    { bankvalue: 7728, value: 49, label: "Deniyaya" },
    { bankvalue: 7728, value: 50, label: "Anamaduwa" },
    { bankvalue: 7728, value: 51, label: "Angunakolapalassa" },
    { bankvalue: 7728, value: 52, label: "Aralaganvila" },
    { bankvalue: 7728, value: 53, label: "Galenbvalueunuwewa" },
    { bankvalue: 7728, value: 54, label: "Wariyapola" },
    { bankvalue: 7728, value: 55, label: "Pilimathalawa" },
    { bankvalue: 7728, value: 56, label: "Kirulapona" },
    { bankvalue: 7728, value: 57, label: "Deraniyagala" },
    { bankvalue: 7728, value: 58, label: "Dehiattakandiya" },
    { bankvalue: 7728, value: 59, label: "Kalawanchikudi" },
    { bankvalue: 7728, value: 60, label: "Samanthurei" },
    { bankvalue: 7728, value: 61, label: "Siyabalanduwa" },
    { bankvalue: 7728, value: 62, label: "Buttala" },
    { bankvalue: 7728, value: 63, label: "Valachchena" },
    { bankvalue: 7728, value: 64, label: "Elpitiya" },
    { bankvalue: 7728, value: 65, label: "Nochchiyagama" },
    { bankvalue: 7728, value: 66, label: "Yakkalamulla" },
    { bankvalue: 7728, value: 67, label: "Katuwana" },
    { bankvalue: 7728, value: 68, label: "Mawanella" },
    { bankvalue: 7728, value: 69, label: "Kilinochchi" },
    { bankvalue: 7728, value: 70, label: "Padavi Parakramapura" },
    { bankvalue: 7728, value: 71, label: "Kekirawa" },
    { bankvalue: 7728, value: 72, label: "Uhana" },
    { bankvalue: 7728, value: 73, label: "Kanthale" },
    { bankvalue: 7728, value: 74, label: "Akkeripattu" },
    { bankvalue: 7728, value: 75, label: "Moratuwa" },
    { bankvalue: 7728, value: 76, label: "Hatharaliyadda" },
    { bankvalue: 7728, value: 77, label: "Hingurna" },
    { bankvalue: 7728, value: 78, label: "Kaduwela" },
    { bankvalue: 7728, value: 79, label: "Narammala" },
    { bankvalue: 7728, value: 80, label: "Aluthgama" },
    { bankvalue: 7728, value: 81, label: "Maharagama" },
    { bankvalue: 7728, value: 82, label: "Gampaha" },
    { bankvalue: 7728, value: 83, label: "Thalawa" },
    { bankvalue: 7728, value: 84, label: "Malabe" },
    { bankvalue: 7728, value: 85, label: "Mahabage" },
    { bankvalue: 7728, value: 86, label: "Dehiwala" },
    { bankvalue: 7728, value: 87, label: "Ekala" },
    { bankvalue: 7728, value: 88, label: "Nuwara Eliya" },
    { bankvalue: 7728, value: 89, label: "Chunnakam" },
    { bankvalue: 7728, value: 90, label: "Mannar" },
    { bankvalue: 7728, value: 91, label: "Tissamaharama" },
    { bankvalue: 7728, value: 92, label: "Bandarawela" },
    { bankvalue: 7728, value: 93, label: "Galewela" },
    { bankvalue: 7728, value: 999, label: "Sanasa Development Bank Head Office" },
  ],
  7737: [{ bankvalue: 7737, value: 1, label: "Head Office" }],
  7746: [
    { bankvalue: 7746, value: 2, label: "Moratuwa" },
    { bankvalue: 7746, value: 3, label: "Negombo" },
    { bankvalue: 7746, value: 4, label: "Kurunegala" },
    { bankvalue: 7746, value: 5, label: "Kelaniya" },
    { bankvalue: 7746, value: 6, label: "Kandy" },
    { bankvalue: 7746, value: 7, label: "Gampaha" },
    { bankvalue: 7746, value: 8, label: "Rathnapura" },
    { bankvalue: 7746, value: 9, label: "Badulla" },
    { bankvalue: 7746, value: 10, label: "Chilaw" },
    { bankvalue: 7746, value: 11, label: "Anuradhapura" },
    { bankvalue: 7746, value: 12, label: "Wellawatta" },
    { bankvalue: 7746, value: 13, label: "Wattala" },
    { bankvalue: 7746, value: 14, label: "Ja-Ela" },
    { bankvalue: 7746, value: 15, label: "Kaduwela" },
    { bankvalue: 7746, value: 16, label: "Kegalle" },
    { bankvalue: 7746, value: 17, label: "Nittambuwa" },
    { bankvalue: 7746, value: 18, label: "Wennappuwa" },
    { bankvalue: 7746, value: 19, label: "Mathugama" },
    { bankvalue: 7746, value: 20, label: "Kaluthara" },
    { bankvalue: 7746, value: 21, label: "Maharagama" },
    { bankvalue: 7746, value: 22, label: "Matara" },
    { bankvalue: 7746, value: 23, label: "Embilipitiya" },
    { bankvalue: 7746, value: 24, label: "Battaramulla" },
    { bankvalue: 7746, value: 25, label: "Tissamaharama" },
    { bankvalue: 7746, value: 26, label: "Mahara" },
    { bankvalue: 7746, value: 27, label: "Galle" },
    { bankvalue: 7746, value: 28, label: "Dambulla" },
    { bankvalue: 7746, value: 29, label: "Kaduruwela" },
    { bankvalue: 7746, value: 30, label: "Kotahena" },
    { bankvalue: 7746, value: 31, label: "Colombo Office" },
    { bankvalue: 7746, value: 32, label: "Jaffna" },
    { bankvalue: 7746, value: 33, label: "Vavuniya" },
    { bankvalue: 7746, value: 34, label: "Batticaloa" },
    { bankvalue: 7746, value: 35, label: "Trincomalee" },
    { bankvalue: 7746, value: 36, label: "Bandarawela" },
    { bankvalue: 7746, value: 38, label: "Head Office" },
    { bankvalue: 7746, value: 39, label: "Kadana" },
    { bankvalue: 7746, value: 40, label: "Ragama" },
    { bankvalue: 7746, value: 41, label: "Ela Kanda" },
    { bankvalue: 7746, value: 42, label: "Eheliyagoda" },
    { bankvalue: 7746, value: 43, label: "Boralesgamuwa" },
    { bankvalue: 7746, value: 44, label: "Marawila" },
    { bankvalue: 7746, value: 45, label: "Kuliyapitiya" },
    { bankvalue: 7746, value: 46, label: "Ratmalana" },
    { bankvalue: 7746, value: 47, label: "Panadura" },
    { bankvalue: 7746, value: 48, label: "Nugegoda" },
    { bankvalue: 7746, value: 49, label: "Premier Center" },
    { bankvalue: 7746, value: 50, label: "Horana" },
    { bankvalue: 7746, value: 51, label: "Pelmadulla" },
    { bankvalue: 7746, value: 52, label: "Kochchikade" },
    { bankvalue: 7746, value: 53, label: "Rajagiriya" },
    { bankvalue: 7746, value: 54, label: "Katugasthota" },
    { bankvalue: 7746, value: 55, label: "Matale" },
    { bankvalue: 7746, value: 56, label: "Piliyandala" },
    { bankvalue: 7746, value: 57, label: "Ambalangoda" },
    { bankvalue: 7746, value: 58, label: "Malabe" },
    { bankvalue: 7746, value: 59, label: "Minuwangoda" },
    { bankvalue: 7746, value: 60, label: "Dehiwala" },
    { bankvalue: 7746, value: 61, label: "Warakapola" },
    { bankvalue: 7746, value: 62, label: "Kottawa" },
    { bankvalue: 7746, value: 63, label: "Narammala" },
    { bankvalue: 7746, value: 64, label: "Mawathagama" },
    { bankvalue: 7746, value: 65, label: "Avissawella" },
    { bankvalue: 7746, value: 66, label: "Wariyapola" },
    { bankvalue: 7746, value: 67, label: "Nikaweratiya" },
    { bankvalue: 7746, value: 68, label: "Kuruvita" },
    { bankvalue: 7746, value: 69, label: "Aluthgama" },
    { bankvalue: 7746, value: 70, label: "Mahiyanganaya" },
    { bankvalue: 7746, value: 71, label: "Thalawathugoda" },
    { bankvalue: 7746, value: 72, label: "Giriulla" },
  ],
  7755: [
    { bankvalue: 7755, value: "001", label: "Head Office" },
    { bankvalue: 7755, value: "003", label: "Provincial Office - Central" },
    { bankvalue: 7755, value: 100, label: "Provincial Office - Western" },
    { bankvalue: 7755, value: 101, label: "Bulathsinghala" },
    { bankvalue: 7755, value: 102, label: "Walagedara" },
    { bankvalue: 7755, value: 103, label: "Agalawatte" },
    { bankvalue: 7755, value: 104, label: "Millaniya" },
    { bankvalue: 7755, value: 105, label: "Goonapola" },
    { bankvalue: 7755, value: 106, label: "Moranthuduwa" },
    { bankvalue: 7755, value: 107, label: "Beruwala" },
    { bankvalue: 7755, value: 108, label: "Panadura" },
    { bankvalue: 7755, value: 109, label: "Horana" },
    { bankvalue: 7755, value: 110, label: "Warakagoda" },
    { bankvalue: 7755, value: 111, label: "Ingiriya" },
    { bankvalue: 7755, value: 112, label: "Dodangoda" },
    { bankvalue: 7755, value: 113, label: "Meegahathenna" },
    { bankvalue: 7755, value: 114, label: "Baduraliya" },
    { bankvalue: 7755, value: 115, label: "Kalutara" },
    { bankvalue: 7755, value: 116, label: "Gampaha" },
    { bankvalue: 7755, value: 117, label: "Mawaramandiya" },
    { bankvalue: 7755, value: 118, label: "Minuwangoda" },
    { bankvalue: 7755, value: 119, label: "Meerigama" },
    { bankvalue: 7755, value: 120, label: "Moragahahena" },
    { bankvalue: 7755, value: 121, label: "Mathugama" },
    { bankvalue: 7755, value: 122, label: "Negombo" },
    { bankvalue: 7755, value: 123, label: "Divulapitiya" },
    { bankvalue: 7755, value: 124, label: "Nittambuwa" },
    { bankvalue: 7755, value: 125, label: "Homagama" },
    { bankvalue: 7755, value: 126, label: "Kolonnawa" },
    { bankvalue: 7755, value: 127, label: "Awissawella" },
    { bankvalue: 7755, value: 128, label: "Piliyandala" },
    { bankvalue: 7755, value: 129, label: "Ragama" },
    { bankvalue: 7755, value: 130, label: "Wadduwa" },
    { bankvalue: 7755, value: 131, label: "Kirindiwela" },
    { bankvalue: 7755, value: 132, label: "J-Ela" },
    { bankvalue: 7755, value: 133, label: "Miriswaththa" },
    { bankvalue: 7755, value: 134, label: "Kelaniya - Head Quarter" },
    { bankvalue: 7755, value: 198, label: "Gampaha District Office" },
    { bankvalue: 7755, value: 199, label: "Kalutara District Office" },
    { bankvalue: 7755, value: 200, label: "Provincial Office - Southern" },
    { bankvalue: 7755, value: 201, label: "Hakmana" },
    { bankvalue: 7755, value: 202, label: "Urubokka" },
    { bankvalue: 7755, value: 203, label: "Deiyandara" },
    { bankvalue: 7755, value: 204, label: "Akuressa" },
    { bankvalue: 7755, value: 205, label: "Weligama" },
    { bankvalue: 7755, value: 206, label: "Gandara" },
    { bankvalue: 7755, value: 207, label: "Kekanadura" },
    { bankvalue: 7755, value: 208, label: "Ambalantota" },
    { bankvalue: 7755, value: 209, label: "Angunukolapalassa" },
    { bankvalue: 7755, value: 210, label: "Katuwana" },
    { bankvalue: 7755, value: 211, label: "Beliatta" },
    { bankvalue: 7755, value: 212, label: "Elpitiya" },
    { bankvalue: 7755, value: 213, label: "Batapola" },
    { bankvalue: 7755, value: 214, label: "Pitigala" },
    { bankvalue: 7755, value: 215, label: "Gonagalapura" },
    { bankvalue: 7755, value: 216, label: "Imaduwa" },
    { bankvalue: 7755, value: 217, label: "Baddegama" },
    { bankvalue: 7755, value: 218, label: "Tissamaharama" },
    { bankvalue: 7755, value: 219, label: "Lunugamwehera" },
    { bankvalue: 7755, value: 220, label: "Pitabaddera" },
    { bankvalue: 7755, value: 221, label: "Thalgaswala" },
    { bankvalue: 7755, value: 222, label: "Akmeemana" },
    { bankvalue: 7755, value: 223, label: "Karandeniya" },
    { bankvalue: 7755, value: 224, label: "Sooriyawewa" },
    { bankvalue: 7755, value: 225, label: "Kamburugamuwa" },
    { bankvalue: 7755, value: 226, label: "Deniyaya" },
    { bankvalue: 7755, value: 227, label: "Kamburupitiya" },
    { bankvalue: 7755, value: 228, label: "Galle" },
    { bankvalue: 7755, value: 229, label: "Uragasmanhandiya" },
    { bankvalue: 7755, value: 230, label: "Yakkalamulla" },
    { bankvalue: 7755, value: 231, label: "Weerakatiya" },
    { bankvalue: 7755, value: 232, label: "Thihagoda" },
    { bankvalue: 7755, value: 233, label: "City - Matara" },
    { bankvalue: 7755, value: 234, label: "Tangalle" },
    { bankvalue: 7755, value: 235, label: "Neluwa" },
    { bankvalue: 7755, value: 236, label: "Mawarala" },
    { bankvalue: 7755, value: 237, label: "Morawaka" },
    { bankvalue: 7755, value: 238, label: "Hambantota" },
    { bankvalue: 7755, value: 239, label: "Walasmulla" },
    { bankvalue: 7755, value: 240, label: "Barawakumbuka" },
    { bankvalue: 7755, value: 241, label: "Udugama" },
    { bankvalue: 7755, value: 242, label: "Ranna" },
    { bankvalue: 7755, value: 243, label: "Ahangama" },
    { bankvalue: 7755, value: 244, label: "Hikkaduwa" },
    { bankvalue: 7755, value: 245, label: "Kirinda" },
    { bankvalue: 7755, value: 246, label: "Mvaluedeniya" },
    { bankvalue: 7755, value: 247, label: "Dikwella" },
    { bankvalue: 7755, value: 248, label: "Karapitiya" },
    { bankvalue: 7755, value: 249, label: "Balapitiya" },
    { bankvalue: 7755, value: 250, label: "Pamburana" },
    { bankvalue: 7755, value: 251, label: "Mirissa" },
    { bankvalue: 7755, value: 252, label: "Kaluwella" },
    { bankvalue: 7755, value: 253, label: "Warapitiya" },
    { bankvalue: 7755, value: 254, label: "Devinuwara" },
    { bankvalue: 7755, value: 297, label: "District Office - Hambantota" },
    { bankvalue: 7755, value: 298, label: "District Office - Galle" },
    { bankvalue: 7755, value: 299, label: "District Office - Matara" },
    { bankvalue: 7755, value: 300, label: "Wayamba Provincial Office" },
    { bankvalue: 7755, value: 301, label: "Kuliyapitiya" },
    { bankvalue: 7755, value: 302, label: "Pannala" },
    { bankvalue: 7755, value: 303, label: "Panduwasnuwara" },
    { bankvalue: 7755, value: 304, label: "Alawwa" },
    { bankvalue: 7755, value: 305, label: "Dummalasooriya" },
    { bankvalue: 7755, value: 306, label: "Pothuhera" },
    { bankvalue: 7755, value: 307, label: "Nikaweratiya" },
    { bankvalue: 7755, value: 308, label: "Rvalueeegama" },
    { bankvalue: 7755, value: 309, label: "Mawathagama" },
    { bankvalue: 7755, value: 310, label: "Wariyapola" },
    { bankvalue: 7755, value: 311, label: "Kurunegala" },
    { bankvalue: 7755, value: 312, label: "Galgamuwa" },
    { bankvalue: 7755, value: 313, label: "Chilaw" },
    { bankvalue: 7755, value: 314, label: "Palakuda" },
    { bankvalue: 7755, value: 315, label: "Anamaduwa" },
    { bankvalue: 7755, value: 316, label: "Nattandiya" },
    { bankvalue: 7755, value: 317, label: "Kirimetiyana" },
    { bankvalue: 7755, value: 318, label: "Puttlam" },
    { bankvalue: 7755, value: 319, label: "Maho" },
    { bankvalue: 7755, value: 320, label: "Giriulla" },
    { bankvalue: 7755, value: 321, label: "Ibbagamuwa" },
    { bankvalue: 7755, value: 322, label: "Mundel" },
    { bankvalue: 7755, value: 323, label: "Nawagattegama" },
    { bankvalue: 7755, value: 324, label: "Mampuri" },
    { bankvalue: 7755, value: 325, label: "Mahawewa" },
    { bankvalue: 7755, value: 326, label: "Narammala" },
    { bankvalue: 7755, value: 327, label: "Polpithigama" },
    { bankvalue: 7755, value: 328, label: "Bowatte" },
    { bankvalue: 7755, value: 329, label: "HQB" },
    { bankvalue: 7755, value: 330, label: "Melsiripura" },
    { bankvalue: 7755, value: 331, label: "Ambanpola" },
    { bankvalue: 7755, value: 332, label: "Santha Anapura" },
    { bankvalue: 7755, value: 398, label: "Puttlam District Office" },
    { bankvalue: 7755, value: 399, label: "Kurunegala District Office" },
    { bankvalue: 7755, value: 400, label: "Provincial Office - North Central" },
    { bankvalue: 7755, value: 401, label: "Mihinthale" },
    { bankvalue: 7755, value: 402, label: "Town Branch - Anuradhapura" },
    { bankvalue: 7755, value: 403, label: "Thambuththegama" },
    { bankvalue: 7755, value: 404, label: "Kahatagasdigiliya" },
    { bankvalue: 7755, value: 405, label: "Galnewa" },
    { bankvalue: 7755, value: 406, label: "Thalawa" },
    { bankvalue: 7755, value: 407, label: "Medawachchiya" },
    { bankvalue: 7755, value: 408, label: "Thirappane" },
    { bankvalue: 7755, value: 409, label: "Rambewa" },
    { bankvalue: 7755, value: 410, label: "Polonnaruwa" },
    { bankvalue: 7755, value: 411, label: "Medirigiriya" },
    { bankvalue: 7755, value: 412, label: "Pulasthigama" },
    { bankvalue: 7755, value: 413, label: "Hingurakgoda" },
    { bankvalue: 7755, value: 414, label: "Bakamoona" },
    { bankvalue: 7755, value: 415, label: "Galenbindunuwewa" },
    { bankvalue: 7755, value: 416, label: "Gonapathirawa" },
    { bankvalue: 7755, value: 417, label: "Manampitiya" },
    { bankvalue: 7755, value: 418, label: "Galamuna" },
    { bankvalue: 7755, value: 419, label: "New Town - Anuradhapura" },
    { bankvalue: 7755, value: 420, label: "Siripura" },
    { bankvalue: 7755, value: 421, label: "Kaduruwela" },
    { bankvalue: 7755, value: 422, label: "Kekirawa" },
    { bankvalue: 7755, value: 423, label: "Aralaganwila" },
    { bankvalue: 7755, value: 424, label: "Economic Centre" },
    { bankvalue: 7755, value: 425, label: "Sevanapitiya" },
    { bankvalue: 7755, value: 498, label: "Dritrict Office Polonnaruwa" },
    { bankvalue: 7755, value: 500, label: "Provincial Office - Sabaragamuwa" },
    { bankvalue: 7755, value: 501, label: "Kegalle" },
    { bankvalue: 7755, value: 502, label: "Pitagaldeniya" },
    { bankvalue: 7755, value: 503, label: "Rambukkana" },
    { bankvalue: 7755, value: 504, label: "Mawanella" },
    { bankvalue: 7755, value: 505, label: "Warakapola" },
    { bankvalue: 7755, value: 506, label: "Aranayaka" },
    { bankvalue: 7755, value: 507, label: "Kithulgala" },
    { bankvalue: 7755, value: 508, label: "Ruwanwella" },
    { bankvalue: 7755, value: 509, label: "Dewalegama" },
    { bankvalue: 7755, value: 510, label: "Bulathkohupitiya" },
    { bankvalue: 7755, value: 511, label: "Deraniyagala" },
    { bankvalue: 7755, value: 512, label: "Rathnapura" },
    { bankvalue: 7755, value: 513, label: "Pelmadulla" },
    { bankvalue: 7755, value: 514, label: "Balangoda" },
    { bankvalue: 7755, value: 515, label: "Embilipitiya" },
    { bankvalue: 7755, value: 516, label: "Hemmathagama" },
    { bankvalue: 7755, value: 517, label: "Kolonna" },
    { bankvalue: 7755, value: 518, label: "Eheliyagoda" },
    { bankvalue: 7755, value: 519, label: "Nelumdeniya" },
    { bankvalue: 7755, value: 520, label: "Kalawana" },
    { bankvalue: 7755, value: 521, label: "Yatiyantota" },
    { bankvalue: 7755, value: 522, label: "Godakawela" },
    { bankvalue: 7755, value: 523, label: "Erathna" },
    { bankvalue: 7755, value: 524, label: "Kuruwita" },
    { bankvalue: 7755, value: 525, label: "Nivithigala" },
    { bankvalue: 7755, value: 526, label: "Kahawatta" },
    { bankvalue: 7755, value: 527, label: "Kotiyakumbura" },
    { bankvalue: 7755, value: 528, label: "Rakwana" },
    { bankvalue: 7755, value: 529, label: "Dehiovita" },
    { bankvalue: 7755, value: 530, label: "Kiriella" },
    { bankvalue: 7755, value: 531, label: "Pothupitiya" },
    { bankvalue: 7755, value: 532, label: "Weligepola" },
    { bankvalue: 7755, value: 533, label: "Sri Palabaddala" },
    { bankvalue: 7755, value: 534, label: "Pulungupitiya" },
    { bankvalue: 7755, value: 599, label: "Kegalle District Office" },
    { bankvalue: 7755, value: 600, label: "Provincial Office - Central" },
    { bankvalue: 7755, value: 601, label: "Gampola" },
    { bankvalue: 7755, value: 602, label: "Udawela" },
    { bankvalue: 7755, value: 603, label: "Hataraliyadda" },
    { bankvalue: 7755, value: 604, label: "Marassana" },
    { bankvalue: 7755, value: 605, label: "Danture" },
    { bankvalue: 7755, value: 606, label: "Wattegama" },
    { bankvalue: 7755, value: 607, label: "Morayaya" },
    { bankvalue: 7755, value: 608, label: "Teldeniya" },
    { bankvalue: 7755, value: 609, label: "Pujapitiya" },
    { bankvalue: 7755, value: 610, label: "Nuwara Eliya" },
    { bankvalue: 7755, value: 611, label: "Rikillagaskada" },
    { bankvalue: 7755, value: 612, label: "Kandy Marketing Centre" },
    { bankvalue: 7755, value: 613, label: "Ginigathena" },
    { bankvalue: 7755, value: 614, label: "Poondaluoya" },
    { bankvalue: 7755, value: 615, label: "Katugastota" },
    { bankvalue: 7755, value: 616, label: "Nildandahinna" },
    { bankvalue: 7755, value: 617, label: "Agarapathana" },
    { bankvalue: 7755, value: 618, label: "Ududumbara" },
    { bankvalue: 7755, value: 619, label: "Matale" },
    { bankvalue: 7755, value: 620, label: "Dambulla" },
    { bankvalue: 7755, value: 621, label: "Galewela" },
    { bankvalue: 7755, value: 622, label: "Laggala" },
    { bankvalue: 7755, value: 623, label: "Rattota" },
    { bankvalue: 7755, value: 624, label: "Kotagala" },
    { bankvalue: 7755, value: 625, label: "Menikhinna" },
    { bankvalue: 7755, value: 626, label: "Hanguranketha" },
    { bankvalue: 7755, value: 627, label: "Daulagala" },
    { bankvalue: 7755, value: 628, label: "Naula" },
    { bankvalue: 7755, value: 629, label: "Nawalapitiya" },
    { bankvalue: 7755, value: 630, label: "Hedeniya" },
    { bankvalue: 7755, value: 631, label: "Wilgamuwa" },
    { bankvalue: 7755, value: 632, label: "Kandy" },
    { bankvalue: 7755, value: 633, label: "Peradeniya" },
    { bankvalue: 7755, value: 700, label: "Provincial Office - Uva" },
    { bankvalue: 7755, value: 701, label: "Buttala" },
    { bankvalue: 7755, value: 702, label: "Medagama" },
    { bankvalue: 7755, value: 703, label: "Monaragala" },
    { bankvalue: 7755, value: 704, label: "Thanamalvila" },
    { bankvalue: 7755, value: 705, label: "Badulla" },
    { bankvalue: 7755, value: 706, label: "Passara" },
    { bankvalue: 7755, value: 707, label: "Welimada" },
    { bankvalue: 7755, value: 708, label: "Kandaketiya" },
    { bankvalue: 7755, value: 709, label: "Mahiyanganaya" },
    { bankvalue: 7755, value: 710, label: "wellawaya" },
    { bankvalue: 7755, value: 711, label: "Badalkumbura" },
    { bankvalue: 7755, value: 712, label: "Haputhale" },
    { bankvalue: 7755, value: 713, label: "Rvalueeemaliyadda" },
    { bankvalue: 7755, value: 714, label: "Uvaparanagama" },
    { bankvalue: 7755, value: 715, label: "Bandarawela" },
    { bankvalue: 7755, value: 716, label: "Meegahakiula" },
    { bankvalue: 7755, value: 717, label: "Lunugala" },
    { bankvalue: 7755, value: 718, label: "Haldummulla" },
    { bankvalue: 7755, value: 719, label: "Girandurukotte" },
    { bankvalue: 7755, value: 720, label: "Bogahakumbura" },
    { bankvalue: 7755, value: 721, label: "Bibile" },
    { bankvalue: 7755, value: 722, label: "Uva Maligathenna" },
    { bankvalue: 7755, value: 723, label: "Siyambalanduwa" },
    { bankvalue: 7755, value: 724, label: "Diyathalawa" },
    { bankvalue: 7755, value: 725, label: "Sewanagala" },
    { bankvalue: 7755, value: 726, label: "Madulla" },
    { bankvalue: 7755, value: 801, label: "Ampara" },
    { bankvalue: 7755, value: 802, label: "Dehiatthakandiya" },
    { bankvalue: 7755, value: 803, label: "Sammanthurai" },
    { bankvalue: 7755, value: 804, label: "Hingurana" },
    { bankvalue: 7755, value: 805, label: "Akkaraipaththu" },
    { bankvalue: 7755, value: 806, label: "Kalmunai" },
    { bankvalue: 7755, value: 807, label: "Mahaoya" },
    { bankvalue: 7755, value: 808, label: "Pothuvil" },
    { bankvalue: 7755, value: 809, label: "Uhana" },
    { bankvalue: 7755, value: 810, label: "Nintavur" },
    { bankvalue: 7755, value: 811, label: "Batticaloa" },
    { bankvalue: 7755, value: 812, label: "Eraur" },
    { bankvalue: 7755, value: 813, label: "Chenkalady" },
    { bankvalue: 7755, value: 814, label: "Kanthale" },
    { bankvalue: 7755, value: 815, label: "Valachenai" },
    { bankvalue: 7755, value: 816, label: "Kathankudi" },
    { bankvalue: 7755, value: 817, label: "Trincomalee" },
    { bankvalue: 7755, value: 818, label: "Kalauwanchikudi" },
    { bankvalue: 7755, value: 819, label: "Kokkadichcholai" },
    { bankvalue: 7755, value: 820, label: "Muthtur" },
    { bankvalue: 7755, value: 901, label: "Vavuniya" },
    { bankvalue: 7755, value: 902, label: "Kanagarayankulam" },
    { bankvalue: 7755, value: 903, label: "Mannar" },
    { bankvalue: 7755, value: 904, label: "Chunnakam" },
    { bankvalue: 7755, value: 905, label: "Jaffna" },
    { bankvalue: 7755, value: 906, label: "Kilinochchi" },
    { bankvalue: 7755, value: 907, label: "Bogaswewa" },
  ],
  7764: [
    { bankvalue: 7764, value: 1, label: "Head Office City" },
    { bankvalue: 7764, value: 2, label: "Kandy" },
    { bankvalue: 7764, value: 3, label: "SMIB Finance Department" },
    { bankvalue: 7764, value: 4, label: "Gampaha" },
    { bankvalue: 7764, value: 5, label: "Galle" },
    { bankvalue: 7764, value: 6, label: "Kurunegala" },
    { bankvalue: 7764, value: 7, label: "Matugama" },
    { bankvalue: 7764, value: 8, label: "Chilaw" },
    { bankvalue: 7764, value: 9, label: "Matara" },
    { bankvalue: 7764, value: 10, label: "Battaramulla" },
    { bankvalue: 7764, value: 11, label: "Kiribathgoda" },
    { bankvalue: 7764, value: 12, label: "Kegalle" },
    { bankvalue: 7764, value: 13, label: "Horana" },
    { bankvalue: 7764, value: 14, label: "Ambalantota" },
    { bankvalue: 7764, value: 15, label: "Batticaloa" },
    { bankvalue: 7764, value: 16, label: "Jaffna" },
    { bankvalue: 7764, value: 17, label: "Ampara" },
    { bankvalue: 7764, value: 18, label: "Ratnapura" },
    { bankvalue: 7764, value: 19, label: "Kaduruwela" },
    { bankvalue: 7764, value: 20, label: "Vavuniya" },
    { bankvalue: 7764, value: 21, label: "Hatton" },
    { bankvalue: 7764, value: 22, label: "Badulla" },
    { bankvalue: 7764, value: 23, label: "Monaragala" },
    { bankvalue: 7764, value: 24, label: "Anuradhapura" },
    { bankvalue: 7764, value: 25, label: "Matale" },
    { bankvalue: 7764, value: 26, label: "Panadura" },
  ],
  7773: [
    { bankvalue: 7773, value: 1, label: "Head Office" },
    { bankvalue: 7773, value: 2, label: "Corporate Office" },
    { bankvalue: 7773, value: 3, label: "Nugegoda" },
    { bankvalue: 7773, value: 4, label: "Negambo" },
    { bankvalue: 7773, value: 5, label: "Kandy" },
    { bankvalue: 7773, value: 6, label: "Badulla" },
    { bankvalue: 7773, value: 7, label: "Panadura" },
    { bankvalue: 7773, value: 8, label: "Anuradhapura" },
    { bankvalue: 7773, value: 9, label: "Galle" },
    { bankvalue: 7773, value: 10, label: "Kiribathgoda" },
    { bankvalue: 7773, value: 11, label: "Kurunegala" },
    { bankvalue: 7773, value: 12, label: "Ratnapura" },
    { bankvalue: 7773, value: 13, label: "Matara" },
    { bankvalue: 7773, value: 14, label: "Kalutara" },
    { bankvalue: 7773, value: 15, label: "Ambalangoda" },
    { bankvalue: 7773, value: 16, label: "Ampara" },
    { bankvalue: 7773, value: 17, label: "Avissawella" },
    { bankvalue: 7773, value: 18, label: "Gampaha" },
    { bankvalue: 7773, value: 19, label: "Chilaw" },
    { bankvalue: 7773, value: 20, label: "Polonnaruwa" },
    { bankvalue: 7773, value: 21, label: "Piliyandala" },
    { bankvalue: 7773, value: 22, label: "Boralesgamuwa" },
    { bankvalue: 7773, value: 23, label: "Dambulla" },
    { bankvalue: 7773, value: 24, label: "Balangoda" },
    { bankvalue: 7773, value: 25, label: "Kegalle" },
    { bankvalue: 7773, value: 26, label: "Bandarawela" },
    { bankvalue: 7773, value: 27, label: "Dehiwala" },
    { bankvalue: 7773, value: 28, label: "Embilipitiya" },
    { bankvalue: 7773, value: 29, label: "Batticaloa" },
    { bankvalue: 7773, value: 30, label: "Elpitiya" },
    { bankvalue: 7773, value: 31, label: "Maradana" },
    { bankvalue: 7773, value: 32, label: "Kandana" },
    { bankvalue: 7773, value: 33, label: "Mahiyanganaya" },
    { bankvalue: 7773, value: 34, label: "Tissamaharama" },
    { bankvalue: 7773, value: 35, label: "Matale" },
    { bankvalue: 7773, value: 36, label: "Maharagama" },
    { bankvalue: 7773, value: 37, label: "Kuliyapitiya" },
    { bankvalue: 7773, value: 38, label: "Puttalam" },
    { bankvalue: 7773, value: 39, label: "Trincomalee" },
    { bankvalue: 7773, value: 40, label: "Gampola" },
    { bankvalue: 7773, value: 41, label: "Horana" },
    { bankvalue: 7773, value: 42, label: "Kadawatha" },
    { bankvalue: 7773, value: 43, label: "Jaffna" },
    { bankvalue: 7773, value: 44, label: "Chunnakam" },
    { bankvalue: 7773, value: 45, label: "Manipay" },
    { bankvalue: 7773, value: 46, label: "Chavakachcheri" },
    { bankvalue: 7773, value: 47, label: "Kilinochchi" },
    { bankvalue: 7773, value: 48, label: "Vavuniya" },
    { bankvalue: 7773, value: 49, label: "Nuwara Eliya" },
    { bankvalue: 7773, value: 50, label: "Hatton" },
    { bankvalue: 7773, value: 51, label: "Nelliady" },
    { bankvalue: 7773, value: 52, label: "Matugama" },
    { bankvalue: 7773, value: 53, label: "Wennappuwa" },
    { bankvalue: 7773, value: 54, label: "Thambuththegama" },
    { bankvalue: 7773, value: 55, label: "Akkaraipattu" },
    { bankvalue: 7773, value: 56, label: "Pothuvil" },
    { bankvalue: 7773, value: 57, label: "Kochchikade" },
    { bankvalue: 7773, value: 58, label: "Kotahena" },
    { bankvalue: 7773, value: 59, label: "Anuradhapura 02" },
    { bankvalue: 7773, value: 60, label: "Rajagiriya" },
    { bankvalue: 7773, value: 61, label: "Welimada" },
    { bankvalue: 7773, value: 62, label: "Pilimathalawa" },
    { bankvalue: 7773, value: 63, label: "Katugasthota" },
    { bankvalue: 7773, value: 64, label: "Kalmunai" },
    { bankvalue: 7773, value: 65, label: "Moratumulla" },
    { bankvalue: 7773, value: 66, label: "Kalawanchikudy" },
    { bankvalue: 7773, value: 67, label: "Sea Street" },
    { bankvalue: 7773, value: 68, label: "Matara City" },
    { bankvalue: 7773, value: 69, label: "Aluthgama" },
    { bankvalue: 7773, value: 70, label: "Kandy City" },
    { bankvalue: 7773, value: 71, label: "Warakapola" },
    { bankvalue: 7773, value: 72, label: "Malabe" },
    { bankvalue: 7773, value: 73, label: "Kaduwela" },
    { bankvalue: 7773, value: 74, label: "Nawalapitiya" },
    { bankvalue: 7773, value: 75, label: "Homagama" },
    { bankvalue: 7773, value: 76, label: "Kurunegala City" },
    { bankvalue: 7773, value: 77, label: "Maskeliya" },
    { bankvalue: 7773, value: 78, label: "Pitigala" },
    { bankvalue: 7773, value: 79, label: "Samanturei" },
    { bankvalue: 7773, value: 80, label: "Saindamarthu" },
    { bankvalue: 7773, value: 81, label: "Kotte" },
    { bankvalue: 7773, value: 82, label: "Badulla City" },
    { bankvalue: 7773, value: 83, label: "Ja Ela" },
    { bankvalue: 7773, value: 84, label: "Delkada" },
    { bankvalue: 7773, value: 85, label: "Kaththankudi" },
    { bankvalue: 7773, value: 86, label: "Dam Street" },
    { bankvalue: 7773, value: 87, label: "Akuressa" },
    { bankvalue: 7773, value: 88, label: "Monaragala" },
    { bankvalue: 7773, value: 89, label: "Tangalle" },
    { bankvalue: 7773, value: 90, label: "Mount Lavinia" },
    { bankvalue: 7773, value: 91, label: "Neluwa" },
    { bankvalue: 7773, value: 92, label: "Battaramulla" },
    { bankvalue: 7773, value: 93, label: "Kurunegala Premier Centre" },
    { bankvalue: 7773, value: 94, label: "Moratuwa" },
    { bankvalue: 7773, value: 95, label: "Medawachchiya" },
    { bankvalue: 7773, value: 96, label: "Chenkalady" },
    { bankvalue: 7773, value: 97, label: "Pussellawa" },
    { bankvalue: 7773, value: 98, label: "Deniyaya" },
    { bankvalue: 7773, value: 99, label: "Jaffna City" },
    { bankvalue: 7773, value: 100, label: "Jaffna Premier" },
    { bankvalue: 7773, value: 101, label: "Valaichchenai" },
    { bankvalue: 7773, value: 102, label: "Wattala" },
    { bankvalue: 7773, value: 104, label: "Kottawa" },
    { bankvalue: 7773, value: 105, label: "Dehiattakandiya" },
    { bankvalue: 7773, value: 106, label: "Kokkadicholai" },
    { bankvalue: 7773, value: 107, label: "Gelioya" },
    { bankvalue: 7773, value: 109, label: "Kinniya" },
    { bankvalue: 7773, value: 110, label: "Anamaduwa" },
    { bankvalue: 7773, value: 111, label: "Puthukkudiyiruppu" },
    { bankvalue: 7773, value: 112, label: "Delgoda" },
    { bankvalue: 7773, value: 113, label: "Eheliyagoda" },
    { bankvalue: 7773, value: 114, label: "Kekirawa" },
    { bankvalue: 7773, value: 115, label: "Divulapitiya" },
    { bankvalue: 7773, value: 116, label: "Narammala" },
    { bankvalue: 7773, value: 117, label: "Medirigiriya" },
    { bankvalue: 7773, value: 118, label: "Achchuveli" },
    { bankvalue: 7773, value: 119, label: "Mannar" },
    { bankvalue: 7773, value: 121, label: "Wadduwa" },
    { bankvalue: 7773, value: 122, label: "Dharga Town" },
    { bankvalue: 7773, value: 123, label: "Karapitiya" },
  ],
  7782: [{ bankvalue: 7782, value: 1, label: "Head Office" }],
  7807: [
    { bankvalue: 7807, value: 1, label: "Head Office" },
    { bankvalue: 7807, value: 2, label: "Virtual Branch" },
  ],
  7816: [{ bankvalue: 7816, value: 1, label: "Head Office" }],
  7825: [
    { bankvalue: 7825, value: 1, label: "Colombo" },
    { bankvalue: 7825, value: 2, label: "Kandy" },
    { bankvalue: 7825, value: 16, label: "Nugegoda" },
  ],
  7834: [{ bankvalue: 7834, value: 1, label: "Head Office" }],
  7852: [
    { bankvalue: 7852, value: 1, label: "Head Office" },
    { bankvalue: 7852, value: 2, label: "Gampaha" },
    { bankvalue: 7852, value: 3, label: "Ratnapura" },
    { bankvalue: 7852, value: 4, label: "Galle" },
    { bankvalue: 7852, value: 5, label: "Vavuniya" },
    { bankvalue: 7852, value: 6, label: "Trincomalee" },
    { bankvalue: 7852, value: 7, label: "Batticaloa" },
    { bankvalue: 7852, value: 8, label: "Jaffna" },
    { bankvalue: 7852, value: 9, label: "Kelaniya" },
    { bankvalue: 7852, value: 10, label: "Horana" },
    { bankvalue: 7852, value: 11, label: "Maharagama" },
    { bankvalue: 7852, value: 12, label: "Ja-Ela" },
    { bankvalue: 7852, value: 13, label: "Kandy" },
    { bankvalue: 7852, value: 14, label: "Matara" },
    { bankvalue: 7852, value: 15, label: "Anuradhapura" },
    { bankvalue: 7852, value: 16, label: "Puttalam" },
    { bankvalue: 7852, value: 17, label: "Kilinochchi" },
    { bankvalue: 7852, value: 18, label: "Chilaw" },
    { bankvalue: 7852, value: 19, label: "Negombo" },
    { bankvalue: 7852, value: 20, label: "Ganemulla" },
    { bankvalue: 7852, value: 21, label: "Kaduruwela" },
    { bankvalue: 7852, value: 22, label: "Ambalanthota" },
    { bankvalue: 7852, value: 23, label: "Kuliyapitiya" },
    { bankvalue: 7852, value: 24, label: "Galewela" },
    { bankvalue: 7852, value: 25, label: "Avissawella" },
    { bankvalue: 7852, value: 26, label: "Kalpitiya" },
    { bankvalue: 7852, value: 27, label: "Kurunegala" },
    { bankvalue: 7852, value: 28, label: "Kadawatha" },
    { bankvalue: 7852, value: 29, label: "Dambulla" },
    { bankvalue: 7852, value: 30, label: "Nuwara Eliya" },
    { bankvalue: 7852, value: 31, label: "Hingurakgoda" },
    { bankvalue: 7852, value: 32, label: "Malambe" },
    { bankvalue: 7852, value: 33, label: "Kegalle" },
    { bankvalue: 7852, value: 34, label: "Ampara" },
    { bankvalue: 7852, value: 35, label: "Embilipitiya" },
    { bankvalue: 7852, value: 36, label: "Aluthgama" },
  ],
  7861: [{ bankvalue: 7861, value: 0, label: "Head Office" }],
  7870: [
    { bankvalue: 7870, value: "001", label: "Ambalangoda" },
    { bankvalue: 7870, value: "002", label: "Batticaloa" },
    { bankvalue: 7870, value: "003", label: "Bandarawela" },
    { bankvalue: 7870, value: "004", label: "City Office-I" },
    { bankvalue: 7870, value: "005", label: "Ambalantota" },
    { bankvalue: 7870, value: "006", label: "Kurunegala" },
    { bankvalue: 7870, value: "007", label: "Kanthale" },
    { bankvalue: 7870, value: "008", label: "Badulla" },
    { bankvalue: 7870, value: "009", label: "City Office-E" },
    { bankvalue: 7870, value: "010", label: "Kalutara" },
    { bankvalue: 7870, value: "011", label: "Wattala" },
    { bankvalue: 7870, value: "012", label: "Kalmunai" },
    { bankvalue: 7870, value: "013", label: "Embilipitiya" },
    { bankvalue: 7870, value: "014", label: "City Office-B" },
    { bankvalue: 7870, value: "015", label: "Wennappuwa" },
    { bankvalue: 7870, value: "016", label: "Kaduwela" },
    { bankvalue: 7870, value: "017", label: "Hingurakgoda" },
    { bankvalue: 7870, value: "018", label: "Matale" },
    { bankvalue: 7870, value: "019", label: "Nelliady" },
    { bankvalue: 7870, value: "020", label: "Trincomalee" },
    { bankvalue: 7870, value: "021", label: "Padaviya" },
    { bankvalue: 7870, value: "022", label: "Balangoda" },
    { bankvalue: 7870, value: "023", label: "Nuwara Eliya" },
    { bankvalue: 7870, value: "024", label: "Vavuniya" },
    { bankvalue: 7870, value: "025", label: "Nittambuwa" },
    { bankvalue: 7870, value: "026", label: "Polonnaruwa" },
    { bankvalue: 7870, value: "027", label: "Ratnapura" },
    { bankvalue: 7870, value: "028", label: "Puttalam" },
    { bankvalue: 7870, value: "029", label: "Medawachchiya" },
    { bankvalue: 7870, value: "030", label: "Moratuwa" },
    { bankvalue: 7870, value: "031", label: "Mathugama" },
    { bankvalue: 7870, value: "032", label: "Homagama" },
    { bankvalue: 7870, value: "033", label: "Nugegoda" },
    { bankvalue: 7870, value: "034", label: "City Office-M" },
    { bankvalue: 7870, value: "035", label: "Negombo" },
    { bankvalue: 7870, value: "036", label: "Nikaweratiya" },
    { bankvalue: 7870, value: "037", label: "City Office-S" },
    { bankvalue: 7870, value: "038", label: "Nawalapitiya" },
    { bankvalue: 7870, value: "039", label: "Galnewa" },
    { bankvalue: 7870, value: "040", label: "Rikillagaskada" },
    { bankvalue: 7870, value: "041", label: "Gampaha" },
    { bankvalue: 7870, value: "042", label: "Thambuththegama" },
    { bankvalue: 7870, value: "043", label: "Mahiyanganaya" },
    { bankvalue: 7870, value: "044", label: "Matara" },
    { bankvalue: 7870, value: "045", label: "Mannar" },
    { bankvalue: 7870, value: "046", label: "Kalpitiya" },
    { bankvalue: 7870, value: "047", label: "City Office-P" },
    { bankvalue: 7870, value: "048", label: "Maharagama" },
    { bankvalue: 7870, value: "049", label: "City Office-Colombo" },
    { bankvalue: 7870, value: "050", label: "City Office-None Fd" },
    { bankvalue: 7870, value: "051", label: "Bandaragama" },
    { bankvalue: 7870, value: "052", label: "Kuliyapitiya" },
    { bankvalue: 7870, value: "053", label: "Kilinochchi" },
    { bankvalue: 7870, value: "054", label: "Monaragala" },
    { bankvalue: 7870, value: "055", label: "City Office-G" },
    { bankvalue: 7870, value: "056", label: "City Office-F" },
    { bankvalue: 7870, value: "057", label: "City Office-K" },
    { bankvalue: 7870, value: "058", label: "City Office-C" },
    { bankvalue: 7870, value: "059", label: "City Office-Q" },
    { bankvalue: 7870, value: "060", label: "Dehiattakandiya" },
    { bankvalue: 7870, value: "061", label: "Elpitiya" },
    { bankvalue: 7870, value: "062", label: "Deniyaya" },
    { bankvalue: 7870, value: "063", label: "Dambulla" },
    { bankvalue: 7870, value: "064", label: "City Office-A" },
    { bankvalue: 7870, value: "065", label: "Galle" },
    { bankvalue: 7870, value: "066", label: "City Office-H" },
    { bankvalue: 7870, value: "067", label: "Galenbindunuwewa" },
    { bankvalue: 7870, value: "068", label: "City Office-D" },
    { bankvalue: 7870, value: "069", label: "Kegalle" },
    { bankvalue: 7870, value: "070", label: "Kandy" },
    { bankvalue: 7870, value: "071", label: "City Office-J" },
    { bankvalue: 7870, value: "072", label: "Kcc" },
    { bankvalue: 7870, value: "073", label: "Aralaganwila" },
    { bankvalue: 7870, value: "074", label: "Avissawella" },
    { bankvalue: 7870, value: "075", label: "Wellawatte" },
    { bankvalue: 7870, value: "076", label: "Anuradhapura" },
    { bankvalue: 7870, value: "077", label: "Ampara" },
    { bankvalue: 7870, value: "078", label: "Jaffna" },
    { bankvalue: 7870, value: "079", label: "Horana" },
    { bankvalue: 7870, value: "080", label: "Welimada" },
    { bankvalue: 7870, value: "081", label: "Aluthgama" },
    { bankvalue: 7870, value: "082", label: "Nochchiyagama" },
    { bankvalue: 7870, value: "083", label: "Horowpathana" },
    { bankvalue: 7870, value: "084", label: "Neluwa" },
    { bankvalue: 7870, value: "085", label: "Siyambalanduwa" },
    { bankvalue: 7870, value: "086", label: "Pulmude" },
    { bankvalue: 7870, value: "087", label: "Kekirawa" },
    { bankvalue: 7870, value: "089", label: "Delft" },
    { bankvalue: 7870, value: "090", label: "Chunnakam" },
    { bankvalue: 7870, value: "091", label: "Panadura" },
    { bankvalue: 7870, value: "092", label: "Weligama" },
    { bankvalue: 7870, value: "093", label: "Gampola" },
    { bankvalue: 7870, value: "094", label: "Wariyapola" },
    { bankvalue: 7870, value: "095", label: "Udugama" },
    { bankvalue: 7870, value: "096", label: "Melsiripura" },
    { bankvalue: 7870, value: "097", label: "Bakamuna" },
    { bankvalue: 7870, value: "098", label: "Akuressa" },
    { bankvalue: 7870, value: "099", label: "Mount Lavinia" },
    { bankvalue: 7870, value: 100, label: "Welikanda" },
    { bankvalue: 7870, value: 101, label: "Muthur" },
    { bankvalue: 7870, value: 102, label: "Kirindiwela" },
    { bankvalue: 7870, value: 103, label: "Teldeniya" },
    { bankvalue: 7870, value: 104, label: "Hatton" },
    { bankvalue: 7870, value: 105, label: "Giriulla" },
    { bankvalue: 7870, value: 106, label: "Thanamalwila" },
    { bankvalue: 7870, value: 107, label: "Pothuvil" },
    { bankvalue: 7870, value: 108, label: "Bibile" },
    { bankvalue: 7870, value: 109, label: "Warakapola" },
    { bankvalue: 7870, value: 110, label: "Mahaoya" },
    { bankvalue: 7870, value: 111, label: "Battaramulla" },
    { bankvalue: 7870, value: 112, label: "Polpithigama" },
    { bankvalue: 7870, value: 113, label: "Chavakachcheri" },
    { bankvalue: 7870, value: 114, label: "Akkaraipattu" },
    { bankvalue: 7870, value: 115, label: "Valaichchenai" },
    { bankvalue: 7870, value: 116, label: "Pussellawa" },
    { bankvalue: 7870, value: 117, label: "Mullativu" },
    { bankvalue: 7870, value: 118, label: "Deraniyagala" },
    { bankvalue: 7870, value: 119, label: "Chilaw" },
    { bankvalue: 7870, value: 120, label: "Angunakolapelessa" },
    { bankvalue: 7870, value: 121, label: "Pilimathalawa" },
    { bankvalue: 7870, value: 122, label: "Tissamaharama" },
    { bankvalue: 7870, value: 123, label: "Ingiriya" },
    { bankvalue: 7870, value: 124, label: "Baddegama" },
    { bankvalue: 7870, value: 125, label: "Tangalle" },
    { bankvalue: 7870, value: 126, label: "Naththandiya" },
    { bankvalue: 7870, value: 127, label: "Anamaduwa" },
    { bankvalue: 7870, value: 128, label: "Kinniya" },
    { bankvalue: 7870, value: 129, label: "Kalawana" },
    { bankvalue: 7870, value: 130, label: "Godakawela" },
    { bankvalue: 7870, value: 131, label: "Divulapitiya" },
    { bankvalue: 7870, value: 132, label: "Beruwala" },
    { bankvalue: 7870, value: 133, label: "Ja-Ela" },
    { bankvalue: 7870, value: 134, label: "Piliyandala" },
    { bankvalue: 7870, value: 135, label: "Kaluwanchikudy" },
    { bankvalue: 7870, value: 136, label: "Galgamuwa" },
    { bankvalue: 7870, value: 137, label: "Galagedara" },
    { bankvalue: 7870, value: 138, label: "Akurana" },
    { bankvalue: 7870, value: 139, label: "Kiribathgoda" },
    { bankvalue: 7870, value: 140, label: "Wellawaya" },
  ],
  7898: [
    { bankvalue: 7898, value: 3, label: "Head Office" },
    { bankvalue: 7898, value: 100, label: "Union Place" },
    { bankvalue: 7898, value: 101, label: "Kadawatha" },
    { bankvalue: 7898, value: 102, label: "Gampaha" },
    { bankvalue: 7898, value: 103, label: "Negombo" },
    { bankvalue: 7898, value: 104, label: "Kottawa" },
    { bankvalue: 7898, value: 105, label: "Kalutara" },
    { bankvalue: 7898, value: 106, label: "Moratuwa" },
    { bankvalue: 7898, value: 107, label: "Galle" },
    { bankvalue: 7898, value: 108, label: "Nugegoda" },
    { bankvalue: 7898, value: 109, label: "Kotahena" },
    { bankvalue: 7898, value: 110, label: "Kurunegala" },
    { bankvalue: 7898, value: 112, label: "Anuradhapura" },
    { bankvalue: 7898, value: 113, label: "Ratnapura" },
    { bankvalue: 7898, value: 114, label: "Ambalangoda" },
    { bankvalue: 7898, value: 115, label: "Ampara" },
    { bankvalue: 7898, value: 116, label: "Ambalantota" },
    { bankvalue: 7898, value: 117, label: "Avissawella" },
    { bankvalue: 7898, value: 118, label: "Bandarawela" },
    { bankvalue: 7898, value: 119, label: "Batticaloa" },
    { bankvalue: 7898, value: 120, label: "Chilaw" },
    { bankvalue: 7898, value: 121, label: "City Office" },
    { bankvalue: 7898, value: 122, label: "Dambulla" },
    { bankvalue: 7898, value: 123, label: "Deniyaya" },
    { bankvalue: 7898, value: 124, label: "Embilipitiya" },
    { bankvalue: 7898, value: 125, label: "Hambantota" },
    { bankvalue: 7898, value: 126, label: "Hingurakgoda" },
    { bankvalue: 7898, value: 127, label: "Horana" },
    { bankvalue: 7898, value: 128, label: "Hatton" },
    { bankvalue: 7898, value: 129, label: "Ja-Ela" },
    { bankvalue: 7898, value: 130, label: "Jaffna" },
    { bankvalue: 7898, value: 131, label: "Kandy" },
    { bankvalue: 7898, value: 132, label: "Kiribathgoda" },
    { bankvalue: 7898, value: 133, label: "Kandy City Office" },
    { bankvalue: 7898, value: 134, label: "Kilinochchi" },
    { bankvalue: 7898, value: 135, label: "Kakirawa" },
    { bankvalue: 7898, value: 136, label: "Kandana" },
    { bankvalue: 7898, value: 137, label: "Kuliyapitiya" },
    { bankvalue: 7898, value: 138, label: "Maharagama" },
    { bankvalue: 7898, value: 140, label: "Monaragala" },
    { bankvalue: 7898, value: 141, label: "Metropolitan" },
    { bankvalue: 7898, value: 142, label: "Matara" },
    { bankvalue: 7898, value: 143, label: "Matugama" },
    { bankvalue: 7898, value: 144, label: "Nittambuwa" },
    { bankvalue: 7898, value: 145, label: "Panadura" },
    { bankvalue: 7898, value: 146, label: "Puttlam" },
    { bankvalue: 7898, value: 147, label: "Trincomalee" },
    { bankvalue: 7898, value: 148, label: "Vavuniya" },
    { bankvalue: 7898, value: 149, label: "Wennappuwa" },
    { bankvalue: 7898, value: 150, label: "Wattala" },
    { bankvalue: 7898, value: 151, label: "Head Office" },
  ],
  7904: [
    { bankvalue: 7904, value: "001", label: "City" },
    { bankvalue: 7904, value: "002", label: "Moratuwa" },
    { bankvalue: 7904, value: "003", label: "Jaffna" },
    { bankvalue: 7904, value: "004", label: "Trincomalee" },
    { bankvalue: 7904, value: "005", label: "Bandaragama" },
    { bankvalue: 7904, value: "006", label: "Vavuniya" },
    { bankvalue: 7904, value: "007", label: "Ampara" },
    { bankvalue: 7904, value: "008", label: "Kurunegala" },
    { bankvalue: 7904, value: "009", label: "Wattala" },
    { bankvalue: 7904, value: "010", label: "Kandy" },
    { bankvalue: 7904, value: "011", label: "Anuradhapura" },
    { bankvalue: 7904, value: "012", label: "Nuwara Eliya" },
    { bankvalue: 7904, value: "013", label: "Matale" },
    { bankvalue: 7904, value: "014", label: "Badulla" },
    { bankvalue: 7904, value: "015", label: "Chilaw" },
    { bankvalue: 7904, value: "016", label: "Puttalam" },
    { bankvalue: 7904, value: "017", label: "Kegalle" },
    { bankvalue: 7904, value: "018", label: "Kalutara" },
    { bankvalue: 7904, value: "019", label: "Batticoloa" },
    { bankvalue: 7904, value: "020", label: "Ja-Ela" },
    { bankvalue: 7904, value: "021", label: "Matara" },
    { bankvalue: 7904, value: "022", label: "Welimada" },
    { bankvalue: 7904, value: "023", label: "Dambulla" },
    { bankvalue: 7904, value: "024", label: "Kantale" },
    { bankvalue: 7904, value: "025", label: "Ambalangoda" },
    { bankvalue: 7904, value: "026", label: "Homagama" },
    { bankvalue: 7904, value: "027", label: "Negombo" },
    { bankvalue: 7904, value: "028", label: "Beliatta" },
    { bankvalue: 7904, value: "029", label: "Embilipitiya" },
    { bankvalue: 7904, value: "030", label: "Polonnaruwa" },
    { bankvalue: 7904, value: "031", label: "Mahiyanganaya" },
    { bankvalue: 7904, value: "032", label: "Moneragala" },
    { bankvalue: 7904, value: "033", label: "Hambantota" },
    { bankvalue: 7904, value: "034", label: "Galle" },
    { bankvalue: 7904, value: "035", label: "Mathugama" },
    { bankvalue: 7904, value: "036", label: "Medawachchiya" },
    { bankvalue: 7904, value: "037", label: "Horana" },
    { bankvalue: 7904, value: "038", label: "Rathnapura" },
    { bankvalue: 7904, value: "039", label: "Nikaweratiya" },
    { bankvalue: 7904, value: "040", label: "Galgamuwa" },
    { bankvalue: 7904, value: "041", label: "Nittambuwa" },
    { bankvalue: 7904, value: "042", label: "Gampaha" },
    { bankvalue: 7904, value: "043", label: "Nawalapitiya" },
    { bankvalue: 7904, value: "044", label: "Wennappuwa" },
    { bankvalue: 7904, value: "045", label: "Avissawella" },
    { bankvalue: 7904, value: "046", label: "Kaduwela" },
    { bankvalue: 7904, value: "047", label: "Mannar" },
    { bankvalue: 7904, value: "048", label: "Padaviya" },
    { bankvalue: 7904, value: "049", label: "Balangoda" },
    { bankvalue: 7904, value: "050", label: "Kalmunai" },
    { bankvalue: 7904, value: "051", label: "Nellyadi" },
    { bankvalue: 7904, value: "052", label: "Mullaitivu" },
    { bankvalue: 7904, value: "053", label: "Pottuvil" },
    { bankvalue: 7904, value: "054", label: "Mallavi" },
    { bankvalue: 7904, value: "055", label: "Norocholai" },
    { bankvalue: 7904, value: "056", label: "Morawaka" },
    { bankvalue: 7904, value: "057", label: "Kuliyapitiya" },
    { bankvalue: 7904, value: "058", label: "Nedunkeni" },
    { bankvalue: 7904, value: "059", label: "Nochchiyagama" },
    { bankvalue: 7904, value: "060", label: "Kilinochchi" },
    { bankvalue: 7904, value: "061", label: "Kekirawa" },
    { bankvalue: 7904, value: "062", label: "Galenbindunuwewa" },
    { bankvalue: 7904, value: "063", label: "Rikillagaskada" },
    { bankvalue: 7904, value: "064", label: "Bandarawela" },
    { bankvalue: 7904, value: "065", label: "Walapane" },
    { bankvalue: 7904, value: "066", label: "Tissamaharama" },
    { bankvalue: 7904, value: "067", label: "Hatton" },
    { bankvalue: 7904, value: "068", label: "Pitigala" },
    { bankvalue: 7904, value: "069", label: "Dehiattakandiya" },
    { bankvalue: 7904, value: 999, label: "Head office" },
  ],
  7913: [{ bankvalue: 7913, value: 1, label: "Head Office" }],
  7922: [
    { bankvalue: 7922, value: 1, label: "Head Office" },
    { bankvalue: 7922, value: 2, label: "Akuressa" },
    { bankvalue: 7922, value: 3, label: "Ambalangoda" },
    { bankvalue: 7922, value: 4, label: "Ambalantota" },
    { bankvalue: 7922, value: 5, label: "Ampara" },
    { bankvalue: 7922, value: 6, label: "Anuradhapura" },
    { bankvalue: 7922, value: 7, label: "Avissawella" },
    { bankvalue: 7922, value: 8, label: "Badulla" },
    { bankvalue: 7922, value: 9, label: "Balangoda" },
    { bankvalue: 7922, value: 10, label: "Bandarawela" },
    { bankvalue: 7922, value: 11, label: "Battaramulla" },
    { bankvalue: 7922, value: 12, label: "Batticaloa" },
    { bankvalue: 7922, value: 13, label: "Bentota" },
    { bankvalue: 7922, value: 14, label: "Chilaw" },
    { bankvalue: 7922, value: 15, label: "Chunnakam" },
    { bankvalue: 7922, value: 16, label: "Kollupitiya" },
    { bankvalue: 7922, value: 17, label: "Dambulla" },
    { bankvalue: 7922, value: 18, label: "Dehiwala" },
    { bankvalue: 7922, value: 19, label: "Deniyaya" },
    { bankvalue: 7922, value: 20, label: "Digana" },
    { bankvalue: 7922, value: 21, label: "Elpitiya" },
    { bankvalue: 7922, value: 22, label: "Embilipitiya" },
    { bankvalue: 7922, value: 23, label: "Galle" },
    { bankvalue: 7922, value: 24, label: "Gampaha" },
    { bankvalue: 7922, value: 25, label: "Gampola" },
    { bankvalue: 7922, value: 26, label: "Giriulla" },
    { bankvalue: 7922, value: 27, label: "Grandpass" },
    { bankvalue: 7922, value: 28, label: "Hambantota" },
    { bankvalue: 7922, value: 29, label: "Hatton" },
    { bankvalue: 7922, value: 30, label: "Havelock City" },
    { bankvalue: 7922, value: 31, label: "Homagama" },
    { bankvalue: 7922, value: 32, label: "Horana" },
    { bankvalue: 7922, value: 33, label: "Ja-Ela" },
    { bankvalue: 7922, value: 34, label: "Jaffna" },
    { bankvalue: 7922, value: 35, label: "Kadawatha" },
    { bankvalue: 7922, value: 36, label: "Kaduwela" },
    { bankvalue: 7922, value: 37, label: "Kalawana" },
    { bankvalue: 7922, value: 38, label: "Kalmunai - AlSafa" },
    { bankvalue: 7922, value: 39, label: "Kalutara" },
    { bankvalue: 7922, value: 40, label: "Kaluwanchikudy" },
    { bankvalue: 7922, value: 41, label: "Kandy" },
    { bankvalue: 7922, value: 42, label: "Kandy - AlSafa" },
    { bankvalue: 7922, value: 43, label: "Kanthale" },
    { bankvalue: 7922, value: 44, label: "Kattankudy - AlSafa" },
    { bankvalue: 7922, value: 45, label: "Kegalle" },
    { bankvalue: 7922, value: 46, label: "Kekirawa" },
    { bankvalue: 7922, value: 47, label: "Kelaniya" },
    { bankvalue: 7922, value: 48, label: "Kilinochchi" },
    { bankvalue: 7922, value: 49, label: "Kirindiwela" },
    { bankvalue: 7922, value: 50, label: "Kuliyapitiya" },
    { bankvalue: 7922, value: 51, label: "Kurunegala" },
    { bankvalue: 7922, value: 52, label: "Mahaiyawa" },
    { bankvalue: 7922, value: 53, label: "Maharagama" },
    { bankvalue: 7922, value: 54, label: "Mahiyanganaya" },
    { bankvalue: 7922, value: 55, label: "Mannar" },
    { bankvalue: 7922, value: 56, label: "Matale" },
    { bankvalue: 7922, value: 57, label: "Matara" },
    { bankvalue: 7922, value: 58, label: "Mathugama" },
    { bankvalue: 7922, value: 59, label: "Medawachchiya" },
    { bankvalue: 7922, value: 60, label: "Melsiripura" },
    { bankvalue: 7922, value: 61, label: "Metropolitan Office" },
    { bankvalue: 7922, value: 62, label: "Minuwangoda" },
    { bankvalue: 7922, value: 63, label: "Mirigama" },
    { bankvalue: 7922, value: 64, label: "Monaragala" },
    { bankvalue: 7922, value: 65, label: "Moratuwa" },
    { bankvalue: 7922, value: 66, label: "Mutur - AlSafa" },
    { bankvalue: 7922, value: 67, label: "Nawalapitiya" },
    { bankvalue: 7922, value: 68, label: "Negombo" },
    { bankvalue: 7922, value: 69, label: "Neluwa" },
    { bankvalue: 7922, value: 70, label: "Nittambuwa" },
    { bankvalue: 7922, value: 71, label: "Nugegoda" },
    { bankvalue: 7922, value: 72, label: "Nuwara Eliya" },
    { bankvalue: 7922, value: 73, label: "Panadura" },
    { bankvalue: 7922, value: 74, label: "Pelmadulla" },
    { bankvalue: 7922, value: 75, label: "Pettah" },
    { bankvalue: 7922, value: 76, label: "Pilimathalawa" },
    { bankvalue: 7922, value: 77, label: "Piliyandala" },
    { bankvalue: 7922, value: 78, label: "Polonnaruwa" },
    { bankvalue: 7922, value: 79, label: "Puttalam - AlSafa" },
    { bankvalue: 7922, value: 80, label: "Ratnapura" },
    { bankvalue: 7922, value: 81, label: "Tangalle" },
    { bankvalue: 7922, value: 82, label: "Thambuttegama" },
    { bankvalue: 7922, value: 83, label: "Tissamaharama" },
    { bankvalue: 7922, value: 84, label: "Trincomalee" },
    { bankvalue: 7922, value: 85, label: "Union Place - Alsafa" },
    { bankvalue: 7922, value: 86, label: "Vavuniya" },
    { bankvalue: 7922, value: 87, label: "Ward Place" },
    { bankvalue: 7922, value: 88, label: "Wariyapola" },
    { bankvalue: 7922, value: 89, label: "Wattala" },
    { bankvalue: 7922, value: 90, label: "Welimada" },
    { bankvalue: 7922, value: 91, label: "Wellawatte" },
    { bankvalue: 7922, value: 92, label: "Wellawaya" },
    { bankvalue: 7922, value: 93, label: "Wennappuwa" },
  ],
  8004: [
    { bankvalue: 8004, value: 1, label: "Head Office" },
    { bankvalue: 8004, value: 998, label: "CBSL-VB" },
    { bankvalue: 8004, value: 999, label: "Central Bank Of Sri Lanka" },
  ],
};
