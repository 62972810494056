import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { ProductContext } from "../contexts/ProductContext";
import { AuthContext } from "../contexts/AuthContext";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import copy from 'copy-text-to-clipboard';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Product from "../components/Product";

import {
  FacebookMessengerShareButton,
  InstapaperShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  FacebookShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  GabIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
  XIcon,
} from "react-share";
import { WishlistContext } from "../contexts/WishlistContext";

const ProductDetails = () => {
  const { id } = useParams();
  const { products, loading } = useContext(ProductContext);
  const { addToCart } = useContext(CartContext);
  const [images, setImages] = useState([]);
  const { token, getAuthToken } = useContext(AuthContext);
  const { addToWishList } = useContext(WishlistContext);
  const [shareUrl, setShareUrl] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [ count, setCount ] = useState(1);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const imgBaseUrl = 'https://firebasestorage.googleapis.com/v0/b/ostore-86bf8.appspot.com/o/';

  useEffect(() => {
    getAuthToken();
    if (token) {
      setShareUrl(window.location.href + "&" + token.userId);
    }
  }, [token]);

  useEffect(() => {
    const myArray = id.split("&");
    if (myArray[1]) {
      window.sessionStorage.setItem("reffererId", JSON.stringify(myArray[1]));
    }
  }, [pathname]);

  const product = products.find((item) => {
    const myArray = id.split("&");
    return item.id === myArray[0];
  });


  useEffect(() => {
    if (product) {
      const { img } = product;
      loadImages(img);
      const fproducts = products.filter((item) => {
        return (
          item.category === product.category &&
          item.id !== product.id
        );
      });
      setFilteredProducts(fproducts);
    }
  }, [product]);

  if (!product) {
    return (
      <section className="h-screen flex justify-center items-center">
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </section>
    );
  }

  const { title, price, description, qty, category, sCategory, shippingFee, subTitle, brand } =
    product;

  const loadImages = (img) => {
    if (img.length >= 0) {
      var tempimg = [];
      img.forEach((item) => {
        tempimg.push(
          {
            original: `${imgBaseUrl + item.replace(/\//g, '%2F') + '?alt=media'}`,
            thumbnail: `${imgBaseUrl + item.replace(/\//g, '%2F') + '?alt=media'}`,
          }
        );
      });
      setImages(tempimg);
    }
  };

  const increaseCount = ()=>{
    let temp = count;
    if(temp < 10){
      setCount(++temp);
    }else{
      setCount(10);
    }
  }

  const decreaseCount = ()=>{
    let temp = count;
    temp -= 1;
    if(temp > 1){
      setCount(temp);
    }else{
      setCount(1);
    }
  }

  return (
    <div className="lg:mt-28 mt-16 mb-10">
      {/* <div className="bg-green-100 w-full bg-no-repeat bg-cover h-auto bg-hero">
        <img className="w-full" src="https://www.glifestyle.net/-/media/sites/shared/products/gll-products/gll-herobanners/5in1_gpro_karaoke.ashx?h=400&w=1600&la=en&hash=F44D007913090B7935797706F70A60ACCF8893BC" />
      </div> */}
      <div className="py-3 sm:block hidden bg-gray-400 text-white">
        <div className="container mx-auto md:px-12 uppercase text-sm md:text-base font-medium">
          <Link to={"/"}>
            <span className="mr-1 hover:underline cursor-pointer">HOME</span>
          </Link>{" "}
          {" > "}{" "}
          <Link to={`/category/${category.replace(/ /g, "-")}`}>
            <span className="mr-1 ml-1 hover:underline cursor-pointer">
              {category}
            </span>
          </Link>{" "}
          {" > "}{" "}
          <Link
            to={`/category/${category.replace(/ /g, "-")}/${sCategory.replace(
              / /g,
              "-"
            )}`}
          >
            <span className="mr-1 ml-1 hover:underline cursor-pointer">
              {sCategory}
            </span>
          </Link>
          {" > "} <span className="mr-1 ml-1">{title}</span>
        </div>
      </div>
      <div className="container mx-auto pt-12 md:px-12">
        <div className="flex flex-col  lg:flex-row ">
          <div className="flex flex-1 justify-center items-center mb-8 lg:mb-0">
            {/* <img className="max-w-[200px] lg:max-w-sm" src={img[0]} alt="" /> */}
            <div className="max-w-[200px] lg:max-w-sm">
              <ImageGallery
                infinite={false}
                showNav = {false}
                showPlayButton = {false}
                items={images}
                showFullscreenButton= {false}
              />
            </div>
          </div>
          <div className="flex-1 lg:text-left md:pl-10 pl-0">
            <h1 className="md:text-[26px] text-3xl uppercase font-medium mb-2 max-w-[450px] mx-auto lg:mx-0">
              {title}
            </h1>
            <div className="mt-4 text-gray-600">
              {subTitle} 
            </div>
            <div class="space-y-2 mt-2">
              <p class="text-gray-800 font-semibold space-x-2">
                <span>Availability: </span>
                {qty > 0 ?<span class="text-green-600">In Stock</span> : <span class="text-red-600">Out of Stock</span>}
              </p>
              <p class="space-x-2">
                <span class="text-gray-800 font-semibold">Brand: </span>
                <span class="text-gray-600">{brand ? brand :'-'}</span>
              </p>
              <p class="space-x-2">
                <span class="text-gray-800 font-semibold">Category: </span>
                <span class="text-gray-600">{sCategory}</span>
              </p>
              <p class="space-x-2">
                <span class="text-gray-800 font-semibold">Price: </span>
                <span class="text-xl text-red-500 font-semibold"> LKR {price}</span>
              </p>
              <p class="space-x-2">
              <span class="text-gray-800 font-semibold">Delivery Fee: </span>
              <span class="text-gray-600">LKR {shippingFee}</span>
            </p>
              {/* <p class="space-x-2">
                    <span class="text-gray-800 font-semibold">SKU: </span>
                    <span class="text-gray-600">BE45VGRT</span>
                </p> */}
            </div>
         
            {/* <div className="text-lg mb-8">
              Delivery Fee : <span>LKR {shippingFee}</span>
            </div> */}
            <div class="mt-4">
              <h3 class="text-sm text-gray-800 uppercase mb-1">Quantity</h3>
              <div class="flex border border-gray-300 text-gray-600 divide-x divide-gray-300 w-max">
                <div onClick={decreaseCount} class="h-8 w-8 text-xl flex items-center justify-center cursor-pointer select-none">-</div>
                <div class="h-8 w-8 text-base flex items-center justify-center">{count}</div>
                <div onClick={increaseCount} class="h-8 w-8 text-xl flex items-center justify-center cursor-pointer select-none">+</div>
              </div>
            </div>

            <div className="mt-6 flex gap-3 border-b border-gray-200 pb-5 pt-5">
              {qty > 0 ? (
                <button
                  onClick={() => {
                    if(token){
                      addToCart(product, product.id, count);
                    }else{
                      navigate('/myaccount');
                    }
                  }}
                  className="bg-blue-900 border border-blue-900 text-white px-8 py-2 font-medium rounded uppercase flex items-center gap-2 hover:bg-transparent hover:text-blue-900 transition"
                >
                  <LocalMallIcon /> Add to cart
                </button>
              ) : (
                <button className="bg-blue-900 text-white px-8 py-2 font-medium rounded uppercase flex items-center gap-2 cursor-not-allowed" disabled>
                  <LocalMallIcon /> Out of stock
                </button>
              )}
              <button
                onClick={() => {
                  if(token){
                    addToWishList(product, product.id);
                  }else{
                    navigate('/myaccount');
                  }
                }}
                className="border border-gray-300 text-gray-600 px-8 py-2 font-medium rounded uppercase flex items-center gap-2 hover:text-primary transition"
              >
                <FavoriteIcon />Wishlist
              </button>

              {/* {qty > 0 ? (
              <button
                onClick={() => {
                  addToCart(product, product.id);
                }}
                className="bg-blue-400 hover:bg-blue-500 text-white font-bold py-3 px-8 rounded"
              >
                Add to cart
              </button>
            ) : (
              <button className="bg-blue-500 text-white font-bold py-3 px-8 rounded opacity-50 cursor-not-allowed" disabled>
                Add to cart
              </button>
            )} */}
            </div>
            {
              token != null && token.role == "user" ? <div className="mt-2 space-x-1 flex">
                {/* <p className="text-dark-50"> Share via </p>  */}
                <FacebookShareButton
                  url={shareUrl}
                  appId="521270401588372"
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <FacebookMessengerShareButton
                  url={shareUrl}
                  appId="521270401588372"
                  className="Demo__some-network__share-button"
                >
                  <FacebookMessengerIcon size={32} round />
                </FacebookMessengerShareButton>
                <WhatsappShareButton
                  url={shareUrl}
                  title={title}
                  separator=":: "
                  className="Demo__some-network__share-button"
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <TelegramShareButton
                  url={shareUrl}
                  title={title}
                  className="Demo__some-network__share-button"
                >
                  <TelegramIcon size={32} round />
                </TelegramShareButton>
                <IconButton aria-label="copy" size="medium" onClick={() => {
                  copy(shareUrl);
                }}>
                  <ContentCopyIcon fontSize="inherit" />
                </IconButton>
              </div> : <div></div>
            }
          </div>
        </div>
      </div>
      <div class="container mx-auto pb-16 md:px-12 pt-4">
        <h3 class="border-b border-gray-200 font-roboto text-gray-800 pb-3 font-medium">Product details</h3>
        <p className="mt-4 text-gray-600"><div dangerouslySetInnerHTML={{__html:description}} /></p>
      </div>
      {
        filteredProducts.length > 0 ?
          <div class="container mx-auto md:px-12 pb-16">
            <h2 class="text-2xl font-medium text-gray-800 uppercase mb-6">Related products</h2>
            <div
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-[30px]
         mx-auto"
            >
              {filteredProducts.slice(0, 5).map((product) => {
                return <Product product={product} key={product.id} />;
              })}
            </div>
          </div> : <div></div>
      }
    </div>
  );
};

export default ProductDetails;
