import React from "react";
import logo from '../img/logow.svg';
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="relative bg-blueGray-200 pt-8  bg-blue-950 text-gray-100">
      <div className="container mx-auto md:px-12">
        <div class=" grid grid-cols-1 ">
          <div class="col-span-1 space-y-4">

            {/* <div class="mr-2">
                    <p class="text-gray-100 text-sm">
                    Onet Market envisions becoming a leading direct selling company that offers premium <br/> lifestyle products with a strong commitment to environmental sustainability.
                    </p>
                </div> */}
            {/* <div class="flex space-x-5">
                    <a href="#" class="text-white hover:text-gray-500"><i
                            class="fa-brands fa-facebook-square"></i></a>
                    <a href="#" class="text-white hover:text-gray-500"><i
                            class="fa-brands fa-instagram-square"></i></a>
                    <a href="#" class="text-white hover:text-gray-500"><i
                            class="fa-brands fa-twitter-square"></i></a>
                    <a href="#" class="text-white hover:text-gray-500">
                        <i class="fa-brands fa-github-square"></i>
                    </a>
                </div> */}
          </div>

          <div class="col-span-2 grid md:grid-cols-2 gap-4 mb-16 mt-8">
            <div class="grid md:grid-cols-2 gap-4 md:gap-8">
              <div>
                <img src={logo} alt="logo" class="md:h-7 h-5 mb-12" />
                {/* <h3 class="text-sm font-semibold text-white uppercase tracking-wider">Solutions</h3> */}
                <div class="mt-4 space-y-2">
                  <a href="#" class="text-sm text-gray-100 hover:text-white block">📍 No. 77/35, Isuru Place, Mampitiya, Galle</a>
                  <a href="#" class="text-sm text-gray-100 hover:text-white block">📍 No. 379/A, Mdidella, Talawa, Galle</a>
                  <a href="#" class="text-sm text-gray-100 hover:text-white block">📞 +94 76 043 6884</a>
                  <a href="#" class="text-sm text-gray-100 hover:text-white block">📧 support@onetmarket.com</a>

                  <div className="pt-4">
                    <button class="bg-transparent font-semibold text-white inline-flex items-center space-x-2 rounded mr-2">
                      <svg
                        class="w-8 h-8  fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
                      </svg>
                    </button>

                    <button class="bg-transparent font-semibold text-white inline-flex items-center space-x-2 rounded mr-2">
                      <svg
                        class="w-8 h-8 text-green-400 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
                      </svg>
                    </button>

                    <button class="bg-transparent font-semibold text-white inline-flex items-center space-x-2 rounded mr-2">
                      <svg
                        class="w-8 h-8 text-blue-400 fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 496 512"
                      >
                        <path d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div>
                <h3 class="md:ml-16 text-lg font-semibold text-white tracking-wider">Shop</h3>
                <div class="md:ml-16 mt-4 space-y-4">
                  <Link to={'/'} class="text-sm text-gray-200 hover:text-white block">Home</Link>
                  <Link to={'/about'} href="#" class="text-sm text-gray-200 hover:text-white block">About Us</Link>
                  <Link to={'/contact'} href="#" class="text-sm text-gray-200 hover:text-white block">Contact</Link>
                </div>
              </div>
            </div>
            <div class="grid md:grid-cols-2 gap-8">
              <div>
                <h3 class="text-lg font-semibold text-white tracking-wider">Support</h3>
                <div class="mt-4 space-y-4">
                  <Link to={'/after-sale-policy'} class="text-sm text-gray-200 hover:text-white block">After Sales Service</Link>
                  <Link to={'/delivery-policy'} href="#" class="text-sm text-gray-200 hover:text-white block">Delivery policy</Link>
                  <Link to={'/return-policy'} href="#" class="text-sm text-gray-200 hover:text-white block">Return and exchange policy</Link>
                  <Link to={'/privacy-policy'} href="#" class="text-sm text-gray-200 hover:text-white block">Privacy Policy</Link>
                </div>
              </div>

              <div className="md:-ml-10">
                <h3 class="text-lg font-semibold text-white tracking-wider">Subscribe to our newsletter</h3>
                <div class="mt-4 space-y-4">
                  <p class="text-sm text-gray-200 hover:text-white block">Stay in the loop! Join our newsletter for exclusive updates and offers.</p>
                  <form>
                    <div class="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
                      <div class="relative w-full">
                        <label for="email" class="hidden mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email address</label>
                        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                          <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                        </div>
                        <input class="block p-3 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:rounded-none sm:rounded-l-lg focus:ring-primary-500 focus:border-primary-500 " placeholder="Enter your email" type="email" id="email1" required="" />
                      </div>
                      <div>
                        <button type="submit" class="py-3 px-5 w-full text-sm font-medium text-center text-white rounded-lg border cursor-pointer bg-primary-700 border-primary-600 sm:rounded-none sm:rounded-r-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Subscribe</button>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-800 py-4 mt-4">
        <div class="container mx-auto md:px-12  flex items-center justify-between">
          <p class="text-gray-100">&copy; Onet Marketing Service Pvt. Ltd. All rights reserved.</p>
          <div>
            {/* <img src="assets/images/methods.png" alt="methods" class="h-5"/> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
