import axios from "axios";

const api = axios.create({
    baseURL:"https://ostore.rapidbyte.org/v1"
});

api.interceptors.request.use(
    (config) => {
        let authToken = null;
        // Do something before request is sent
        const token = JSON.parse(window.sessionStorage.getItem('user_token'));
        if(token){
            authToken = token.token;
        }
        config.headers.Authorization = `Bearer ${authToken}`;
        return config;
    },
    (error) => {
        // Do something with request error
        console.log(error);
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // console.log(response);
        console.log(response.status);
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        console.log(error);
        console.log(error.response);
        if (error.response.status === 401) {
            let msg = 'Unauthorized User';
            window.location = '/';
            window.sessionStorage.removeItem('user_token')
        } else if (error.response.status === 404) {
            let msg = 'Cannot find the Server';
            window.location = '/';
            window.sessionStorage.removeItem('user_token')
        } else {
            let msg = 'Unknown Error Occured';
        }
        return Promise.reject(error);
    }
);

export default api;
