import React, { useContext, useState } from "react";
import { RecoveryContext } from "../../contexts/RecoveryContext";
import API from "../api";

function FPassword() {
  const { setPage, page, email, setEmail } =
    useContext(RecoveryContext);
  const [text, setText] = useState("");

  function navigateToOtp(e) {
    e.preventDefault();

    if (text) {
      setEmail(text.toLowerCase());
      setPage("otp");

      API
        .post("/auth/reset_password", {
          recipient_email: text,
        })
        .then(() => setPage("otp"))
        .catch(console.log);
      return;
    }
  }

  return (
    <div className="py-10 md:py-20 mb-20 container mx-auto md:px-12">
      <div class="md:grid md:grid-cols-2 md:divide-x items-center justify-center py-2 lg:py-0">
        <div class="w-full">
          <div class=" space-y-6 md:space-y-8 lg:pr-40">
            <h1 class="text-3xl font-bold leading-tight tracking-tight">
              Reset password
            </h1>
            <p>Enter your email address.</p>
            <form class="space-y-4 md:space-y-6 pb-40" onSubmit={navigateToOtp}>
              <div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  class="w-full py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                  required
                  onChange={(evt) => {
                    setText(evt.target.value);
                  }}
                />
              </div>
              <button
                type="submit"
                class="w-1/2 bg-blue-600 hover:bg-blue-600 text-white hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                SUBMIT
              </button>
            </form>
          </div>
        </div>
        <div class="w-1/2 h-full"></div>
      </div>
    </div>
  );
}

export default FPassword;
