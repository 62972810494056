import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import API from "../api";

export default function Address() {
    const { register, reset, handleSubmit } = useForm();
    const { token, loading } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading) {
            if (!token) {
                navigate("/");
            } else {
                setUserDetails(token.userId);
            }
        }
    }, [token, loading]);

    const setUserDetails = async (id) => {
        if (token.role == "user") {
            await API.get(`/api/user/${id}`)
                .then((res) => {
                    if (res.data.data) {
                        const user = res.data.data[0];
                        reset({
                            userId: user.userId,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            phone: user.phone,
                            address: user.address,
                            city: user.city,
                            dob: user.dob,
                            mobile: user.mobile,
                            province: user.province,
                            landmark: user.landmark,
                            postcode: user.postcode
                        });
                    }
                })
                .catch((err) => {
                });
        } else {
            await API.get(`/api/customer/${id}`)
                .then((res) => {
                    if (res.data.data) {
                        const user = res.data.data[0];
                        reset({
                            userId: user.userId,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            phone: user.phone,
                            mobile: user.mobile,
                            address: user.address,
                            city: user.city,
                            dob: user.dob,
                            province: user.province,
                            landmark: user.landmark,
                            postcode: user.postcode
                        });
                    }
                })
                .catch((err) => {
                });
        }
    }

    const onUserUpdate = (data) => {
        if (token.role == "user") {
            const newData = {
                ...data,
                userId: token.userId,
            }
            API.put("/api/user", newData)
                .then(function (response) {
                    if (response.data.data) {
                        Swal.fire({
                            icon: 'success',
                            title: 'MY DETAILS',
                            text: 'Profiel details updated succesfully.',
                            confirmButtonColor: "#2563eb"
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'MY DETAILS',
                            text: 'Oops.. something went wrong.',
                            confirmButtonColor: "#2563eb"
                        })
                    }
                })
                .catch(function (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'MY DETAILS',
                        text: 'Oops.. something went wrong.',
                        confirmButtonColor: "#2563eb"
                    })
                });
        } else {
            const newData = {
                ...data,
                userId: token.userId,
            }
            API.put("/api/customer", newData)
                .then(function (response) {
                    if (response.data.data) {
                        Swal.fire({
                            icon: 'success',
                            title: 'MY DETAILS',
                            text: 'Profiel details updated succesfully.',
                            confirmButtonColor: "#2563eb"
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'MY DETAILS',
                            text: 'Oops.. something went wrong.',
                            confirmButtonColor: "#2563eb"
                        })
                    }
                })
                .catch(function (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'MY DETAILS',
                        text: 'Oops.. something went wrong.',
                        confirmButtonColor: "#2563eb"
                    })
                });
        }

    }

    return (
        <section>
            <div class="col-span-9 shadow rounded px-6 pt-5 pb-7">
                <h4 class="text-lg font-medium capitalize mb-4">
                    Shipping address
                </h4>
                <form onSubmit={handleSubmit(onUserUpdate)} class="space-y-4 text-sm">
                    <div>
                        <div className="space-x-0 lg:flex lg:space-x-4">
                            <div className="w-full lg:w-1/2">
                                <label
                                    for="firstName"
                                    required
                                >
                                    First Name
                                </label>
                                <input
                                    {...register("firstName")}
                                    name="firstName"
                                    type="text"
                                    required
                                    className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                                />
                            </div>
                            <div className="w-full lg:w-1/2 ">
                                <label
                                    for="firstName"
                                    required
                                >
                                    Last Name
                                </label>
                                <input
                                    {...register("lastName")}
                                    name="lastName"
                                    type="text"
                                    required
                                    className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                                />
                            </div>
                        </div>
                        <div className="mt-3 space-x-0 lg:flex lg:space-x-4">
                            {/* <div className="w-full lg:w-1/2">
                                <label
                                    for="Email"
                                    required
                                >
                                    Email
                                </label>
                                <input
                                    disabled
                                    {...register("email")}
                                    name="email"
                                    type="text"
                                    required
                                    className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                                />
                            </div> */}
                            <div className="w-full lg:w-1/2 ">
                                <label
                                    for="phone"
                                    required
                                >
                                    Mobile No 1
                                </label>
                                <input
                                    {...register("phone")}
                                    name="phone"
                                    type="text"
                                    required
                                    className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                                />
                            </div>
                            <div className="w-full lg:w-1/2 ">
                                <label
                                    for="mobile"
                                    required
                                >
                                    Mobile No 2
                                </label>
                                <input
                                    {...register("mobile")}
                                    name="mobile"
                                    type="text"
                                    required
                                    className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                                />
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="w-full">
                                <label
                                    for="Address"
                                    required
                                >
                                    Address
                                </label>
                                <textarea
                                    {...register("address")}
                                    className="w-full px-4 py-3 text-xs border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                                    name="address"
                                    cols="20"
                                    rows="4"
                                    required
                                ></textarea>
                            </div>
                        </div>
                        <div className="space-x-0 lg:flex lg:space-x-4">
                            <div className="w-full lg:w-1/2">
                                <label
                                    for="city"
                                    required
                                >
                                    City
                                </label>
                                <input
                                    {...register("city")}
                                    name="city"
                                    type="text"
                                    required
                                    className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                                />
                            </div>
                            <div className="w-full lg:w-1/2 ">
                                <label
                                    for="postcode"
                                    required
                                >
                                    Postcode
                                </label>
                                <input
                                    {...register("postcode")}
                                    name="postcode"
                                    required
                                    type="text"
                                    className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <button type="submit"
                            class="py-3 px-4 text-center text-white bg-blue-600 rounded-md  font-medium">save
                            changes</button>
                    </div>
                </form>
            </div>
        </section>
    );
}