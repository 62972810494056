import React, { createContext, useState } from "react";
import API from "../components/api";
export const OrderContext = createContext();

const OrderProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchOrders = async (id) => {
    API.get(`/api/order/userId/${id}`)
      .then((res) => {
        if (res.data) {
          setOrders(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  const fetchOrderbyOrderId = async (id) => {
    API.get(`/api/order/id/${id}`)
      .then((res) => {
        if (res.data) {
          setOrders(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  return (
    <OrderContext.Provider value={{ orders, loading, fetchOrders, fetchOrderbyOrderId }}>
      {children}
    </OrderContext.Provider>
  );
};

export default OrderProvider;
