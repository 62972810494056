import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import Order from "../Order";
import { OrderContext } from "../../contexts/OrderContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Pagination from "@mui/material/Pagination";
import API from "../api";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

function MyOrders({viewOrder}) {
  const Swal = require("sweetalert2");
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(5);
  const [status, setStatus] = useState({});
  const { token, getAuthToken, loading } = useContext(AuthContext);

  const [page, setPage] = React.useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const options = [
    { value: "all", label: "All Orders" },
    { value: "unpaid", label: "Awaiting Payment" },
    { value: "processing", label: "Processing" },
    { value: "shipped", label: "Shipped" },
    { value: "completed", label: "Completed" },
    { value: "cancel", label: "Cancelled" },
    // { value: "refund", label: "Vanilla" },
  ];

  const { orders, fetchOrders } = useContext(OrderContext);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  useEffect(() => {
    let arr = pathname.split('/');
    if(arr[4]){
      let label = options.filter((option)=>{
         return option.value === arr[4]
      })
      handleSelect({value: arr[4], label: label[0].label});
    }else{
      handleSelect({ value: "all", label: "All Orders" });
    }
  }, [pathname]);

  useEffect(() => {
    getAuthToken();
    if (!loading) {
      if (!token) {
        navigate("/");
      } else {
        fetchOrders(token.userId);
        initialize();
      }
    }
  }, [token, orders.length, loading]);

  const initialize = () => {
    setFilteredOrders(orders);
    if (!orders) {
      return (
        <section className="h-screen flex justify-center items-center">
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </section>
      );
    } else {
      var no = Math.ceil(orders.length / 5);
      setNoOfPages(no);
    }
  };

  const cancelOrder = (id) => {
    Swal.fire({
      title: "Cancel Order?",
      text: "Are you sure you want to cancel this order.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563eb",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        API.put(`/api/order/status`, {
          id: id,
          status: "cancel",
        })
          .then((res) => {
            if (res.data) {
              fetchOrders(token.userId);
              if (status !== "all") {
                let data = orders.filter((item) => {
                  return item.status === status;
                });
                setFilteredOrders(data);
                var no = Math.ceil(data.length / 5);
                setNoOfPages(no);
              } else {
                setFilteredOrders(orders);
                var no = Math.ceil(orders.length / 5);
                setNoOfPages(no);
              }
              setPage(1);
              setStart(0);
              setEnd(5);

              Swal.fire({
                title: "Cancelled!",
                text: "Your order has been cancelled.",
                icon: "success",
                confirmButtonColor: "#2563eb",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handleChange = (event, value) => {
    setStart(5 * (value - 1));
    setEnd(value * 5);
    setPage(value);
  };

  const handleSelect = (e) => {
    setStatus(e);
    if (e.value !== "all") {
      let data = orders.filter((item) => {
        return item.status === e.value;
      });
      setFilteredOrders(data);
      var no = Math.ceil(data.length / 5);
      setNoOfPages(no);
    } else {
      setFilteredOrders(orders);
      var no = Math.ceil(orders.length / 5);
      setNoOfPages(no);
    }
    setPage(1);
    setStart(0);
    setEnd(5);
  };

  return (
    <div>
      <div class="col-span-9 shadow rounded px-6 pt-5 pb-7">
        <div className="w-full md:flex justify-between items-center">  <h4 class="text-lg font-medium capitalize mb-4">
          Order History
        </h4>
          <div className="w-full md:w-96 flex items-center">
            <FilterAltIcon />{" "}
            <Select
              className="w-full ml-2 text-sm"
              options={options}
              onChange={handleSelect}
              value={status}
            />
          </div>
        </div>
        <div className="min-h-[36rem] mt-2">
          {filteredOrders.length != 0 ? (
            filteredOrders.slice(start, end).map((order) => {
              return (
                <Order
                  order={order}
                  key={order.id}
                  viewOrder ={viewOrder}
                  cancelOrder={cancelOrder}
                />
              );
            })
          ) : (
            <section className="h-[36rem] mt-8 bg-white border border-gray-200 rounded-lg shadow flex justify-center items-center">
              No orders were found.
            </section>
          )}
        </div>
        <Pagination
          className="mt-8"
          count={noOfPages}
          page={page}
          onChange={handleChange}
          color="primary"
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
}

export default MyOrders;
