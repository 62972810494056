import React, { createContext, useEffect, useState, useContext } from 'react';
import API from "../components/api";
import { AuthContext } from './AuthContext';
export const WishlistContext = createContext();

const WishlistProvider = ({ children }) => {
    const [wishlist, setWishlist] = useState([]);
    const { token, getAuthToken } = useContext(AuthContext);

    useEffect(() => {
        getAuthToken();
        if (!token) {
        }
        getWishlistItems();
    }, [token]);

    const getWishlistItems = async () => {
        if (token) {
            API.get(`/api/wishlist/${token.userId}`)
                .then((res) => {
                    if (res.data) {
                        setWishlist(res.data.data[0].wishlist);
                    }
                })
                .catch((err) => { });
        }
    }

    const removeFromWishList = (id) => {
        const newWishlist = wishlist.filter(item => {
          return item.id !== id;
        });
        setWishlist(newWishlist);
        if (token) {
          API.post("/api/wishlist/", { userId: token.userId, wishlist: newWishlist })
            .then(function (response) {
              
            })
            .catch(function (error) {
              console.log(error)
            });
        }
    }

    const addToWishList = (product, id) => {
        const newWish = wishlist.find((item) => {
          return item.id === id;
        })
        if (newWish) {
        
        } else {
            setWishlist([...wishlist, product]);
          if (token) {
            API.post("/api/wishlist/", { userId: token.userId, wishlist: [...wishlist, product] })
              .then(function (response) {
    
              })
              .catch(function (error) {
                console.log(error)
              });
          }
        }
    }

    return (
        <WishlistContext.Provider value={{ wishlist, addToWishList, removeFromWishList }}>
            {children}
        </WishlistContext.Provider>
    );
};

export default WishlistProvider;
