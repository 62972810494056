import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import Order from "../Order";
import { OrderContext } from "../../contexts/OrderContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Pagination from "@mui/material/Pagination";
import API from "../api";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import moment from 'moment';
import { CartContext } from "../../contexts/CartContext";

function OrderDetails() {
    const { pathname } = useLocation();
    const [order, setOrder] = useState({});
    const { orders, fetchOrderbyOrderId } = useContext(OrderContext);
    const { addToCart } = useContext(CartContext);
    const imgBaseUrl = 'https://firebasestorage.googleapis.com/v0/b/ostore-86bf8.appspot.com/o/';

    useEffect(() => {
        let arr = pathname.split('/');
        if (arr[5]) {
            fetchOrderbyOrderId(arr[5]);
            initialize();
        }
    }, [pathname, orders[0]]);

    function Status(props) {
        if (props.status === "unpaid") {
            return <span>Awaiting Payment</span>;
        } else if (props.status === "processing") {
            return <span>Awaiting Delivery</span>;
        } else if (props.status === "shipped") {
            return <span>Delivered</span>;
        } else if (props.status === "return") {
            return <span>Return</span>;
        } else if (props.status === "cancel") {
            return <span>Cancelled</span>;
        } else {
            return <span>Completed</span>;
        }

    }

    const initialize = () => {
        if (!orders) {
            return (
                <section className="h-screen flex justify-center items-center">
                    <Box sx={{ display: "flex" }}>
                        <CircularProgress />
                    </Box>
                </section>
            );
        } else {
            setOrder(orders[0])
        }
    };
    return (
        <div>
            <div class="col-span-9 shadow rounded px-6 pt-5 pb-7">
                <div className="w-full md:flex justify-between items-center">  <h4 class="text-lg font-medium capitalize mb-4">
                    Order Details
                </h4>
                </div>
                <div className="min-h-[36rem] mt-2">
                    <div class="items-center justify-between border gap-6 p-4 border-gray-200 rounded mb-2">
                        <div className="md:flex flex-col-2 mb-2 justify-between">
                            <div className="md:text-base font-semibold">Status: <Status status={order.status} /></div>
                            <div>
                                {/* <div>{moment(order.orderedDate).format('DD MMM, YYYY')}</div> */}
                            </div>
                        </div>
                        <hr />
                        {order.cart ? order.cart.map((item) => {
                            return (
                                <div className="md:flex md:flex-col-2 py-3 justify-between items-center ">
                                    <div className="md:flex md:flex-col-2 items-center">
                                        <div class="w-28">
                                            <img
                                                class="mx-auto h-[100px]"
                                                src={`${imgBaseUrl + item.img[0].replace(/\//g, '%2F') + '?alt=media'}`}
                                                alt="image"
                                            />
                                        </div>
                                        <div class="md:w-3/4 md:pl-8">
                                            <h5 class="mb-2 mt-2 md:text-lg font-medium uppercase tracking-tight text-gray-900 ">
                                                {item.title}
                                            </h5>
                                            <p class="font-normal text-sm text-gray-700 ">
                                                LKR {item.price} x {item.qty}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <Link to={`/product/${item.id}`}>
                                            <p
                                                class="px-6 md:ml-2 py-2 text-center text-sm text-white border border-blue-600 bg-blue-600 rounded uppercase font-roboto font-medium">View Item</p>
                                        </Link>
                                        {/* <Link to={`/product/${item.id}`}>
                                            <p
                                                class="px-6 ml-2 py-2 text-center text-sm text-white border border-blue-600 bg-blue-600 rounded uppercase font-roboto font-medium">Add To Cart</p>
                                        </Link> */}
                                    </div>

                                </div>

                            );
                        }) : <div></div>}
                        <hr />
                        <div className="md:text-sm text-gray-400 flex mt-2 justify-between">
                            <div></div>
                            <div className="md:w-1/2 w-full flex justify-between">
                                <div>
                                    SubTotal:
                                </div>
                                <div>
                                    LKR {order.orderAmount ? order.orderAmount.toFixed(2) : "LKR 0.00"}
                                </div>
                            </div>
                        </div>
                        <div className="md:text-sm text-gray-400 mt-2 flex justify-between">
                            <div></div>
                            <div className="md:w-1/2 w-full flex justify-between">
                                <div>
                                    Shipping:
                                </div>
                                <div>
                                    LKR {order.shippingFee ? order.shippingFee.toFixed(2) : "LKR 0.00"}
                                </div>
                            </div>
                        </div>
                        {
                            order.registrationFee ? <div className="md:text-sm mt-2 text-gray-400 flex justify-between">
                                <div></div>
                                <div className="md:w-1/2 w-full flex justify-between">
                                    <div>
                                        Registration Fee:
                                    </div>
                                    <div>
                                        LKR {order.registrationFee ? order.registrationFee.toFixed(2) : "LKR 0.00"}
                                    </div>
                                </div>
                            </div> : <div></div>
                        }
                        <div className="md:text-base mt-2 font-bold flex justify-between">
                            <div></div>
                            <div className="md:w-1/2 w-full flex justify-between">
                                <div>
                                    Total:
                                </div>
                                <div>
                                    LKR {order.totalAmount ? order.totalAmount.toFixed(2) : "LKR 0.00"}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="items-center justify-between border gap-6 p-4 border-gray-200 rounded mb-2">
                        <div className="text-lg font-semibold">
                            Order Info
                        </div>
                        <div className="text-sm text-gray-600 md:flex justify-between ">
                            <div className="flex mt-2 border h-40 gap-6 p-4 border-gray-200 rounded mb-2s md:w-1/2">
                                <div>
                                    Address
                                </div>
                                <div className="ml-2">
                                    <p>{order.firstName}{' '}{order.lastName}</p>
                                    <p>{order.phone}</p>
                                    <p>{order.address}</p>
                                    <p>{order.city}</p>
                                    <p>{order.postcode}</p>
                                </div>
                            </div>
                            <div className="space-y-1 mt-2 h-40 border gap-6 p-4 border-gray-200 rounded mb-2 md:ml-2 md:w-1/2">
                                <div className="flex">
                                    <div>
                                        Order ID
                                    </div>
                                    <div className="ml-2">
                                        <p>{order.id}</p>
                                    </div>
                                </div>
                                <div className="flex ">
                                    <div>
                                        Order placed on
                                    </div>
                                    <div className="ml-2">
                                        <p>{moment(order.orderedDate).format('DD MMM, YYYY, hh : mm a')}</p>
                                    </div>
                                </div>
                                <div className="flex">
                                    <div>
                                        Payment method
                                    </div>
                                    <div className="ml-2">
                                        <p>{order.paymentType}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderDetails;
