import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { RecoveryContext } from "../../contexts/RecoveryContext";
import API from "../api";

export default function () {
  const { email, setPage, setOTP, setRole } = useContext(RecoveryContext);
  const [timerCount, setTimer] = React.useState(60);
  const [OTPinput, setOTPinput] = useState([0, 0, 0, 0]);
  const [disable, setDisable] = useState(true);

  function resendOTP() {
    if (disable) return;
    API.post("/auth/reset_password", {
      recipient_email: email,
    })
      .then(() => setDisable(true))
      .then(() => alert("A new OTP has succesfully been sent to your email."))
      .then(() => setTimer(60))
      .catch(console.log);
  }

  function verfiyOTP() {

    API.post("/auth/verify_otp", {
      recipient_email: email,
      otp: parseInt(OTPinput.join(""))
    })
      .then((result) => {
        if(result.data){
          if (result.data.validate) {
            setRole(result.data.role);
            setPage("reset");
            return;
          } else {
            alert(
              "The code you have entered is not correct, try again or re-send the link"
            )
            return;
          }
        }
      })
      .then(() => setTimer(60))
      .catch(console.log);
  }

  React.useEffect(() => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        if (lastTimerCount <= 1) setDisable(false);
        if (lastTimerCount <= 0) return lastTimerCount;
        return lastTimerCount - 1;
      });
    }, 1000); //each count lasts for a second
    //cleanup the interval on complete
    return () => clearInterval(interval);
  }, [disable]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    let fieldIntIndex = parseInt(fieldIndex, 10);
    if (value.length >= 1) {
      if (fieldIntIndex < 4) {
        const nextfield = document.querySelector(
          `input[name=otp-${fieldIntIndex + 1}]`
        );
        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
  };

  return (
    <div className="py-10 md:py-20 mb-20 container mx-auto md:px-12">
      <div class="md:grid md:grid-cols-2 md:divide-x items-center justify-center py-2 lg:py-0">
        <div className="bg-white pb-9 w-full max-w-lg">
          <div className="flex w-full max-w-md flex-col space-y-16">
            <div className="flex flex-col space-y-2">
              <div className="font-semibold text-3xl">
                <p>Email Verification</p>
              </div>
              <div className="flex flex-row text-sm font-medium text-gray-400">
                <p>We have sent a code to your email {email}</p>
              </div>
            </div>

            <div>
              <form>
                <div className="flex flex-col space-y-16">
                  <div className="flex flex-row items-center justify-between w-full max-w-xs">
                    <div className="w-16 h-16 ">
                      <input
                        maxLength="1"
                        className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                        type="text"
                        name="otp-1"
                        id=""
                        onChange={(e) => {
                          setOTPinput([
                            e.target.value,
                            OTPinput[1],
                            OTPinput[2],
                            OTPinput[3],
                          ]);
                          handleChange(e);
                        }}
                      ></input>
                    </div>
                    <div className="w-16 h-16 ">
                      <input
                        maxLength="1"
                        className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                        type="text"
                        name="otp-2"
                        id=""
                        onChange={(e) => {
                          handleChange(e);
                          setOTPinput([
                            OTPinput[0],
                            e.target.value,
                            OTPinput[2],
                            OTPinput[3],
                          ]);
                        }}
                      ></input>
                    </div>
                    <div className="w-16 h-16 ">
                      <input
                        maxLength="1"
                        className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                        type="text"
                        name="otp-3"
                        id=""
                        onChange={(e) => {
                          setOTPinput([
                            OTPinput[0],
                            OTPinput[1],
                            e.target.value,
                            OTPinput[3],
                          ]);
                          handleChange(e);
                        }}
                      ></input>
                    </div>
                    <div className="w-16 h-16 ">
                      <input
                        maxLength="1"
                        className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                        type="text"
                        name="otp-4"
                        id=""
                        onChange={(e) => {
                          handleChange(e);
                          setOTPinput([
                            OTPinput[0],
                            OTPinput[1],
                            OTPinput[2],
                            e.target.value,
                          ]);
                        }}
                      ></input>
                    </div>
                  </div>

                  <div className="flex flex-col space-y-5">
                    <div
                      onClick={() => verfiyOTP()}
                      className="w-1/2 cursor-pointer bg-blue-600 hover:bg-blue-600 text-white hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm py-2.5 text-center"
                    >
                      Verify Account
                    </div>

                    <div className="flex flex-row items-center text-sm font-medium space-x-1 text-gray-500">
                      <p>Didn't recieve code?</p>{" "}
                      <a
                        className="flex flex-row items-center"
                        style={{
                          color: disable ? "gray" : "blue",
                          cursor: disable ? "none" : "pointer",
                          textDecorationLine: disable ? "none" : "underline",
                        }}
                        onClick={() => resendOTP()}
                      >
                        {disable
                          ? `Resend OTP in ${timerCount}s`
                          : "Resend OTP"}
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="w-1/2 h-full"></div>
      </div>
    </div>
  );
}
