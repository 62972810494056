import React,{useContext} from "react";
import SuccessImg from "../img/Order.svg";
import { useParams } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function OrderSuccess() {
  const { id } = useParams();
  const {
    cart,
    total,
    clearCart,
    shippingFee,
    totalDirectCommision,
    totalIndirectCommision,
  } = useContext(CartContext);

  const navigate = useNavigate();
  const { state } = useLocation();

  const navigateToHome = (id) => {
    let path = `/`;
    navigate(path);
  };
  return (
    <div className="lg:mt-28 mt-16">
      <div className="md:px-12 py-12 container mx-auto">
        <div class="w-full border-2 rounded overflow-hidden mx-auto">
          <div className="md:p-10 py-4">
            <img
              class="w-1/4 sm:w-1/6 mx-auto"
              src={SuccessImg}
              alt="Sunset in the mountains"
            />
            <div class="px-6 py-4 mt-4">
              <div class="font-bold md:text-xl mb-2 text-center">
                Your order is placed. <br/> Order Id: {id}
              </div>
              <div class="rounded overflow-hidden border-2 m-4 p-2 text-sm mx-auto">
                <div className="text-slate-700">
                  Make your payment directly into our bank account. Please use
                  your Order Id as the payment reference. Your order will not be
                  shipped until the funds have been cleared in our account. Please
                  contact us for any further information or clarification.
                </div>
                <div className="mt-2">
                  <div className="mt-1 mb-1">Account Details </div>
                  Account Number : 01411 0001 267
                  <br /> Bank : Sampath Bank <br /> Branch : Karapitiya
                  <br />
                  Name : Onet Marketing
                  <br />
                  Amount : LKR {state.total.toFixed(2)}
                </div>
              </div>
              <p class="text-gray-700 md:text-base text-sm text-center">
                Your order is currently bieng processed. You will receive order
                confirmation email once you completed your payment.
              </p>
              <div class="font-bold md:text-2xl mt-3 mb-2 text-center">
                Thank You!
              </div>
              <div onClick={navigateToHome} className="w-full text-center px-6 md:py-3 py-2 rounded-lg text-white bg-blue-600 cursor-pointer hover:bg-blue-600">
                  Continue Shopping
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
