import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header/Header";
import Footer from "./components/Footer";
import Checkout from "./pages/Checkout";
import CategoryDetails from "./pages/CategoryDetails";
import OrderSuccess from "./pages/OrderSuccess";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ScrollToTop from "./contexts/ScrollToTop";
import Payments from "./pages/Payments";
import Orders from "./pages/Orders";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import CusRegister from "./pages/CusRegister";
import SearchResult from "./pages/SearchResult";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import DeliveryPolicy from "./pages/DeliveryPolicy";
import AfterSalePolicy from "./pages/AfterSalePolicy";
import ReturnPolicy from "./pages/ReturnPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const App = () => {
  return (
    <div className="overflow-hidden">
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/delivery-policy" element={<DeliveryPolicy />} />
          <Route path="/after-sale-policy" element={<AfterSalePolicy />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/products/:id" element={<SearchResult />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/myaccount" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/customer/register" element={<CusRegister />} />
          <Route path="/myaccount/payments" element={<Payments />} />
          <Route path="/myaccount/orders" element={<Orders />} />
          <Route path="/myaccount/profile" element={<Profile />} />
          <Route path="/myaccount/profile/*" element={<Profile />} />
          <Route
            path="/myaccount/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            path="/checkout/order/completed/:id"
            element={<OrderSuccess />}
          />
          <Route path="/category/:category" element={<CategoryDetails />} />
          <Route
            path="/category/:category/:scategory"
            element={<CategoryDetails />}
          />
          <Route path="*" element={<Home />} />
        </Routes>
        <Sidebar />
        <Footer />
      </Router>
    </div>
  );
};

export default App;
