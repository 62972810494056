import logo1 from "../img/banner-bg.jpg";

export default function AboutUs() {

    return <div className="lg:mt-28 mt-16">
        <section className="py-10 container mx-auto md:px-12">
            <div class=" ">
                <div class="items-center gap-8">
                    <div class="bg-cover bg-no-repeat bg-center border rounded-lg py-36" style={{ backgroundImage: `url(${logo1})` }}>
                        <div class="container mx-auto md:px-12 text-center">
                            <h1 class="text-6xl text-gray-800 font-medium mb-4 capitalize">
                                About Us
                            </h1>
                        </div>
                    </div>
                    <div className="w-full flex justify-center py-8">
                        <div className="w-1/2 text-center">
                            <p class="mt-4 text-gray-600 text-lg">Onet Market envisions becoming a leading direct selling company that offers premium lifestyle products with a strong commitment to environmental sustainability. Our mission is to provide eco-friendly solutions to our customers while maintaining a high standard of quality, at the most affordable prices!</p>
                        </div>
                    </div>
                    <div class="w-full">
                        {/* <h2 class="text-2xl font-bold text-center text-gray-900 sm:text-4xl">
                            Our Services</h2> */}

                        {/* <div class="mt-8">
                            <a href="#" class="text-blue-500 hover:text-blue-600 font-medium">Learn more about us
                                <span class="ml-2">&#8594;</span></a>
                        </div> */}
                    </div>
                    {/* <div class="mt-12 md:mt-0">
                        <img src="https://images.unsplash.com/photo-1531973576160-7125cd663d86" alt="About Us Image" class="object-cover rounded-lg shadow-md" />
                    </div> */}
                </div>
            </div>
        </section>
    </div>
}