import React, { useEffect, useState } from "react";
import moment from 'moment';
import { Link } from "react-router-dom";

function Order({ order, cancelOrder, viewOrder }) {
  const { id, orderedDate, cart, orderAmount, status, registrationFee, address, } = order;
  const imgBaseUrl = 'https://firebasestorage.googleapis.com/v0/b/ostore-86bf8.appspot.com/o/';

  function Status(props) {
    if (props.status === "unpaid") {
      return <span>Awaiting Payment</span>;
    } else if (props.status === "processing") {
      return <span>Awaiting Delivery</span>;
    } else if (props.status === "shipped") {
      return <span>Delivered</span>;
    } else if (props.status === "return") {
      return <span>Return</span>;
    } else if (props.status === "cancel") {
      return <span>Cancelled</span>;
    } else {
      return <span>Completed</span>;
    }

  }

  function TextAbstract(text, length) {
    if (text == null) {
      return "";
    }
    if (text.length <= length) {
      return text;
    }
    text = text.substring(0, length);
    var last = text.lastIndexOf(" ");
    text = text.substring(0, last);
    return text + "...";
  }

  return (
    <div>
      <div>
        <div class="items-center justify-between border gap-6 p-4 border-gray-200 rounded mb-2">
          <div className="md:flex flex-col-2 mb-2 justify-between">
            <div className="md:text-sm font-bold md:flex"><span className="hidden md:block mr-2">Status:</span><Status status={status} /></div>
            <div className="flex justify-between">
              <div>
                <div className="md:flex text-xs justify-end"><span className="hidden md:block mr-2">Order date:</span>{moment(orderedDate).format('DD MMM, YYYY')}</div>
                <div className="md:flex text-xs"><span className="hidden md:block mr-2">Order Id:</span>{id}</div>
              </div>
              <div class="mx-2 my-1 w-0.5 bg-gray-600"></div>
              <button onClick={() => { viewOrder(id) }} className=" px-6 py-1 text-center text-sm border border-blue-600 rounded text-blue-600 transition uppercase font-roboto font-medium">Order Details</button>
            </div>
          </div>
          <hr />
          {cart.map((item) => {
            return (
              <div className="md:flex md:flex-col-2 py-3 items-center justify-between">
                <div className="md:flex md:flex-col-2 items-center">
                  <div class="w-28">
                    <img
                      class="mx-auto h-[100px]"
                      src={`${imgBaseUrl + item.img[0].replace(/\//g, '%2F') + '?alt=media'}`}
                      alt="image"
                    />
                  </div>
                  <div class="md:w-3/4 md:pl-8">
                    <h5 class="mb-2 mt-2 md:text-lg font-medium uppercase tracking-tight text-gray-900 ">
                      {item.title}
                    </h5>
                    <p class="font-normal text-sm text-gray-700 ">
                      LKR {item.price} x {item.qty}
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <Link to={`/product/${item.id}`}>
                    <p
                      class="px-6 md:ml-2 py-2 text-center text-sm text-white border border-blue-600 bg-blue-600 rounded uppercase font-roboto font-medium">View Item</p>
                  </Link>
                </div>
              </div>
            );
          })}
          <hr />
          {/* <div className="flex justify-between">
            <div>
              {address}
              {address}
            </div>
            <div>

            </div>
          </div>
          <hr /> */}
          <div className="md:flex md:flex-col-2 mt-2 justify-between">
            {status === "unpaid" ? <button onClick={() => { cancelOrder(id) }} className="mt-2 px-6 py-1 hidden md:block text-center text-sm text-white bg-red-600 border border-red-600 rounded hover:bg-transparent hover:text-red-600 transition uppercase font-roboto font-medium">Cancel</button> : <div></div>}
            <div>
              <div className="md:text-base font-bold">
                Total: LKR {orderAmount.toFixed(2)}
              </div>
              {status === "unpaid" ? <button onClick={() => { cancelOrder(id) }} className="mt-2 px-6 py-1 text-center md:hidden text-sm text-white bg-red-600 border border-red-600 rounded hover:bg-transparent hover:text-red-600 transition uppercase font-roboto font-medium">Cancel</button> : <div></div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Order;
