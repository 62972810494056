import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Order from "../Order";
import { OrderContext } from "../../contexts/OrderContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Pagination from "@mui/material/Pagination";
import API from "../api";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import WishlistItem from "../WishlistItem";
import { WishlistContext } from "../../contexts/WishlistContext";

function MyWishlist() {
  const Swal = require("sweetalert2");
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(5);
  const { token, getAuthToken, loading } = useContext(AuthContext);
  const { wishlist } = useContext(WishlistContext);
  const [page, setPage] = React.useState(1);
  const [noOfPages, setNoOfPages] = useState(1);


  const navigate = useNavigate();

  useEffect(() => {
    getAuthToken();
    if (!loading) {
      if (!token) {
        navigate("/");
      } else {
        initialize();
      }
    }
  }, [token, wishlist.length, loading]);

  const initialize = () => {
    if (!wishlist) {
      return (
        <section className="h-screen flex justify-center items-center">
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </section>
      );
    } else {
      var no = Math.ceil(wishlist.length / 5);
      setNoOfPages(no);
    }
  };

  
  const handleChange = (event, value) => {
    setStart(5 * (value - 1));
    setEnd(value * 5);
    setPage(value);
  };


  return (
    <div>
      <div class="col-span-9 shadow rounded px-6 pt-5 pb-7">
        <div className="w-full">  <h4 class="text-lg font-medium capitalize mb-4">
          My Wishlist
        </h4>
        </div>
        <div className="min-h-[36rem]">
          {wishlist.length != 0 ? (
            wishlist.slice(start, end).map((wishistItem) => {
              return (
                <WishlistItem wishistItem={wishistItem} key={wishistItem.id}/>
              );
            })
          ) : (
            <section className="h-[36rem] mt-8 bg-white border border-gray-200 rounded-lg shadow flex justify-center items-center">
              Wishlist is empty.
            </section>
          )}
        </div>
        <Pagination
          className="mt-8"
          count={noOfPages}
          page={page}
          onChange={handleChange}
          color="primary"
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
}

export default MyWishlist;
