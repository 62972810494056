import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import API from "../components/api";
import { useForm } from "react-hook-form";
import { AuthContext } from "../contexts/AuthContext";
import { RecoveryContext } from "../contexts/RecoveryContext";
import Select from "react-select";
import * as Bank from "../components/constants";

function Register() {
  const hCategory = "MY ACCOUNT";
  const { register, handleSubmit } = useForm();
  const { register: login, handleSubmit: handleLogin } = useForm();
  const { setToken } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const { setPage } = useContext(RecoveryContext);
  const Swal = require("sweetalert2");
  const [wait, setWait] = useState(false);
  const navigate = useNavigate();

  const bankList = Bank.bankList;
  const bList = Bank.branchList;
  const [branchList, setBranchList] = useState([]);
  const [isBranchDisable, setBranchDisable] = useState(true);
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");

  function validatePassword(p) {
    if (p.length < 8) {
      return "Your password must be at least 8 characters.";
    }
    if (p.search(/[a-z]/i) < 0) {
      return "Your password must contain at least one letter.";
    }
    if (p.search(/[0-9]/) < 0) {
      return "Your password must contain at least one digit.";
    }
    return "";
  }

  const onUserRegister = (data) => {
    setWait(true);
    if (data.password === data.repassword) {
      if (bankName && branchName) {
        if (!validatePassword(data.password)) {
          const newData = {
            ...data,
            email: data.email.toLowerCase(),
            refferrerID: data.refferrerID.trim(),
            nameOfBank: bankName,
            branchName: branchName,
          };
          API.get(`/api/user/${data.refferrerID}`)
            .then((res) => {
              if (res.data.data.length > 0) {
                API.post("/auth/register", newData)
                  .then(function (res) {
                    if (res.data.data) {
                      localStorage.setItem(
                        "user_token",
                        JSON.stringify(res.data.data)
                      );
                      setToken(res.data.data);
                      let path = `/`;
                      navigate(path);
                      // Swal.fire({
                      //   icon: "success",
                      //   title: "REGISTRATION",
                      //   text: "Registered succesfully.",
                      //   confirmButtonColor: "#2563eb",
                      // });
                    } else {
                      setWait(false);
                      Swal.fire({
                        icon: "error",
                        title: "REGISTRATION",
                        text: "Oops.. something went wrong.",
                        confirmButtonColor: "#2563eb",
                      });
                    }
                  })
                  .catch(function (error) {
                    if (
                      error.response.data.message === "Email is already exist"
                    ) {
                      setWait(false);
                      Swal.fire({
                        icon: "error",
                        title: "REGISTRATION",
                        text: "This email is already in use.",
                        confirmButtonColor: "#2563eb",
                      });
                    } else {
                      setWait(false);
                      Swal.fire({
                        icon: "error",
                        title: "REGISTRATION",
                        text: "Oops.. something went wrong.",
                        confirmButtonColor: "#2563eb",
                      });
                    }
                  });
              } else {
                setWait(false);
                Swal.fire({
                  icon: "error",
                  title: "REGISTRATION",
                  text: "Oops.. Entered MA ID is invalid.",
                  confirmButtonColor: "#2563eb",
                });
              }
            })
            .catch((err) => {
              setWait(false);
              Swal.fire({
                icon: "error",
                title: "REGISTRATION",
                text: "Oops.. Entered MA ID is invalid.",
                confirmButtonColor: "#2563eb",
              });
            });
        } else {
          setWait(false);
          Swal.fire({
            icon: "error",
            title: "REGISTRATION",
            text: validatePassword(data.password),
            confirmButtonColor: "#2563eb",
          });
        }
      } else {
        setWait(false);
        Swal.fire({
          icon: "error",
          title: "REGISTRATION",
          text: "Bank details are required",
          confirmButtonColor: "#2563eb",
        });
      }
    } else {
      setWait(false);
      Swal.fire({
        icon: "error",
        title: "REGISTRATION",
        text: "Passwords do not match",
        confirmButtonColor: "#2563eb",
      });
    }
  };

  const handleBankName = (e) => {
    setBranchList(bList[e.value]);
    setBranchDisable(false);
    setBankName(e.name);
  };

  const handleBranchName = (e) => {
    setBranchList(bList[e.bankvalue]);
    setBranchDisable(false);
    setBranchName(e.label);
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 45,
      minHeight: 35,
    }),
  };

  return (
    <div>
      <section class="lg:mt-28 mt-16">
        <div className="py-3 bg-gray-400 md:text-lg text-sm text-white">
          <div className="container items-center mx-auto md:px-12 uppercase text-sm md:text-base font-medium">
            <Link to={"/"}>
              <span className="mr-1 hover:underline cursor-pointer">HOME</span>
            </Link>{" "}
            {hCategory && " > "} <span className="mr-1 ml-1">REGISTRATION</span>
          </div>
        </div>
        <hr />
        <div className="md:py-6 mt-4 container mx-auto md:px-12">
          <div class="w-full">
            <div class="space-y-4 md:space-y-6 lg:pr-28 mt-12 md:mt-0">
              <h1 class="text-3xl font-bold leading-tight tracking-tight">
                REGISTRATION DETAILS
              </h1>
              <hr />
              <form
                class="space-y-4 md:space-y-6 md:w-8/12 w-full"
                onSubmit={handleSubmit(onUserRegister)}
              >
                <div>
                  <label for="firstName" class="block mb-2 text-sm font-medium">
                    Referrer MA ID
                  </label>
                  <input
                    {...register("refferrerID")}
                    type="refferrerID"
                    name="refferrerID"
                    id="refferrerID"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div>
                  <label for="firstName" class="block mb-2 text-sm font-medium">
                    First Name
                  </label>
                  <input
                    {...register("firstName")}
                    type="firstName"
                    name="firstName"
                    id="firstName"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div>
                  <label for="lastName" class="block mb-2 text-sm font-medium">
                    Last Name
                  </label>
                  <input
                    {...register("lastName")}
                    type="lastName"
                    name="lastName"
                    id="lastName"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div>
                  <label for="nic" class="block mb-2 text-sm font-medium">
                    Date of Birth
                  </label>
                  <input
                    {...register("dob")}
                    type="date"
                    name="dob"
                    id="dob"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div>
                  <label for="nic" class="block mb-2 text-sm font-medium">
                    National Identity Card
                  </label>
                  <input
                    {...register("nic")}
                    type="nic"
                    name="nic"
                    id="nic"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div>
                  <label for="email" class="block mb-2 text-sm font-medium">
                    Email
                  </label>
                  <input
                    {...register("email")}
                    type="email"
                    name="email"
                    id="email"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div>
                  <label for="phone" class="block mb-2 text-sm font-medium">
                    Mobile No 1
                  </label>
                  <input
                    {...register("phone")}
                    type="phone"
                    name="phone"
                    id="phone"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    placeholder=""
                    required
                  />
                </div>
                <div>
                  <label for="mobile" class="block mb-2 text-sm font-medium">
                    Mobile No 2
                  </label>
                  <input
                    {...register("mobile")}
                    type="mobile"
                    name="mobile"
                    id="mobile"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    placeholder=""
                    required
                  />
                </div>
                <div>
                  <label for="password" class="block mb-2 text-sm font-medium">
                    Password
                  </label>
                  <input
                    {...register("password")}
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div className="text-sm text-gray-400">
                  * Your password must be at least 8 characters.
                  <br />* Your password must contain at least one letter.
                  <br />* Your password must contain at least one digit.
                </div>
                <div>
                  <label
                    for="repassword"
                    class="block mb-2 text-sm font-medium"
                  >
                    Confirm Password
                  </label>
                  <input
                    {...register("repassword")}
                    type="password"
                    name="repassword"
                    id="repassword"
                    placeholder="••••••••"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div>
                  <label
                    for="repassword"
                    class="block mb-2 text-sm font-medium"
                  >
                    Address
                  </label>
                  <input
                    {...register("address")}
                    type="address"
                    name="address"
                    id="address"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div>
                  <label
                    for="repassword"
                    class="block mb-2 text-sm font-medium"
                  >
                    City
                  </label>
                  <input
                    {...register("city")}
                    type="city"
                    name="city"
                    id="city"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div>
                  <label
                    for="repassword"
                    class="block mb-2 text-sm font-medium"
                  >
                    Province
                  </label>
                  <input
                    {...register("province")}
                    type="province"
                    name="province"
                    id="province"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div>
                  <label
                    for="repassword"
                    class="block mb-2 text-sm font-medium"
                  >
                    Landmark
                  </label>
                  <input
                    {...register("landmark")}
                    type="landmark"
                    name="landmark"
                    id="landmark"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div>
                  <label
                    for="repassword"
                    class="block mb-2 text-sm font-medium"
                  >
                    Postal Code
                  </label>
                  <input
                    {...register("postcode")}
                    type="postcode"
                    name="postcode"
                    id="postcode"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div>
                  <label
                    for="repassword"
                    class="block mb-2 text-sm font-medium"
                  >
                    Name of Bank
                  </label>
                  <Select
                    styles={customStyles}
                    placeholder=""
                    onChange={handleBankName}
                    options={bankList}
                  />
                </div>
                <div>
                  <label
                    for="repassword"
                    class="block mb-2 text-sm font-medium"
                  >
                    Branch Name
                  </label>
                  <Select
                    styles={customStyles}
                    placeholder=""
                    onChange={handleBranchName}
                    options={branchList}
                    isDisabled={isBranchDisable}
                  />
                </div>
                <div>
                  <label
                    for="repassword"
                    class="block mb-2 text-sm font-medium"
                  >
                    Account Number
                  </label>
                  <input
                    {...register("accountNumber")}
                    type="accountNumber"
                    name="accountNumber"
                    id="accountNumber"
                    class="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    required
                  />
                </div>
                <div class="flex items-start">
                  <div class="flex items-center h-5">
                    <input
                      onInvalid={(e) =>
                        e.target.setCustomValidity(
                          "You need to agree to the terms and conditions before proceeding."
                        )
                      }
                      onInput={(e) => e.target.setCustomValidity("")}
                      id="terms"
                      aria-describedby="terms"
                      type="checkbox"
                      class="w-4 h-4 borderfocus:ring-3 focus:ring-blue-600"
                      required
                    />
                  </div>
                  <div>
                    {errorMessage && (
                      <p className="error -mt-4 text-red-400">
                        {" "}
                        {errorMessage}{" "}
                      </p>
                    )}
                  </div>
                  <div class="ml-3 text-sm">
                    <label for="terms" class="font-light">
                      I accept the terms and practices outlined in the{" "}
                      <Link
                        class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                        to={"/privacy-policy"}
                      >
                        privacy policy.
                      </Link>
                    </label>
                  </div>
                </div>
                {wait ? (
                  <button
                    type="submit"
                    disabled= {true}
                    class="w-1/2 bg-blue-400 hover:bg-blue-400 text-white cursor-not-allowed hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Register
                  </button>
                ) : (
                  <button
                    type="submit"
                    class="w-1/2 bg-blue-600 hover:bg-blue-600 text-white hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Register
                  </button>
                )}
                <div className="h-5"></div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Register;
