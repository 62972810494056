
export default function AfterSalePolicy() {

    return <div className="lg:mt-28 mt-16">
        <section className="py-10 container mx-auto md:px-12">
            <div class="border rounded-lg ">
                <div class="items-center gap-8">
                    {/* <div class="bg-cover bg-no-repeat bg-center border rounded-lg py-36" style={{ backgroundImage: `url(${logo1})` }}>
                        <div class="container mx-auto md:px-12 text-center">
                            <h1 class="text-6xl text-gray-800 font-medium mb-4 capitalize">
                                Contact Us
                            </h1>
                        </div>
                    </div>
                    <div className="w-full flex justify-center py-8">
                        <div className="w-1/2 text-center">
                            <p class="mt-4 text-gray-600 text-lg">Onet Market envisions becoming a leading direct selling company that offers premium lifestyle products with a strong commitment to environmental sustainability. Our mission is to provide eco-friendly solutions to our customers while maintaining a high standard of quality, at the most affordable prices!</p>
                        </div>
                    </div> */}
                    <div class="w-full">
                        <section class="bg-white">
                            <div class="py-8 lg:py-16 px-4 mx-auto">
                                <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900">After Sale policy</h2>
                                <div className="flex justify-center w-full mt-8">
                                    <p class="font-light text-center md:w-1/2 text-gray-500 sm:text-lg">1. Certain products may be covered by a manufacturer's warranty. Refer to the product documentation for warranty details.</p>
                                </div>
                                <div className="flex justify-center w-full">
                                    <p class="mt-8 font-light text-center md:w-1/2 text-gray-500 sm:text-lg">2. Report any missing items or order discrepancies within 2 days of receiving your order. We will investigate and resolve the issue promptly.</p>
                                </div>
                                <div className="flex justify-center w-full">
                                    <p class="mt-8 font-light text-center md:w-1/2 text-gray-500 sm:text-lg">3. Our customer support team is available to assist you with any post-sale inquiries or concerns.</p>
                                </div>
                                <div className="flex justify-center w-full">
                                    <p class="mt-8 font-light text-center md:w-1/2 text-gray-500 sm:text-lg">4. Refer to product care instructions to ensure the longevity of your purchase. Contact us for guidance on product maintenance if needed.</p>
                                </div>
                                <div className="flex justify-center w-full">
                                    <p class="mt-8 font-light text-center md:w-1/2 text-gray-500 sm:text-lg">5. If you receive a damaged or defective item, contact us immediately with supporting evidence (photos). We will provide a prepaid return label and promptly send a replacement or issue a refund.</p>
                                </div>
                                <div className="flex justify-center w-full">
                                    <p class="mt-8 mb-16 font-light text-center md:w-1/2 text-gray-500 sm:text-lg">6. This after-sale policy is subject to change. Check our website for the most up-to-date information.</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    </div>
}