import React, { useContext, useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import { styled, alpha } from "@mui/material/styles";
import { CategoryContext } from "../../contexts/CategoryContext";
import { Link, useNavigate } from "react-router-dom";

function Categories() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [categoryList, setCategoryList] = useState([]);
  const { categories, loading } = useContext(CategoryContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const navigateToCategory = (category) => {
    let path = `/category/${category.replace(/ /g, "-")}`;
    navigate(path);
    setAnchorEl(null);
  };

  useEffect(() => {
    setCategoryList(categories);
  }, [loading]);

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <>
      <div
        id="dropdown-button"
        data-dropdown-toggle="dropdown"
        class="flex-shrink-0 cursor-pointer z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-l-lg hover:bg-gray-200 focus:ring-4 focus:outline-none"
        type="button"
        onClick={handleClick}
      >
        Categories{" "}
        <svg
          aria-hidden="true"
          class="w-4 h-4 ml-1"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
      {categoryList.lenght !== 0 && (
        <StyledMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {/* <MenuItem disableRipple> My Profile</MenuItem>
          <MenuItem>My Orders</MenuItem>
          <MenuItem>My Payments</MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem>Logout</MenuItem> */}
          {categoryList.map((element) => {
            if (element.head) {
            } else {
              return (
                <MenuItem onClick={()=>{navigateToCategory(element.name)}}>{element.name}</MenuItem>
              );
            }
          })}
        </StyledMenu>
      )}
      {/* <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-10 py-2 px-2 float-left list-none text-left w-60 rounded shadow-lg mt-1"
        }
        style={{ minWidth: "12rem" }}
      >
        <div className="h-0 border border-solid border-t-1 border-blue-600 opacity-25" />
        <div
                ref={btnDropdownRef1}
                onClick={() => {
                    openDropdownPopover1();
                    console.log("test....")
                  }}
          className={
            "text-sm py-2 flex justify-between px-4 cursor-pointer font-normal w-full whitespace-no-wrap bg-transparent text-gray-800" 
          }
        >
          <div>
          My Orders
          </div>
          {'>'}
        </div>
        <div className="h-0 border border-solid border-t-0 border-gray-500 opacity-25" />
        <div
          className={
            "text-sm py-2 flex justify-between px-4 cursor-pointer font-normal w-full whitespace-no-wrap bg-transparent hover:text-black hover:bg-slate-200 text-gray-800" 
          }
        >
          <div>
          My Orders
          </div>
          {'>'}
        </div>
        <div className="h-0 border border-solid border-t-0 border-gray-500 opacity-25" />
      </div>
      <div
        ref={popoverDropdownRef1}

        className={
          (dropdownPopoverShow1 ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left list-none text-left w-60 rounded shadow-lg mt-1"
        }
        style={{ minWidth: "12rem" }}
      >
        <div className="h-0 border border-solid border-t-1 border-blue-600 opacity-25" />
        <div
          className={
            "text-sm py-2 flex justify-between px-4 cursor-pointer font-normal w-full whitespace-no-wrap bg-transparent text-gray-800" 
          }
        >
          <div>
          TEST
          </div>
          {'>'}
        </div>
        <div className="h-0 border border-solid border-t-0 border-gray-500 opacity-25" />
        <div
          className={
            "text-sm py-2 flex justify-between px-4 cursor-pointer font-normal w-full whitespace-no-wrap bg-transparent hover:text-black hover:bg-slate-200 text-gray-800" 
          }
        >
          <div>
          My Orders
          </div>
          {'>'}
        </div>
      </div> */}
    </>
  );
}

export default Categories;
