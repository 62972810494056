import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Order from "../components/Order";
import { OrderContext } from "../contexts/OrderContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import Pagination from "@mui/material/Pagination";
import API from "../components/api";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function Orders() {
  const Swal = require("sweetalert2");
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(5);
  const [status, setStatus] = useState("");
  const { token, getAuthToken, loading } = useContext(AuthContext);

  const [page, setPage] = React.useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [filteredOrders, setFilteredOrders] = useState([]);

  const options = [
    { value: "all", label: "All Orders" },
    { value: "unpaid", label: "Awaiting Payment" },
    { value: "processing", label: "Processing" },
    { value: "shipped", label: "Awaiting Delivery" },
    { value: "completed", label: "Completed" },
    { value: "return", label: "Return" },
    { value: "cancel", label: "Cancelled" },
    // { value: "refund", label: "Vanilla" },
  ];

  const { orders, fetchOrders } = useContext(OrderContext);

  const navigate = useNavigate();

  useEffect(() => {
    getAuthToken();
    if (!loading){
      if (!token) {
        navigate("/");
      } else {
        fetchOrders(token.userId);
        initialize();
      }
    }
  }, [token, orders.length, loading]);

  const initialize = () => {
    setFilteredOrders(orders);
    if (!orders) {
      return (
        <section className="h-screen flex justify-center items-center">
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </section>
      );
    } else {
      var no = Math.ceil(orders.length / 5);
      setNoOfPages(no);
    }
  };

  const cancelOrder = (id) => {
    Swal.fire({
      title: "Cancel Order?",
      text: "Are you sure you want to cancel this order.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2563eb",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        API.put(`/api/order/status`, {
          id: id,
          status: "cancel",
        })
          .then((res) => {
            if (res.data) {
              fetchOrders(token.userId);
              if (status !== "all") {
                let data = orders.filter((item) => {
                  return item.status === status;
                });
                setFilteredOrders(data);
                var no = Math.ceil(data.length / 5);
                setNoOfPages(no);
              } else {
                setFilteredOrders(orders);
                var no = Math.ceil(orders.length / 5);
                setNoOfPages(no);
              }
              setPage(1);
              setStart(0);
              setEnd(5);

              Swal.fire({
                title: "Cancelled!",
                text: "Your order has been cancelled.",
                icon: "success",
                confirmButtonColor: "#2563eb",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handleChange = (event, value) => {
    setStart(5 * (value - 1));
    setEnd(value * 5);
    setPage(value);
  };

  const handleSelect = (e) => {
    setStatus(e.value);
    if (e.value !== "all") {
      let data = orders.filter((item) => {
        return item.status === e.value;
      });
      setFilteredOrders(data);
      var no = Math.ceil(data.length / 5);
      setNoOfPages(no);
    } else {
      setFilteredOrders(orders);
      var no = Math.ceil(orders.length / 5);
      setNoOfPages(no);
    }
    setPage(1);
    setStart(0);
    setEnd(5);
  };

  return (
    <div className="lg:mt-28 mt-16">
      <div className="py-3 bg-gray-400 md:text-lg text-sm text-white">
        <div className="container mx-auto md:px-12 uppercase text-sm md:text-base font-medium">
          <Link to={"/"}>
            <span className="mr-1 hover:underline cursor-pointer">HOME</span>
          </Link>{" "}
          {" > "} <span className="mr-1 ml-1">My Account</span>
          {" > "} <span className="mr-1 ml-1">My Orders</span>
        </div>
      </div>
      <div className="items-center container mx-auto my-3 md:px-12 py-4 md:py-6">
        <div className="bg-white">
          <div className=" pb-4 flex justify-between">
            <div className="hidden w-full md:flex text-2xl">Order History</div>
            <div className="w-full md:w-96 flex items-center">
              Status:{" "}
              <Select
                className="w-full ml-2 text-sm"
                options={options}
                onChange={handleSelect}
                defaultValue={{ value: "all", label: "All Orders" }}
              />
            </div>
          </div>
          <hr />
          <div>
            {filteredOrders.length != 0 ? (
              filteredOrders.slice(start, end).map((order) => {
                return (
                  <Order
                    order={order}
                    key={order.id}
                    cancelOrder={cancelOrder}
                  />
                );
              })
            ) : (
              <section className="h-[36rem] mt-8 bg-white border border-gray-200 rounded-lg shadow flex justify-center items-center">
                No orders were found.
              </section>
            )}
          </div>
          <Pagination
            className="mt-8 mb-12"
            count={noOfPages}
            page={page}
            onChange={handleChange}
            color="primary"
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
}

export default Orders;
