import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2';
import API from "../api";

export default function ResetPassword() {
    const { token, loading } = useContext(AuthContext);
    const { register, reset, handleSubmit } = useForm();
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading) {
            if (!token) {
                navigate("/");
            }
        }
    }, [token, loading]);

    const onPasswordChange = (data) => {
        if (token.role == "user") {
            const newData = {
                ...data,
                userId: token.userId,
            }
            if (data.password === data.repassword) {
                API.put("/api/user/password", newData)
                    .then(function (response) {
                        if (response.data.data) {
                            Swal.fire({
                                icon: 'success',
                                title: 'MY DETAILS',
                                text: 'Password updated succesfully.',
                                confirmButtonColor: "#2563eb"
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'MY DETAILS',
                                text: 'Oops.. something went wrong.',
                                confirmButtonColor: "#2563eb"
                            })
                        }
                    })
                    .catch(function (error) {
                        if (error.response.data.error === "password unmatched.") {
                            Swal.fire({
                                icon: 'error',
                                title: 'MY DETAILS',
                                text: 'Your old password is entered incorrectly.',
                                confirmButtonColor: "#2563eb"
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'MY DETAILS',
                                text: 'Oops.. something went wrong.',
                                confirmButtonColor: "#2563eb"
                            })
                        }
                    });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'MY DETAILS',
                    text: 'New passwords do not match',
                    confirmButtonColor: "#2563eb"
                })
            }

        } else {
            const newData = {
                ...data,
                userId: token.userId,
            }
            if (data.password === data.repassword) {
                API.put("/api/customer/password", newData)
                    .then(function (response) {
                        if (response.data.data) {
                            Swal.fire({
                                icon: 'success',
                                title: 'MY DETAILS',
                                text: 'Password updated succesfully.',
                                confirmButtonColor: "#2563eb"
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'MY DETAILS',
                                text: 'Oops.. something went wrong.',
                                confirmButtonColor: "#2563eb"
                            })
                        }
                    })
                    .catch(function (error) {
                        console.log(error)
                        if (error.response.data.error === "password unmatched.") {
                            Swal.fire({
                                icon: 'error',
                                title: 'MY DETAILS',
                                text: 'Your old password is incorrect.',
                                confirmButtonColor: "#2563eb"
                            })
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'MY DETAILS',
                                text: 'Oops.. something went wrong.',
                                confirmButtonColor: "#2563eb"
                            })
                        }
                    });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'MY DETAILS',
                    text: 'New passwords do not match',
                    confirmButtonColor: "#2563eb"
                })
            }

        }
    }

    return (
        <section>
            <div class="col-span-9 shadow rounded px-6 pt-5 pb-7">
                <h4 class="text-lg font-medium capitalize mb-4">
                    Change Password
                </h4>
                <form onSubmit={handleSubmit(onPasswordChange)} class="space-y-4 text-sm">
                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <label for="first"> Current Password</label>
                            <input {...register("oldpassword")} className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" type="password" name="oldpassword" id="oldpassword" />
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <label for="first">New Password</label>
                            <input {...register("password")} className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" type="password" name="password" id="password" />
                        </div>
                    </div>
                    <div class="grid grid-cols-2 gap-4">
                        <div>
                            <label for="first">Confirm Password</label>
                            <input {...register("repassword")} className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600" type="password" name="repassword" id="repassword" />
                        </div>
                    </div>
                    <div class="mt-4">
                        <button type="submit"
                            class="py-3 px-4 text-center text-white bg-blue-600 rounded-md  font-medium">save
                            changes</button>
                    </div>
                </form>
            </div>
        </section>
    );
}