import React, { useContext, useEffect, useState } from "react";
import OrderItem from "../components/OrderItem";
import { CartContext } from "../contexts/CartContext";
import { useForm } from "react-hook-form";
import API from "../components/api";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import md5 from "crypto-js/md5";
const Swal = require("sweetalert2");

const Checkout = () => {
  const {
    cart,
    total,
    clearCart,
    shippingFee,
    totalDirectCommision,
    totalIndirectCommision,
  } = useContext(CartContext);
  const { register, reset, handleSubmit } = useForm();
  const navigate = useNavigate();
  const registrationFee = 500;

  const { token, getAuthToken } = useContext(AuthContext);
  const [wait, setWait] = useState(false);

  const [user, setUser] = useState(null);
  const [orderId, setOrderId] = useState(null);

  useEffect(() => {
    getAuthToken();
    if (!token) {
      navigate("/");
    } else {
      setUserDetails(token.userId);
    }
  }, [token]);

  const setUserDetails = async (id) => {
    let found = false;
    await API.get(`/api/user/${id}`)
      .then((res) => {
        if (res.data.data) {
          let user = res.data.data[0];
          if (user) {
            found = true;
            setUser(user);
            reset({
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              phone: user.phone,
              mobile: user.mobile,
              address: user.address,
              city: user.city,
              province: user.province,
              landmark: user.landmark,
              postcode: user.postcode,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    if (!found) {
      await API.get(`/api/customer/${id}`)
        .then((res) => {
          if (res.data.data) {
            found = true;
            let user = res.data.data[0];
            setUser(user);
            reset({
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              phone: user.phone,
              mobile: user.mobile,
              address: user.address,
              city: user.city,
              province: user.province,
              landmark: user.landmark,
              postcode: user.postcode,
            });
          }
        })
        .catch((err) => {});
    }
  };

  var payment = null;

  async function initiatePayment(data, orderId) {
    setOrderId(orderId);
    await API.get(`/api/order/payhere/${data.totalAmount}/${orderId}`)
      .then((res) => {
        if (res.data.data) {
          // Put the payment variables here
          var items = "";
          cart.forEach((element) => {
            items += element.title + ", ";
          });

          items = items.substring(0, items.length - 2);

          payment = {
            sandbox: false, // if the account is sandbox or real
            merchant_id: res.data.data.merchantId, // Replace your Merchant ID
            return_url: window.location.origin + "/myaccount/profile/orders",
            cancel_url: window.location.origin,
            notify_url:
              "https://ostore.rapidbyte.org/v1/api/order/payhere",
            order_id: orderId,
            items: items,
            amount:
              user && user.status === "pending"
                ? total + shippingFee + registrationFee
                : total + shippingFee,
            currency: "LKR",
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            phone: data.phone,
            address: data.address,
            city: data.city,
            country: "Sri Lanka",
            hash: res.data.data.hash,
          };
          pay();
        }
      })
      .catch((err) => {});
  }

  window.payhere.onCompleted = function onCompleted(orderId) {
    clearCart();
    let path = `/myaccount/profile/orders`;
    navigate(path);
  };

  // Called when user closes the payment without completing
  window.payhere.onDismissed = function onDismissed() {
    setWait(false);
    if (orderId) {
      API.delete(`/api/order/${orderId}`)
        .then((res) => {

        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Called when error happens when initializing payment such as invalid parameters
  window.payhere.onError = function onError(error) {
    // Note: show an error page
    console.log("Error:" + error);
    setWait(false);
  };

  function pay() {
    window.payhere.startPayment(payment);
  }

  const onSubmit = (data) => {
    setWait(true);
    if (data.paymentType === "dbt") {
      let newuser = false;
      if (!user) {
        setUserDetails(token.userId);
      }
      if (user && user.role == "user") {
        API.get(`/api/user/reflist/${10}/${user.refferrerID}`).then(function (
          response
        ) {
          var commissionIds = response.data;
          API.get(`/api/user/${user.refferrerID}`)
            .then(function (response) {
              let newData = null;
              if (user.status === "pending") {
                newuser = true;
                newData = {
                  ...data,
                  orderedDate: new Date(),
                  cart: cart,
                  orderAmount: total,
                  totalAmount: total + shippingFee + registrationFee,
                  shippingFee: shippingFee,
                  userId: user.userId,
                  uplinerId: user.refferrerID,
                  refId: response.data.data[0].refferrerID,
                  totalDirectCommision: totalDirectCommision,
                  totalIndirectCommision: totalIndirectCommision,
                  registrationFee: registrationFee,
                  commissionIds: commissionIds,
                };
              } else {
                newData = {
                  ...data,
                  orderedDate: new Date(),
                  cart: cart,
                  orderAmount: total,
                  totalAmount: total + shippingFee,
                  shippingFee: shippingFee,
                  userId: user.userId,
                  uplinerId: user.refferrerID,
                  refId: response.data.data[0].refferrerID,
                  totalDirectCommision: totalDirectCommision,
                  totalIndirectCommision: totalIndirectCommision,
                  commissionIds: commissionIds,
                };
              }
              if (response.data.data[0].userId) {
                API.post("/api/order/", newData)
                  .then(function (response) {
                    if (response.data.data.id) {
                      clearCart();
                      if (newuser) {
                        navigateToOrderSuccess(
                          response.data.data.id,
                          total + shippingFee + registrationFee
                        );
                      } else {
                        navigateToOrderSuccess(
                          response.data.data.id,
                          total + shippingFee
                        );
                      }
                    }
                  })
                  .catch(function (error) {
                    Swal.fire({
                      icon: "error",
                      title: "CHECKOUT",
                      text: "Oops.. something went wrong.",
                      confirmButtonColor: "#2563eb",
                    });
                  });
              }
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "CHECKOUT",
                text: "Oops.. something went wrong.",
                confirmButtonColor: "#2563eb",
              });
            });
        });
      } else {
        var reffererId = window.sessionStorage.getItem("reffererId");
        if (reffererId) {
          API.get(`/api/user/reflist/${10}/${reffererId.replace(/^"(.*)"$/, "$1")}`).then(function (
            response
          ) {
            var commissionIds = response.data;
            API.get(`/api/user/${reffererId.replace(/^"(.*)"$/, "$1")}`)
              .then(function (response) {
                if (response.data.data.length > 0) {
                  if (response.data.data[0].userId) {
                    let newData = {
                      ...data,
                      orderedDate: new Date(),
                      cart: cart,
                      orderAmount: total,
                      totalAmount: total + shippingFee,
                      shippingFee: shippingFee,
                      userId: user.userId,
                      uplinerId: reffererId.replace(/^"(.*)"$/, "$1"),
                      refId: response.data.data[0].refferrerID,
                      totalDirectCommision: totalDirectCommision,
                      totalIndirectCommision: totalIndirectCommision,
                      commissionIds: commissionIds,
                    };
                    API.post("/api/order/", newData)
                      .then(function (response) {
                        if (response.data.data.id) {
                          clearCart();
                          navigateToOrderSuccess(
                            response.data.data.id,
                            total + shippingFee
                          );
                        }
                      })
                      .catch(function (error) {
                        Swal.fire({
                          icon: "error",
                          title: "CHECKOUT",
                          text: "Oops.. something went wrong.",
                          confirmButtonColor: "#2563eb",
                        });
                      });
                  }
                } else {
                  let newData = {
                    ...data,
                    orderedDate: new Date(),
                    cart: cart,
                    orderAmount: total,
                    totalAmount: total + shippingFee,
                    shippingFee: shippingFee,
                    userId: user.userId,
                    totalDirectCommision: totalDirectCommision,
                    totalIndirectCommision: totalIndirectCommision,
                  };
                  API.post("/api/order/", newData)
                    .then(function (response) {
                      if (response.data.data.id) {
                        clearCart();
                        navigateToOrderSuccess(
                          response.data.data.id,
                          total + shippingFee
                        );
                      }
                    })
                    .catch(function (error) {
                      Swal.fire({
                        icon: "error",
                        title: "CHECKOUT",
                        text: "Oops.. something went wrong.",
                        confirmButtonColor: "#2563eb",
                      });
                    });
                }
              })
              .catch(function (error) {
                Swal.fire({
                  icon: "error",
                  title: "CHECKOUT",
                  text: "Oops.. something went wrong.",
                  confirmButtonColor: "#2563eb",
                });
              });
          });
        } else {
          let newData = {
            ...data,
            orderedDate: new Date(),
            cart: cart,
            orderAmount: total,
            totalAmount: total + shippingFee,
            shippingFee: shippingFee,
            userId: user.userId,
            totalDirectCommision: totalDirectCommision,
            totalIndirectCommision: totalIndirectCommision,
          };
          API.post("/api/order/", newData)
            .then(function (response) {
              if (response.data.data.id) {
                clearCart();
                navigateToOrderSuccess(
                  response.data.data.id,
                  total + shippingFee
                );
              }
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "CHECKOUT",
                text: "Oops.. something went wrong.",
                confirmButtonColor: "#2563eb",
              });
            });
        }
      }
    } else if (data.paymentType == "payhere") {
      let newuser = false;
      if (!user) {
        setUserDetails(token.userId);
      }
      if (user && user.role == "user") {
        API.get(`/api/user/reflist/${10}/${user.refferrerID}`).then(function (
          response
        ) {
          var commissionIds = response.data;
          API.get(`/api/user/${user.refferrerID}`)
            .then(function (response) {
              let newData = null;
              if (user.status === "pending") {
                newuser = true;
                newData = {
                  ...data,
                  orderedDate: new Date(),
                  cart: cart,
                  orderAmount: total,
                  totalAmount: total + shippingFee + registrationFee,
                  shippingFee: shippingFee,
                  userId: user.userId,
                  uplinerId: user.refferrerID,
                  refId: response.data.data[0].refferrerID,
                  totalDirectCommision: totalDirectCommision,
                  totalIndirectCommision: totalIndirectCommision,
                  registrationFee: registrationFee,
                  commissionIds: commissionIds,
                };
              } else {
                newData = {
                  ...data,
                  orderedDate: new Date(),
                  cart: cart,
                  orderAmount: total,
                  totalAmount: total + shippingFee,
                  shippingFee: shippingFee,
                  userId: user.userId,
                  uplinerId: user.refferrerID,
                  refId: response.data.data[0].refferrerID,
                  totalDirectCommision: totalDirectCommision,
                  totalIndirectCommision: totalIndirectCommision,
                  commissionIds: commissionIds,
                };
              }
              if (response.data.data[0].userId) {
                API.post("/api/order/", newData)
                  .then(function (response) {
                    if (response.data.data.id) {
                      if (newuser) {
                        initiatePayment(newData, response.data.data.id);
                      } else {
                        initiatePayment(newData, response.data.data.id);
                      }
                    }
                  })
                  .catch(function (error) {
                    Swal.fire({
                      icon: "error",
                      title: "CHECKOUT",
                      text: "Oops.. something went wrong.",
                      confirmButtonColor: "#2563eb",
                    });
                  });
              }
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "CHECKOUT",
                text: "Oops.. something went wrong.",
                confirmButtonColor: "#2563eb",
              });
            });
        });
      } else {
        var reffererId = window.sessionStorage.getItem("reffererId");
        if (reffererId) {
          API.get(`/api/user/reflist/${10}/${reffererId.replace(/^"(.*)"$/, "$1")}`).then(function (
            response
          ) {
            var commissionIds = response.data;
            API.get(`/api/user/${reffererId.replace(/^"(.*)"$/, "$1")}`)
              .then(function (response) {
                if (response.data.data.length > 0) {
                  if (response.data.data[0].userId) {
                    let newData = {
                      ...data,
                      orderedDate: new Date(),
                      cart: cart,
                      orderAmount: total,
                      totalAmount: total + shippingFee,
                      shippingFee: shippingFee,
                      userId: user.userId,
                      uplinerId: reffererId.replace(/^"(.*)"$/, "$1"),
                      refId: response.data.data[0].refferrerID,
                      totalDirectCommision: totalDirectCommision,
                      totalIndirectCommision: totalIndirectCommision,
                      commissionIds: commissionIds,
                    };
                    API.post("/api/order/", newData)
                      .then(function (response) {
                        if (response.data.data.id) {
                          initiatePayment(newData, response.data.data.id);
                        }
                      })
                      .catch(function (error) {
                        Swal.fire({
                          icon: "error",
                          title: "CHECKOUT",
                          text: "Oops.. something went wrong.",
                          confirmButtonColor: "#2563eb",
                        });
                      });
                  }
                } else {
                  let newData = {
                    ...data,
                    orderedDate: new Date(),
                    cart: cart,
                    orderAmount: total,
                    totalAmount: total + shippingFee,
                    shippingFee: shippingFee,
                    userId: user.userId,
                    totalDirectCommision: totalDirectCommision,
                    totalIndirectCommision: totalIndirectCommision,
                  };
                  API.post("/api/order/", newData)
                    .then(function (response) {
                      if (response.data.data.id) {
                        initiatePayment(newData, response.data.data.id);
                      }
                    })
                    .catch(function (error) {
                      Swal.fire({
                        icon: "error",
                        title: "CHECKOUT",
                        text: "Oops.. something went wrong.",
                        confirmButtonColor: "#2563eb",
                      });
                    });
                }
              })
              .catch(function (error) {
                Swal.fire({
                  icon: "error",
                  title: "CHECKOUT",
                  text: "Oops.. something went wrong.",
                  confirmButtonColor: "#2563eb",
                });
              });
          });
        } else {
          let newData = {
            ...data,
            orderedDate: new Date(),
            cart: cart,
            orderAmount: total,
            totalAmount: total + shippingFee,
            shippingFee: shippingFee,
            userId: user.userId,
            totalDirectCommision: totalDirectCommision,
            totalIndirectCommision: totalIndirectCommision,
          };
          API.post("/api/order/", newData)
            .then(function (response) {
              if (response.data.data.id) {
                initiatePayment(newData, response.data.data.id);
              }
            })
            .catch(function (error) {
              Swal.fire({
                icon: "error",
                title: "CHECKOUT",
                text: "Oops.. something went wrong.",
                confirmButtonColor: "#2563eb",
              });
            });
        }
      }
    }
  };

  const navigateToOrderSuccess = (id, total) => {
    let path = `/checkout/order/completed/${id}`;
    navigate(path, {
      state: {
        total: total,
      },
    });
  };

  return (
    <div className="lg:mt-28 mt-16">
      <div className="py-3 bg-gray-400 md:text-lg text-sm text-white">
        <div className="container mx-auto md:px-12 uppercase text-sm md:text-base font-medium">
          <Link to={"/"}>
            <span className="mr-1 hover:underline cursor-pointer">HOME</span>
          </Link>{" "}
          {" > "} <span className="mr-1 ml-1">My Account</span>
          {" > "} <span className="mr-1 ml-1">Checkout</span>
        </div>
      </div>
      <div className="container mx-auto md:px-12 my-3 py-4 md:py-6">
        <form
          className="justify-center   w-full "
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col w-full px-0 mx-auto md:flex-row">
            <div className="flex flex-col md:w-full">
              <h2 className="mb-4 py-4 font-bold md:text-xl text-heading ">
                Shipping Address
              </h2>
              <hr />
              <div className="mt-3">
                <div className="space-x-0 lg:flex lg:space-x-4">
                  <div className="w-full lg:w-1/2">
                    <label
                      for="firstName"
                      className="block mb-3 text-sm font-semibold text-gray-500"
                    >
                      First Name
                    </label>
                    <input
                      {...register("firstName")}
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      required
                      className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    />
                  </div>
                  <div className="w-full lg:w-1/2 ">
                    <label
                      for="firstName"
                      className="block mb-3 text-sm font-semibold text-gray-500"
                    >
                      Last Name
                    </label>
                    <input
                      {...register("lastName")}
                      name="lastName"
                      type="text"
                      required
                      placeholder="Last Name"
                      className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    />
                  </div>
                </div>
                <div className="mt-3 space-x-0 lg:flex lg:space-x-4">
                  <div className="w-full lg:w-1/2">
                    <label
                      for="Email"
                      className="block mb-3 text-sm font-semibold text-gray-500"
                    >
                      Email
                    </label>
                    <input
                      {...register("email")}
                      name="email"
                      type="text"
                      required
                      placeholder="Email"
                      className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    />
                  </div>
                  <div className="w-full lg:w-1/2 ">
                    <label
                      for="phone"
                      className="block mb-3 text-sm font-semibold text-gray-500"
                    >
                      Mobile No 1
                    </label>
                    <input
                      {...register("phone")}
                      name="phone"
                      type="text"
                      required
                      placeholder="Phone No 1"
                      className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    />
                  </div>
                  <div className="w-full lg:w-1/2 ">
                    <label
                      for="mobile"
                      className="block mb-3 text-sm font-semibold text-gray-500"
                    >
                      Mobile No 2
                    </label>
                    <input
                      {...register("mobile")}
                      name="mobile"
                      type="text"
                      required
                      placeholder="Phone No 2"
                      className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <div className="w-full">
                    <label
                      for="Address"
                      className="block mb-3 text-sm font-semibold text-gray-500"
                    >
                      Address
                    </label>
                    <textarea
                      {...register("address")}
                      className="w-full px-4 py-3 text-xs border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                      name="address"
                      cols="20"
                      rows="4"
                      required
                      placeholder="Address"
                    ></textarea>
                  </div>
                </div>
                <div className="space-x-0 lg:flex lg:space-x-4">
                  <div className="w-full lg:w-1/2">
                    <label
                      for="city"
                      required
                      className="block mb-3 text-sm font-semibold text-gray-500"
                    >
                      City
                    </label>
                    <input
                      {...register("city")}
                      name="city"
                      type="text"
                      required
                      placeholder="City"
                      className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    />
                  </div>
                  <div className="w-full lg:w-1/2 ">
                    <label
                      for="postcode"
                      className="block mb-3 text-sm font-semibold text-gray-500"
                    >
                      Postcode
                    </label>
                    <input
                      {...register("postcode")}
                      name="postcode"
                      required
                      type="text"
                      placeholder="Post Code"
                      className="w-full px-4 py-3 text-sm border border-gray-300 rounded lg:text-sm focus:outline-none focus:ring-1 focus:ring-blue-600"
                    />
                  </div>
                </div>
                <div className="relative pt-3 xl:pt-6 mb-12">
                  <label
                    for="note"
                    className="block mb-3 text-sm font-semibold text-gray-500"
                  >
                    {" "}
                    Notes (Optional)
                  </label>
                  <textarea
                    {...register("note")}
                    name="note"
                    className="flex items-center w-full px-4 py-3 text-sm border border-gray-300 rounded focus:outline-none focus:ring-1 focus:ring-blue-600"
                    rows="4"
                    placeholder="Notes for delivery"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="flex mt-4 md:mt-0 flex-col w-full ml-0 lg:ml-12 lg:w-2/5">
              <div className="2xl:ps-4 bg-gray-50 md:p-8 p-4">
                <h2 className="text-xl font-bold">Your Order</h2>
                <hr className="mt-2" />
                <div className="mt-6">
                  <div className="flex flex-col space-y-2">
                    <div className="flex justify-between">
                      <div className="font-semibold">Product</div>
                      <div className="font-semibold">Subtotal</div>
                    </div>
                    {cart.map((item) => {
                      return <OrderItem item={item} key={item.id} />;
                    })}
                  </div>
                </div>
                <hr className="my-4" />
                <div className="flex justify-between">
                  <div>Subtotal</div>
                  <div className="font-semibold">{`LKR ${parseFloat(
                    total
                  ).toFixed(2)}`}</div>
                </div>
                <div className="flex justify-between">
                  <div>Shipping</div>
                  <div className="font-semibold">{`LKR ${parseFloat(
                    shippingFee
                  ).toFixed(2)}`}</div>
                </div>

                {user && user.status === "pending" && (
                  <div>
                    <hr className="my-4" />
                    <div className="flex justify-between">
                      <div>Registration Fee</div>
                      <div className="font-semibold">{`LKR ${parseFloat(
                        registrationFee
                      ).toFixed(2)}`}</div>
                    </div>
                    <p className="text-sm font-semibold text-justify pt-2">
                      Note: You need to purchase total amount of LKR 10000.00
                      worth of products to activate your account.
                    </p>
                  </div>
                )}
                <hr className="my-4" />
                {user && user.status === "pending" ? (
                  <div className="flex justify-between">
                    <div>Total</div>
                    <div className="font-semibold">{`LKR ${parseFloat(
                      total + shippingFee + registrationFee
                    ).toFixed(2)}`}</div>
                  </div>
                ) : (
                  <div className="flex justify-between">
                    <div>Total</div>
                    <div className="font-semibold">{`LKR ${parseFloat(
                      total + shippingFee
                    ).toFixed(2)}`}</div>
                  </div>
                )}
                <hr className="my-4" />
                <div className="flex items-center mt-4">
                  <label className="flex items-center text-sm group text-heading">
                    <input
                      required
                      type="radio"
                      {...register("paymentType")}
                      value="dbt"
                      name="paymentType"
                      id="paymentType"
                      className="w-4 h-4 border border-gray-300 rounded focus:outline-none focus:ring-1"
                    />
                    <span className="ml-2 font-semibold">
                      Direct bank transfer
                    </span>
                  </label>
                </div>
                <div className="ml-6 text-sm">
                  Make your payment directly into our bank account. Please use
                  your Order ID as the payment reference. Your order will not be
                  shipped until the funds have cleared in our account.
                </div>
                <div className="flex items-center mt-4">
                  <label className="flex items-center text-sm group text-heading">
                    <input
                      type="radio"
                      {...register("paymentType")}
                      name="paymentType"
                      id="paymentType"
                      value="payhere"
                      className="w-4 h-4 border border-gray-300 rounded focus:outline-none focus:ring-1"
                    />
                    <span className="ml-2 font-semibold">
                      Credit/Debit Card
                    </span>
                  </label>
                </div>
                <div className="ml-6 mt-2">
                  <a href="https://www.payhere.lk" target="_blank">
                    <img
                      src="https://www.payhere.lk/downloads/images/payhere_short_banner.png"
                      alt="PayHere"
                      width="250"
                    />
                  </a>
                </div>
                {wait ? (
                  <div className="mt-4">
                    <input
                      type="submit"
                      className="w-full px-6 py-3 rounded-lg text-white bg-blue-400 cursor-not-allowed"
                      value="Place Order"
                      disabled={true}
                    />
                  </div>
                ) : (
                  <div className="mt-4">
                    <input
                      type="submit"
                      className="w-full px-6 py-3 rounded-lg text-white bg-blue-600 cursor-pointer"
                      value="Place Order"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Checkout;
